app.controller('AnalyticsModalInstanceCtrl', ['$scope', '$q', '$log', '$filter', '$timeout', '$interval', '$modalInstance',
    'requestService', 'notificationService', 'analyticsService', 'cacheService', 'store', 'userSettingsService', 'Nvd3TransformService',
    'TabularTransformService', 'QUERY_TYPE', '$translate',
    function($scope, $q, $log, $filter, $timeout, $interval, $modalInstance, requestService, notificationService, analyticsService, cacheService,
        store, userSettingsService, Nvd3TransformService, TabularTransformService, QUERY_TYPE, $translate) {

        $scope.translations = initTranslations();

        var user = $scope.globals.currentUser.username;
        var STORE_ANALYTICS_KEY = user + '_ANALYTICS_DATA';
        $scope.analytics = analyticsService;
        $scope.modalOpened = true;

        initModal();
        initForm();
        if ($scope.DEBUG) {
            // initData();
        }
        $scope.getMetrics();
        $scope.getTerms();

        function initModal() {
            $scope.onSaveModal = function() {
                closeModal($scope.formData);
            };

            $scope.onCancelModal = function() {
                cancelModal();
            };
        }

        function initForm() {
            var providedFormData;
            if ($scope.modalSearchQuery) {
                providedFormData = $scope.modalSearchQuery;
            } else {
                providedFormData = store.get(STORE_ANALYTICS_KEY);
            }

            //$log.info('RESTORED', stored);
            $scope.formData = (providedFormData) ? providedFormData : {
                relativeTimeInterval: analyticsService.getTimeIntervals()[1],
                absoluteTimeInterval: undefined,
                graphDefinition: undefined,
                metric: undefined,
                series: undefined,
                term: undefined,
                aggregationInterval: undefined,
                filter: []
            };

            $scope.list = {
                metrics: [],
                terms: [],
                filterDefinitions: [] //TODO fields & ops for filter builder
            };

            $scope.onChange = function() {
                store.set(STORE_ANALYTICS_KEY, $scope.formData);
                if (!$scope.isGraphAvailable($scope.formData.graphDefinition)) {
                    $scope.formData.graphDefinition = undefined;
                }
            };

            $scope.filterAlreadySelectedSeries = function(item) {
                return angular.isDefined(item) && item !== $scope.formData.series;
            };

            $scope.isGraphAvailable = function(graphDefinition) {
                if (angular.isUndefined(graphDefinition)) {
                    return false;
                }
                var searchQuery = $scope.formData;
                var isSeriesSelected = angular.isDefined(searchQuery.series);
                var isFlatSelected = angular.isUndefined(searchQuery.series);
                if (graphDefinition.supportSeries === false && isSeriesSelected) {
                    return false;
                }
                if (graphDefinition.supportFlat === false && isFlatSelected) {
                    return false;
                }

                var isTimeSelected = angular.isDefined(searchQuery.aggregationInterval);
                var isTermSelected = angular.isDefined(searchQuery.term);
                var isMetricSelected = angular.isDefined(searchQuery.metric);
                if (angular.isUndefined(graphDefinition.types)) {
                    return false;
                }

                if (isTermSelected) {
                    if (isMetricSelected) {
                        if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_HISTOGRAM) === -1) {
                            return false;
                        }
                    } else {
                        if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_LIST) === -1) {
                            return false;
                        }
                    }
                } else if (isTimeSelected) {
                    if (graphDefinition.types.indexOf(QUERY_TYPE.TIME_HISTOGRAM) === -1) {
                        return false;
                    }
                }

                return true;
            };

            $scope.getMetrics = function() {
                requestService.get(
                    '/api/analytics/metrics',
                    function(data) {
                        if (data) {
                            $scope.list.metrics = data;
                        } else {
                            notificationService.showWarningDialog($scope.translations.errorNoMetrics);
                        }
                    }
                );
            };

            $scope.getTerms = function() {
                requestService.get(
                    '/api/analytics/terms',
                    function(data) {
                        if (data) {
                            $scope.list.terms = data;
                        } else {
                            notificationService.showWarningDialog($scope.translations.errorNoTerms)
                        }
                    }
                );
            };

            $scope.getAggregationInterval = function() {
                return analyticsService.getAggregationInterval();
            };
        }

        function closeModal(data) {
            $scope.modalOnSuccess(data);
            $scope.analyticsModal.close(data);
        }

        function cancelModal() {
            $scope.analyticsModal.dismiss('cancel');
        }

        function initTranslations() {
            var translations = {};
            $translate(['ANALYTICS.ERROR_MESSAGES.NO_METRICS', 'ANALYTICS.ERROR_MESSAGES.NO_TERMS'])
                .then(function(translation) {
                    translations.errorNoMetrics = translation['ANALYTICS.ERROR_MESSAGES.NO_METRICS'];
                    translations.errorNoTerms = translation['ANALYTICS.ERROR_MESSAGES.NO_TERMS'];
                }, function(translationIds) {
                    translations.errorNoMetrics = translationIds['ANALYTICS.ERROR_MESSAGES.NO_METRICS'];
                    translations.errorNoTerms = translationIds['ANALYTICS.ERROR_MESSAGES.NO_TERMS'];
                });
            return translations;
        }
    }
]);
