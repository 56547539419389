app.controller('AlarmsCtrl', ['$scope', '$log', '$filter', 'requestService', 'notificationService', '$modal', '$translate',
    function($scope, $log, $filter, requestService, notificationService, $modal, $translate) {

        $scope.translations = initTranslations();
        init();
        getAlarmStatuses();
        getAlarms();

        function init() {
            $scope.formData = {};
            $scope.statuses = [];

            $scope.table = {
                alarms: [],
                sortColumns: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25,
                totalSize: 0,
                sortBy: [],
                sortColumn: null,
                sortAsc: null,
                offset: 0,
                filterStatus: null,
                columnsInfo: {
                    'time': {displayName: 'TRIGGERED_ON', visible: true},
                    'level': {displayName: 'LEVEL', visible: true},
                    'source': {displayName: 'SOURCE', visible: true},
                    'type': {displayName: 'TYPE', visible: true},
                    'status': {displayName: 'STATUS', visible: true},
                    'acknowledged': {displayName: 'ACKNOWLEDGED', visible: true},
                    'notification': {displayName: 'PEOPLE_NOTIFIED', visible: true},
                    'player': {displayName: 'PLAYER_ID', visible: true},
                    'casinoName': {displayName: 'CASINO', visible: true},
                    'message': {displayName: 'MESSAGE', visible: true},
                    'gameRoundId': {displayName: 'GAME_ROUND_ID', visible: false},
                    'gameId': {displayName: 'GAME_ID', visible: false},
                    'data': {displayName: 'DATA', visible: false}
                }
            };

            $scope.filterOptions = {};
            $scope.selectedFilters = {
                level: [],
                source: [],
                type: [],
                status: [],
                casinos: [],
                gameId: "",
                acknowledgedBy: "",
                acknowledgedOnFrom: null,
                acknowledgedOnTo: null,
                triggeredOnFrom: null,
                triggeredOnTo: null
            };

            $scope.formErrors = {};

            $scope.filters = {};

            $scope.changeStatusEnabled = false;

            $scope.filterExpanded = false;

            $scope.isSortable = function(columnName) {
                //return $scope.table.sortColumns.indexOf(columnName) != -1;
                return true;
            };

            // Watch for changes, then load data
            $scope.$watch('table.offset', onPageOffsetChange);
            $scope.$watch('table.sortBy', onSortByChange, true);
            $scope.$watch('table.pageSize', onPaginationChange, true);

            $scope.onRefresh = function() {
                getAlarms();
            };

            $scope.onStatusChange = function(alarm) {
                alarm.acknowledgedBy = $scope.globals.currentUser.username;
                alarm.acknowledgedOn = new Date().toISOString();

                acknowledgeAlarm(alarm.id, alarm.status);
            };

            $scope.onUpdateMultiple = function(selectedStatus) {
                var selectedAlarms = $filter('filter')($scope.table.alarms, {selected: true});
                acknowledgeAlarms(selectedAlarms, selectedStatus);
            };

            $scope.onClearAssignment = function(alarm) {
                if (alarm.acknowledgedBy !== $scope.globals.currentUser.username) {
                    notificationService.showWarningToast($scope.translations.unableClearAssignment)
                }

                unAssignAlarm(alarm.id);
            };

            $scope.onSelectAll = function() {
                angular.forEach($scope.table.alarms, function(alarm) {
                    alarm.selected = true;
                });
                $scope.changeStatusEnabled = true;
            };

            $scope.onDeselectAll = function() {
                angular.forEach($scope.table.alarms, function(alarm) {
                    alarm.selected = false;
                });
                $scope.changeStatusEnabled = false;
            };

            $scope.onChangeStatus = function(status) {
                if (status === 'undefined') {
                    $scope.table.filterStatus = undefined;
                    getAlarms();
                } else {
                    $scope.table.filterStatus = status;
                    getAlarms();
                }
            };

            $scope.updateChangeStatusEnabled = function(selected) {
                if (selected) {
                    $scope.changeStatusEnabled = true;
                } else {
                    for (var i = 0; i < $scope.table.alarms.length; i++) {
                        if ($scope.table.alarms[i].selected === true) {
                            $scope.changeStatusEnabled = true;
                            return;
                        }
                    }
                    $scope.changeStatusEnabled = false;
                }
            };

            $scope.filterUpdate = function(option, array, checked){
                if (checked) {
                    array.push(option);
                } else {
                    for (var i = 0; i < array.length; i++) {
                        if (array[i] === option) {
                            array.splice(i, 1);
                            return;
                        }
                    }
                }
            };

            $scope.applyFilters = function() {
                $scope.filters = {
                    level: $scope.selectedFilters.level,
                    source: $scope.selectedFilters.source,
                    type: $scope.selectedFilters.type,
                    status: $scope.selectedFilters.status,
                    casinos: $scope.selectedFilters.casinos,
                    gameId: $scope.selectedFilters.gameId,
                    acknowledgedBy: $scope.selectedFilters.acknowledgedBy,
                    acknowledgedOnFrom: $scope.selectedFilters.acknowledgedOnFrom,
                    acknowledgedOnTo: $scope.selectedFilters.acknowledgedOnTo,
                    triggeredOnFrom: $scope.selectedFilters.triggeredOnFrom,
                    triggeredOnTo: $scope.selectedFilters.triggeredOnTo
                };
                getAlarms();
            };

            $scope.toggleOverflow = function(event) {
                setTimeout(function(){
                    event.currentTarget.parentElement.children[1].classList.toggle('overflow-inherit');
                }, 400);
            };

            $scope.onColumnsChange = function(element) {
                console.log(element);
                //$(element).
            };

            $scope.openDetailsModal = openDetailsModal;

            $scope.onClose = function () {
                $scope.detailsModal.close();
            };

            $scope.setCurrentAlarm = function(alarm) {
                $scope.selectedAlarm = alarm;
            };

            getFiltersOptions();
            getCasinosTree();
            //notificationService.initTooltips();
        }

        function getFiltersOptions() {
            requestService.get(
                '/api/alarms/filter-options',
                function(data) {
                    $scope.filterOptions.level = data.level;
                    $scope.filterOptions.source = data.type;
                    $scope.filterOptions.type = data.messageType;
                    $scope.filterOptions.status = data.status;
                }
            );
        }

        function getCasinosTree() {
            requestService.get(
                '/api/alarms/casino-tree',
                function(data) {
                    $scope.filterOptions.casinos = data;
                }
            );
        }

        function onPaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getAlarms();
            }
        }

        function onPageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getAlarms();
            }
        }

        function onSortByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.table.sortBy.length > 0) {
                    if ($scope.table.sortBy[0].charAt(0) !== '-') {
                        $scope.table.sortColumn = $scope.table.sortBy[0];
                        $scope.table.sortAsc = true;
                    } else {
                        $scope.table.sortColumn = $scope.table.sortBy[0].slice(1);
                        $scope.table.sortAsc = false;
                    }
                } else {
                    $scope.table.sortAsc = null;
                    $scope.table.sortColumn = null;
                }
                getAlarms();
            }
        }

        function getAlarms() {
            var sortColumn = "";
            if ($scope.table.sortColumn) {
                sortColumn = '&orderColumn=' + $scope.table.sortColumn;
            }
            requestService.postEncoded(
                '/api/alarms/table-data' +
                '?dataOffset=' + $scope.table.offset +
                '&dataSegmentSize=' + $scope.table.pageSize +
                '&dataSize=' + $scope.table.totalSize +
                sortColumn +
                '&orderAsc=' + $scope.table.sortAsc,
                {
                    level: $scope.filters.level,
                    source: $scope.filters.source,
                    type: $scope.filters.type,
                    status: $scope.filters.status,
                    casinos: $scope.filters.casinos,
                    gameId: $scope.filters.gameId,
                    acknowledgedBy: $scope.filters.acknowledgedBy,
                    acknowledgedOnFrom: $scope.filters.acknowledgedOnFrom,
                    acknowledgedOnTo: $scope.filters.acknowledgedOnTo,
                    triggeredOnFrom: $scope.filters.triggeredOnFrom,
                    triggeredOnTo: $scope.filters.triggeredOnTo
                },
                function(response) {
                    updateData(response);
                }
            );
        }

        function getAlarmStatuses() {
            requestService.get(
                '/api/alarms/status-list',
                function(data) {
                    $scope.statuses = data;
                }
            );
        }

        function updateData(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);
            requestService.parseJsonInResponse(tableData, 'additionalData');

            $scope.table.totalSize = response.dataSize;
            $scope.table.alarms = tableData;
            $scope.table.offset = response.dataOffset;
            $scope.changeStatusEnabled = false;

            if (response.dataSize === 0) {
                notificationService.showInfoToast($scope.translations.noDataFound);
            }
        }

        function acknowledgeAlarms(selectedAlarms, selectedStatus) {
            var alarmIds = selectedAlarms.map(function(alarm) {
                return alarm.id;
            });

            requestService.postEncoded(
                '/api/alarms/acknowledge-multiple/',
                {
                    alarmIds: alarmIds,
                    status: selectedStatus
                },
                function(data) {
                    if (data.status.code !== 'OK') {
                        notificationService.processApplicationError(data.status, $scope.translations.acknowledgeError);
                    } else {
                        getAlarms();
                    }
                }
            )
        }

        function acknowledgeAlarm(alarmId, status) {
            requestService.putEncoded(
                '/api/alarms/acknowledge/' + alarmId,
                {status: status},
                function(data) {
                    if (data.status.code !== 'OK') {
                        notificationService.processApplicationError(data.status, $scope.translations.acknowledgeError);
                    } else {
                        getAlarms();
                    }
                }
            )
        }

        function unAssignAlarm(alarmId) {
            requestService.putEncoded(
                '/api/alarms/un-assign/' + alarmId,
                null,
                function(data) {
                    if (data.status.code !== 'OK') {
                        notificationService.processApplicationError(data.status, $scope.translations.unAssign);
                    } else {
                        getAlarms();
                    }
                }
            )
        }

        function openDetailsModal() {
            $scope.detailsModal = $modal.open({
                animation: true,
                templateUrl: 'app/alarms/details_modal.html',
                scope: $scope,
                backdrop: true,
                size: 'lg',
                resolve: {}
            });

            $scope.detailsModal.result.then(
                function() {},
                function() {}
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['ALARMS.ERROR_MESSAGES.UNABLE_CLEAR_ASSIGNMENT', 'ALARMS.ERROR_MESSAGES.NO_DATA_FOUND',
                'ALARMS.ERROR_MESSAGES.ACKNOWLEDGE_ERROR', 'ALARMS.ERROR_MESSAGES.UN_ASSIGN'])
                .then(function(translation) {
                    translations.unableClearAssignment = translation['ALARMS.ERROR_MESSAGES.UNABLE_CLEAR_ASSIGNMENT'];
                    translations.noDataFound = translation['ALARMS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    translations.acknowledgeError = translation['ALARMS.ERROR_MESSAGES.ACKNOWLEDGE_ERROR'];
                    translations.unAssign = translation['ALARMS.ERROR_MESSAGES.ALARMS.ERROR_MESSAGES.UN_ASSIGN'];
                }, function(translationIds) {
                    translations.unableClearAssignment = translationIds['ALARMS.ERROR_MESSAGES.UNABLE_CLEAR_ASSIGNMENT'];
                    translations.noDataFound = translationIds['ALARMS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    translations.acknowledgeError = translationIds['ALARMS.ERROR_MESSAGES.ACKNOWLEDGE_ERROR'];
                    translations.unAssign = translationIds['ALARMS.ERROR_MESSAGES.ALARMS.ERROR_MESSAGES.UN_ASSIGN'];
                });
            return translations;
        }
    }
]);
