app.controller('NavigationCtrl', ['$scope', '$location', '$log', 'USER_ROLES', 'requestService', 'GLOBAL_CONSTANTS',
    function($scope, $location, $log, USER_ROLES, requestService, GLOBAL_CONSTANTS) {

        init();

        function init() {
            initMenu();

            $scope.isAvailable = function(selectedMenuItem) {
                if (selectedMenuItem.subItems) {
                    for (var i = 0, l = selectedMenuItem.subItems.length; i < l; i++) {
                        if (selectedMenuItem.subItems[i].url === $location.path()) {
                            return true;
                        }
                    }
                }
                return $location.path().contains(selectedMenuItem.url);
            };
        }

        function initMenu() {

            $scope.menuItems = [
                {
                    url: '/game-sessions/sessions/player',
                    name: 'GAME_SESSIONS_ROUNDS',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.GAME_SESSIONS_VIEW
                    ]
                },
                {
                    url: '/reports',
                    name: 'REPORTS',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.INVOICING_REPORT,
                        USER_ROLES.CASINO_GAME_PERFORMANCE_REPORT,
                        USER_ROLES.FREE_ROUNDS_CAMPAIGN_COST_REPORT,
                        USER_ROLES.PLAYER_PERFORMANCE_REPORT,
                        USER_ROLES.CASINO_PERFORMANCE_PER_DAY_REPORT,
                        USER_ROLES.CASINO_PERFORMANCE_PER_DAY_DETAILED_REPORT,
                        USER_ROLES.PLAYER_DEMOGRAPHIC_DATA_REPORT,
                        USER_ROLES.USERS_LIST_REPORT,
                        USER_ROLES.GAME_ROUND_FEATURES_PER_PLAYER_REPORT
                    ],
                    subItems: [
                        {
                            url: '/reports/invoicing',
                            name: 'INVOICING_REPORT',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.INVOICING_REPORT
                            ]
                        },
                        {
                            url: '/reports/casino-game-performance',
                            name: 'CASINO_GAME_PERFORMANCE',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.CASINO_GAME_PERFORMANCE_REPORT
                            ]
                        },
                        {
                            url: '/reports/casino-performance-per-day-report',
                            name: 'CASINO_PERFORMANCE_PER_DAY',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.CASINO_PERFORMANCE_PER_DAY_REPORT
                            ]
                        },
                        {
                            url: '/reports/casino-performance-per-day-detailed',
                            name: 'CASINO_PERFORMANCE_DAY_DETAILED',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.CASINO_PERFORMANCE_PER_DAY_DETAILED_REPORT
                            ]
                        },
                        {
                            url: '/reports/free-rounds-campaign-cost',
                            name: 'CAMPAIGN_COST_REPORT',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.FREE_ROUNDS_CAMPAIGN_COST_REPORT
                            ]
                        },
                        {
                            url: '/reports/player-performance',
                            name: 'PLAYER_PERFORMANCE',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.PLAYER_PERFORMANCE_REPORT
                            ]
                        },
                        {
                            url: '/reports/player-demographic-data',
                            name: 'PLAYER_DEMOGRAPHIC_DATA',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.PLAYER_DEMOGRAPHIC_DATA_REPORT
                            ]
                        },
                        {
                            url: '/reports/users-list',
                            name: 'USERS_LIST',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.USERS_LIST_REPORT
                            ]
                        },
                        {
                            url: '/reports/game-round-features-per-player',
                            name: 'GAME_ROUND_FEATURES_PER_PLAYER',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.GAME_ROUND_FEATURES_PER_PLAYER_REPORT
                            ]
                        }
                    ]
                },
                {
                    url: '/analytics',
                    name: 'ANALYTICS',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.REPORTING_VIEW
                    ]
                },
                {
                    url: '/alarms',
                    name: 'ALARMS',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.ALARMS_VIEW,
                        USER_ROLES.ALARMS_UPDATE
                    ]
                },
                {
                    url: '/wallet-management',
                    name: 'WALLET_MANAGEMENT',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                        USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE,
                        USER_ROLES.GSMC_FREE_ROUND_CAMPAIGNS_UPDATE,
                        USER_ROLES.SESSION_WALLET_VIEW,
                        USER_ROLES.SESSION_WALLET_UPDATE
                    ],
                    subItems: [
                        {
                            url: '/free-spins',
                            name: 'CAMPAIGN_MANAGER',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                                USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE
                            ]
                        },
                        {
                            url: '/session-wallet/active-sessions',
                            name: 'SESSION_WALLET',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.SESSION_WALLET_VIEW,
                                USER_ROLES.SESSION_WALLET_UPDATE
                            ]
                        }
                    ]
                },
                {
                    url: '/materials',
                    name: 'MARKETING_MATERIALS',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.MARKETING_MATERIALS_VIEW,
                        USER_ROLES.MARKETING_MATERIALS_UPDATE
                    ]
                },
                {
                    url: '/casino-game-management/games-by-casino',
                    name: 'CASINO_GAME_MANAGEMENT',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.GAME_MANAGEMENT,
                        USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW,
                        USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE
                    ]
                },
                {
                    url: '/admin',
                    name: 'ADMIN',
                    roles: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.GAME_MANAGEMENT,
                        USER_ROLES.PLAYERS_SEARCH,
                    ],
                    subItems: [
                        {
                            url: '/admin-game-administration/games',
                            name: 'GAME_ADMINISTRATION',
                            roles: [
                                USER_ROLES.ADMIN
                            ]
                        },
                        {
                            url: '/admin-game-management',
                            name: 'GAME_MANAGEMENT',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.GAME_MANAGEMENT
                            ]
                        },
                        {
                            url: '/admin-casino-management',
                            name: 'CASINO_MANAGEMENT',
                            roles: [USER_ROLES.ADMIN]
                        },
                        {
                            url: '/admin-demo-user-management',
                            name: 'DEMO_USER_MANAGEMENT',
                            roles: [USER_ROLES.ADMIN]
                        },
                        {
                            url: '/admin-user-administration',
                            name: 'USER_ADMINISTRATION',
                            roles: [USER_ROLES.ADMIN]
                        },
                        {
                            url: '/admin-alarm-settings/general',
                            name: 'ALARM_SETTINGS',
                            roles: [
                                USER_ROLES.ADMIN
                            ]
                        },
                        {
                            url: '/admin-players',
                            name: 'PLAYERS_OVERVIEW',
                            roles: [
                                USER_ROLES.ADMIN, USER_ROLES.PLAYERS_SEARCH
                            ]
                        },
                        {
                            url: '/admin-audit',
                            name: 'AUDIT_LOGS',
                            roles: [
                                USER_ROLES.ADMIN
                            ]
                        },
                        {
                            url: '/admin-general-settings',
                            name: 'GENERAL_SETTINGS',
                            roles: [
                                USER_ROLES.ADMIN
                            ]
                        }
                    ]
                }
            ];

            requestService.get(
                'api/managed-freerounds-enabled',
                function(response) {
                    GLOBAL_CONSTANTS.MANAGED_FREEROUNDS = (response.replace(/"/g,'') === 'true');
                    if (GLOBAL_CONSTANTS.MANAGED_FREEROUNDS === true) {
                        $scope.menuItems[4].subItems.splice(1, 0, {
                            url: '/managed-freerounds',
                            name: 'MANAGED_FREEROUNDS',
                            roles: [
                                USER_ROLES.ADMIN,
                                USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                                USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE,
                                USER_ROLES.GSMC_FREE_ROUND_CAMPAIGNS_UPDATE
                            ]
                        });
                    }
                }
            );
        }
    }
]);
