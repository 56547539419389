app.controller('UserCtrl', ['$scope', '$log', '$modal', 'requestService', 'userAuthService', 'notificationService', 'ERROR_CODES', '$translate',
    '$state', 'cookieService',
    function($scope, $log, $modal, requestService, userAuthService, notificationService, ERROR_CODES, $translate, $state, cookieService) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {};
            $scope.formErrors = {};

            $scope.onUpdateUserInfo = function() {
                updateUserInfo();
            };

            $scope.onUpdateUserPassword = function() {
                updateUserPassword();
            };

            getCurrentUser(false);
        }

        function getCurrentUser(updatingInfo) {
            userAuthService.getUser(function(response) {
                $scope.formData = response.user;
                if (updatingInfo && $translate.use() !== response.user.locale) {
                    requestService.setAcceptLanguage(response.user.locale);

                    $translate.use(response.user.locale);
                    cookieService.setCookie('userLanguage', response.user.locale);
                    $state.reload();
                }
            });
        }

        function updateUserInfo() {
            requestService.postEncoded(
                '/api/user/update-user-info',
                $scope.formData,
                function(response) {
                    $scope.formErrors = response.status.formErrors || {};

                    if (response.status.code === ERROR_CODES.OK) {
                        getCurrentUser(true);
                        notificationService.showInfoToast($scope.translations.infoUserUpdated);

                    } else {
                        if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.errorUpdatingUser);
                        }
                    }

                }
            );
        }

        function updateUserPassword() {
            userAuthService.updatePassword(
                $scope.formData,
                function(response) {
                    $scope.formErrors = response.status.formErrors || {};

                    if (response.status.code === ERROR_CODES.OK) {
                        //Update user credentials on client
                        if ($scope.formData.newPassword) {
                            userAuthService.setUser($scope.globals.currentUser.username, $scope.formData.newPassword, response.user.roles);
                        }
                        getCurrentUser(false);
                        notificationService.showInfoToast($scope.translations.infoUserUpdated);

                    } else {
                        if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.errorUpdatingUser);
                        }
                    }
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['USER_PROFILE.INFO_MESSAGES.USER_UPDATED', 'USER_PROFILE.ERROR_MESSAGES.INVALID_FORM',
                'USER_PROFILE.ERROR_MESSAGES.UPDATING_USER'])
                .then(function(translation) {
                    translations.errorInvalidForm = translation['USER_PROFILE.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorUpdatingUser = translation['USER_PROFILE.ERROR_MESSAGES.UPDATING_USER'];
                    translations.infoUserUpdated = translation['USER_PROFILE.INFO_MESSAGES.USER_UPDATED'];
                }, function(translationIds) {
                    translations.errorInvalidForm = translationIds['USER_PROFILE.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorUpdatingUser = translationIds['USER_PROFILE.ERROR_MESSAGES.UPDATING_USER'];
                    translations.infoUserUpdated = translationIds['USER_PROFILE.INFO_MESSAGES.USER_UPDATED'];
                });
            return translations;
        }
    }
]);
