app.controller('MarketingMaterialsRootFolderModalCtrl', ['$scope', '$log', '$filter', '$modalInstance', '$modal',
    'Upload', 'requestService', 'notificationService', 'path', 'name', 'ERROR_CODES', '$q', '$translate',
    function($scope, $log, $filter, $modalInstance, $modal, Upload, requestService, notificationService, path, name, ERROR_CODES, $q, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {
                name: name,
                path: path,
                oldPath: path,
                categories: ['all'],
                status: 'CONCEPT',
                restrictedCasinoIds: []
            };

            getAllCasinos();

            $scope.formErrors = {};

            $scope.progress = '';

            $scope.availableCategories = [
                {type: 'slots', name: 'SLOTS', selected: false},
                {type: 'branded', name: 'BRANDED_SLOTS', selected: false},
                {type: 'threesixty', name: 'SLOTS_360', selected: false},
                {type: 'mobi', name: 'MOBI', selected: false},
                {type: 'classic', name: 'CLASSIC', selected: false},
                {type: 'dice', name: 'DICE', selected: false}
            ];

            $scope.folderPromise = {};
            $scope.treeStructurePromise = getTreeStructure();

            $scope.updateSelectedCategories = function() {
                var categories = $scope.formData.categories;
                angular.forEach($scope.availableCategories, function(category) {
                    category.selected = categories.indexOf(category.type) !== -1;
                });
            };

            $scope.onCategorySelected = function(categoryType) {
                var index = $scope.formData.categories.indexOf(categoryType);
                if (index > 0) {
                    $scope.formData.categories.splice(index, 1);
                } else {
                    $scope.formData.categories.push(categoryType);
                }
            };

            $scope.onImageSelected = function() {
                $scope.formData.name = $scope.formData.name ||
                    $scope.image.name.split('_').join(' ').split('.')[0];
                $scope.imageData = null;
            };

            $scope.onCreate = function() {
                createItem();
            };

            $scope.onSave = function() {
                saveItem();
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.removeCoverImage = function(name) {
                requestService.delete(
                    '/api/files/remove?path=' + name + '.preview',
                    function(response) {
                        if (response.status.code === 'OK') {
                            $scope.image = null;
                            $scope.imageData = null;
                        } else {
                            $log.error(response.status);
                            notificationService.processApplicationError(response.status, $scope.translations.errorRemovingFolder);
                        }
                    }
                )
            };

            if (name) {
                $scope.folderPromise = getItem(name);
            }

            $q.all([$scope.treeStructurePromise, $scope.folderPromise]).then(updateTreeview);
        }

        function getTreeStructure() {
            var deferred = $q.defer();
            requestService.get(
                '/api/enums/casino-tree',
                function(data) {
                    $scope.treeStructure = data;
                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function updateTreeview() {
            if ($scope.formData.restrictedCasinoIds && $scope.formData.restrictedCasinoIds.length > 0) {
                for (var i = 0; i < $scope.formData.restrictedCasinoIds.length; i++) {
                    selectNode($scope.treeStructure, $scope.formData.restrictedCasinoIds[i]);
                }
            }
            $scope.treeStructureFinal = $scope.treeStructure;
        }

        function selectNode(node, casinoId) {
            if (node.id === casinoId) {
                node.selected = true;
            } else {
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        selectNode(node.children[i], casinoId);
                    }
                }
            }
        }

        function getAllCasinos() {
            $scope.casinosList = [];
            requestService.get(
                '/api/enums/casino-list',
                function(data) {
                    $scope.casinosList = data;
                }
            );
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getItem(path) {
            var deferred = $q.defer();
            requestService.get('/api/files/folder?path=' + path,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;
                        $scope.imageData = data.values.imageData;
                        $scope.formData.oldPath = data.values.path;
                        delete $scope.formData.imageData;
                        $scope.updateSelectedCategories();
                        deferred.resolve();
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorGettingItem, cancelModal());
                        deferred.reject();
                    }
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred;
        }

        function createItem() {
            var file = $scope.image;
            if ($scope.formData.path === null || $scope.formData.path === undefined) {
                $scope.formData.path = $scope.formData.name;
            }
            var promise = createFolder();
            promise.then(function() {
                if (file) {
                    if (!file.$error) {
                        Upload.upload({
                            url: '/api/files/preview-update',
                            data: {
                                name: $scope.formData.name,
                                path: $scope.formData.path,
                                file: file
                            }
                        }).progress(function(evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.progress = progressPercentage + '% ' + evt.config.data.file.name + '\n' + $scope.progress;
                        }).success(function(data, status, headers, config) {
                            onSuccess(data);
                        }).error(function(error) {
                            onError(error);
                        });
                    }
                }
            });
        }

        function createFolder() {
            var file = $scope.image;
            var deferred = $q.defer();
            requestService.postEncoded('/api/files/folder-create',
                $scope.formData,
                function(data) {
                    if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                        $scope.formErrors = data.status.formErrors;
                    }
                    deferred.resolve(data);
                    if (!file) {
                        onSuccess(data);
                    }
                },
                function(error) {
                    onError(error);
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function saveItem() {
            var file = $scope.image;
            $scope.formData.path = $scope.formData.name;
            var promise = updateFolder();
            promise.then(function() {
                if (file) {
                    if (!file.$error) {
                        Upload.upload({
                            url: '/api/files/preview-update',
                            data: {
                                name: $scope.formData.name,
                                path: $scope.formData.name,
                                oldPath: $scope.formData.oldPath,
                                file: file
                            }
                        }).progress(function(evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.progress = progressPercentage + '% ' + evt.config.data.file.name + '\n' + $scope.progress;
                        }).success(function(data, status, headers, config) {
                            onSuccess(data);
                        }).error(function(error) {
                            onError(error);
                        });
                    }
                }
            });
        }

        function updateFolder() {
            var file = $scope.image;
            var deferred = $q.defer();
            requestService.putEncoded('/api/files/folder-update',
                $scope.formData,
                function(data) {
                    if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                        $scope.formErrors = data.status.formErrors;
                    }
                    deferred.resolve(data);
                    if (!file) {
                        onSuccess(data);
                    }
                },
                function(error) {
                    onError(error);
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function onError(error) {
            $scope.formErrors = {};
            notificationService.processServerError(error);
        }

        function onSuccess(data) {
            $scope.progress = null;
            if (data.status.code === ERROR_CODES.OK) {
                //$scope.formData = data.values;

                notificationService.showInfoToast('Folder stored');
                closeModal();
            } else {
                if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                    notificationService.showWarningToast('Invalid form');
                } else {
                    notificationService.processApplicationError(data.status, $scope.translations.errorStoringFolder);
                }
            }
        }

        function initTranslations() {
            var translations = {};
            $translate(['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER', 'MARKETING_MATERIALS.ERROR_MESSAGES.GETTING_ITEM',
                'MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'])
                .then(function(translate) {
                    translations.errorRemovingFolder = translate['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER'];
                    translations.errorStoringFolder = translate['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'];
                    translations.errorGettingItem = translate['MARKETING_MATERIALS.ERROR_MESSAGES.GETTING_ITEM'];
                }, function(translationIds) {
                    translations.errorRemovingFolder = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER'];
                    translations.errorStoringFolder = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'];
                    translations.errorGettingItem = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.GETTING_ITEM'];
                });
            return translations;
        }
    }
]);
