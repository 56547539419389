app.factory('analyticsService', ['$q', '$log', 'QUERY_TYPE', '$translate',
    function($q, $log, QUERY_TYPE, $translate) {

        var translations = initTranslations();
        
        var service = {};
        service.q = $q;

        service.timeIntervals = [
            {from: 'now/d', to: 'now', group: translations.thisPeriod, title: translations.today},
            {from: 'now/w', to: 'now', group: translations.thisPeriod, title: translations.thisWeek},
            {from: 'now/M', to: 'now', group: translations.thisPeriod, title: translations.thisMonth},
            {from: 'now/y', to: 'now', group: translations.thisPeriod, title: translations.thisYear},

            {from: 'now-15m', to: 'now', group: translations.lastPeriod, title: translations.last15Minutes},
            {from: 'now-30m', to: 'now', group: translations.lastPeriod, title: translations.last30Minutes},
            {from: 'now-1h', to: 'now', group: translations.lastPeriod, title: translations.last1Hour},
            {from: 'now-4h', to: 'now', group: translations.lastPeriod, title: translations.last4Hours},
            {from: 'now-12h', to: 'now', group: translations.lastPeriod, title: translations.last12Hours},
            {from: 'now-24h', to: 'now', group: translations.lastPeriod, title: translations.last24Hours},
            {from: 'now-7d', to: 'now', group: translations.lastPeriod, title: translations.last7Days},

            {from: 'now-30d', to: 'now', group: translations.longerPeriod, title: translations.last30Days},
            {from: 'now-60d', to: 'now', group: translations.longerPeriod, title: translations.last60Days},
            {from: 'now-90d', to: 'now', group: translations.longerPeriod, title: translations.last90Days},
            {from: 'now-6M', to: 'now', group: translations.longerPeriod, title: translations.last6Months},
            {from: 'now-1y', to: 'now', group: translations.longerPeriod, title: translations.last1Year}
        ];

        service.refreshIntervals = [
            {value: 0, title: translations.off, section: 0},
            {value: 30000, title: translations.seconds30, section: 1},
            {value: 60000, title: translations.minutes1, section: 2},
            {value: 300000, title: translations.minutes5, section: 2},
            {value: 900000, title: translations.minutes15, section: 2},
            {value: 1800000, title: translations.minutes30, section: 2}
        ];

        var MONTH = translations.month;
        var WEEK = translations.week;
        var DAY = translations.day;
        var HOUR = translations.hour;

        service.aggregationInterval = [
            HOUR, DAY, WEEK, MONTH
        ];

        // DOCS: https://nvd3-community.github.io/nvd3/examples/documentation.html
        service.graphDefinitions = [
            {
                type: 'historicalBarChart',
                imgName: 'historicalBarChart.png',
                supportFlat: true,
                supportSeries: false, // due to core nvd3 bug not supported
                types: [QUERY_TYPE.TIME_HISTOGRAM],
                title: 'HISTORICAL_BAR_CHART'
            },
            {
                type: 'table',
                imgName: 'table.png',
                supportFlat: true,
                supportSeries: true,
                types: [QUERY_TYPE.TIME_HISTOGRAM, QUERY_TYPE.TERM_HISTOGRAM, QUERY_TYPE.TERM_LIST],
                title: 'TABLE'
            },
            {
                type: 'multiBarChart',
                imgName: 'multiBarChart.png',
                supportFlat: true,
                supportSeries: true,
                types: [QUERY_TYPE.TIME_HISTOGRAM, QUERY_TYPE.TERM_HISTOGRAM],
                title: 'MULTI_BAR_CHART'
            },
            {
                type: 'multiBarHorizontalChart',
                imgName: 'multiBarHorizontalChart.png',
                supportFlat: true,
                supportSeries: true,
                types: [QUERY_TYPE.TIME_HISTOGRAM, QUERY_TYPE.TERM_HISTOGRAM],
                title: 'MULTI_BAR_HORIZONTAL_CHART'
            },
            {
                type: 'stackedAreaChart',
                imgName: 'stackedAreaChart.png',
                supportFlat: true,
                supportSeries: true,
                types: [QUERY_TYPE.TIME_HISTOGRAM],
                title: 'STACKED_AREA_CHART'
            },
            //{
            //    type: 'lineChart',
            //    imgName: 'lineChart.png',
            //    supportFlat: true,
            //    supportSeries: true,
            //    types: [QUERY_TYPE.TIME_HISTOGRAM],
            //    title: 'Line Chart'
            //},
            {
                type: 'pieChart',
                imgName: 'pieChart.png',
                supportFlat: true,
                supportSeries: false,
                types: [QUERY_TYPE.TIME_HISTOGRAM, QUERY_TYPE.TERM_HISTOGRAM],
                title: 'PIE_CHART'
            }
            //{type: 'donutChart', imgName: 'donutChart.png', supportSeries: false, isTimeBased: true, isTermBased: true, title: 'Donut Chart'}
        ];

        service.graphConfig = {
            visible: true, // default: true
            extended: false, // default: false
            disabled: false, // default: false
            refreshDataOnly: true, // default: true
            deepWatchOptions: true, // default: true
            deepWatchData: false, // default: true
            deepWatchDataDepth: 0, // default: 2
            debounce: 10 // default: 10
        };

        // https://github.com/mbostock/d3/wiki/Time-Formatting#format_utc
        var xAxisLabelFormaterHour = function(d) {
            return d3.time.format.utc('%m/%d/%y %H:%M')(new Date(d));
        };

        var xAxisLabelFormaterDay = function(d) {
            return d3.time.format.utc('%m/%d/%y')(new Date(d));
        };

        var xAxisLabelFormaterWeek = function(d) {
            return d3.time.format.utc('%m/%y - %W')(new Date(d));
        };

        var xAxisLabelFormaterMonth = function(d) {
            return d3.time.format.utc('%m / %Y')(new Date(d));
        };

        var yFormatter = function(d) {
            return d.y;
        };

        var yTooltipFormatter = function(d, i) {
            return d3.format(",.2f")(d);
        };

        var yAxisLabelFormatter = function(d) {
            // return d3.format(",.4s")(d);
            return _numberAbbreviation(d);
        };

        function _numberAbbreviation(number) {
            if (number >= 10000000) {
                return (number / 1000000).toFixed(2) + 'M';
            }

            if (number >= 1000000) {
                return (number / 1000000).toFixed(2) + 'M';
            }

            if (number >= 10000) {
                return (number / 1000).toFixed(2) + 'K';
            }

            if (number >= 1000) {
                return (number / 1000).toFixed(2) + 'K';
            }

            return yTooltipFormatter(number);
        }

        var margin = {
            top: 20,
            right: 30,
            bottom: 45,
            left: 100
        };

        var zoom = {
            enabled: true,
            useFixedDomain: false,
            scaleExtent: [0, 100],
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: "dblclick.zoom"
        };

        var xFormatter = function(d) {
            return d.x;
        };

        // ######################### Public API ###################################################################/

        //TODO adjust options per time / term based chart
        // NVD3 documentation - http://nvd3-community.github.io/nvd3/examples/documentation.html
        service.getGraphDefaultOptions = function(graphDefinition, queryType, title, xAxisLabel, yAxisLabel, aggregationInterval) {

            var isTimeBased = (queryType == QUERY_TYPE.TIME_HISTOGRAM);

            var xAxisLabelFormatter;
            if (isTimeBased) {
                switch (aggregationInterval) {
                    case 'hour':
                        xAxisLabelFormatter = xAxisLabelFormaterHour;
                        break;
                    case 'day':
                        xAxisLabelFormatter = xAxisLabelFormaterDay;
                        break;
                    case 'week':
                        xAxisLabelFormatter = xAxisLabelFormaterWeek;
                        break;
                    case 'month':
                        xAxisLabelFormatter = xAxisLabelFormaterMonth;
                        break;
                    default:
                        xAxisLabelFormatter = xAxisLabelFormaterHour;
                        break;
                }
            } else {
                xAxisLabelFormatter = function(d) {
                    return d;
                }
            }

            var chart;
            switch (graphDefinition.type) {
                case 'table':
                    chart = {
                        chart: {
                            type: 'table',
                            xFormatter: xAxisLabelFormatter,
                            yFormatter: yTooltipFormatter
                        }
                    };
                    break;

                case 'historicalBarChart':
                    chart = {
                        chart: {
                            type: 'historicalBarChart',
                            margin: margin,
                            showLegend: true,
                            duration: 100,
                            useInteractiveGuideline: true,
                            x: xFormatter,
                            xAxis: {
                                axisLabel: xAxisLabel || 'X Axis',
                                tickFormat: xAxisLabelFormatter,
                                showMaxMin: false,
                                staggerLabels: false
                            },
                            yAxis: {
                                axisLabel: yAxisLabel || 'Y Axis',
                                tickFormat: yTooltipFormatter,
                                rotateYLabel: false,
                                showMaxMin: true
                            },
                            tooltip: {
                                // valueFormatter: yTooltipFormatter, // not working, internal nvd3 problem, yAxis ticker is used instead
                                keyFormatter: xAxisLabelFormatter
                            },
                            zoom: zoom
                        },

                        // title options
                        title: _getGraphTitle(title, translations.chartTypes.historicalBarChart)
                    };
                    break;

                case 'stackedAreaChart':
                    chart = {
                        chart: {
                            type: "stackedAreaChart",
                            margin: margin,
                            x: xFormatter,
                            interpolate: 'linear',
                            clipEdge: true,
                            useInteractiveGuideline: true,
                            duration: 100,
                            xAxis: {
                                axisLabel: xAxisLabel || 'X Axis',
                                tickFormat: xAxisLabelFormatter,
                                showMaxMin: false,
                                staggerLabels: true,
                                axisLabelDistance: 5
                            },
                            yAxis: {
                                axisLabel: yAxisLabel || "Y Axis",
                                tickFormat: yTooltipFormatter,
                                rotateYLabel: false,
                                showMaxMin: true
                            },
                            tooltip: {
                                // valueFormatter: yTooltipFormatter, // not working, internal nvd3 problem, yAxis ticker is used instead
                                keyFormatter: xAxisLabelFormatter
                            },
                            zoom: zoom
                        },

                        // title options
                        title: _getGraphTitle(title, 'Stacked area chart')
                    };
                    break;

                case 'multiBarChart':
                    chart = {
                        chart: {
                            type: "multiBarChart",
                            margin: margin,
                            //barColor: ["#FF0000","#00FF00","#0000FF"], //color per bar + shading to distinguish
                            //color: ["#F0000F","#0F00F0","#00FF00"], // color per series
                            //barColor: function (d, i) {
                            //    $log.info('d', d);
                            //    if (d.y < 0) {
                            //        return '#FF0000';
                            //    } else if (d.y > 20) {
                            //        return '#00FF00';
                            //    } else {
                            //        return '#0000FF';
                            //    }
                            //    //var colors = d3.scale.category20().range().slice(10);
                            //    //return colors[i % colors.length-1];
                            //},
                            x: xFormatter,
                            y: yFormatter,
                            clipEdge: true,
                            duration: 100,
                            valueFormatter: yTooltipFormatter,
                            tooltip: {
                                valueFormatter: yTooltipFormatter
                            },
                            stacked: false,
                            xAxis: {
                                axisLabel: xAxisLabel || 'X Axis',
                                tickFormat: xAxisLabelFormatter,
                                showMaxMin: false,
                                staggerLabels: true,
                                axisLabelDistance: 5
                            },
                            yAxis: {
                                axisLabel: yAxisLabel || "Y Axis",
                                tickFormat: yAxisLabelFormatter,
                                rotateYLabel: false
                            }
                        },

                        // title options
                        title: _getGraphTitle(title, 'Multi bar chart')
                    };
                    break;

                case 'multiBarHorizontalChart':
                    chart = {
                        "chart": {
                            type: "multiBarHorizontalChart",
                            margin: {
                                top: 20,
                                right: 30,
                                bottom: 45,
                                left: 160
                            },
                            showControls: true,
                            duration: 100,
                            x: xFormatter,
                            stacked: false,
                            xAxis: {
                                axisLabel: xAxisLabel || 'X Axis',
                                tickFormat: xAxisLabelFormatter,
                                staggerLabels: true,
                                axisLabelDistance: 5,
                                rotateYLabel: false
                            },
                            yAxis: {
                                axisLabel: yAxisLabel || 'Y Axis',
                                tickFormat: yAxisLabelFormatter
                            },
                            tooltip: {
                                valueFormatter: yTooltipFormatter
                            }
                            //zoom: zoom //not supported
                        },

                        // title options
                        title: _getGraphTitle(title, 'Multibar Horizontal chart')
                    };
                    break;

                case 'lineChart':
                    chart = {
                        chart: {
                            type: "lineChart",
                            margin: margin,
                            interpolate: 'linear',
                            useInteractiveGuideline: true,
                            x: xFormatter,
                            y: yFormatter,
                            clipEdge: true,
                            xAxis: {
                                axisLabel: xAxisLabel || 'X Axis',
                                tickFormat: xAxisLabelFormatter,
                                staggerLabels: false
                            },
                            yAxis: {
                                axisLabel: yAxisLabel || "Y Axis",
                                tickFormat: yAxisLabelFormatter,
                                rotateYLabel: false
                            },
                            tooltip: {
                                valueFormatter: yTooltipFormatter
                            },
                            zoom: zoom
                        },

                        // title options
                        title: _getGraphTitle(title, 'Line chart')
                    };
                    break;

                case 'pieChart':
                    chart = {
                        chart: {
                            type: 'pieChart',
                            margin: {
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            x: function(d) {
                                return xAxisLabelFormatter(d.x);
                            },
                            showLabels: true,
                            duration: 100,
                            labelThreshold: 0.04,
                            labelSunbeamLayout: false,
                            labelsOutside: true,
                            legendPosition: 'right',
                            legend: {
                                margin: {
                                    top: 5,
                                    right: 5,
                                    bottom: 20,
                                    left: 0
                                }
                            }
                        },

                        // title options
                        title: _getGraphTitle(title, 'Pie chart')
                    };
                    break;

                case 'donutChart':
                    chart = {
                        chart: {
                            type: 'pieChart',
                            donut: true,
                            pie: {
                                startAngle: function(d) {
                                    return d.startAngle / 2 - Math.PI / 2
                                },
                                endAngle: function(d) {
                                    return d.endAngle / 2 - Math.PI / 2
                                }
                            },
                            margin: {
                                top: 0,
                                right: 0,
                                bottom: 30,
                                left: 0
                            },
                            x: xFormatter,
                            showLabels: true,
                            duration: 100,
                            labelThreshold: 0.01,
                            labelsOutside: true,
                            labelSunbeamLayout: false,
                            legend: {
                                margin: {
                                    top: 5,
                                    right: 35,
                                    bottom: 5,
                                    left: 0
                                }
                            }
                        },

                        // title options
                        title: _getGraphTitle(title, 'Donut chart')
                    };
                    break;
            }

            return chart;
        };

        function _getGraphTitle(title, defaultText) {
            return {
                enable: true,
                text: title || defaultText,
                className: 'h4'
            }
        }

        service.getSampleData = function(graphType) {
            switch (graphType) {
                case 'historicalBarChart':
                    return [
                        {
                            key: "Casino 1",
                            bar: true,
                            values: [
                                {x: new Date('1/1/2016').getTime(), y: 100},
                                {x: new Date('1/2/2016').getTime(), y: -30},
                                {x: new Date('1/3/2016').getTime(), y: 200}
                            ]
                        },
                        {
                            key: "Casino 2",
                            bar: true,
                            values: [
                                {x: new Date('1/1/2016').getTime(), y: 10},
                                {x: new Date('1/2/2016').getTime(), y: -20},
                                {x: new Date('1/3/2016').getTime(), y: 100}
                            ]
                        }
                    ];

                case 'multiBarChart':
                    return [
                        {
                            "key": "Series1",
                            "values": [
                                {"x": "Group A", "y": -1.8746444827653},
                                {"x": "Group B", "y": -8.0961543492239},
                                {"x": "Group C", "y": -0.57072943117674}
                            ]
                        },
                        {
                            "key": "Series2",
                            "values": [
                                {"x": "Group A", "y": 25.307646510375},
                                {"x": "Group B", "y": 16.756779544553},
                                {"x": "Group C", "y": 18.451534877007}
                            ]
                        }
                    ];

                case 'pieChart':
                    return [
                        {x: "One", y: 5},
                        {x: "Two", y: 2},
                        {x: "Three", y: 9},
                        {x: "Four", y: 7},
                        {x: "Five", y: 4},
                        {x: "Six", y: 3},
                        {x: "Seven", y: .5}
                    ];

                case 'donutChart':
                    return [
                        {x: "One", y: 5},
                        {x: "Two", y: 2},
                        {x: "Three", y: 9},
                        {x: "Four", y: 7},
                        {x: "Five", y: 4},
                        {x: "Six", y: 3},
                        {x: "Seven", y: .5}
                    ];

                case 'multiBarHorizontalChart':
                    return [
                        {
                            "key": "Series1",
                            "color": "#d62728",
                            "values": [
                                {"x": "Group A", "y": -1.8746444827653},
                                {"x": "Group B", "y": -8.0961543492239},
                                {"x": "Group C", "y": -0.57072943117674}
                            ]
                        },
                        {
                            "key": "Series2",
                            "color": "#1f77b4",
                            "values": [
                                {"x": "Group A", "y": 25.307646510375},
                                {"x": "Group B", "y": 16.756779544553},
                                {"x": "Group C", "y": 18.451534877007}
                            ]
                        }
                    ];

                case 'stackedAreaChart':
                    return [
                        {
                            "key": "Area 1",
                            "values": [
                                {"x": new Date('1/1/2016').getTime(), "y": 1.8746444827653},
                                {"x": new Date('1/2/2016').getTime(), "y": 8.0961543492239},
                                {"x": new Date('1/3/2016').getTime(), "y": 0.57072943117674},
                                {"x": new Date('1/4/2016').getTime(), "y": 20},
                                {"x": new Date('1/5/2016').getTime(), "y": 30}
                            ]
                        },
                        {
                            "key": "Area 2",
                            "values": [
                                {"x": new Date('1/1/2016').getTime(), "y": 50},
                                {"x": new Date('1/2/2016').getTime(), "y": 40},
                                {"x": new Date('1/3/2016').getTime(), "y": 30},
                                {"x": new Date('1/4/2016').getTime(), "y": 40},
                                {"x": new Date('1/5/2016').getTime(), "y": 50}
                            ]
                        }
                    ];

                case 'lineChart':
                    return [
                        {
                            "key": "Line 1",
                            "values": [
                                {"x": new Date('1/1/2016').getTime(), "y": 1.8746444827653},
                                {"x": new Date('1/2/2016').getTime(), "y": 8.0961543492239},
                                {"x": new Date('1/3/2016').getTime(), "y": 0.57072943117674},
                                {"x": new Date('1/4/2016').getTime(), "y": 20},
                                {"x": new Date('1/5/2016').getTime(), "y": 30}
                            ]
                        },
                        {
                            "key": "Line 2",
                            "values": [
                                {"x": new Date('1/1/2016').getTime(), "y": 50},
                                {"x": new Date('1/2/2016').getTime(), "y": 40},
                                {"x": new Date('1/3/2016').getTime(), "y": 30},
                                {"x": new Date('1/4/2016').getTime(), "y": 40},
                                {"x": new Date('1/5/2016').getTime(), "y": 50}
                            ]
                        }
                    ];
            }
        };

        service.getGraphDefaultConfig = function() {
            return service.graphConfig;
        };

        service.getTimeIntervals = function() {
            return service.timeIntervals;
        };

        service.getRefreshIntervals = function() {
            return service.refreshIntervals;
        };

        service.getAggregationInterval = function() {
            return service.aggregationInterval;
        };

        service.getGraphDefinitions = function() {
            return service.graphDefinitions;
        };

        return service;

        function initTranslations() {
            return {
                today: $translate.instant('GENERAL.TIME_INTERVALS.TODAY'),
                thisWeek: $translate.instant('GENERAL.TIME_INTERVALS.THIS_WEEK'),
                thisMonth: $translate.instant('GENERAL.TIME_INTERVALS.THIS_MONTH'),
                thisYear: $translate.instant('GENERAL.TIME_INTERVALS.THIS_YEAR'),
                last15Minutes: $translate.instant('GENERAL.TIME_INTERVALS.LAST_15_MINUTES'),
                last30Minutes: $translate.instant('GENERAL.TIME_INTERVALS.LAST_30_MINUTES'),
                last1Hour: $translate.instant('GENERAL.TIME_INTERVALS.LAST_1_HOUR'),
                last4Hours: $translate.instant('GENERAL.TIME_INTERVALS.LAST_4_HOURS'),
                last12Hours: $translate.instant('GENERAL.TIME_INTERVALS.LAST_12_HOURS'),
                last24Hours: $translate.instant('GENERAL.TIME_INTERVALS.LAST_24_HOURS'),
                last7Days: $translate.instant('GENERAL.TIME_INTERVALS.LAST_7_DAYS'),
                last30Days: $translate.instant('GENERAL.TIME_INTERVALS.LAST_30_DAYS'),
                last60Days: $translate.instant('GENERAL.TIME_INTERVALS.LAST_60_DAYS'),
                last90Days: $translate.instant('GENERAL.TIME_INTERVALS.LAST_90_DAYS'),
                last6Months: $translate.instant('GENERAL.TIME_INTERVALS.LAST_6_MONTHS'),
                last1Year: $translate.instant('GENERAL.TIME_INTERVALS.LAST_1_YEAR'),
                off: $translate.instant('GENERAL.TIME_INTERVALS.OFF'),
                seconds30: $translate.instant('GENERAL.TIME_INTERVALS.SECONDS_30'),
                minutes1: $translate.instant('GENERAL.TIME_INTERVALS.MINUTES_1'),
                minutes5: $translate.instant('GENERAL.TIME_INTERVALS.MINUTES_5'),
                minutes15: $translate.instant('GENERAL.TIME_INTERVALS.MINUTES_15'),
                minutes30: $translate.instant('GENERAL.TIME_INTERVALS.MINUTES_30'),
                month: $translate.instant('GENERAL.TIME_INTERVALS.MONTH'),
                week: $translate.instant('GENERAL.TIME_INTERVALS.WEEK'),
                day: $translate.instant('GENERAL.TIME_INTERVALS.DAY'),
                hour: $translate.instant('GENERAL.TIME_INTERVALS.HOUR'),
                thisPeriod: $translate.instant('ANALYTICS.MODAL.THIS'),
                lastPeriod: $translate.instant('ANALYTICS.MODAL.LAST'),
                longerPeriod: $translate.instant('ANALYTICS.MODAL.LONGER_PERIOD'),
                chartTypes: {
                    historicalBarChart: $translate.instant('CHART_TYPES.HISTORICAL_BAR_CHART')
                }
            };
        }
    }
]);

//
// abbreviate_number = function(num, fixed) {
//     if (num === null) { return null; } // terminate early
//     if (num === 0) { return '0'; } // terminate early
//     fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
//     var b = (num).toPrecision(2).split("e"), // get power
//         k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
//         c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
//         d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
//         e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
//     return e;
// }
