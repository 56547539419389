app.factory('notificationService', ['$rootScope', '$log', '$modal', '$modalStack', 'ngToast', '$translate',
    function($rootScope, $log, $modal, $modalStack, ngToast, $translate) {

        var service = {};

        service.dismissAll = dismissAll;

        service.processApplicationError = processApplicationError;
        service.processServerError = processServerError;

        service.showErrorDialog = showErrorDialog;
        service.showWarningDialog = showWarningDialog;
        service.showMessageDialog = showMessageDialog;
        service.showConfirmDialog = showConfirmDialog;
        service.showConfirmWarningDialog = showConfirmWarningDialog;
        service.showConfirmDangerDialog = showConfirmDangerDialog;

        service.showErrorToast = showErrorToast;
        service.showWarningToast = showWarningToast;
        service.showInfoToast = showInfoToast;

        // service.initTooltips = initTooltips;

        return service;

        function processApplicationError(responseStatus, msg, onConfirm) {
            $log.error(responseStatus);
            if ($rootScope.DEBUG) {
                showWarningToast($translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.APPLICATION_ERROR') + ': ' + msg);
            } else {
                showWarningDialog($translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.APPLICATION_ERROR'), [
                        msg || $translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.IN_APPLICATION'),
                        responseStatus.message || ''
                    ].join('\n')
                ).then(function() {
                        if (onConfirm) {
                            onConfirm();
                        }
                    }
                );
            }
        }

        function processServerError(error, msg, onConfirm) {
            $log.error(error);
            if ($rootScope.DEBUG) {
                showErrorToast($translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.SERVER_ERROR') + ': ' + msg);
            } else {
                showErrorDialog($translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.SERVER_ERROR'), [
                        msg || $translate.instant('GENERAL.SERVICES.NOTIFICATION.ERROR_MESSAGES.ON_SERVER_ERROR'),
                        error && error.message || ''
                    ].join('\n')
                ).then(function() {
                        if (onConfirm) {
                            onConfirm();
                        }
                    }
                );
            }
        }

        function showErrorDialog(title, textBody, textOk) {
            return _showMessageDialog('error', title, textBody, textOk);
        }

        function showWarningDialog(title, textBody, textOk) {
            return _showMessageDialog('warning', title, textBody, textOk);
        }

        function showMessageDialog(title, textBody, textOk) {
            return _showMessageDialog(null, title, textBody, textOk);
        }

        /**
         * @param type
         * @param title
         * @param textBody
         * @param textOk
         * @returns {*|Object|n}
         */
        function _showMessageDialog(type, title, textBody, textOk) {
            var settings = {
                templateUrl: 'app/templates/message_dialog.html',
                modalTexts: {
                    type: type,
                    title: title || $translate.instant('GENERAL.SERVICES.NOTIFICATION.MESSAGE_DIALOG.HEADLINE'),
                    text: textBody,
                    ok: textOk || $translate.instant('GENERAL.SERVICES.NOTIFICATION.MESSAGE_DIALOG.BUTTON_OK')
                },
                size: '-xsm',
                backdrop: true,
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    data: function() {
                        return settings.modalTexts;
                    }
                }
            };

            return $modal.open(settings).result;
        }

        /**
         *
         * @param title
         * @param textBody
         * @param textOk
         * @param textCancel
         * @returns {*|Object|n}
         */
        function showConfirmDialog(title, textBody, textOk, textCancel) {
            var settings = {
                templateUrl: 'app/templates/confirm_dialogs/confirm_dialog.html',
                modalTexts: {
                    title: title || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TITLE'),
                    text: textBody || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TEXT_BODY'),
                    ok: textOk || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_OK'),
                    cancel: textCancel || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_CANCEL')
                },
                size: '-xsm',
                backdrop: true,
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    data: function() {
                        return settings.modalTexts;
                    }
                }
            };

            return $modal.open(settings).result;
        }

        function showConfirmWarningDialog(title, textBody, textOk, textCancel) {
            var settings = {
                templateUrl: 'app/templates/confirm_dialogs/confirm_warning_dialog.html',
                modalTexts: {
                    title: title || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TITLE'),
                    text: textBody || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TEXT_BODY'),
                    ok: textOk || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_OK'),
                    cancel: textCancel || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_CANCEL')
                },
                size: 'xs',
                backdrop: true,
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    data: function() {
                        return settings.modalTexts;
                    }
                }
            };

            return $modal.open(settings).result;
        }

        function showConfirmDangerDialog(title, textBody, textOk, textCancel) {
            var settings = {
                templateUrl: 'app/templates/confirm_dialogs/confirm_danger_dialog.html',
                modalTexts: {
                    title: title || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TITLE'),
                    text: textBody || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.TEXT_BODY'),
                    ok: textOk || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_OK'),
                    cancel: textCancel || $translate.instant('GENERAL.SERVICES.NOTIFICATION.CONFIRM_DIALOG.BUTTON_CANCEL')
                },
                size: 'xs',
                backdrop: true,
                controller: 'ConfirmModalInstanceCtrl',
                resolve: {
                    data: function() {
                        return settings.modalTexts;
                    }
                }
            };

            return $modal.open(settings).result;
        }

        function showErrorToast(message) {
            _showToast('danger', message);
        }

        function showWarningToast(message) {
            _showToast('warning', message);
        }

        function showInfoToast(message) {
            _showToast('success', message);
        }

        function _showToast(type, message) {
            ngToast.create({
                className: type,
                content: message
            });
        }

        function dismissAll() {
            ngToast.dismiss();
            $modalStack.dismissAll('close');
        }

        // function initTooltips() {
        //     $('.tooltip-init').tooltip();
        // }
    }
]);

app.controller('ConfirmModalInstanceCtrl', ['$scope', '$modalInstance', 'data',
    function($scope, $modalInstance, data) {
        $scope.data = angular.copy(data);

        $scope.onClose = function() {
            $modalInstance.close();
        };

        $scope.onCancel = function() {
            $modalInstance.dismiss('cancel');
        };

    }
]);

app.directive('error', ['notificationService', function(notificationService) {
    return {
        priority: 1,
        restrict: 'A',
        scope: {
            ngClick: '&',
            error: '@',
            title: '@title',
            ok: '@ok'
        },
        link: function(scope, element, attrs) {

            element.unbind("click").bind("click", function($event) {
                $event.preventDefault();
                notificationService.showErrorDialog(scope.title, scope.error, scope.ok).then(scope.ngClick);
            });
        }
    }
}]);

app.directive('confirm', ['notificationService', function(notificationService) {
    return {
        priority: 1,
        restrict: 'A',
        scope: {
            condition: "=",
            ngClick: '&',
            confirm: '@',
            title: '@title',
            ok: '@ok',
            cancel: '@cancel'
        },
        link: function(scope, element, attrs) {

            element.unbind("click").bind("click", function($event) {
                $event.preventDefault();
                if (angular.isUndefined(scope.condition) || scope.condition) {
                    notificationService.showConfirmDialog(scope.title, scope.confirm, scope.ok, scope.cancel).then(scope.ngClick);
                } else {
                    scope.$apply(scope.ngClick);
                }
            });
        }
    }
}]);
