app.controller('GeneralSettingsCtrl', ['$scope', 'requestService', 'ERROR_CODES', 'notificationService', '$modal', '$timeout', '$translate',
    function($scope, requestService, ERROR_CODES, notificationService, $modal, $timeout, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {
                restrictedCountries: [],
                lockedPlayerWhitelistIps: []
            };
            $scope.countriesList = [];
            $scope.countriesIdList = [];
            $scope.reverse = {
                view: false,
                countries: []
            };

            $scope.onSaveRestrictedCountries = function() {
                if($scope.reverse.view === true) {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
                requestService.postEncoded(
                    '/api/general-settings/restricted-countries',
                    {
                        restrictedCountries: $scope.formData.restrictedCountries
                    },
                    function(response) {
                        if (response.status.code === ERROR_CODES.OK) {
                            notificationService.showInfoToast($scope.translations.infoRestrictedCountriesUpdated);
                        }
                    }
                )
            };

            $scope.editWhitelist = function() {
                var modalInstance = $modal.open({
                    animation: true,
                    templateUrl: 'app/templates/whitelist/whitelist_modal.html',
                    controller: 'WhitelistModalCtrl',
                    backdrop: false,
                    size: '-lg',
                    resolve: {
                        getUrl: function() {
                            return "/api/general-whitelist-ip/table-data";
                        },
                        postUrl: function() {
                            return "/api/general-whitelist-ip/merge";
                        },
                        deleteUrl: function() {
                            return "/api/general-whitelist-ip/delete/";
                        },
                        deleteAllUrls: function() {
                            return "/api/general-whitelist-ip/delete-all";
                        },
                        deleteUnlockedUrls: function() {
                            return "/api/general-whitelist-ip/delete-unlocked";
                        },
                        uploadCsvUrl: function() {
                            return "/api/general-whitelist-ip/upload-csv";
                        },
                        downloadCsvUrl: function() {
                            return "/api/general-whitelist-ip/download-csv";
                        },
                        downloadXlsUrl: function() {
                            return "/api/general-whitelist-ip/download-xls";
                        },
                        casinoId: function() {
                            return null;
                        },
                        inReadMode: function() {
                            return false;
                        }
                    }
                });

                modalInstance.result.then(function() {
                    getWhitelistIps();
                }, function() {
                    getWhitelistIps();
                });
            };

            $scope.$on('countriesInitialized', function(event, data) {
                var ids = [];
                for (var i = 0; i < data.length; i++) {
                    ids.push(data[i].id);
                }
                $scope.countriesList = data;
                $scope.countriesIdList = ids;
            });

            $scope.viewChanged = function() {
                $scope.reverse.view = !$scope.reverse.view;
                if($scope.reverse.view === true) {
                    $scope.reverse.countries = _.difference($scope.countriesIdList, $scope.formData.restrictedCountries);
                } else {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
            };

            $timeout(function() {
                    getRestrictedCountries();
                },
                $scope.DEFAULT_TIMEOUT
            );
            getWhitelistIps();
        }

        function getRestrictedCountries() {
            requestService.get(
                '/api/general-settings/restricted-countries',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.formData.restrictedCountries = response.list;
                    } else {
                        notificationService.processServerError(response.state.message, $scope.translations.errorLoadingGeneralRestrCountries);
                    }
                },
                function(response) {
                    notificationService.processServerError(response.status, $scope.translations.errorLoadingGeneralRestrCountries);
                }
            );
        }

        function getWhitelistIps() {
            requestService.get(
                '/api/general-whitelist-ip/preview',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.formData.lockedPlayerWhitelistIps = response.lockedPlayerWhitelistIps;
                        $scope.formData.totalPlayerWhitelistIps = response.totalPlayerWhitelistIps;
                    } else {
                        notificationService.processServerError(response.state.message, $scope.translations.errorLoadingIpWhitelists);
                    }
                },
                function(response) {
                    notificationService.processServerError(response.status, $scope.translations.errorLoadingIpWhitelists);
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['GENERAL_SETTINGS.INFO_MESSAGES.RESTRICTED_COUNTRIES_UPDATED', 'GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_GENERAL_RESTRICTED_COUNTRIES',
                'GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_IP_WHITELISTS'])
                .then(function(translation) {
                    translations.errorLoadingGeneralRestrCountries = translation['GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_GENERAL_RESTRICTED_COUNTRIES'];
                    translations.errorLoadingIpWhitelists = translation['GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_IP_WHITELISTS'];
                    translations.infoRestrictedCountriesUpdated = translation['GENERAL_SETTINGS.INFO_MESSAGES.RESTRICTED_COUNTRIES_UPDATED'];
                }, function(translationIds) {
                    translations.errorLoadingGeneralRestrCountries = translationIds['GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_GENERAL_RESTRICTED_COUNTRIES'];
                    translations.errorLoadingIpWhitelists = translationIds['GENERAL_SETTINGS.ERROR_MESSAGES.LOADING_IP_WHITELISTS'];
                    translations.infoRestrictedCountriesUpdated = translationIds['GENERAL_SETTINGS.INFO_MESSAGES.RESTRICTED_COUNTRIES_UPDATED'];
                });
            return translations;
        }
    }
]);
