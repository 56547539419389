app.factory('cookieService', function () {

    var service = {};
    service.setCookie = setCookie;
    service.getCookie = getCookie;
    return service;

    function setCookie(name, value, expdays) {
        if (expdays) {
            var date = new Date();
            date.setTime(date.getTime() + (expdays * 24 * 60 * 60 * 1000));
            var expires = "expires="+ date.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        } else {
            document.cookie = name + "=" + value + ";path=/";
        }
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookies = decodedCookie.split(';');
        for(var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return "";
    }

});
