app.controller('PlatformDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/platform-list',
                function(data) {
                    $scope.list = data;
                }
            )
        };
    }

}]);

app.controller('LanguageDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/language-list',
                function(data) {
                    $scope.list = data;
                }
            )
        };
    }

}]);

app.controller('GameDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/game-list',
                function(data) {
                    data.sort(function(a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    $scope.list = data;
                }
            )
        };
    }

}]);

app.controller('CurrenciesDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/currency-list',
                function(data) {
                    $scope.list = data;
                });
        };
    }

}]);

app.controller('CountryDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/country-list',
                function(data) {
                    $scope.list = data;
                    $scope.$emit('countriesInitialized', data);
                });
        };
    }

}]);

// Controller needed for inverse multiselect component to isolate the scope
app.controller('CountryDropdownHelperCtrl', ['$scope', 'requestService', function($scope, requestService) {}]);

app.controller('WalletTypeDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/wallet-type-list',
                function(data) {
                    $scope.list = data;
                });
        };
    }

}]);

app.controller('TagDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/game-tags-list',
                function(data) {
                    $scope.list = data;
                });
        };
    }

}]);

app.controller('JackpotTypeDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {

    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/enums/jackpot-type-list',
                function(data) {
                    $scope.list = data;
                });
        };
    }

}]);

app.controller('ProtocolDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {
    $scope.list = [];
    $scope.onRefreshList = function() {
        requestService.get(
            '/api/enums/protocols-list',
            function(data) {
                $scope.list = data;
            }
        );
    }
}]);

app.controller('GameTypeDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {
    $scope.list = [];
    $scope.onRefreshList = function() {
        requestService.get(
            '/api/enums/game-type-list',
            function(data) {
                $scope.list = data;
            }
        );
    }
}]);

app.controller('CasinosDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {
    $scope.list = [];
    $scope.onRefreshList = function() {
        requestService.get(
            '/api/enums/casino-list',
            function(data) {
                $scope.list = data;
            }
        );
    }
}]);

app.controller('ChildCasinosCtrl', ['$scope', 'requestService', function($scope, requestService) {
    $scope.list = [];
    $scope.onRefreshList = function(casinoId) {
        requestService.get(
            '/api/casino/child-casinos?casinoId=' + casinoId,
            function(data) {
                $scope.list = data;
                $scope.$emit('childCasinosResponse', data);
            }
        );
    }
}]);

app.controller('LanguagesDropdownCtrl', ['$scope', 'requestService', '$translate', function($scope, requestService, $translate) {
    $scope.list = [];
    var languageTranslations = {
        EN: null,
        ZH: null
    };
    $scope.onRefreshList = function() {
        requestService.get(
            '/api/enums/languages-list',
            function(data) {
                $translate(['GENERAL.LANGUAGES.EN', 'GENERAL.LANGUAGES.ZH'])
                    .then(function (translations) {
                        languageTranslations.EN = translations['GENERAL.LANGUAGES.EN'];
                        languageTranslations.ZH = translations['GENERAL.LANGUAGES.ZH'];
                        $scope.list = transformResponseData(data);
                    }, function (translationIds) {
                        languageTranslations.EN = translationIds['GENERAL.LANGUAGES.EN'];
                        languageTranslations.ZH = translationIds['GENERAL.LANGUAGES.ZH'];
                        $scope.list = transformResponseData(data);
                    });
            }
        );
    };

    function transformResponseData(data) {
        var result = [];
        for (var i = 0; i < data.length; i++) {
            result.push({
                code: data[i],
                displayName: languageTranslations[data[i]]
            });
        }
        return result;
    }
}]);
