app.controller('PlayersTargetingModalInstanceCtrl', ['$scope', '$log', '$filter', '$modalInstance', '$modal', '$timeout', 'Upload', 'requestService',
    'notificationService', 'campaignId', 'campaignName', 'campaignTargetingId', 'openInReadMode', 'editAllowed', 'ERROR_CODES', '$translate',
    function($scope, $log, $filter, $modalInstance, $modal, $timeout, Upload, requestService, notificationService, campaignId, campaignName,
        campaignTargetingId, openInReadMode, editAllowed, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {};
            $scope.formErrors = {};
            $scope.table = {
                players: [],
                pageSizes: [1, 10, 25, 50],
                pageSize: 10,
                totalSize: 0
            };
            $scope.list = [];

            $scope.progress = '';

            $scope.onAddPlayers = function(players) {
                if (players) {
                    createPlayersTargeting(campaignId, players);
                }
            };

            $scope.onUploadPlayers = function(file) {
                if (file) {
                    uploadPlayersTargeting(campaignId, file);
                }
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.tagTransform = function(playerId) {
                return {
                    playerId: playerId
                };
            };

        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function uploadPlayersTargeting(campaignId, file) {
            if (!file.$error) {
                Upload.upload({
                    url: '/api/campaigns/players-targeting',
                    data: {
                        campaignId: campaignId,
                        file: file
                    }
                }).progress(function(evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.progress = progressPercentage + '% ' + evt.config.data.file.name + '\n' + $scope.progress;
                }).success(function(data, status, headers, config) {
                    onSuccess(data);
                }).error(function(error) {
                    onError(error);
                });
            }
        }

        function createPlayersTargeting(campaignId, players) {
            requestService.postEncoded('/api/campaigns/players-targeting',
                {
                    campaignId: campaignId,
                    players: players
                },
                function(data) {
                    onSuccess(data);
                },
                function(error) {
                    onError(error);
                }
            );
        }

        function onSuccess(data) {
            $scope.progress = null;
            if (data.status.code === ERROR_CODES.OK) {

                // TODO clear formData
                // $scope.formData = data.values;

                notificationService.showInfoToast($scope.translations.popupPlayersTargetingCreated);
                // TODO reload/update(data) table 
                closeModal();
            } else {
                if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                    notificationService.showWarningToast($scope.translations.errorInvalidForm);
                } else {
                    notificationService.processApplicationError(data.status, $scope.translations.errorCreatingPlayersTargeting);
                }
            }
        }

        function onError(error) {
            $scope.formErrors = {};
            notificationService.processServerError(error);
        }

        function initTranslations() {
            var translations = {};
            $translate(['FR_CAMPAIGN_MANAGER.POPUP_PLAYERS_TARGETING_CREATED', 'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_PLAYERS_TARGETING',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM']).then(function(translation) {
                translations.popupPlayersTargetingCreated = translation['FR_CAMPAIGN_MANAGER.POPUP_PLAYERS_TARGETING_CREATED'];
                translations.errorInvalidForm = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                translations.errorCreatingPlayersTargeting = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_PLAYERS_TARGETING'];
            }, function(translationIds) {
                translations.popupPlayersTargetingCreated = translationIds['FR_CAMPAIGN_MANAGER.POPUP_PLAYERS_TARGETING_CREATED'];
                translations.errorInvalidForm = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                translations.errorCreatingPlayersTargeting = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_PLAYERS_TARGETING'];
            });
            return translations;
        }
    }
]);

