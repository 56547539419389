app.controller('MarketingMaterialsRootCtrl', ['$scope', '$filter', '$log', '$modal', 'cacheService', 'requestService', 'notificationService',
    'mailService', 'USER_ROLES', 'ERROR_CODES', '$translate',
    function($scope, $filter, $log, $modal, cacheService, requestService, notificationService, mailService, USER_ROLES, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.availableCategories = [
                {type: 'all', name: 'ALL', selected: true},
                {type: 'slots', name: 'SLOTS', selected: false},
                {type: 'branded', name: 'BRANDED_SLOTS', selected: false},
                {type: 'threesixty', name: 'SLOTS_360', selected: false},
                {type: 'mobi', name: 'MOBI', selected: false},
                {type: 'classic', name: 'CLASSIC', selected: false},
                {type: 'dice', name: 'DICE', selected: false}
            ];

            $scope.statuses = {
                concept: true,
                published: true
            };

            $scope.inReadMode = cacheService.getWithDefault('materials.inReadMode', true);
            $scope.$watch('inReadMode', function() {
                cacheService.put('materials.inReadMode', $scope.inReadMode);
            });

            $scope.list = [];

            $scope.onCreateRootFolder = function() {
                openItemModal(null);
            };

            $scope.onEditRootFolder = function(name) {
                openItemModal(name);
            };

            $scope.onRemoveRootFolder = function(name) {
                removeFolder(name);
            };

            $scope.onSendRequest = function() {
                mailService.showMailModal($scope.translations.requestMaterialsSubject, null, $scope.globals.currentUser.email || '');
            };

            $scope.onDownloadAll = function(path) {
                downloadAll(path);
            };

            $scope.searchFilter = '';
            $scope.orderField = 'name';

            $scope.statusFilter = function(folder) {
                if ($scope.isAuthorized([USER_ROLES.ADMIN, USER_ROLES.MARKETING_MATERIALS_UPDATE])) {
                    if ($scope.statuses.published && folder.status === 'PUBLISHED') {
                        return true;
                    }
                    if ($scope.statuses.concept && folder.status === 'CONCEPT') {
                        return true;
                    }
                } else {
                    if (folder.status === 'PUBLISHED') {
                        return true;
                    }
                }
                return false;
            };

            $scope.isSelected = function(categories) {
                var allFiltered = $filter('filter')($scope.availableCategories, {type: 'all'}, true);
                if (allFiltered[0].selected == true) {
                    return true;
                }

                if (categories) {
                    for (var i = 0, len = categories.length; i < len; i++) {
                        var filtered = $filter('filter')($scope.availableCategories, {type: categories[i]}, true);
                        if (filtered.length !== 0 && filtered[0].selected == true) {
                            return true;
                        }
                    }
                }
                return false;
            };

            $scope.onChangeStatus = function(status) {
                switch (status) {
                    case "published":
                        if ($scope.statuses.concept !== false) {
                            $scope.statuses.published = !$scope.statuses.published;
                        }
                        break;
                    case "concept":
                        if ($scope.statuses.published !== false) {
                            $scope.statuses.concept = !$scope.statuses.concept;
                        }
                        break;
                }
            };

            $scope.selectCategory = function(category) {
                var availableCategories = $scope.availableCategories;
                if (category.type === 'all' && category.selected === false) {
                    for (var i = 0; i < availableCategories.length; i++) {
                        availableCategories[i].selected = false;
                    }
                } else {
                    for (var j = 0; j < availableCategories.length; j++) {
                        if (availableCategories[j].type === 'all') {
                            if (availableCategories[j].selected === true) {
                                availableCategories[j].selected = false;
                            }
                            break;
                        }
                    }
                }
                category.selected = !category.selected;
            };

            getFolders();
        }

        function downloadAll(path) {
            requestService.get(
                '/api/files/get-download-link?path=' + path,
                function(data) {
                    if (data) {
                        var url = '/api/files/folder-download?link=' + data;
                        download(url);
                    } else {
                        notificationService.showErrorToast($scope.translations.errorGenerateDownloadLink);
                    }
                }
            );
        }

        function download(url) {
            $("#downloadFormPoster").remove();
            $("<div id='downloadFormPoster' style='display: none;'><iframe name='downloadFormPosterIframe'></iframe></div>").appendTo('body');
            $("<form action='" + url + "' target='downloadFormPosterIframe' method='post'></form>")
                .appendTo("#downloadFormPoster")
                .submit();
        }

        function getFolders() {
            requestService.get(
                '/api/files/folder-list',
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.list = data.list;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingMarketingMaterials);
                    }
                }
            );
        }

        function removeFolder(path) {
            requestService.delete(
                '/api/files/remove?path=' + path,
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                        notificationService.processApplicationError(response.status, $scope.translations.errorRemovingFolder);
                    }
                    getFolders();
                }
            )
        }

        function openItemModal(name) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/marketing/marketing_materials_root_folder_modal.html',
                controller: 'MarketingMaterialsRootFolderModalCtrl',
                backdrop: false,
                resolve: {
                    path: function() {
                        return name;
                    },
                    name: function() {
                        return name;
                    }
                }
            });

            modalInstance.result.then(function() {
                getFolders();
            }, function() {
                getFolders();
            });
        }

        function initTranslations() {
            var translations = {};
            $translate(['MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST', 'MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK',
                'MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS', 'MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER'])
                .then(function(translation) {
                    translations.requestMaterialsSubject = translation['MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST'];
                    translations.errorGenerateDownloadLink = translation['MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK'];
                    translations.errorLoadingMarketingMaterials = translation['MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS'];
                    translations.errorRemovingFolder = translation['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER'];
                }, function(translationIds) {
                    translations.requestMaterialsSubject = translationIds['MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST'];
                    translations.errorGenerateDownloadLink = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK'];
                    translations.errorLoadingMarketingMaterials = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS'];
                    translations.errorRemovingFolder = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_FOLDER'];
                });
            return translations;
        }
    }
]);
