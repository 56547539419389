app.controller('ResetPageCtrl',
    ['$rootScope', '$stateParams', '$scope', '$modal', '$log', 'userAuthService',
        'notificationService', 'EVENT_CODES', 'ERROR_CODES',
        function ($rootScope, $stateParams, $scope, $modal, $log, userAuthService,
            notificationService, EVENT_CODES, ERROR_CODES) {

            const STATES = {
                RESET: 'RESET',
                TOKEN_EXPIRED: 'TOKEN_EXPIRED'
            };

            init();

            function init() {
                var token = $stateParams.token;

                $scope.formData = {};
                $scope.formErrors = {};
                $scope.STATES = STATES;
                $scope.state = STATES.RESET;
                $scope.token = token;

                $scope.onChangePassword = onChangePassword;

                getUserByToken(token);
            }

            function getUserByToken(token) {
                userAuthService.getUserByToken(token, function (response) {
                    $scope.formErrors = response.status.formErrors || {};

                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.formData.username = response.user.login;
                    } else {
                        if (response.status.code === ERROR_CODES.UNAUTHORIZED) {
                            $scope.state = STATES.TOKEN_EXPIRED;

                            notificationService.showMessageDialog(
                                'Password reset link expired',
                                'Please send new request to reset password.',
                                null);
                        } else {
                            notificationService.processApplicationError(
                                response.status,
                                'Error when checking reset token'
                            );
                        }
                    }
                })
            }

            function onChangePassword() {
                userAuthService.changePassword(
                    $scope.token,
                    $scope.formData,
                    function (response) {
                        $scope.formErrors = response.status.formErrors
                            || {};

                        if (response.status.code === ERROR_CODES.OK) {
                            notificationService.showInfoToast(
                                'Password successfully changed');
                            showPswdChangedMessageDialog()
                                .then(function () {
                                    $scope.formErrors = {};
                                    $scope.$emit(EVENT_CODES.PASSWORD_CHANGED);
                                });
                        } else {
                            if (response.status.code
                                === ERROR_CODES.UNAUTHORIZED) {
                                $scope.state = STATES.TOKEN_EXPIRED;

                                notificationService.showMessageDialog(
                                    'Password reset link expired',
                                    'Please send new request to reset password.',
                                    null);
                            } else if (response.status.code
                                === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast(
                                    'Invalid form');
                            } else {
                                notificationService.processApplicationError(
                                    response.status,
                                    'Error when changing password'
                                );
                            }
                        }
                    }
                );
            }

            function showPswdChangedMessageDialog() {
                var settings = {
                    templateUrl: 'app/templates/message_dialog.html',
                    modalTexts: {
                        title: 'Password successfully changed',
                        ok: 'OK'
                    },
                    size: '-xsm',
                    backdrop: false,
                    controller: 'ConfirmModalInstanceCtrl',
                    resolve: {
                        data: function () {
                            return settings.modalTexts;
                        }
                    }
                };

                return $modal.open(settings).result;
            }
        }
    ]
);
