app.controller('UsersCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', '$translate',
    function($scope, $log, $modal, requestService, notificationService, $translate) {

        $scope.translations = initTranslations();
        init();
        initTable();

        function init() {
            $scope.searchFilter = '';

            $scope.onNewUser = function() {
                openUserModal(null, null);
            };

            $scope.onEditUser = function(login) {
                openUserModal(login, null);
            };

            $scope.onDuplicateUser = function(loginToDuplicate) {
                openUserModal(null, loginToDuplicate);
            };

            $scope.onRemoveUser = function(login) {
                removeUser(login)
            };

            $scope.onEvaluateUserStatus = function(user) {
                return evaluateUserStatus(user);
            }
        }

        function initTable() {
            $scope.table = {
                users: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getTableData();
            };

            getTableData();
        }

        function getTableData() {
            requestService.get(
                '/api/users/users-list',
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                    } else {
                        $scope.table.users = response.list;
                        updateUserStatuses();
                    }
                }
            );
        }

        function removeUser(login) {
            requestService.delete(
                '/api/users/user/' + login,
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                        notificationService.processApplicationError(response.status, $scope.translations.errorRemovingUser);
                    }
                    getTableData();
                }
            )
        }

        function updateUserStatuses() {
            for (var i = 0; i < $scope.table.users.length; i++) {
                var user = $scope.table.users[i];
                user.status = evaluateUserStatus(user);
            }
        }

        function evaluateUserStatus(user) {
            var isActive = Boolean(user.active);
            var used = Boolean(user.lastLogin);

            if (used && isActive) {
                return $translate.instant('USER_ADMINISTRATION.STATUSES.ACTIVE');
            }
            if (!used && isActive) {
                return $translate.instant('USER_ADMINISTRATION.STATUSES.CREATED');
            }
            if (used && !isActive) {
                return $translate.instant('USER_ADMINISTRATION.STATUSES.DEACTIVATED');
            }
            if (!used && !isActive) {
                return $translate.instant('USER_ADMINISTRATION.STATUSES.NOT_ACTIVATED');
            }

            return "";
        }

        function openUserModal(login, loginToDuplicate) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/admin/user_administration/user_modal.html',
                controller: 'UserModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    login: function() {
                        return login;
                    },
                    loginToDuplicate: function() {
                        return loginToDuplicate;
                    }
                }
            });

            modalInstance.result.then(function() {
                getTableData();
            }, function() {
                getTableData();
            });
        }

        function initTranslations() {
            var translations = {};
            $translate(['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER'])
                .then(function(translation) {
                    translations.errorRemovingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER'];
                }, function(translationIds) {
                    translations.errorRemovingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER'];
                });
            return translations;
        }
    }
]);
