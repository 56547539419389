app.controller('SearchCtrl', ['$rootScope', '$scope', '$log', '$filter', '$state', '$timeout', '$window',
    'urlParamsService', 'requestService', 'notificationService', 'ERROR_CODES', 'GAME_ROUND_STATE',
    function($rootScope, $scope, $log, $filter, $state, $timeout, $window,
        urlParamsService, requestService, notificationService, ERROR_CODES, GAME_ROUND_STATE) {

        initForm();
        initTable();
        initData();

        if ($scope.DEBUG) {
            initTestData();
        }

        $timeout(function() {
            if (urlParamsService.hasAnyParams()) {
                $scope.tab.formData = urlParamsService.getParams();

                if ($state.is('game-sessions.session_by_player') &&
                    urlParamsService.hasParams(['casinoId', 'playerId', 'dateFrom', 'dateTo'])
                ) {
                    $scope.tab.formData = changeDateFormat($scope.tab.formData);

                    $scope.dateFrom = $scope.tab.formData.dateFrom;
                    $scope.dateTo = $scope.tab.formData.dateTo;

                    searchFilter();
                }
                if ($state.is('game-sessions.session_by_session_id') &&
                    urlParamsService.hasParams(['casinoId', 'playerId', 'sessionId'])
                ) {
                    $scope.tab.formData.dateFrom = null;
                    $scope.tab.formData.dateTo = null;

                    searchBySessionId();
                }
                if ($state.is('game-sessions.round_by_round_id') &&
                    urlParamsService.hasParams(['casinoId', 'playerId', 'gameRoundId'])
                ) {
                    searchByRoundId();
                }
            }
        }, $scope.DEFAULT_TIMEOUT);

        function initData() {
            $scope.mainExpanded = true;
            $scope.datesExpanded = true;
            $scope.moneyExpanded = true;
            $scope.bonusesFrsExpanded = true;
            $scope.featuresExpanded = true;
            $scope.gameRoundsExpanded = false;

            $scope.session = null;
            $scope.round = null;
        }

        function initTestData() {
            $scope.tab.table = [
                {
                    gameSessionConfig: [
                        {
                            data: [
                                {
                                    sessionId: 12,
                                    totalWin: 20,
                                    dateStarted: new Date('5/8/2015').toISOString(),
                                    dateFinished: new Date('8/8/2015').toISOString(),
                                    replayAllowed: true,
                                    sessions: [
                                        {
                                            sessionId: 12,
                                            totalWin: 20,
                                            dateStarted: '6/8/2015',
                                            dateFinished: '8/8/2015',
                                            rounds: [
                                                {
                                                    sessionId: 12,
                                                    gameRoundId: 23,
                                                    state: 'finished'
                                                }
                                            ]
                                        }
                                    ],
                                    rounds: [
                                        {
                                            sessionId: 12,
                                            gameRoundId: 23,
                                            state: 'finished',
                                            features: {
                                                round: {},
                                                spin: [
                                                    {CREDITWON: [300], SPINSWON: [1], GLOBMULTI: 1, EXPANDINGWILD: true},
                                                    {CREDITWON: [70], SPINSWON: [0], GLOBMULTI: 2, EXPANDINGWILD: false}
                                                ]
                                            }
                                        }
                                    ]
                                },
                                {
                                    sessionId: 12,
                                    totalWin: 20,
                                    dateStarted: new Date('5/8/2015').toISOString(),
                                    dateFinished: new Date('8/8/2015').toISOString(),
                                    sessions: [
                                        {
                                            sessionId: 12,
                                            totalWin: 20,
                                            dateStarted: '6/8/2015',
                                            dateFinished: '8/8/2015',
                                            rounds: [
                                                {
                                                    sessionId: 12,
                                                    gameRoundId: 23,
                                                    state: 'finished'
                                                }
                                            ]
                                        }
                                    ],
                                    rounds: [
                                        {
                                            sessionId: 12,
                                            gameRoundId: 23,
                                            state: 'finished',
                                            features: {
                                                round: {MACHINECNT: 2},
                                                spin: [
                                                    {CREDITWON: [36, 0, 0, 0], SPINSWON: [10, 0, 0, 0], WILDMULTI: [[3], [], [], []], DROPPINGWILD: true},
                                                    {CREDITWON: [1720], SPINSWON: [0], WILDMULTI: [[8, 12]], DROPPINGWILD: false},
                                                    {CREDITWON: [36, 0, 0, 0], SPINSWON: [0, 0, 0, 0], WILDMULTI: [[], [], [], []], DROPPINGWILD: true},
                                                    {CREDITWON: [0, 500, 0, 0], SPINSWON: [0, 0, 0, 0], WILDMULTI: [[], [5], [], [9]], DROPPINGWILD: true},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[6, 7]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[13]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[]], DROPPINGWILD: false},
                                                    {CREDITWON: [0], SPINSWON: [0], WILDMULTI: [[]], DROPPINGWILD: false}
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    gameRoundConfig: [
                        {
                            data: [
                                {
                                    sessionId: 12,
                                    gameRoundId: 1,
                                    state: 'finished',
                                    platform: $scope.GAME_PLATFORM.DESKTOP,
                                    replayAllowed: true
                                },
                                {
                                    gameRoundId: 2,
                                    state: 'finished',
                                    gameName: 'Dragons Mystery',
                                    features: {
                                        round: {MACHINECNT: 2},
                                        spin: [
                                            {CREDITWON: [300], SPINSWON: [1], GLOBMULTI: 1, EXPANDINGWILD: true},
                                            {CREDITWON: [70], SPINSWON: [0], GLOBMULTI: 2, EXPANDINGWILD: false}
                                        ]
                                    },
                                    platform: $scope.GAME_PLATFORM.MOBILE,
                                    replayAllowed: false
                                },
                                {
                                    gameRoundId: 3,
                                    state: 'finished',
                                    platform: $scope.GAME_PLATFORM.MINIGAME
                                }
                            ]
                        }
                    ]
                }
            ];

            $scope.player = {
                playerId: '8aefe1e55463fe81015463fe81af0000',
                casinoId: 3,
                username: 'Demo Player',
                registrationDate: null,
                gender: 'M',
                dateOfBirth: null,
                isTestingUser: false
            }
        }

        function initForm() {
            $scope.tab = {
                formData: {},
                formErrors: {}
            };

            $scope.onFilterSearch = function() {
                searchFilter();
            };

            $scope.onSessionIdSearch = function() {
                searchBySessionId();
            };

            $scope.onRoundIdSearch = function() {
                searchByRoundId();
            };

            $scope.onGameSessionReplay = function(sessionId) {
                replayGameRound($scope.page.selectedCasinoId, $scope.tab.formData.playerId, sessionId, null);
            };

            $scope.onGameRoundReplay = function(sessionId, gameRoundId) {
                replayGameRound($scope.page.selectedCasinoId, $scope.tab.formData.playerId, sessionId, gameRoundId);
            };

            $scope.toggleOverflow = function(event) {
                setTimeout(function(){
                    event.currentTarget.parentElement.parentElement.children[1].classList.toggle('overflow-inherit');
                }, 450);
            };
        }

        function initTable() {
            $scope.tab.table = {
                gameSessionConfig: {
                    data: [],
                    pageSizes: [1, 10, 25, 50, 100],
                    pageSize: 25,
                    totalSize: 0,
                    sortBy: [],
                    sortColumn: null,
                    sortAsc: null,
                    offset: 0
                },

                gameRoundConfig: {
                    data: [],
                    pageSizes: [1, 10, 25, 50, 100],
                    pageSize: 25,
                    totalSize: 0,
                    sortBy: [],
                    sortColumn: null,
                    sortAsc: null,
                    offset: 0
                },

                sessionMenuOptions: [
                    [
                        function($itemScope, $event, session) {
                            if (session.isRoundSelected) {
                                return 'Hide Game Rounds';
                            } else {
                                return 'Show Game Rounds';
                            }
                        },
                        function($itemScope, $event, session) {
                            $scope.onShowGameRounds(session);
                        }
                    ],
                    null, // Divider
                    [
                        'Replay Session',
                        function($itemScope, $event, session) {
                            $scope.onGameSessionReplay(session.sessionId);
                        },
                        function($itemScope, $event, title, session) {
                            return session.replayAllowed;
                        }
                    ]
                ],

                roundMenuOptions: [
                    [
                        'Replay Session',
                        function($itemScope, $event, round) {
                            $scope.onGameSessionReplay(round.sessionId);
                        },
                        function($itemScope, $event, title, round) {
                            return round.replayAllowed;
                        }
                    ],
                    [
                        'Replay Game Round',
                        function($itemScope, $event, round) {
                            $scope.onGameRoundReplay(round.sessionId, round.gameRoundId);
                        },
                        function($itemScope, $event, title, round) {
                            return round.replayAllowed;
                        }
                    ]
                ],

                sessions: []
            };

            // Watch for changes, then load data
            $scope.$watch('tab.table.gameSessionConfig.offset', onSessionTablePageOffsetChange);
            $scope.$watch('tab.table.gameRoundConfig.offset', onRoundTablePageOffsetChange);
            $scope.$watch('tab.table.gameSessionConfig.sortBy', onSortSessionsByChange, true);
            $scope.$watch('tab.table.gameRoundConfig.sortBy', onSortRoundsByChange, true);
            $scope.$watch('tab.table.gameSessionConfig.pageSize', onSessionTablePaginationChange, true);
            $scope.$watch('tab.table.gameRoundConfig.pageSize', onRoundTablePaginationChange, true);

            $scope.onShowGameRounds = function(session) {
                if (session) {
                    if (session.isRoundSelected) {
                        session.isRoundSelected = false;
                    } else {
                        session.isRoundSelected = true;

                        getRoundTableDataBySession(session, session.isRoundSelected);
                    }
                } else {
                    getRoundTableDataBySession();
                }
            };

            $scope.onRefreshSessionTableData = function() {
                getSessionTableData(url, true);
            };

            $scope.onRefreshGameRoundTableData = function(session) {
                var config = {
                    pageSize: session.pageSize,
                    totalSize: session.totalSize,
                    offset: session.offset
                }

                getRoundTableDataBySession(session, true, config);
            };

            $scope.onSetSession = function(session) {
                onSetSession(session);
            };

            $scope.onChangePaging = function(session) {
                if ($scope.tab.table.gameRoundConfig.offset === 0) {
                    $scope.onUpdateRoundTable(session);
                }

                onSetSession(session);
            }

            $scope.onUpdateRoundTable = function(session, offset) {
                var config = {
                    pageSize: session.pageSize,
                    totalSize: session.totalSize,
                }

                if (offset != null) {
                    config.offset = offset;
                } else {
                    config.offset = session.offset;
                }

                getRoundTableDataBySession(session, true, config);
            };

            $scope.isGameRoundValidState = function(gameRoundState) {
                return gameRoundState === GAME_ROUND_STATE.FINISHED ? true : false;
            };
        }

        var url;

        function searchFilter() {
            url = 'api/game-session-filter/table-data';
            getSessionTableData(url, true);
            getPlayerInfo();
        }

        function searchBySessionId() {
            url = '/api/game-session-id/table-data';
            getSessionTableData(url, false);
            getPlayerInfo();
        }

        function searchByRoundId() {
            url = '/api/game-round-id/table-data';
            getRoundByRoundId(url);
            getPlayerInfo();
        }

        function onSetSession(session) {
            $scope.selectedSession = session;
        }

        function onSessionTablePaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getSessionTableData(url, true);
            }
        }

        function onSessionTablePageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getSessionTableData(url, true);
            }
        }

        function onRoundTablePaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getRoundTableDataBySession();
            }
        }

        function onRoundTablePageOffsetChange(newValue, oldValue) {
            if ($scope.selectedSession) {
                if ($scope.tab.table.gameRoundConfig.offset != null) {
                    $scope.onUpdateRoundTable($scope.selectedSession, $scope.tab.table.gameRoundConfig.offset);
                    $scope.tab.table.gameRoundConfig.offset = null;
                }
            } else {
                if (newValue !== oldValue) {
                    getRoundTableDataBySession();
                }
            }
        }

        function onSortSessionsByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.tab.table.gameSessionConfig.sortBy.length > 0) {
                    if ($scope.tab.table.gameSessionConfig.sortBy[0].charAt(0) !== '-') {
                        $scope.tab.table.gameSessionConfig.sortColumn = $scope.tab.table.gameSessionConfig.sortBy[0];
                        $scope.tab.table.gameSessionConfig.sortAsc = true;
                    } else {
                        $scope.tab.table.gameSessionConfig.sortColumn = $scope.tab.table.gameSessionConfig.sortBy[0].slice(1);
                        $scope.tab.table.gameSessionConfig.sortAsc = false;
                    }
                } else {
                    $scope.tab.table.gameSessionConfig.sortAsc = null;
                    $scope.tab.table.gameSessionConfig.sortColumn = null;
                }
                getSessionTableData(url, true);
            }
        }

        function onSortRoundsByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.tab.table.gameRoundConfig.sortBy.length > 0) {
                    if ($scope.tab.table.gameRoundConfig.sortBy[0].charAt(0) !== '-') {
                        $scope.tab.table.gameRoundConfig.sortColumn = $scope.tab.table.gameRoundConfig.sortBy[0];
                        $scope.tab.table.gameRoundConfig.sortAsc = true;
                    } else {
                        $scope.tab.table.gameRoundConfig.sortColumn = $scope.tab.table.gameRoundConfig.sortBy[0].slice(1);
                        $scope.tab.table.gameRoundConfig.sortAsc = false;
                    }
                } else {
                    $scope.tab.table.gameRoundConfig.sortAsc = null;
                    $scope.tab.table.gameRoundConfig.sortColumn = null;
                }
                getRoundTableDataBySession();
            }
        }

        function replayGameRound(casinoId, playerId, gameSessionId, gameRoundId) {
            var url = '/api/game-session/get-replay-url';
            requestService.postEncoded(url,
                {
                    playerId: playerId,
                    casinoId: casinoId,
                    gameSessionId: gameSessionId,
                    gameRoundId: gameRoundId
                },
                function(data) {
                    if (data.status.code === 'OK') {
                        openReplayWindow(data.gameClientJsLibUrl, data.replaySessionUrl, data.gameRoundRef);
                    } else {
                        notificationService.processApplicationError(
                            data.status,
                            'Error when fetching replay url. Data migration may be under way. Try to refresh the page.'
                        );
                    }
                }
            );
        }

        function openReplayWindow(gcwUrl, replayUrl, roundRef) {
            var replayLink = window.location.origin + '/app/templates/replay.html';
            $log.info('Replay page: ', replayLink);

            var hash = '#' + 'gcwUrl=' + gcwUrl + '&' + 'replayUrl=' + replayUrl;
            if (roundRef) {
                hash += '&' + 'roundRef=' + roundRef;
            }

            $window.open(replayLink + hash, '_blank');
        }

        function getPlayerInfo() {
            var casinoId = $scope.page.selectedCasinoId;
            var playerId = $scope.tab.formData.playerId;
            var ignoreError = true;

            if (casinoId && playerId) {
                requestService.postEncoded(
                    '/api/game-session/player-info',
                    {
                        casinoId: casinoId,
                        playerId: playerId
                    },
                    function(data) {
                        $scope.player = data.player || null;
                    },
                    null,
                    ignoreError
                );
            }
        }

        function getSessionTableData(url, dateRequired) {
            if (url) {
                var filterData = angular.extend({}, $scope.tab.formData, {casinoId: $scope.page.selectedCasinoId});

                if (dateRequired) {
                    urlParamsService.setParams(changeDateFormat(filterData, true));
                } else {
                    urlParamsService.setParams(filterData);
                }

                requestService.postEncoded(
                    getDataUrl(url, $scope.tab.table.gameSessionConfig),

                    filterData,
                    function(response) {
                        updateSessionTableData(response);
                    },
                    function() {
                        setErrors({});
                    }
                );
            } else {
                //$log.warn('Request without url. Before search clicked.');
            }
        }

        function updateSessionTableData(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);

            $scope.tab.table.gameSessionConfig.data = tableData;
            $scope.tab.table.gameSessionConfig.totalSize = response.dataSize;

            if (tableData) {
                $scope.session = tableData[0];
            }

            if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                notificationService.showWarningToast('Invalid search');
            } else if (response.dataSize == 0) {
                notificationService.showInfoToast('No data found');
            }

            // Set errors
            setErrors(response.status.formErrors || {});
        }

        function getRoundByRoundId(url) {
            if (url) {
                var filterData = angular.extend({}, $scope.tab.formData, {casinoId: $scope.page.selectedCasinoId});

                urlParamsService.setParams(filterData);

                requestService.postEncoded(
                    getDataUrl(url, $scope.tab.table.gameRoundConfig),

                    filterData,
                    function(response) {
                        updateRoundTableData(response);
                    },
                    function() {
                        setErrors({});
                    }
                );
            } else {
                //$log.warn('Request without url. Before search clicked.');
            }
        }

        function getRoundTableDataBySession(session, isRoundSelected, config) {
            if ($scope.gameRoundsExpanded || isRoundSelected) {
                var filterData = angular.extend({}, $scope.tab.formData, {casinoId: $scope.page.selectedCasinoId});

                if (session){
                    filterData.sessionId = session.sessionId;
                    filterData.dateFrom = session.dateStarted;
                    filterData.dateTo = session.dateFinished;
                }

                var url = '/api/game-round-session-id/table-data';

                if (!config) {
                    config = $scope.tab.table.gameRoundConfig;
                }

                requestService.postEncoded(
                    getDataUrl(url, config),
                    filterData,
                    function(response) {
                        updateRoundTableData(response, session);
                    },
                    function() {
                        setErrors({});
                    }
                );

            }
        }

        var getDataUrl = function (endpoint, config) {
            var url = endpoint +
                '?dataOffset=' + config.offset +
                '&dataSegmentSize=' + config.pageSize +
                '&dataSize=' + config.totalSize;

            if (config.sortColumn) {
                url += '&orderColumn=' + config.sortColumn;
            }
            if (config.sortAsc) {
                url += '&orderAsc=' + config.sortAsc;
            }

            return url;
        };

        function updateRoundTableData(response, session) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);
            _parseFeatures(tableData);

            if (session) {
                session['rounds'] = tableData;
                session.totalSize = response.dataSize;
                session.pageSize = response.dataSegmentSize;
                session.offset = response.dataOffset;
            } else if ($scope.session) {
                $scope.tab.table.gameRoundConfig.data = tableData;
                $scope.tab.table.gameRoundConfig.totalSize = response.dataSize;
            } else {
                if (tableData) {
                    $scope.round = tableData[0];
                }
            }

            if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                notificationService.showWarningToast('Validation errors when fetching game rounds'/*, response.status.formErrors*/);
            } else if (response.dataSize == 0) {
                notificationService.showInfoToast('No data found');
            }

            // Set errors
            setErrors(response.status.formErrors || {});
        }

        function setErrors(formErrors) {
            $scope.tab.formErrors = formErrors;
            $scope.page.casinoError = formErrors.casinoId || '';
        }

        function _parseFeatures(tableData) {
            for (var i = 0, len = tableData.length; i < len; i++) {
                tableData[i]['features'] = JSON.parse(tableData[i]['features']);
            }
        }

        function changeDateFormat(filterData, urlFormat) {
            var dateFrom = null;

            if (filterData.dateFrom) {
                if (urlFormat) {
                    dateFrom = filterData.dateFrom.replace(new RegExp('/', 'g'), '-');
                } else {
                    dateFrom = filterData.dateFrom.replace(new RegExp('-', 'g'), '/');
                }
            }

            var dateTo = null;

            if (filterData.dateTo) {
                if (urlFormat) {
                    dateTo = filterData.dateTo.replace(new RegExp('/', 'g'), '-');
                } else {
                    dateTo = filterData.dateTo.replace(new RegExp('-', 'g'), '/');
                }
            }

            return {
                casinoId: filterData.casinoId,
                playerId: filterData.playerId,
                sessionId: filterData.sessionId,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        }
    }
]);
