app.controller('MarketingMaterialsSublevelFileModalCtrl', ['$scope', '$log', '$modalInstance', '$modal', 'requestService', 'notificationService',
    'path', 'name', 'ERROR_CODES', '$translate',
    function($scope, $log, $modalInstance, $modal, requestService, notificationService, path, name, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {
                oldName: name,
                name: name,
                parentPath: path,
                oldPath: path + "/" + name
            };
            $scope.formErrors = {};

            $scope.onSave = function() {
                saveItem();
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function saveItem() {
            requestService.putEncoded('/api/files/file-update',
                $scope.formData,
                function(data) {
                    onSuccess(data);
                },
                function(error) {
                    onError(error);
                }
            );
        }

        function onError(error) {
            $scope.formErrors = {};
            notificationService.processServerError(error);
        }

        function onSuccess(data) {
            $scope.progress = null;
            $scope.formErrors = data.status.formErrors || {};

            if (data.status.code === ERROR_CODES.OK) {
                $scope.formData = data.values;

                notificationService.showInfoToast($scope.translations.infoFileStored);
                closeModal();
            } else {
                if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                    notificationService.showWarningToast($scope.translations.errorInvalidForm);
                } else {
                    notificationService.processApplicationError(data.status, $scope.translations.errorStoringFile);
                }
            }
        }

        function initTranslations() {
            var translations;
            $translate(['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FILE', 'MARKETING_MATERIALS.ERROR_MESSAGES.INVALID_FORM',
                'MARKETING_MATERIALS.ERROR_MESSAGES.INFO_FILE_STORED'])
                .then(function(translation) {
                    translations.errorStoringFile = translation['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FILE'];
                    translations.errorInvalidForm = translation['MARKETING_MATERIALS.ERROR_MESSAGES.INVALID_FORM'];
                    translations.infoFileStored = translation['MARKETING_MATERIALS.ERROR_MESSAGES.INFO_FILE_STORED'];
                }, function(translationIds) {
                    translations.errorStoringFile = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FILE'];
                    translations.errorInvalidForm = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.INVALID_FORM'];
                    translations.infoFileStored = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.INFO_FILE_STORED'];
                });
            return translations;
        }
    }
]);

