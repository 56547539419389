app.factory('userSettingsService', ['$http', '$log', '$httpParamSerializer', 'notificationService', '$translate',
    function($http, $log, $httpParamSerializer, notificationService, $translate) {

        var service = {};

        service.get = get;
        service.put = put;

        return service;

        function put(key, value, onSuccess, onError) {
            $log.info('User settings put', key);
            $http({
                method: 'PUT',
                url: '/api/user/user-setting',
                headers: {'Content-Type': 'application/json'},
                params: {key: key},
                data: angular.toJson(value)
            }).success(function(data) {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).error(function(error) {
                $log.error('Caching of user settings failed.', error);
                if (onError) {
                    onError(error);
                } else {
                    notificationService.showErrorToast($translate.instant('GENERAL.SERVICES.USER_SETTINGS.ERROR_MESSAGES.USER_SETTINGS_CACHING'));
                }
            });
        }

        function get(key, onSuccess, onError) {
            $log.info('User settings get', key);
            $http({
                method: 'GET',
                url: '/api/user/user-setting',
                params: {key: key}
            }).success(function(data) {
                if (onSuccess) {
                    $log.info(data);
                    onSuccess(angular.fromJson(data.value));
                }
            }).error(function(error) {
                $log.error('Retrieving of user settings (' + key + ') failed. ', error);
                if (onError) {
                    onError(error);
                } else {
                    notificationService.showErrorToast($translate.instant('GENERAL.SERVICES.USER_SETTINGS.ERROR_MESSAGES.USER_SETTINGS_RETRIEVING'));
                }
            });
        }
    }
]);
