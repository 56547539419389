app.factory('requestService', ['$http', '$log', '$httpParamSerializer', 'notificationService', 'PROPERTIES',
    function($http, $log, $httpParamSerializer, notificationService, PROPERTIES) {

        var service = {};
        var logCommunication = !PROPERTIES.PRODUCTION;

        service.get = get;
        service.getWithParams = getWithParams;
        service.getWithResponseType = getWithResponseType;
        service.post = post;
        service.postEncoded = postEncoded;
        service.postEncodedWithResponseType = postEncodedWithResponseType;
        service.putEncoded = putEncoded;
        service.delete = remove;

        service.getUrlEncodedRequest = getUrlEncodedRequest;

        service.logConnInfo = logConnInfo;
        service.logConnError = logConnError;

        service.transformResponseIntoTableData = _transformResponseIntoTableData;
        service.parseJsonInResponse = _parseJsonInResponse;
        service.setAcceptLanguage = setAcceptLanguage;

        return service;


        function setAcceptLanguage(lang) {
            $http.defaults.headers.common["Accept-Language"] = lang.toLowerCase();
        }

        function postEncoded(url, data, onSuccess, onError, ignoreError) {
            $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: $httpParamSerializer(data),
            }).success(function(data, status, headers) {
                logConnInfo(data);
                if (onSuccess) {
                    onSuccess(data, status, headers);
                }
            }).error(function(error) {
                logConnError(error);
                if (!ignoreError) {
                    notificationService.processServerError(error);
                }
                if (onError) {
                    onError(error);
                }
            });
        }

        function postEncodedWithResponseType(url, data, responseType, onSuccess, onError) {
            $http({
                method: 'POST',
                url: url,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: $httpParamSerializer(data),
                responseType: responseType
            }).success(function(data, status, headers) {
                logConnInfo(data);
                if (onSuccess) {
                    onSuccess(data, status, headers);
                }
            }).error(function(error, status, headers) {
                var decodedError = String.fromCharCode.apply(null, new Uint8Array(error));
                var errorJson = JSON.parse(decodedError);

                logConnError(errorJson.status.code + ' - ' + errorJson.status.message);

                if (onError) {
                    onError(errorJson, status, headers);
                }
            });
        }

        function post(url, data, onSuccess, onError) {
            $http.post(url, data)
                .success(function(data) {
                    logConnInfo(data);
                    if (onSuccess) {
                        onSuccess(data);
                    }
                }).error(function(error) {
                logConnError(error);
                notificationService.processServerError(error);
                if (onError) {
                    onError(error);
                }
            });
        }

        function putEncoded(url, data, onSuccess, onError) {
            $http({
                method: 'PUT',
                url: url,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: $httpParamSerializer(data)
            }).success(function(data) {
                logConnInfo(data);
                if (onSuccess) {
                    onSuccess(data);
                }
            }).error(function(error) {
                logConnError(error);
                notificationService.processServerError(error);
                if (onError) {
                    onError(error);
                }
            });
        }

        function get(url, onSuccess, onError) {
            $http.get(url)
                .success(function(data) {
                    logConnInfo(data);
                    if (onSuccess) {
                        onSuccess(data);
                    }
                }).error(function(error) {
                logConnError(error);
                if (onError) {
                    onError(error);
                } else {
                    notificationService.processServerError(error);
                }
            });
        }



        function getWithParams(url, params, onSuccess, onError) {
            $http({
                method: 'GET',
                params: params,
                url: url
            }).success(function(data) {
                logConnInfo(data);
                if (onSuccess) {
                    onSuccess(data);
                }
            }).error(function(error) {
                logConnError(error);
                if (onError) {
                    onError(error);
                } else {
                    notificationService.processServerError(error);
                }
            });
        }

        function getWithResponseType(url, responseType, onSuccess, onError) {
            $http({
                method: 'GET',
                url: url,
                responseType: responseType
            }).success(function(data, status, headers) {
                logConnInfo(data);
                if (onSuccess) {
                    onSuccess(data, status, headers);
                }
            }).error(function(error) {
                var decodedError = String.fromCharCode.apply(null, new Uint8Array(error));
                var errorJson = JSON.parse(decodedError);

                logConnError(errorJson.status.code + ' - ' + errorJson.status.message);

                if (onError) {
                    onError(errorJson);
                } else {
                    notificationService.processServerError(errorJson);
                }
            });
        }

        function remove(url, onSuccess, onError) {
            $http.delete(url)
                .success(function(data) {
                    logConnInfo(data);
                    if (onSuccess) {
                        onSuccess(data);
                    }
                })
                .error(function(error) {
                    logConnError(error);
                    if (onError) {
                        onError(error);
                    } else {
                        notificationService.processServerError(error);
                    }
                });
        }

        function _parseJsonInResponse(tableData, column) {
            for (var i = 0, len = tableData.length; i < len; i++) {
                try {
                    tableData[i][column] = JSON.parse(tableData[i][column]);
                } catch (e) {
                    tableData[i][column] = {column: tableData[i][column]}
                }
            }
        }

        function _transformResponseIntoTableData(data, dataColumns) {
            var gridData = [];
            for (var i = 0, l = data.length; i < l; i++) {

                var row = {};
                for (var j = 0, l2 = data[i].length; j < l2; j++) {
                    row[dataColumns[j]] = data[i][j];
                }

                gridData.push(row);
            }
            return gridData;
        }

        // Replaced with httpParamSerializer
        function getUrlEncodedRequest(obj) {
            var result = [];
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] instanceof Array) {
                        //var stringified = JSON.stringify(obj[key]);
                        //result.push(encodeURIComponent(key) + "=" + encodeURIComponent(stringified));

                        for (var idx in obj[key]) {
                            var subObj = obj[key][idx];
                            if (angular.isDefined(subObj)) {
                                // 1.
                                result.push(encodeURIComponent(key) + "=" + encodeURIComponent(subObj));

                                // 2.
                                //result.push(encodeURIComponent(key) + "[" + idx + "]=" + encodeURIComponent(subObj));

                                // 3.
                                //for (var subKey in subObj) {
                                //    result.push(encodeURIComponent(key) + "[" + idx + "][" + encodeURIComponent(subKey) + "]=" + encodeURIComponent(subObj[subKey]));
                                //}
                            }
                        }
                    } else {
                        if (angular.isDefined(obj[key])) {
                            result.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
                        }
                    }
                }
            }
            return result.join("&");
        }

        function logConnInfo(data) {
            if (logCommunication) {
                $log.info(data);
            }
        }

        function logConnError(error) {
            $log.error(error);
        }

    }
]);
