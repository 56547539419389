app.controller('GameSettingsModalInstanceCtrl', ['$scope', '$log', '$modalInstance', '$modal', '$timeout', 'requestService', 'notificationService',
    'gameId', 'gameName', 'openInReadMode', 'ERROR_CODES', '$translate',
    function($scope, $log, $modalInstance, $modal, $timeout, requestService, notificationService, gameId, gameName, openInReadMode, ERROR_CODES,
        $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.gameName = gameName;
            $scope.formData = {
                gameId: gameId,
                settings: [],
                tags: [],
                restrictedCountries: []
            };
            $scope.formErrors = {};
            $scope.inReadMode = openInReadMode;
            $scope.countriesList = [];
            $scope.countriesIdList = [];
            $scope.reverse = {
                view: false,
                countries: []
            };

            $scope.onCreate = function() {
                createGameSettings();
            };

            $scope.onSave = function(gameSettingsId) {
                saveGameSettings(gameSettingsId);
            };

            $scope.onEdit = function() {
                $scope.inReadMode = false;
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.$on('countriesInitialized', function(event, data) {
                var ids = [];
                for (var i = 0; i < data.length; i++) {
                    ids.push(data[i].id);
                }
                $scope.countriesList = data;
                $scope.countriesIdList = ids;
            });

            $scope.viewChanged = function() {
                $scope.reverse.view = !$scope.reverse.view;
                if($scope.reverse.view === true) {
                    $scope.reverse.countries = _.difference($scope.countriesIdList, $scope.formData.restrictedCountries);
                } else {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
            };

            if (gameId) {
                $timeout(function() {
                        getGameSettings(gameId);
                    },
                    $scope.DEFAULT_TIMEOUT
                );
            }
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getGameSettings(id) {
            requestService.get('/api/games/game-settings?gameId=' + id,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        if (data.values) {
                            $scope.formData = data.values;
                        }
                        $scope.gameSettingsId = data.gameSettingsId;
                        if (!$scope.gameSettingsId) {
                            $scope.formData.enabled = true;
                        }
                        $scope.allowedCgsGames = data.allowedCgsGames;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingGameSettings, cancelModal);
                    }
                }
            );
        }

        function createGameSettings() {
            if($scope.reverse.view === true) {
                $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
            }
            requestService.postEncoded('/api/games/game-settings',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;
                        $scope.gameSettingsId = data.gameSettingsId;

                        notificationService.showInfoToast($scope.translations.infoGameSettingsCreated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorCreatingGameSettings);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function saveGameSettings(id) {
            if($scope.reverse.view === true) {
                $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
            }
            requestService.putEncoded('/api/games/game-settings/' + id,
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;
                        $scope.gameSettingsId = data.gameSettingsId;

                        notificationService.showInfoToast($scope.translations.infoGameSettingsUpdated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorUpdatingGameSettings);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_GAME_SETTINGS', 'GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_CREATED',
                'GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_GAME_SETTINGS', 'GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM',
                'GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_GAME_SETTINGS', 'GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_UPDATED'])
                .then(function(translation) {
                    translations.errorCreatingGameSettings = translation['GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_GAME_SETTINGS'];
                    translations.errorUpdatingGameSettings = translation['GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_GAME_SETTINGS'];
                    translations.errorLoadingGameSettings = translation['GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_GAME_SETTINGS'];
                    translations.errorInvalidForm = translation['GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.infoGameSettingsCreated = translation['GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_CREATED'];
                    translations.infoGameSettingsUpdated = translation['GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_UPDATED'];
                }, function(translationIds) {
                    translations.errorCreatingGameSettings = translationIds['GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_GAME_SETTINGS'];
                    translations.errorUpdatingGameSettings = translationIds['GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_GAME_SETTINGS'];
                    translations.errorLoadingGameSettings = translationIds['GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_GAME_SETTINGS'];
                    translations.errorInvalidForm = translationIds['GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.infoGameSettingsCreated = translationIds['GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_CREATED'];
                    translations.infoGameSettingsUpdated = translationIds['GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_UPDATED'];
                });
            return translations;
        }
    }
]);
