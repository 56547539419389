app.controller('CasinoGamesManagementByCasinoCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', 'GAME_PLATFORM', 'ERROR_CODES',
    'GLOBAL_CONSTANTS', '$translate',
    function($scope, $log, $modal, requestService, notificationService, GAME_PLATFORM, ERROR_CODES, GLOBAL_CONSTANTS, $translate) {

        init();
        initTable();

        function initTable() {
            $scope.table = {
                games: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getTableData();
            };
        }

        if ($scope.formData && $scope.formData.casinoId) {
            getTableData();
        }
        function init() {
            $scope.formData = {};
            $scope.ROOT_CASINO_ID = GLOBAL_CONSTANTS.ROOT_CASINO_ID;
            $scope.formErrors = {};
            $scope.selectedCasinoData = {};
            $scope.confirmationModal = null;
            $scope.canEdit = false;


            $scope.onNewCasinoSettings = function(casinoId, gameId, name, canInherit) {
                openCasinoSettingsModal(casinoId, gameId, name, false, canInherit);
            };

            $scope.onViewCasinoSettings = function(casinoId, gameId, name, canInherit) {
                openCasinoSettingsModal(casinoId, gameId, name, true, canInherit);
            };

            $scope.onEditCasinoSettings = function(casinoId, gameId, name, canInherit) {
                openCasinoSettingsModal(casinoId, gameId, name, false, canInherit);
            };

            $scope.onCasinoSelect = function() {
                requestService.get(
                    'api/casino-management/casino/' + $scope.formData.casinoId,
                    function(data) {
                        if (data.status.code === ERROR_CODES.OK) {
                            $scope.selectedCasinoData = data.casinoValues;
                        } else {
                            notificationService.processServerError(data.status, $scope.translations.errorLoadingSelectedCasinoData);
                        }
                    }
                );
            };

            $scope.openConfirmationModal = openConfirmationModal;

            $scope.onSuccessConfirmation = function() {
                var gameIds = [];
                for (var i = 0; i < $scope.table.games.length; i++) {
                    gameIds.push($scope.table.games[i].gameId);
                }
                requestService.post(
                    '/api/casino/inherit-casino-game-settings',
                    {
                        casinoId: $scope.formData.casinoId,
                        gameIds: gameIds
                    },
                    function(response) {
                        if (response.status.code === ERROR_CODES.OK) {
                            notificationService.showInfoToast($scope.translations.infoSettingsSuccessfullyDuplicated);
                        } else if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showErrorDialog($scope.translations.errorValidationFailed, response.status.message);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorInheritingSettings);
                        }
                        $scope.confirmationModal.dismiss();
                        getTableData();
                    }
                );
            };

            $scope.onCancelConfirmation = function() {
                $scope.confirmationModal.dismiss('cancel');
            };

            $scope.generateDefaultSettings = function() {
                requestService.get('/api/casino-management/generate-default-settings?casinoId=' + $scope.formData.casinoId,
                    function(response) {
                        if (response.status.code === ERROR_CODES.OK) {
                            if (response.list.length > 0) {
                                notificationService.showInfoToast(
                                    $translate.instant('CASINO_GAME_MANAGEMENT.INFO_MESSAGES.GAMES_SETTINGS_GENERATED',
                                        {gamesNumber: response.list.length}));
                                getTableData();
                            } else {
                                notificationService.showInfoToast($translate.instant('CASINO_GAME_MANAGEMENT.INFO_MESSAGES.NO_GAME_WITHOUT_SETTINGS'));
                            }
                        } else {
                            notificationService.processApplicationError($scope.translations.errorGeneratingGamesSettings)
                        }
                    });
            };
        }

        function getTableData() {
            requestService.postEncoded(
                '/api/casino/casino-settings-list',
                {
                    casinoId: $scope.formData.casinoId
                },
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.table.games = data.list;
                        $scope.canEdit = data.canEdit;
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorFetchingTableData);
                        }
                    }

                }
            );
        }

        function openCasinoSettingsModal(casinoId, gameId, gameName, openInReadMode, canInherit) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/casino_game_management/casino_game_settings_modal.html',
                controller: 'CasinoSettingsModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    gameName: function() {
                        return gameName;
                    },
                    gameId: function() {
                        return gameId;
                    },
                    casinoId: function() {
                        return casinoId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    },
                    parentId: function() {
                        return $scope.selectedCasinoData.parentId;
                    },
                    canEdit: function() {
                        return $scope.canEdit;
                    },
                    canInherit: function() {
                        return canInherit;
                    }
                }
            });

            modalInstance.result.then(function() {
                getTableData();
            }, function() {
                getTableData();
            });
        }

        function openConfirmationModal() {
            $scope.confirmationModal = $modal.open({
                animation: true,
                templateUrl: 'app/casino_game_management/confirmation_modal.html',
                scope: $scope,
                backdrop: true,
                resolve: {}
            });
        }

    }
]);
