app.controller('DatePickerCtrl', ['$scope', '$filter', '$log', '$translate',
    function($scope, $filter, $log, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.opened = false;

            $scope.toggle = function($event) {
                $event.preventDefault();
                $scope.opened = !$scope.opened;
            };

            $scope.dateOptions = {
                format: 'M/d/yy',
                startingDay: 1
            };
        }

        $scope.isDateAfter = function(minDate, selectedDate) {
            if (minDate && selectedDate) {
                var fromDate = new Date(minDate);
                var toDate = new Date(selectedDate);
                if (dateAfter(fromDate, toDate)) {
                    return $scope.translations.infoInvalidRange;
                }
            }
            return '';
        };

        function dateAfter(from, to) {
            return from.getTime() > to.getTime();
        }

        function initTranslations() {
            var translations = {};
            $translate(['GENERAL.DATE_PICKER.INVALID_RANGE'])
                .then(function(translation) {
                    translations.infoInvalidRange = translation['GENERAL.DATE_PICKER.INVALID_RANGE'];
                }, function(translationIds) {
                    translations.infoInvalidRange = translationIds['GENERAL.DATE_PICKER.INVALID_RANGE'];
                });
            return translations;
        }
    }
]);

// Overrides datePickerPopup to hold formatted value in ngModel instead of Date object
app.directive('datepickerPopup', ['$compile', '$parse', '$document', '$position',
    'dateFilter', 'dateParser', 'datepickerPopupConfig',
    function($compile, $parse, $document, $position,
        dateFilter, dateParser, datepickerPopupConfig) {
        return {
            restrict: 'EAC',
            priority: 100,
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!attrs.formatted || attrs.formatted === 'true') {
                    ngModel.$parsers.push(function(viewValue) {
                        return dateFilter(viewValue, attrs.datepickerPopup);
                    });
                } else {
                    ngModel.$parsers.push(function(value) {
                        if (value) {
                            if (attrs.ignoreTimeZone === 'true') {
                                return new Date(value.getTime());
                            } else {
                                return new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
                            }
                        } else {
                            return value;
                        }
                    });
                }

                ngModel.$formatters.push(function(value) {
                    if (value) {
                        var date = new Date(Date.parse(value));

                        if (attrs.ignoreTimeZone === 'true') {
                            date = new Date(date.getTime());
                        } else {
                            date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
                        }

                        //if (!dateFormat || !modelValue) return "";
                        //var retVal = moment(modelValue).format(dateFormat);
                        return date;
                    } else {
                        return value;
                    }
                });
            }
        }
    }
]);

app.directive('timepicker', ['$compile', '$parse', '$document', '$position',
    'dateFilter', 'dateParser', 'datepickerPopupConfig',
    function($compile, $parse, $document, $position,
        dateFilter, dateParser, datepickerPopupConfig) {
        return {
            restrict: 'EAC',
            priority: 100,
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(value) {
                    if (value) {
                        return new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
                    } else {
                        return value;
                    }
                });

                ngModel.$formatters.push(function(value) {
                    if (value) {
                        var date = new Date(Date.parse(value));
                        date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
                        //if (!dateFormat || !modelValue) return "";
                        //var retVal = moment(modelValue).format(dateFormat);
                        return date;
                    } else {
                        return value;
                    }
                });
            }
        }
    }
]);

app.directive('ignoreNgValidation', [
    function(){
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attributes, ngModel) {
                function ref(obj, str) {
                    str = str.split(".");
                    for (var i = 0; i < str.length; i++) {
                        obj = obj[str[i]];
                    }
                    return obj;
                }

                function getRawData(value) {
                    var str = attributes.beforeValidationValue;
                    parts = str.split(/\.(?=[^.]+$)/);  // Split "foo.bar.baz" into ["foo.bar", "baz"]
                    ref(scope.$parent, parts[0])[parts[1]] = value;
                }

                scope.$watch(function () {
                    return ngModel.$viewValue;
                }, getRawData);
            }
        }
    }
]);
