app.controller('CampaignModalInstanceCtrl', ['$scope', '$modalInstance', '$modal', '$log', '$filter', '$timeout', 'requestService',
    'notificationService', 'campaignId', 'openInReadMode', 'ERROR_CODES', 'GLOBAL_CONSTANTS', 'CAMPAIGN_CONSTANTS', '$q', '$translate',
    function ($scope, $modalInstance, $modal, $log, $filter, $timeout, requestService, notificationService, campaignId, openInReadMode, ERROR_CODES,
        GLOBAL_CONSTANTS, CAMPAIGN_CONSTANTS, $q, $translate) {

        $scope.translations = initTranslations();
        init();
        initTime();

        function initTime() {
            // TODO put into separate TimeController
            $scope.options = {
                hstep: [1, 2, 3],
                mstep: [30],
                showSpinners: false,
                showMeridian: false
            };
        }

        function init() {
            var d = new Date();
            d.setUTCHours(0, 0, 0, 0);

            $scope.formData = {
                validFrom: d,
                validTo: d,
                enabled: true,
                gamesWagering: [],
                games: [],
                casinos: []
            };
            $scope.formErrors = {};
            $scope.table = {
                targetings: [],
                pageSizes: [1, 10, 25, 50],
                pageSize: 10,
                totalSize: 0,
                availableGamesWagering: []
            };
            $scope.campaignId = campaignId;
            $scope.inReadMode = openInReadMode;
            $scope.editRoundsAllowed = true;
            $scope.confirmationModal = null;
            $scope.treeStructure = [];
            $scope.campaignPromise = {};
            $scope.currencyPromise = {};
            $scope.treeStructurePromise = getCasinoTreeStructure();

            $scope.onCreateCampaign = function() {
                if ($scope.formData.roundsAwarded > $scope.maxAwarded ||
                    $scope.formData.desiredRoundValue > ($scope.maxRoundValueInSystemBase / selectedCurrencyRate())) {
                    if ($scope.formData.roundsAwarded > $scope.maxAwarded) {
                        $scope.roundsAwardedMessage = $translate.instant('FR_CAMPAIGN_MANAGER.MODAL.WARNING_MAX_AWARDED', {maxAwarded: $scope.maxAwarded});
                    }
                    if ($scope.formData.desiredRoundValue > ($scope.maxRoundValueInSystemBase / selectedCurrencyRate())) {
                        $scope.roundValueMessage = $translate.instant('FR_CAMPAIGN_MANAGER.MODAL.WARNING_MAX_ROUND_VALUE',
                            {maxRoundValueInSystemBase: $scope.maxRoundValueInSystemBase / selectedCurrencyRate()});
                    }
                    openConfirmationModal();
                } else {
                    createCampaign();
                }
            };

            $scope.onSaveCampaign = function() {
                var hasPlayers = hasPlayerTargetings($scope.table.targetings);
                var onlyOneCasinoSelected = $scope.formData.casinos.length === 1;

                if ($scope.formData.roundsAwarded > $scope.maxAwarded ||
                    $scope.formData.desiredRoundValue > ($scope.maxRoundValueInSystemBase / selectedCurrencyRate()) ||
                    $scope.table.targetings.length < 1 ||
                    (!onlyOneCasinoSelected && hasPlayers)) {
                    if ($scope.formData.roundsAwarded > $scope.maxAwarded) {
                        $scope.roundsAwardedMessage = $translate.instant('FR_CAMPAIGN_MANAGER.MODAL.WARNING_MAX_AWARDED', {maxAwarded: $scope.maxAwarded});
                    }
                    if ($scope.formData.desiredRoundValue > ($scope.maxRoundValueInSystemBase / selectedCurrencyRate())) {
                        $scope.roundValueMessage = $translate.instant('FR_CAMPAIGN_MANAGER.MODAL.WARNING_MAX_ROUND_VALUE',
                            {maxRoundValueInSystemBase: $scope.maxRoundValueInSystemBase / selectedCurrencyRate()});
                    }
                    if ($scope.table.targetings.length < 1) {
                        $scope.noTargetingsMessage = $scope.translations.warningNoTargetingAdded;
                    } else if (!onlyOneCasinoSelected && hasPlayers) {
                        $scope.playerTargetingsMessage = $scope.translations.warningPlayerTargetingForOneCasinoButMoreCasinos;
                    }
                    openConfirmationModal();
                } else {
                    saveCampaign();
                }
            };

            $scope.onEditCampaign = function() {
                $scope.inReadMode = false;
            };

            $scope.onCampaignSelected = function($item, $model) {
                var hasPlayers = hasPlayerTargetings($scope.table.targetings);
                if ($scope.formData.casinos.length !== 1 && hasPlayers) {
                    notificationService.showWarningDialog($scope.translations.warningPlayerTargetingTitle,
                        $scope.translations.warningPlayerTargetingForOneCasino);
                }
            };

            $scope.onCampaignRemoved = function($item, $model) {
                var hasPlayers = hasPlayerTargetings($scope.table.targetings);
                if ($scope.formData.casinos.length !== 1 && hasPlayers) {
                    notificationService.showWarningDialog($scope.translations.warningPlayerTargetingTitle,
                        $scope.translations.warningPlayerTargetingForOneCasino);
                }
            };

            function hasPlayerTargetings(targetings) {
                var playersTargetings = targetings.filter(function(elem, index, array) {
                    return elem.playersSize > 0;
                });
                return playersTargetings.length > 0
            }

            $scope.onCampaignTargetingTableData = function() {
                getCampaignTargetingTableData($scope.campaignId);
            };

            $scope.onNewCampaignTargeting = function() {
                openCampaignTargetingModal($scope.campaignId, $scope.formData.name, null, false);
            };

            $scope.onNewPlayersTargeting = function() {
                var hasPlayers = hasPlayerTargetings($scope.table.targetings);
                var onlyOneCasinoSelected = $scope.formData.casinos.length === 1;
                if (!onlyOneCasinoSelected && hasPlayers) {
                    notificationService
                        .showConfirmDialog($scope.translations.warningAddPlayerTargetingTitle,
                            $scope.translations.warningAddPlayerTargeting)
                        .then(function() {
                            openPlayersTargetingModal($scope.campaignId, $scope.formData.name, null, false);
                        });
                } else {
                    openPlayersTargetingModal($scope.campaignId, $scope.formData.name, null, false);
                }
            };

            $scope.onViewCampaignTargeting = function(targetingId) {
                openCampaignTargetingModal($scope.campaignId, $scope.formData.name, targetingId, true);
            };

            $scope.onEditCampaignTargeting = function(targetingId) {
                openCampaignTargetingModal($scope.campaignId, $scope.formData.name, targetingId, false);
            };

            $scope.onDeleteCampaignTargeting = function(targetingId) {
                deleteCampaignTargeting(targetingId);
            };

            $scope.onExportPlayerTargeting = function(targetingId) {
                exportPlayerTargeting(targetingId);
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.onCancelConfirmation = function() {
                $scope.confirmationModal.dismiss();
            };

            $scope.onGamesWageringLoad = function() {
                requestService.get(
                    '/api/enums/game-list',
                    function(data) {
                        $scope.availableGamesWagering = data;
                    }
                );
            };

            $scope.onPreselect = function() {
                $scope.formData.gamesWagering = $scope.formData.games;
            };

            if ($scope.campaignId) {
                $scope.campaignPromise = getCampaign($scope.campaignId);
                getCampaignTargetingTableData($scope.campaignId);
            }

            $scope.notContains = function(selectedArray, mappedValue) {
                if (!selectedArray || !selectedArray.length > 0) {
                    return true;
                }

                return selectedArray.indexOf(mappedValue) === -1;
            };

            $scope.successFunction = function() {
                $scope.confirmationModal.close();
                if (!$scope.campaignId) {
                    createCampaign();
                } else {
                    saveCampaign();
                }
            };

            $scope.failFunction = function() {
                $scope.confirmationModal.close();
            };

            $scope.isReadyToPersist = function() {
                return !!($scope.formData.name
                    && $scope.formData.validFrom
                    && $scope.formData.validTo
                    && $scope.formData.games.length > 0
                    && $scope.formData.casinos.length > 0
                    && $scope.formData.desiredRoundValue
                    && $scope.formData.desiredRoundValueCurrency
                    && $scope.formData.roundsAwarded
                    && $scope.formData.priority);
            };

            $scope.onChangeWagering = onChangeWagering;

            $scope.currentRatesList = [];

            getCampaignProperties();

            $q.all([$scope.treeStructurePromise, $scope.campaignPromise]).then(updateTreeview);
        }

        function updateTreeview() {
            if ($scope.formData.casinos && $scope.formData.casinos.length > 0) {
                for (var i = 0; i < $scope.formData.casinos.length; i++) {
                    selectNode($scope.treeStructure, $scope.formData.casinos[i]);
                }
            }
            $scope.treeStructureFinal = $scope.treeStructure;
        }

        function selectNode(node, casinoId) {
            if (node.id === casinoId) {
                node.selected = true;
            } else {
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        selectNode(node.children[i], casinoId);
                    }
                }
            }
        }

        function initCurrentRatesList() {
            var deferred = $q.defer();
            requestService.get(
                '/api/enums/current-rates-list',
                function(data) {
                    $scope.currentRatesList = data;
                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function selectedCurrencyRate() {
            if ($scope.currentRatesList.length === 0) {
                // lazy load
                $scope.currencyPromise = initCurrentRatesList();
            }
            $scope.currencyPromise.then(function () {
                for (var i = 0; i < $scope.currentRatesList.length; i++) {
                    if ($scope.currentRatesList[i]["currencyId"] === $scope.formData.desiredRoundValueCurrency) {
                        return $scope.currentRatesList[i]["rateToSystemBase"];
                    }
                }
            });
        }

        function onChangeWagering() {
            if ($scope.formData.hasToWagerWinnings === true &&
                !$scope.formData.daysToWager) {
                $scope.formData.daysToWager = CAMPAIGN_CONSTANTS.DEFUALT_FR_DAYS_TO_WAGER;
            }
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getCampaign(id) {
            var deferred = $q.defer();
            requestService.get('/api/campaigns/campaign?id=' + id,
                function(data) {
                    if (data.status.code === 'OK') {
                        $scope.formData = data.campaignValues;
                        $scope.editAllowed = data.allowedToModify;
                        $scope.editRoundsAllowed = data.allowedToModify && !data.isClaimed;
                        $scope.active = data.active;
                        deferred.resolve();
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingCampaign, cancelModal());
                        deferred.reject();
                    }
                }
            );
            return deferred.promise;
        }

        function createCampaign() {
            requestService.postEncoded('/api/campaigns/campaign',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === 'OK') {
                        $scope.formData = data.campaignValues;
                        $scope.editAllowed = data.allowedToModify;
                        $scope.active = data.active;
                        $scope.campaignId = data.campaignId;
                        notificationService.showInfoToast($scope.translations.popupCampaignCreated);
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCampaign);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function saveCampaign() {
            requestService.putEncoded('/api/campaigns/campaign/' + $scope.campaignId,
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === 'OK') {
                        $scope.formData = data.campaignValues;
                        $scope.editAllowed = data.allowedToModify;
                        $scope.active = data.active;
                        $scope.campaignId = data.campaignId;

                        notificationService.showInfoToast($scope.translations.popupCampaignUpdated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorUpdatingCampaign);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function getCampaignTargetingTableData(campaignId) {
            requestService.get(
                '/api/campaigns/campaign-targeting-list?campaignId=' + campaignId,
                function(data) {
                    if (data.status.code !== 'OK') {
                        $log.error(data.status);
                    } else {
                        $scope.table.totalSize = $scope.table.targetings.length;
                        $scope.table.targetings = data.list;
                    }
                }
            );
        }

        function getCampaignProperties() {
            $scope.maxAwarded = null;
            $scope.maxRoundValueInSystemBase = null;
            requestService.get(
                '/api/campaigns/campaign-properties',
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.maxAwarded = data.campaignProperties.maxAwarded;
                        $scope.maxRoundValueInSystemBase = data.campaignProperties.maxRoundValueInSystemBase;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorGettingCampaignProperties);
                    }
                }
            );
        }

        function deleteCampaignTargeting(targetingId) {
            requestService.delete(
                '/api/campaigns/campaign-targeting/' + targetingId,
                function(data) {
                    if (data.status.code !== 'OK') {
                        $log.error(data.status);
                    }
                    getCampaignTargetingTableData($scope.campaignId);
                }
            );
        }

        function exportPlayerTargeting(targetingId) {
            requestService.postEncoded(
                '/api/files/players-targeting-export-link',
                {
                    id: targetingId
                },
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        var url = '/api/files/players-targeting-export-generate?link=' + data.link +
                            '&id=' + targetingId;
                        $scope.downloadFile(url);
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidData);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorExportingPlayerTargeting);
                        }
                    }
                }
            );
        }

        function getCasinoTreeStructure() {
            var deferred = $q.defer();
            requestService.get(
                '/api/campaigns/campaign-casino-tree',
                function(data) {
                    $scope.treeStructure = data;
                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function openCampaignTargetingModal(campaignId, campaignName, campaignTargetingId, openInReadMode) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/campaign_manager/campaign_targeting.html',
                controller: 'CampaignTargetingModalInstanceCtrl',
                backdrop: false,
                size: '-sm',
                resolve: {
                    campaignName: function() {
                        return campaignName;
                    },
                    campaignId: function() {
                        return campaignId;
                    },
                    campaignTargetingId: function() {
                        return campaignTargetingId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    },
                    editAllowed: function() {
                        return $scope.editAllowed;
                    }
                }
            });

            // Update table when closed
            modalInstance.result.then(function() {
                getCampaignTargetingTableData($scope.campaignId);
            }, function() {
                getCampaignTargetingTableData($scope.campaignId);
            });
        }

        function openPlayersTargetingModal(campaignId, campaignName, campaignTargetingId, openInReadMode) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/campaign_manager/players_targeting.html',
                controller: 'PlayersTargetingModalInstanceCtrl',
                backdrop: false,
                size: '-sm',
                resolve: {
                    campaignName: function() {
                        return campaignName;
                    },
                    campaignId: function() {
                        return campaignId;
                    },
                    campaignTargetingId: function() {
                        return campaignTargetingId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    },
                    editAllowed: function() {
                        return $scope.editAllowed;
                    }
                }
            });

            // Update table when closed
            modalInstance.result.then(function() {
                getCampaignTargetingTableData($scope.campaignId);
            }, function() {
                getCampaignTargetingTableData($scope.campaignId);
            });
        }

        function openConfirmationModal() {
            $scope.confirmationModal = $modal.open({
                animation: true,
                templateUrl: 'app/campaign_manager/confirmation_modal.html',
                scope: $scope,
                backdrop: true,
                resolve: {}
            });

            $scope.confirmationModal.result.then(
                function() {
                    $scope.roundsAwardedMessage = "";
                    $scope.roundValueMessage = "";
                    $scope.playerTargetingsMessage = "";
                    $scope.noTargetingsMessage = "";
                },
                function() {
                    $scope.roundsAwardedMessage = "";
                    $scope.roundValueMessage = "";
                    $scope.playerTargetingsMessage = "";
                    $scope.noTargetingsMessage = "";
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['FR_CAMPAIGN_MANAGER.MODAL.WARNING_NO_TARGETING_ADDED',
                'FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_TITLE',
                'FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO_BUT_MORE_CASINOS',
                'FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO', 'FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING_TITLE',
                'FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING', 'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN', 'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.UPDATING_CAMPAIGN',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM', 'FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_CREATED',
                'FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_UPDATED',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.GETTING_CAMPAIGN_PROPERTIES',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.EXPORTING_PLAYER_TARGETING',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_DATA'
            ]).then(function(translation) {
                translations.warningNoTargetingAdded = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_NO_TARGETING_ADDED'];
                translations.warningPlayerTargetingTitle = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_TITLE'];
                translations.warningPlayerTargetingForOneCasino = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO'];
                translations.warningPlayerTargetingForOneCasinoButMoreCasinos = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO_BUT_MORE_CASINOS'];
                translations.warningAddPlayerTargetingTitle = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING_TITLE'];
                translations.warningAddPlayerTargeting = translation['FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING'];

                translations.errorLoadingCampaign = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN'];
                translations.errorCreatingCampaign = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN'];
                translations.errorUpdatingCampaign = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.UPDATING_CAMPAIGN'];
                translations.errorInvalidForm = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                translations.errorInvalidData = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_DATA'];
                translations.errorGettingCampaignProperties = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.GETTING_CAMPAIGN_PROPERTIES'];
                translations.errorExportingPlayerTargeting = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.EXPORTING_PLAYER_TARGETING'];

                translations.popupCampaignCreated = translation['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_CREATED'];
                translations.popupCampaignUpdated = translation['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_UPDATED'];
            }, function(translationIds) {
                translations.warningNoTargetingAdded = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_NO_TARGETING_ADDED'];
                translations.warningPlayerTargetingTitle = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_TITLE'];
                translations.warningPlayerTargetingForOneCasino = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO'];
                translations.warningPlayerTargetingForOneCasinoButMoreCasinos = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_PLAYER_TARGETING_FOR_ONE_CASINO_BUT_MORE_CASINOS'];
                translations.warningAddPlayerTargetingTitle = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING_TITLE'];
                translations.warningAddPlayerTargeting = translationIds['FR_CAMPAIGN_MANAGER.MODAL.WARNING_ADD_PLAYER_TARGETING'];

                translations.errorLoadingCampaign = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN'];
                translations.errorCreatingCampaign = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN'];
                translations.errorUpdatingCampaign = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.UPDATING_CAMPAIGN'];
                translations.errorInvalidForm = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                translations.errorInvalidData = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_DATA'];
                translations.errorGettingCampaignProperties = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.GETTING_CAMPAIGN_PROPERTIES'];
                translations.errorExportingPlayerTargeting = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.EXPORTING_PLAYER_TARGETING'];

                translations.popupCampaignCreated = translationIds['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_CREATED'];
                translations.popupCampaignUpdated = translationIds['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_UPDATED'];
            });
            return translations;
        }
    }
]);

app.controller('SpecialTargetingDropdownCtrl', ['$scope', 'requestService', function($scope, requestService) {
    init();

    function init() {
        $scope.list = [];
        $scope.onRefreshList = function() {
            requestService.get(
                '/api/campaigns/special-targeting-list',
                function(data) {
                    $scope.list = data;
                }
            );
        };
    }

}]);

app.controller('CampaignCasinoDropdownCtrl', ['$scope', 'requestService',
    function($scope, requestService) {
        init();

        function init() {
            $scope.list = [];
            $scope.onRefreshList = function() {
                requestService.get(
                    '/api/campaigns/campaign-casino-list',
                    function(data) {
                        $scope.list = data;
                    }
                );
            };
        }
    }
]);
