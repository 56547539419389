app.controller('ManagedFreeroundsCtrl', ['$scope', 'GLOBAL_CONSTANTS', '$state', 'requestService', '$modal', 'notificationService', '$q', '$translate',
    function($scope, GLOBAL_CONSTANTS, $state, requestService, $modal, notificationService, $q, $translate) {
        if (GLOBAL_CONSTANTS.MANAGED_FREEROUNDS === false) {
            $state.go('home');
        }

        $scope.translations = initTranslations();
        init();
        getCurrencyList().then(getTableData());
        //getTableData();
        getCasinosTree();
        getFiltersOptions();

        function init() {
            $scope.table = {
                campaigns: [],
                sortColumns: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25,
                totalSize: 0,
                sortBy: [],
                sortColumn: null,
                sortAsc: null,
                offset: 0
            };
            $scope.filterOptions = {};
            $scope.selectedFilters = {
                refId: "",
                casinoIds: [],
                campaignName: "",
                handoutBeforeFrom: null,
                handoutBeforeTo: null,
                consumeBeforeFrom: null,
                consumeBeforeTo: null,
                gameIds: [],
                state: []
            };
            $scope.appliedFilters = {};
            $scope.currencyList = [];
            $scope.selectedCampaign = "";

            $scope.onRefresh = function() {
                getTableData();
            };

            $scope.applyFilters = function() {
                $scope.appliedFilters = {
                    refId: $scope.selectedFilters.refId,
                    casinoIds: $scope.selectedFilters.casinoIds,
                    campaignName: $scope.selectedFilters.campaignName,
                    handoutBeforeFrom: $scope.selectedFilters.handoutBeforeFrom,
                    handoutBeforeTo: $scope.selectedFilters.handoutBeforeTo,
                    consumeBeforeFrom: $scope.selectedFilters.consumeBeforeFrom,
                    consumeBeforeTo: $scope.selectedFilters.consumeBeforeTo,
                    gameIds: $scope.selectedFilters.gameIds,
                    state: $scope.selectedFilters.state
                };
                getTableData();
            };

            $scope.filterUpdate = function(option, array, checked){
                if (checked) {
                    array.push(option);
                } else {
                    for (var i = 0; i < array.length; i++) {
                        if (array[i] === option) {
                            array.splice(i, 1);
                            return;
                        }
                    }
                }
            };

            $scope.openDetailsModal = openDetailsModal;

            $scope.onClose = function () {
                $scope.detailsModal.close();
            };

            $scope.openConfirmationModal = openConfirmationModal;

            $scope.setSelectedCampaign = function(campaign) {
                $scope.selectedCampaign = campaign;
            };

            $scope.getCurrencyCode = function(currencyId) {
                for (var i = 0; i < $scope.currencyList.length; i++) {
                    if ($scope.currencyList[i].id == currencyId) {
                        return $scope.currencyList[i].code;
                    }
                }
            };

            $scope.getCurrencyName = function(currencyId) {
                for (var i = 0; i < $scope.currencyList.length; i++) {
                    if ($scope.currencyList[i].id == currencyId) {
                        return $scope.currencyList[i].name;
                    }
                }
            };

            // Watch for changes, then load data
            $scope.$watch('table.offset', onPageOffsetChange);
            $scope.$watch('table.sortBy', onSortByChange, true);
            $scope.$watch('table.pageSize', onPaginationChange, true);
        }

        function getCurrencyList() {
            var deferred = $q.defer();
            requestService.get(
                '/api/enums/currency-list',
                function(data) {
                    $scope.currencyList = data;
                    deferred.resolve();
                },
                function(response){
                    deferred.reject(response)
                });
            return deferred.promise;
        }

        function onPageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getTableData();
            }
        }

        function onSortByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.table.sortBy.length > 0) {
                    if ($scope.table.sortBy[0].charAt(0) !== '-') {
                        $scope.table.sortColumn = $scope.table.sortBy[0];
                        $scope.table.sortAsc = true;
                    } else {
                        $scope.table.sortColumn = $scope.table.sortBy[0].slice(1);
                        $scope.table.sortAsc = false;
                    }
                } else {
                    $scope.table.sortAsc = null;
                    $scope.table.sortColumn = null;
                }
                getTableData();
            }
        }

        function onPaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getTableData();
            }
        }

        function getTableData() {
            var sortColumn = "";
            if ($scope.table.sortColumn) {
                sortColumn = '&orderColumn=' + $scope.table.sortColumn;
            }
            requestService.postEncoded(
                '/api/managed-freerounds/table-data' +
                '?dataOffset=' + $scope.table.offset +
                '&dataSegmentSize=' + $scope.table.pageSize +
                '&dataSize=' + $scope.table.totalSize +
                sortColumn +
                '&orderAsc=' + $scope.table.sortAsc,
                {
                    refId: $scope.appliedFilters.refId,
                    casinoIds: $scope.appliedFilters.casinoIds,
                    campaignName: $scope.appliedFilters.campaignName,
                    handoutBeforeFrom: $scope.appliedFilters.handoutBeforeFrom,
                    handoutBeforeTo: $scope.appliedFilters.handoutBeforeTo,
                    consumeBeforeFrom: $scope.appliedFilters.consumeBeforeFrom,
                    consumeBeforeTo: $scope.appliedFilters.consumeBeforeTo,
                    gameIds: $scope.appliedFilters.gameIds,
                    state: $scope.appliedFilters.state
                },
                function(response) {
                    updateData(response);
                }
            );
        }

        function getCasinosTree() {
            requestService.get(
                '/api/managed-freerounds/casino-tree',
                function(data) {
                    $scope.filterOptions.casinos = data;
                }
            );
        }

        function getFiltersOptions() {
            requestService.get(
                '/api/managed-freerounds/filter-options',
                function(data) {
                    $scope.filterOptions.state = data.state;
                }
            );
        }

        function updateData(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);
            requestService.parseJsonInResponse(tableData, 'additionalData');

            $scope.table.totalSize = response.dataSize;
            $scope.table.campaigns = tableData;
            $scope.table.offset = response.dataOffset;

            if (response.dataSize == 0) {
                notificationService.showInfoToast($scope.translations.noDataFound);
            }
        }

        function openDetailsModal() {
            $scope.detailsModal = $modal.open({
                animation: true,
                templateUrl: 'app/managed_freerounds/details_modal.html',
                scope: $scope,
                backdrop: true,
                size: 'lg',
                resolve: {}
            });

            $scope.detailsModal.result.then(
                function() {
                    getTableData();
                },
                function() {
                    getTableData();
                }
            );
        }

        function openConfirmationModal() {
            var modalInstance = notificationService.showConfirmWarningDialog($scope.translations.modalCancelGsmcFrCampaignTitle,
                $scope.translations.modalCancelGsmcFrCampaignMessage,
                $scope.translations.modalCancelGsmcFrCampaignBtnYes,
                $scope.translations.modalCancelGsmcFrCampaignBtnNo);
            modalInstance.then(
                function() {
                    onCancelCampaign();
                },
                function() {
                    // CANCEL
                }
            );
        }

        function onCancelCampaign() {
            requestService.postEncoded(
                '/api/managed-freerounds/cancel-gsmc-free-rounds?refId=' + $scope.selectedCampaign.refId,
                null,
                function() {
                    notificationService.showInfoToast($scope.translations.modalCancelGsmcFrCampaignCancelled);
                    $scope.onClose();
                },
                function(error) {
                    notificationService.processApplicationError(error.status, $scope.translations.cancelGsmcFreeRoundCampaign);
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['MANAGED_FREE_ROUNDS.ERROR_MESSAGES.NO_DATA_FOUND', 'MANAGED_FREE_ROUNDS.ERROR_MESSAGES.CANCEL_GSMC_FREE_ROUND_CAMPAIGN',
                'MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.TITLE', 'MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.MESSAGE',
                'MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_YES', 'MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_NO',
                'MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.POPUP_GSMC_FREE_ROUND_CANCELLED'])
                .then(function(translation) {
                    translations.noDataFound = translation['MANAGED_FREE_ROUNDS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    translations.cancelGsmcFreeRoundCampaign = translation['MANAGED_FREE_ROUNDS.ERROR_MESSAGES.CANCEL_GSMC_FREE_ROUND_CAMPAIGN'];
                    translations.modalCancelGsmcFrCampaignTitle = translation['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.TITLE'];
                    translations.modalCancelGsmcFrCampaignMessage = translation['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.MESSAGE'];
                    translations.modalCancelGsmcFrCampaignBtnYes = translation['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_YES'];
                    translations.modalCancelGsmcFrCampaignBtnNo = translation['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_NO'];
                    translations.modalCancelGsmcFrCampaignCancelled = translation['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.POPUP_GSMC_FREE_ROUND_CANCELLED'];
                }, function(translationIds) {
                    translations.noDataFound = translationIds['MANAGED_FREE_ROUNDS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    translations.cancelGsmcFreeRoundCampaign = translationIds['MANAGED_FREE_ROUNDS.ERROR_MESSAGES.CANCEL_GSMC_FREE_ROUND_CAMPAIGN'];
                    translations.modalCancelGsmcFrCampaignTitle = translationIds['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.TITLE'];
                    translations.modalCancelGsmcFrCampaignMessage = translationIds['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.MESSAGE'];
                    translations.modalCancelGsmcFrCampaignBtnYes = translationIds['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_YES'];
                    translations.modalCancelGsmcFrCampaignBtnNo = translationIds['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.BUTTON_NO'];
                    translations.modalCancelGsmcFrCampaignCancelled = translationIds['MANAGED_FREE_ROUNDS.MODAL_CANCEL_GSMC_CAMPAIGN.POPUP_GSMC_FREE_ROUND_CANCELLED'];

            });
            return translations;
        }
    }
]);
