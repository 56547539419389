app.controller('MarketingMaterialsSublevelCtrl', ['$scope', '$stateParams', '$log', '$timeout', 'Upload', '$modal', '$location',
    'cacheService', 'requestService', 'notificationService', 'mailService', 'ERROR_CODES', 'USER_ROLES', '$translate',
    function($scope, $stateParams, $log, $timeout, Upload, $modal, $location, cacheService, requestService, notificationService, mailService,
        ERROR_CODES, USER_ROLES, $translate) {

        $scope.translations = initTranslations();
        init();
        initFileUpload();

        function init() {
            $scope.list = [];

            $scope.searchFilter = '';
            $scope.orderField = 'name';

            $scope.folderPath = $stateParams.path;
            if ($scope.folderPath.contains('/')) {
                var pos = $scope.folderPath.lastIndexOf("/") + 1;
                $scope.folderName = $scope.folderPath.substring(pos, $scope.folderPath.length);
            } else {
                $scope.folderName = $scope.folderPath;
            }

            $scope.folderHistory = [];
            var pathSplitted = $scope.folderPath.split('/');
            pathSplitted.pop(); // Remove current

            while (pathSplitted.length > 0) {
                $scope.folderHistory.push({
                    address: pathSplitted.join('/'),
                    name: pathSplitted.pop()
                });
            }
            $scope.folderHistory.reverse();

            $scope.inReadMode = cacheService.getWithDefault('materials.inReadMode', true);
            $scope.$watch('inReadMode', function() {
                cacheService.put('materials.inReadMode', $scope.inReadMode);
            });

            $scope.onBack = function() {
                if ($scope.folderHistory.length > 0) {
                    $location.path('materials-details/' + $scope.folderHistory[$scope.folderHistory.length - 1].address);
                } else {
                    $location.path('materials')
                }
            };

            $scope.onAddSublevelFolder = function() {
                openFolderModal($scope.folderPath, null);
            };

            $scope.onEditSublevelFolder = function(name) {
                openFolderModal($scope.folderPath, name);
            };

            $scope.onEditFile = function(name) {
                openFileModal($scope.folderPath, name);
            };

            $scope.onRemoveFolder = function(path) {
                requestService.delete(
                    '/api/files/remove?path=' + path,
                    function(response) {
                        if (response.status.code !== 'OK') {
                            $log.error(response.status);
                            notificationService.processApplicationError(
                                response.status,
                                $scope.translations.errorRemovingItemOnPath + path + '/' + name
                            );
                        }
                        list($scope.folderPath);
                    }
                )
            };

            $scope.onRemoveFile = function(path) {
                requestService.delete(
                    '/api/files/remove?path=' + path,
                    function(response) {
                        if (response.status.code !== 'OK') {
                            $log.error(response.status);
                            notificationService.processApplicationError(
                                response.status,
                                $scope.translations.errorRemovingItemOnPath + path + '/' + name
                            );
                        }
                        list($scope.folderPath);
                    }
                )
            };

            $scope.onSendRequest = function() {
                mailService.showMailModal($scope.translations.requestMaterialsSubject);
            };

            $scope.onDownloadAll = function(path) {
                downloadAll(path);
            };

            $scope.onDownloadFile = function(path) {
                downloadFile(path);
            };

            $scope.onCategorizeType = function(contentType) {
                var type;
                if (contentType) {
                    // https://gist.github.com/lsauer/5196979
                    switch (contentType) {
                        case 'application/pdf' :
                            type = 'pdf';
                            break;
                        case 'text/plain' :
                            type = 'text';
                            break;
                        case 'application/zip' :
                            type = 'archive';
                            break;
                        default :
                            if (contentType.startsWith('video')) {
                                type = 'video'
                            } else if (contentType.startsWith('audio')) {
                                type = 'audio'
                            }
                    }
                }
                return type;
            };

            if ($scope.folderPath) {
                list($scope.folderPath);
            }
        }

        function downloadAll(path) {
            requestService.get(
                '/api/files/get-download-link?path=' + path,
                function(data) {
                    if (data) {
                        var url = '/api/files/folder-download?link=' + data;
                        $scope.downloadFile(url);
                    } else {
                        notificationService.showErrorToast($scope.translations.errorGenerateDownloadLink);
                    }
                }
            );
        }

        function downloadFile(path) {
            requestService.get(
                '/api/files/get-download-link?path=' + path,
                function(data) {
                    if (data) {
                        var url = '/api/files/file-download?link=' + data;
                        $scope.downloadFile(url);
                    } else {
                        notificationService.showErrorToast($scope.translations.errorGenerateDownloadLink);
                    }
                }
            );
        }

        function initFileUpload() {
            $scope.progress = 0;
            $scope.onAddFiles = function(files) {
                $log.info('On Add Files');
                var path = $scope.folderPath;
                if (files) {
                    Upload.upload({
                        url: '/api/files/file-upload',
                        data: {
                            path: path,
                            file: files
                        }
                    }).progress(function(evt) {
                        $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
                    }).success(function(data, status, headers, config) {
                        $timeout(function() {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningDialog($scope.translations.errorUploadFailed, data.status.message);
                            } else if (data.status.code === ERROR_CODES.OK) {
                                notificationService.showInfoToast($scope.translations.successfullyUploaded);
                            }
                            $scope.progress = 0;
                            list($scope.folderPath);
                        })
                    }).error(function(error) {
                        $log.error($scope.translations.errorUploadFailed, error);
                        $timeout(function() {
                            $scope.progress = 0;
                            list($scope.folderPath);
                        })
                    });
                }
            };

            $scope.showWarnIcon = function(folder) {
                return folder.hasInvalidRestrictions == true && $scope.isAuthorized([USER_ROLES.ADMIN]);
            };
        }

        function list(path) {
            requestService.get(
                '/api/files/folder-list?path=' + path,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.list = data.list;
                    } else if (data.status.code === ERROR_CODES.UNAUTHORIZED) {
                        notificationService.showWarningDialog(
                            $scope.translations.errorNotAuthorizedTitle,
                            $scope.translations.errorNotAuthorizedMessage
                        ).then(function() {
                                $location.path('/materials');
                            }
                        );
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingMarketingMaterials);
                    }
                }
            );
        }

        function openFileModal(path, name) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/marketing/marketing_materials_sublevel_file_modal.html',
                controller: 'MarketingMaterialsSublevelFileModalCtrl',
                backdrop: false,
                resolve: {
                    path: function() {
                        return path;
                    },
                    name: function() {
                        return name;
                    }
                }
            });

            modalInstance.result.then(function() {
                list($scope.folderPath);
            }, function() {
                list($scope.folderPath);
            });
        }

        function openFolderModal(path, name) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/marketing/marketing_materials_sublevel_folder_modal.html',
                controller: 'MarketingMaterialsSublevelFolderModalCtrl',
                backdrop: false,
                resolve: {
                    path: function() {
                        return path;
                    },
                    name: function() {
                        return name;
                    }
                }
            });

            modalInstance.result.then(function() {
                list($scope.folderPath);
            }, function() {
                list($scope.folderPath);
            });
        }

        function initTranslations() {
            var translations = {};
            $translate(['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_ITEM_ON_PATH', 'MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST',
                'MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK', 'MARKETING_MATERIALS.ERROR_MESSAGES.UPLOAD_FAILED',
                'MARKETING_MATERIALS.INFO_SUCCESSFULLY_UPLOADED', 'MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_TITLE',
                'MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_MESSAGE', 'MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS'])
                .then(function(translation) {
                    translations.errorRemovingItemOnPath = translation['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_ITEM_ON_PATH'];
                    translations.errorGenerateDownloadLink = translation['MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK'];
                    translations.errorUploadFailed = translation['MARKETING_MATERIALS.ERROR_MESSAGES.UPLOAD_FAILED'];
                    translations.errorNotAuthorizedTitle = translation['MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_TITLE'];
                    translations.errorNotAuthorizedMessage = translation['MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_MESSAGE'];
                    translations.errorLoadingMarketingMaterials = translation['MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS'];
                    translations.requestMaterialsSubject = translation['MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST'];
                    translations.successfullyUploaded = translation['MARKETING_MATERIALS.INFO_SUCCESSFULLY_UPLOADED'];
            }, function(translationIds) {
                translations.errorRemovingItemOnPath = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.REMOVING_ITEM_ON_PATH'];
                    translations.requestMaterialsSubject = translationIds['MARKETING_MATERIALS.MODAL_REQUEST_MATERIALS.SUBJECT_REQUEST'];
                    translations.errorUploadFailed = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.UPLOAD_FAILED'];
                    translations.errorNotAuthorizedTitle = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_TITLE'];
                    translations.errorNotAuthorizedMessage = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.NOT_AUTHORIZED_MESSAGE'];
                    translations.errorGenerateDownloadLink = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.GENERATE_DOWNLOAD_LINK'];
                    translations.errorLoadingMarketingMaterials = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.LOADING_MARKETING_MATERIALS'];
                    translations.successfullyUploaded = translationIds['MARKETING_MATERIALS.INFO_SUCCESSFULLY_UPLOADED'];
            });
            return translations;
        }
    }
]);
