app.controller('GameListCtrl', ['$scope', '$log', '$modal', 'requestService', 'GAME_PLATFORM',
    function($scope, $log, $modal, requestService, GAME_PLATFORM) {

        init();

        function init() {
            initTable();

            $scope.searchFilter = '';

            $scope.onNewGame = function() {
                openGameModal(null, false);
            };

            $scope.onViewGame = function(id) {
                openGameModal(id, true);
            };

            $scope.onEditGame = function(id) {
                openGameModal(id, false);
            };

        }

        function initTable() {
            $scope.table = {
                games: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getTableData();
            };

            getTableData();
        }

        function getTableData() {
            requestService.get(
                '/api/games/game-list',
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                    }
                    updateTable(response);
                }
            );
        }

        function updateTable(response) {
            $scope.table.games = response.list;
        }

        function openGameModal(gameId, openInReadMode) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/admin/game_administration/modals/game_modal.html',
                controller: 'GameModalInstanceCtrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    gameId: function() {
                        return gameId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    }
                }
            });

            modalInstance.result.then(function() {
                getTableData();
            }, function() {
                getTableData();
            });
        }

    }
]);
