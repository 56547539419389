app.controller('CasinoManagementModalCtrl', ['$scope', '$modalInstance', 'requestService', 'notificationService', 'casinoId',
    'casinosAndGroups', 'ERROR_CODES', 'CASINO_TYPES', 'GLOBAL_CONSTANTS', '$modal', '$translate',
    function($scope, $modalInstance, requestService, notificationService, casinoId, casinosAndGroups, ERROR_CODES, CASINO_TYPES, GLOBAL_CONSTANTS,
        $modal, $translate) {
        console.log('casinoId: ' + casinoId);

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.casinoId = casinoId;
            if ($scope.casinoId === null) {
                $scope.create = true;
            } else {
                getCasino($scope.casinoId, false);
                getWhitelistIps($scope.casinoId);
            }

            $scope.formData = {
                casinoType: null,
                groupType: null,
                inheritedSettings: false
            };
            $scope.displayData = {
                casinosAndGroups: casinosAndGroups,
                casinoTypes: [],
                groupTypes: [],
                lockedPlayerWhitelistIps: [],
                totalPlayerWhitelistIps: null
            };

            $scope.countriesList = [];
            $scope.countriesIdList = [];
            $scope.reverse = {
                view: false,
                countries: []
            };

            $scope.initialInheritanceChanged = false;
            $scope.convertPassOpen = false;
            $scope.overflowVisible = false;
            $scope.cleartextToConvert = "";

            $scope.onCloseModal = function() {
                closeModal();
            };

            $scope.onCancelModal = function() {
                cancelModal();
            };

            $scope.inReadMode = false;

            $scope.onCreateCasino = function() {
                if($scope.reverse.view === true) {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
                requestService.postEncoded(
                    '/api/casino-management/casino-create/',
                    $scope.formData,
                    function(data) {
                        $scope.formErrors = data.status.formErrors || data.status.validationErrors || {};
                        if (data.status.code === ERROR_CODES.OK) {
                            notificationService.showInfoToast($scope.translations.infoCasinoCreated);
                            $scope.casinoId = data.casinoId;
                            $scope.create = false;
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCasino);
                            }
                        }
                    }
                );
            };

            $scope.onUpdateCasino = function(onUpdate) {
                if($scope.reverse.view === true) {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
                requestService.putEncoded(
                    'api/casino-management/casino-update/',
                    $scope.formData,
                    function(data) {
                        $scope.formErrors = data.status.formErrors || data.status.validationErrors || {};
                        if (data.status.code === ERROR_CODES.OK) {
                            notificationService.showInfoToast($scope.translations.infoCasinoUpdated);
                            if (onUpdate === false) {
                                closeModal();
                            } else {
                                $scope.initialInheritanceChanged = false;
                            }
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorUpdatingCasino);
                            }
                        }
                    }
                );
            };

            $scope.getCasinoTypes = function() {
                requestService.get(
                    'api/casino-management/casino-types-list',
                    function(response) {
                        $scope.displayData.casinoTypes = response;
                    },
                    function(response) {
                        notificationService.processServerError(response.status, $scope.translations.errorLoadingCasinoTypes, cancelModal);
                    }
                );
            };

            $scope.getGroupTypes = function() {
                requestService.get(
                    'api/casino-management/casino-group-types-list',
                    function(response) {
                        $scope.displayData.groupTypes = response;
                    },
                    function(response) {
                        notificationService.processServerError(response.status, $scope.translations.errorLoadingGroupTypes, cancelModal);
                    }
                );
            };

            $scope.onTypeSelect = function() {
                if ($scope.formData.casinoType === CASINO_TYPES.CASINO) {
                    $scope.formData.groupType = null;
                }
            };

            $scope.showGroupTypes = function() {
                if ($scope.formData.casinoType === CASINO_TYPES.GROUP) {
                    return true;
                }
                return false;
            };

            $scope.isGroup = function(casino) {
                if (casino.type === CASINO_TYPES.GROUP) {
                    return true;
                }
                return false;
            };

            $scope.sharingChanged = function() {
                if ($scope.formData.inheritedSettings === true) {
                    getCasino($scope.formData.parentId, true);
                    getWhitelistIps($scope.formData.parentId);
                } else {
                    if ($scope.create === true) {
                        $scope.displayData.lockedPlayerWhitelistIps = [];
                        $scope.displayData.totalPlayerWhitelistIps = null;
                    }
                }
                if (!$scope.create) {
                    $scope.initialInheritanceChanged = !$scope.initialInheritanceChanged;
                }
            };

            $scope.onParentSelect = function() {
                if ($scope.formData.parentId && $scope.formData.inheritedSettings) {
                    getCasino($scope.formData.parentId, true);
                }
            };

            $scope.showRadioButtons = function() {
                if ($scope.formData.parentId && $scope.formData.parentId !== GLOBAL_CONSTANTS.ROOT_CASINO_ID) {
                    return true;
                }
                return false;
            };

            $scope.editWhitelist = function(inReadMode) {
                var modalInstance = $modal.open({
                    animation: true,
                    templateUrl: 'app/templates/whitelist/whitelist_modal.html',
                    controller: 'WhitelistModalCtrl',
                    backdrop: false,
                    size: '-lg',
                    resolve: {
                        getUrl: function() {
                            return "/api/casino-whitelist-ip/table-data";
                        },
                        postUrl: function() {
                            return "/api/casino-whitelist-ip/merge";
                        },
                        deleteUrl: function() {
                            return "/api/casino-whitelist-ip/delete/";
                        },
                        deleteAllUrls: function() {
                            return "/api/casino-whitelist-ip/delete-all";
                        },
                        deleteUnlockedUrls: function() {
                            return "/api/casino-whitelist-ip/delete-unlocked";
                        },
                        uploadCsvUrl: function() {
                            return "/api/casino-whitelist-ip/upload-csv";
                        },
                        downloadCsvUrl: function() {
                            return "/api/casino-whitelist-ip/download-csv?casinoId=" + $scope.casinoId;
                        },
                        downloadXlsUrl: function() {
                            return "/api/casino-whitelist-ip/download-xls?casinoId=" + $scope.casinoId;
                        },
                        casinoId: function() {
                            return $scope.casinoId;
                        },
                        inReadMode: function() {
                            return inReadMode;
                        }
                    }
                });

                modalInstance.result.then(function() {
                    getWhitelistIps($scope.casinoId);
                }, function() {
                    getWhitelistIps($scope.casinoId);
                });
            };

            $scope.$on('countriesInitialized', function(event, data) {
                var ids = [];
                for (var i = 0; i < data.length; i++) {
                    ids.push(data[i].id);
                }
                $scope.countriesList = data;
                $scope.countriesIdList = ids;
            });

            $scope.viewChanged = function() {
                $scope.reverse.view = !$scope.reverse.view;
                if($scope.reverse.view === true) {
                    $scope.reverse.countries = _.difference($scope.countriesIdList, $scope.formData.restrictedCountries);
                } else {
                    $scope.formData.restrictedCountries = _.difference($scope.countriesIdList, $scope.reverse.countries);
                }
            };

            $scope.togglePassConverter = function() {
                if ($scope.convertPassOpen === false) {
                    setTimeout(function() {
                        $scope.overflowVisible = !$scope.overflowVisible;
                    }, 200);
                } else {
                    $scope.overflowVisible = !$scope.overflowVisible;
                }

                $scope.convertPassOpen = !$scope.convertPassOpen;
            };

            $scope.convertPass = function() {
                $scope.formData.cisPasswordHash = sha1($scope.cleartextToConvert);
                $scope.overflowVisible = !$scope.overflowVisible;
                $scope.convertPassOpen = !$scope.convertPassOpen;
            };

            $scope.$watch('formData.inheritedSettings', function() {
                $scope.inReadMode = $scope.formData.inheritedSettings;
            }, true);
        }

        function getCasino(casinoId, isParentCasino) {
            requestService.get(
                'api/casino-management/casino/' + casinoId,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        if (isParentCasino) {
                            var requestData = data.casinoValues;
                            $scope.formData.cisEndpointUrl = requestData.cisEndpointUrl;
                            $scope.formData.cisPasswordHash = requestData.cisPasswordHash;
                            $scope.formData.cisUsername = requestData.cisUsername;
                            $scope.formData.gsAnotherSecurity1 = requestData.gsAnotherSecurity1;
                            $scope.formData.gsAnotherSecurity2 = requestData.gsAnotherSecurity2;
                            $scope.formData.gsAnotherSecurity3 = requestData.gsAnotherSecurity3;
                            $scope.formData.gsPassword = requestData.gsPassword;
                            $scope.formData.gsUsername = requestData.gsUsername;
                            $scope.formData.inboundIpAddresses = requestData.inboundIpAddresses;
                            $scope.formData.whitelistIpAddresses = requestData.whitelistIpAddresses;
                            $scope.formData.protocol = requestData.protocol;
                            $scope.formData.restrictedCountries = requestData.restrictedCountries;
                        } else {
                            $scope.formData = data.casinoValues;
                        }
                        $scope.formErrors = data.message;
                    } else {
                        notificationService.processServerError(data.status, $scope.translations.errorLoadingCasino, cancelModal);
                    }
                }
            );
        }

        function getWhitelistIps(casinoId) {
            requestService.get(
                '/api/casino-whitelist-ip/preview/' + casinoId,
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.displayData.lockedPlayerWhitelistIps = response.lockedPlayerWhitelistIps;
                        $scope.displayData.totalPlayerWhitelistIps = response.totalPlayerWhitelistIps;
                    } else {
                        notificationService.processServerError(response.state.message, $scope.translations.errorCasinoIpWhitelist);
                    }
                },
                function(response) {
                    notificationService.processServerError(response.status, $scope.translations.errorLoadingCasinoIpWhitelist   );
                }
            );
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function initTranslations() {
            var translations = {};
            $translate(['CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_CREATED', 'CASINO_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM',
                'CASINO_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO', 'CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_UPDATED',
                'CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_TYPES', 'CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_GROUP_TYPES',
                'CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO', 'CASINO_MANAGEMENT.ERROR_MESSAGES.CASINO_IP_WHITELIST',
                'CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_IP_WHITELIST', 'CASINO_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO'])
                .then(function(translation) {
                    translations.errorInvalidForm = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCasino = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO'];
                    translations.errorUpdatingCasino = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO'];
                    translations.errorLoadingCasino = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO'];
                    translations.errorLoadingCasinoTypes = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_TYPES'];
                    translations.errorLoadingGroupTypes = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_GROUP_TYPES'];
                    translations.errorCasinoIpWhitelist = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.CASINO_IP_WHITELIST'];
                    translations.errorLoadingCasinoIpWhitelist = translation['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_IP_WHITELIST'];
                    translations.infoCasinoCreated = translation['CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_CREATED'];
                    translations.infoCasinoUpdated = translation['CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_UPDATED'];
                }, function(translationIds) {
                    translations.errorInvalidForm = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCasino = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO'];
                    translations.errorUpdatingCasino = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO'];
                    translations.errorLoadingCasino = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO'];
                    translations.errorLoadingCasinoTypes = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_TYPES'];
                    translations.errorLoadingGroupTypes = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_GROUP_TYPES'];
                    translations.errorCasinoIpWhitelist = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.CASINO_IP_WHITELIST'];
                    translations.errorLoadingCasinoIpWhitelist = translationIds['CASINO_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_IP_WHITELIST'];
                    translations.infoCasinoCreated = translationIds['CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_CREATED'];
                    translations.infoCasinoUpdated = translationIds['CASINO_MANAGEMENT.INFO_MESSAGES.CASINO_UPDATED'];
                });
            return translations;
        }
    }]
);
