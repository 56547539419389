'use strict';

/**
 * @ngdoc Transform routines for ElasticSearch search results to data structure similar to table.
 * @name ngs.graph.sevices:TabularTransformService
 * @description
 * # Transform routines for ElasticSearch search results to data structure similar to table.
 * # This means remove all unnecessary fields and flatten the structure as needed.
 */
app.factory('TabularTransformService', [
    function() {

        function getAggregatedValue(bucket, metric) {
            switch (metric) {
                case "rtp":
                    if (!bucket.aggregatedValueWin || !bucket.aggregatedValueStake) {
                        return 0;
                    } else if (!bucket.aggregatedValueWin.value || !bucket.aggregatedValueStake.value) {
                        return 0;
                    } else {
                        return bucket.aggregatedValueWin.value / bucket.aggregatedValueStake.value;
                    }
                case "count":
                    return bucket.doc_count;
                default:
                    return bucket.aggregatedValue && bucket.aggregatedValue.value;
            }
        }

        function extractMultiMetricValue(bucket, metric) {
            switch (metric) {
                case "rtp":
                    if (!bucket.aggregatedValue_rtpWin || !bucket.aggregatedValue_rtpStake) {
                        return 0;
                    } else if (!bucket.aggregatedValue_rtpWin.value || !bucket.aggregatedValue_rtpStake.value) {
                        return 0;
                    } else {
                        return bucket.aggregatedValue_rtpWin.value / bucket.aggregatedValue_rtpStake.value;
                    }
                case "count":
                    return bucket.doc_count;
                default:
                    return bucket["aggregatedValue_" + metric] && bucket["aggregatedValue_" + metric].value;
            }
        }

        function extractWithoutSeries(buckets, metric) {
            return buckets.map(function(bucket) {
                return {
                    term: bucket.key,
                    value: getAggregatedValue(bucket, metric)
                };
            });
        }

        function extractSeries(buckets, metric) {
            var result = [];

            buckets.forEach(function(termBucket) {
                termBucket.series.buckets.forEach(function(seriesBucket) {
                    result.push({
                        term: termBucket.key,
                        series: seriesBucket.key,
                        value: getAggregatedValue(seriesBucket, metric)
                    });
                });
            });

            return result;
        }

        function extractMultiMetric(buckets, metrics) {
            var result = [];

            buckets.forEach(function(termBucket) {
                var row = {
                    term: termBucket.key
                };

                metrics.forEach(function(metric) {
                    row[metric] = extractMultiMetricValue(termBucket, metric);
                });

                result.push(row);
            });

            return result;
        }

        function transformTimeBasedSeriesData(data, metric) {
            return extractSeries(data.aggregations.data.by_date.buckets, metric);
        }

        function transformTimeBasedData(data, metric) {
            return extractWithoutSeries(data.aggregations.data.by_date.buckets, metric, true);
        }

        function transformTermBasedSeriesData(data, metric) {
            return extractSeries(data.aggregations.data.by_term.buckets, metric);
        }

        function transformTermBasedData(data, metric) {
            return extractWithoutSeries(data.aggregations.data.by_term.buckets, metric, false);
        }

        function transformTermsMultiMetricSearchData(data, metrics) {
            return extractMultiMetric(data.aggregations.data.by_term.buckets, metrics);
        }

        return {
            /**
             * Expects ES result and optionally default value and returns tabular data
             * If defaultValue if null no default is used otherwise value || defaultValue || 0.
             * params - data, metric, defaultValue
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             */
            transformTimeBasedSeriesData: transformTimeBasedSeriesData,
            /**
             * Expects ES result and returns tabular data
             * params - data, metric, seriesName
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   seriesName - name for the series that will be created from values
             */
            transformTimeBasedData: transformTimeBasedData,

            /**
             * Expects ES result and optionally default value and return tabular data
             * If defaultValue if null no default is used otherwise value || defaultValue || 0.
             * params - data, metric, defaultValue
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             */
            transformTermBasedSeriesData: transformTermBasedSeriesData,
            /**
             * Expects ES result and returns tabular data
             * params - data, metric, seriesName
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   seriesName - name for the series that will be created from values
             */
            transformTermBasedData: transformTermBasedData,

            /**
             * Expects ES result and returns tabular data
             * params - data, metrics, defaultValue
             *   data - search result from ES (raw json)
             *   metrics - array of metric names e.g. [rtp, count, winInEur]
             */
            transformTermsMultiMetricSearchData: transformTermsMultiMetricSearchData
        };
    }
]);
