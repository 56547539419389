app.controller('PlayersCtrl', ['$scope', '$log', 'requestService', 'notificationService', 'ERROR_CODES', '$translate',
    function($scope, $log, requestService, notificationService, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();
        initTable();

        function init() {
            $scope.formData = {};
            $scope.formErrors = {};
            $scope.treeStructure = [];
            $scope.casinosAndGroups = [];

            $scope.onSearch = function() {
                searchPlayer();
            };

            $scope.treeStructure = getCasinoTreeStructure();

            $scope.getCasinoName = function(casinoId) {
                for (var i = 0; i < $scope.casinosAndGroups.length; i++) {
                    if ($scope.casinosAndGroups[i].id === casinoId) {
                        return $scope.casinosAndGroups[i].name;
                    }
                }
            };

            $scope.isDisabled = function() {
                return $scope.formData.casinoIds === undefined || $scope.formData.casinoIds.length === 0 || $scope.formData.userName === undefined;
            };

            getCasinosAndGroups();
        }

        function initTable() {
            $scope.table = {
                players: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefresh = function() {
                searchPlayer();
            };
        }

        function searchPlayer() {
            requestService.postEncoded(
                '/api/players/search-player',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.table.players = data.list;
                        if (data.list == 0) {
                            notificationService.showInfoToast($scope.translations.infoNoDataFound);
                        }
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidData);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorFetchingData);
                        }
                    }
                }
            );
        }

        function getCasinoTreeStructure() {
            requestService.get(
                '/api/enums/casino-tree',
                function(data) {
                    $scope.treeStructure = data;
                },
                function(error) {
                    notificationService.processServerError(error, $scope.translations.errorCasinoTreeStructure);
                }
            );
        }

        function getCasinosAndGroups() {
            requestService.get(
                '/api/enums/casino-list',
                function(data) {
                    $scope.casinosAndGroups = data;
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['PLAYERS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND', 'PLAYERS_OVERVIEW.ERROR_MESSAGES.INVALID_DATA',
                'PLAYERS_OVERVIEW.ERROR_MESSAGES.FETCHING_DATA', 'PLAYERS_OVERVIEW.ERROR_MESSAGES.CASINO_TREE_STRUCTURE'])
                .then(function(translation) {
                    translations.errorInvalidData = translation['PLAYERS_OVERVIEW.ERROR_MESSAGES.INVALID_DATA'];
                    translations.errorFetchingData = translation['PLAYERS_OVERVIEW.ERROR_MESSAGES.FETCHING_DATA'];
                    translations.errorCasinoTreeStructure = translation['PLAYERS_OVERVIEW.ERROR_MESSAGES.CASINO_TREE_STRUCTURE'];
                    translations.infoNoDataFound = translation['PLAYERS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND'];
                }, function(translationIds) {
                    translations.errorInvalidData = translationIds['PLAYERS_OVERVIEW.ERROR_MESSAGES.INVALID_DATA'];
                    translations.errorFetchingData = translationIds['PLAYERS_OVERVIEW.ERROR_MESSAGES.FETCHING_DATA'];
                    translations.errorCasinoTreeStructure = translationIds['PLAYERS_OVERVIEW.ERROR_MESSAGES.CASINO_TREE_STRUCTURE'];
                    translations.infoNoDataFound = translationIds['PLAYERS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND'];
                });
            return translations;
        }
    }
]);
