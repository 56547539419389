app.controller('CasinoSettingsModalInstanceCtrl', ['$scope', '$log', '$modalInstance', '$modal', '$timeout', 'requestService', 'notificationService',
    'gameId', 'casinoId', 'gameName', 'openInReadMode', 'parentId', 'ERROR_CODES', 'GLOBAL_CONSTANTS', 'canEdit', 'canInherit', '$translate',
    function($scope, $log, $modalInstance, $modal, $timeout, requestService, notificationService, gameId, casinoId, gameName, openInReadMode,
        parentId, ERROR_CODES, GLOBAL_CONSTANTS, canEdit, canInherit, $translate) {

        $scope.translations = initTranslations();
        initForm();

        function initForm() {
            $scope.formData = {
                gameId: gameId,
                casinoId: casinoId,
                settings: [],
                seoTexts: [],
                configParamsDefault: [],
                configParamsRanges: [],
                tags: [],
                inherited: false
            };
            $scope.gameClientConfigControl = {};
            $scope.gameName = gameName;
            $scope.formErrors = {};
            $scope.inReadMode = openInReadMode;
            $scope.parentId = parentId;
            $scope.childCasinoIds = [];
            $scope.ROOT_CASINO_ID = GLOBAL_CONSTANTS.ROOT_CASINO_ID;
            $scope.canEdit = canEdit;
            $scope.canInherit = canInherit;
            $scope.distributions = [];

            $scope.onCreate = function() {
                createCasinoSettings();
            };

            $scope.configParamsDefaultControl = {};
            $scope.configParamsRangesControl = {};

            $scope.onSave = function(casinoSettingsId) {
                $scope.configParamsDefaultControl.onSaveForm(function() {
                    $scope.configParamsRangesControl.onSaveForm(function() {
                        saveCasinoSettings(casinoSettingsId);
                    });
                });
            };

            $scope.onEdit = function() {
                $scope.inReadMode = false;
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                $scope.configParamsDefaultControl.onCancelForm();
                $scope.configParamsRangesControl.onCancelForm();
                cancelModal();
            };

            $scope.onAddNewText = function() {
                $scope.formData.seoTexts.push({
                    languageId: null,
                    text: ''
                });
            };

            $scope.onRemoveText = function(index) {
                $scope.formData.seoTexts.splice(index, 1);
            };

            $scope.languages = [];
            requestService.get(
                '/api/enums/language-list',
                function(data) {
                    $scope.languages = data;
                }
            );

            $scope.onChangeInheritance = function() {
                if ($scope.formData.inherited === true) {
                    getCasinoSettings(gameId, parentId, true);
                }
            };

            $scope.$on('childCasinosResponse', function (event, data) {
                $scope.childCasinoIds = data;
            });

            $scope.onRefreshDistributionsList = function() {
                requestService.get(
                    '/api/enums/distributions',
                    function(response) {
                        $scope.distributions = response;
                    },
                    function(error) {
                        notificationService.processApplicationError(error.status,
                            $scope.translations.errorLoadingDistributions);
                    }
                )
            };

            $timeout(function() {
                    getCasinoSettings(gameId, casinoId, false);
                },
                $scope.DEFAULT_TIMEOUT
            );
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getCasinoSettings(gameId, casinoId, parentSettings) {
            requestService.get('/api/casino/casino-settings?gameId=' + gameId + '&casinoId=' + casinoId,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        if (data.values) {
                            var inheritingChildCasinoIds = $scope.formData.inheritingChildCasinoIds;
                            var originalId = $scope.formData.id;
                            var originalCasinoId = $scope.formData.casinoId;
                            $scope.formData = data.values;
                            //overwrite values, that are not overwrited by parent casino game settings
                            $scope.formData.id = originalId;
                            $scope.formData.casinoId = originalCasinoId;
                            if (parentSettings) {
                                $scope.formData.inherited = true;
                                $scope.formData.inheritingChildCasinoIds = inheritingChildCasinoIds;
                            }
                        }
                        $scope.casinoSettingsId = data.casinoSettingsId;
                        if (!$scope.casinoSettingsId) {
                            $scope.formData.enabled = true;
                        }
                        $scope.allowedCgsGames = data.allowedCgsGames;
                        $scope.allowedCoinValuesFrom = data.allowedCoinValuesFrom;
                        $scope.allowedCoinValuesTo = data.allowedCoinValuesTo;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingCasinoSettings, cancelModal);
                    }
                }
            );
        }

        function createCasinoSettings() {
            requestService.postEncoded('/api/casino/casino-settings',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;
                        $scope.casinoSettingsId = data.casinoSettingsId;

                        notificationService.showInfoToast($scope.translations.infoCasinoSettingsCreated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCasinoSettings);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function saveCasinoSettings(id) {
            requestService.putEncoded('/api/casino/casino-settings/' + id,
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;
                        $scope.casinoSettingsId = data.casinoSettingsId;

                        notificationService.showInfoToast($scope.translations.infoCasinoSettingsUpdated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorUpdatingCasinoSettings);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_SETTINGS', 'CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_CREATED',
                'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO_SETTINGS',
                'CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_UPDATED', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO_SETTINGS'])
                .then(function(translation) {
                    translations.errorLoadingCasinoSettings = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_SETTINGS'];
                    translations.errorLoadingDistributions = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_DISTRIBUTIONS'];
                    translations.errorInvalidForm = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCasinoSettings = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO_SETTINGS'];
                    translations.errorUpdatingCasinoSettings = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO_SETTINGS'];
                    translations.infoCasinoSettingsCreated = translation['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_CREATED'];
                    translations.infoCasinoSettingsUpdated = translation['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_UPDATED'];
                }, function(translationIds) {
                    translations.errorLoadingCasinoSettings = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_CASINO_SETTINGS'];
                    translations.errorLoadingDistributions = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_DISTRIBUTIONS'];
                    translations.errorInvalidForm = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCasinoSettings = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.CREATING_CASINO_SETTINGS'];
                    translations.errorUpdatingCasinoSettings = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.UPDATING_CASINO_SETTINGS'];
                    translations.infoCasinoSettingsCreated = translationIds['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_CREATED'];
                    translations.infoCasinoSettingsUpdated = translationIds['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.CASINO_SETTINGS_UPDATED'];
                });
            return translations;
        }
    }
]);

app.filter('seoLanguageFilter', function() {
    return function(languages, seoTexts) {
        var result = [];

        for (var i = 0, len = languages.length; i < len; i++) {
            var alreadySelected = false;
            for (var j = 0, len2 = seoTexts.length; j < len2; j++) {
                if (seoTexts[j].languageId == languages[i].id) {
                    alreadySelected = true;
                    break;
                }
            }
            if (!alreadySelected) {
                result.push(languages[i]);
            }
        }

        return result;
    }
});
