app.controller('DemoUserManagementCtrl', ['$scope', 'requestService', 'notificationService', '$q', '$translate',
    function($scope, requestService, notificationService, $q, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.users = [];

            $scope.addDemoUser = function() {
                // in a case when in the casino search filter was entered text, remove search term to be able to add new row in edit mode
                delete $scope.searchFilter;
                $scope.insertedUser = {
                    id: null,
                    username: "",
                    password: "",
                    casinoRefId: undefined,
                    active: true
                };
                $scope.users.push($scope.insertedUser);
            };

            $scope.createOrUpdateDemoUser = function(user, data) {
                var d = $q.defer();
                var originalUserData = angular.copy(user);
                user.originalData = originalUserData;
                for (var property in data) {
                    if (user.hasOwnProperty(property)) {
                        user[property] = data[property];
                    }
                }
                if (user.id === null) {
                    //create
                    requestService.postEncoded(
                        '/api/demo-user-management/create-or-update',
                        user,
                        function(response) {
                            if (response.status.code === 'OK') {
                                updateDemoUser(response.demoUser);
                                notificationService.showInfoToast($scope.translations.infoDemoUserCreated);
                                d.resolve();
                            } else if (response.status.code === 'VALIDATION_FAILED') {
                                user.formErrors = response.status.formErrors;
                                d.resolve($scope.translations.errorValidation);
                            } else {
                                notificationService.processApplicationError(response.status, $scope.translations.errorSavingDemoUser);
                                d.resolve($scope.translations.errorSavingDemoUser);
                            }
                        },
                        function() {
                            d.reject($scope.translations.errorServer);
                        }
                    );
                } else {
                    //update
                    requestService.putEncoded(
                        '/api/demo-user-management/create-or-update',
                        user,
                        function(response) {
                            if (response.status.code === 'OK') {
                                updateDemoUser(response.demoUser);
                                notificationService.showInfoToast($scope.translations.infoDemoUserUpdated);
                                d.resolve();
                            } else if (response.status.code === 'VALIDATION_FAILED') {
                                user.formErrors = response.status.formErrors;
                                d.resolve($scope.translations.errorValidation);
                            } else {
                                notificationService.processApplicationError(response.status, $scope.translations.errorDemoUserUpdating);
                                d.resolve($scope.translations.errorDemoUserUpdating);
                            }
                        },
                        function() {
                            d.reject($scope.translations.errorServer);
                        }
                    );
                }

                return d.promise;
            };

            $scope.removeDemoUser = function(form, demoUserId) {
                requestService.delete(
                    '/api/demo-user-management/remove-demo-user/' + demoUserId,
                    function(response) {
                        if (response.status.code === 'OK') {
                            //form.$hide();
                            for (var i = 0; i < $scope.users.length; i++) {
                                if ($scope.users[i].id === demoUserId) {
                                    $scope.users.splice(i, 1);
                                    notificationService.showInfoToast($scope.translations.infoDemoUserRemoved);
                                    break;
                                }
                            }
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorDemoUserRemoving);
                        }
                    }
                );
            };

            $scope.cancelDemoUser = function(data) {
                if (data.id === null) {
                    for (var i = 0; i < $scope.users.length; i++) {
                        if ($scope.users[i].id === data.id) {
                            $scope.users.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    if (data.originalData) {
                        for (var i = 0; i < $scope.users.length; i++) {
                            if ($scope.users[i].id === data.id) {
                                $scope.users[i] = data.originalData;
                            }
                        }
                    }
                }
            };

            getDemoUsers();
        }

        function updateDemoUser(demoUser) {
            for (var i = 0; i < $scope.users.length; i++) {
                if ($scope.users[i].username === demoUser.username) {
                    $scope.users[i] = demoUser;
                    break;
                }
            }
        }

        function getDemoUsers() {
            requestService.get(
                'api/demo-user-management/get-users',
                function(response) {
                    $scope.users = response.list;
                },
                function(response) {
                    notificationService.processServerError(response.status, $scope.translations.errorDemoUserLoading);
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_CREATED', 'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.VALIDATION_ERROR',
                'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SAVING_DEMO_USER', 'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SERVER_ERROR',
                'DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_UPDATED', 'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_UPDATING',
                'DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_REMOVED', 'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_REMOVING',
                'DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_LOADING'])
                .then(function(translation) {
                    translations.errorValidation = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.VALIDATION_ERROR'];
                    translations.errorSavingDemoUser = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SAVING_DEMO_USER'];
                    translations.errorServer = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorDemoUserUpdating = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_UPDATING'];
                    translations.errorDemoUserRemoving = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_REMOVING'];
                    translations.errorDemoUserLoading = translation['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_LOADING'];
                    translations.infoDemoUserCreated = translation['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_CREATED'];
                    translations.infoDemoUserUpdated = translation['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_UPDATED'];
                    translations.infoDemoUserRemoved = translation['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_REMOVED'];
                }, function(translationIds) {
                    translations.errorValidation = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.VALIDATION_ERROR'];
                    translations.errorSavingDemoUser = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SAVING_DEMO_USER'];
                    translations.errorServer = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorDemoUserUpdating = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_UPDATING'];
                    translations.errorDemoUserRemoving = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_REMOVING'];
                    translations.errorDemoUserLoading = translationIds['DEMO_USER_MANAGEMENT.ERROR_MESSAGES.DEMO_USER_LOADING'];
                    translations.infoDemoUserCreated = translationIds['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_CREATED'];
                    translations.infoDemoUserUpdated = translationIds['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_UPDATED'];
                    translations.infoDemoUserRemoved = translationIds['DEMO_USER_MANAGEMENT.INFO_MESSAGES.DEMO_USER_REMOVED'];
                });
            return translations;
        }
    }
]);
