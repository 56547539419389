app.factory('mailService', ['$rootScope', '$log', '$location', '$modal',
    function($rootScope, $log, $location, $modal) {

        var service = {};
        service.showMailModal = showMailModal;

        return service;

        function showMailModal(subject, message, email) {
            var settings = {
                templateUrl: 'app/templates/mail_dialog.html',
                modalData: {
                    subject: subject,
                    email: email,
                    message: message
                },
                size: '-xlogin',
                backdrop: true,
                controller: 'MailModalInstanceCtrl',
                resolve: {
                    data: function() {
                        return settings.modalData;
                    }
                }
            };

            return $modal.open(settings).result;
        }
    }
]);

app.controller('MailModalInstanceCtrl', ['$rootScope', '$scope', '$modalInstance', '$log', 'requestService', 'notificationService', 'data',
    'ERROR_CODES', '$translate',
    function($rootScope, $scope, $modalInstance, $log, requestService, notificationService, data, ERROR_CODES, $translate) {

        init();

        function init() {
            $scope.formData = angular.copy(data);
            $scope.formErrors = {};

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.onSend = function(formData) {
                sendMail(formData.subject, formData.message, formData.email);
            }
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function sendMail(subject, message, email) {
            // Authorized, based on user we know email
            requestService.postEncoded(
                '/api/send-mail',
                {
                    subject: subject,
                    email: email,
                    message: message
                },
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};
                    if (data.status.code === ERROR_CODES.OK) {
                        notificationService.showInfoToast($translate.instant('GENERAL.SERVICES.MAIL.INFO_MESSAGES.MAIL_SENT'));
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($translate.instant('GENERAL.SERVICES.MAIL.ERROR_MESSAGES.INVALID_FORM'));
                        } else {
                            notificationService.processApplicationError(data.status, $translate.instant('GENERAL.SERVICES.MAIL.ERROR_MESSAGES.SENDING_MAIL'));
                        }
                    }
                }
            );
        }
    }
]);
