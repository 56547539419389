app.controller('CasinoSettingsCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', 'GAME_PLATFORM', 'ERROR_CODES',
    'GLOBAL_CONSTANTS', '$translate',
    function($scope, $log, $modal, requestService, notificationService, GAME_PLATFORM, ERROR_CODES, GLOBAL_CONSTANTS, $translate) {

        getCasinosList();

        $scope.translations = initTranslations();

        $scope.tabsHandler = {
            activeTab: "GAMES_BY_CASINO",
            tabs: [
                {name: 'GAMES_BY_CASINO', route: 'casino-game-management.games-by-casino'},
                {name: 'CASINOS_BY_GAME', route: 'casino-game-management.casinos-by-game'}
            ]
        };

        $scope.tabClicked = function(tab) {
            $scope.tabsHandler.activeTab = tab;
        };

        function getCasinosList() {
            $scope.casinoList = [];
            requestService.get(
                '/api/casino/casino-settings-casino-list',
                function(data) {
                    $scope.casinoList = data;
                    if ($scope.casinoList.size === 1) {
                        $scope.formData.casinoId = $scope.casinoList[0];
                    }
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_SELECTED_CASINO_DATA',
                'CASINO_GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_DUPLICATED', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.VALIDATION_FAILED',
                'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INHERITING_SETTINGS', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.FETCHING_TABLE_DATA',
                'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GENERATING_GAMES_SETTINGS',
                'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_ALL_CGS', 'CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_CGS'])
                .then(function(translation) {
                    translations.errorLoadingSelectedCasinoData = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_SELECTED_CASINO_DATA'];
                    translations.errorValidationFailed = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorInheritingSettings = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INHERITING_SETTINGS'];
                    translations.errorFetchingTableData = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.FETCHING_TABLE_DATA'];
                    translations.errorInvalidForm = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorGeneratingGamesSettings = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GENERATING_GAMES_SETTINGS'];
                    translations.errorGeneratingAllCgs = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_ALL_CGS'];
                    translations.errorGeneratingCgs = translation['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_CGS'];
                    translations.infoSettingsSuccessfullyDuplicated = translation['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_DUPLICATED'];
                }, function(translationIds) {
                    translations.errorLoadingSelectedCasinoData = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.LOADING_SELECTED_CASINO_DATA'];
                    translations.errorValidationFailed = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorInheritingSettings = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INHERITING_SETTINGS'];
                    translations.errorFetchingTableData = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.FETCHING_TABLE_DATA'];
                    translations.errorInvalidForm = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorGeneratingGamesSettings = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GENERATING_GAMES_SETTINGS'];
                    translations.errorGeneratingAllCgs = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_ALL_CGS'];
                    translations.errorGeneratingCgs = translationIds['CASINO_GAME_MANAGEMENT.ERROR_MESSAGES.GETTING_CGS'];
                    translations.infoSettingsSuccessfullyDuplicated = translationIds['CASINO_GAME_MANAGEMENT.INFO_MESSAGES.GAME_SETTINGS_DUPLICATED'];
                });
            return translations;
        }
    }
]);
