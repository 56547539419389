app.controller('AlarmSettingsHelpModalCtrl', ['$scope', '$modalInstance', 'alarmType',
    function($scope, $modalInstance, alarmType) {
        $scope.tableData = [
            {
                categoryName: "NOTIFICATION",
                subcategories: [
                    {
                        subcategoryName: "",
                        properties: [
                            {
                                propertyName: "${time}",
                                description: "NOTIFICATION_TIME"
                            },
                            {
                                propertyName: "${alarmLevel}",
                                description: "ALARM_LEVEL"
                            },
                            {
                                propertyName: "${alarmMessageType}",
                                description: "MESSAGE_TYPE"
                            },
                            {
                                propertyName: "${alarmType}",
                                description: "ALARM_TYPE"
                            },
                            {
                                propertyName: "${message}",
                                description: "PREDEFINED_GENERAL_ALARM_NOTIFICATION"
                            }
                        ]
                    }
                ]
            },
            {
                categoryName: "GAME_ROUND_DATA",
                subcategories: [
                    {
                        subcategoryName: "CASINO_DATA",
                        properties: [
                            {
                                propertyName: "${data.gameRound.casinoName}",
                                description: "CASINO_NAME"
                            }
                        ]
                    },
                    {
                        subcategoryName: "PLAYER_DATA",
                        properties: [
                            {
                                propertyName: "${data.gameRound.playerId}",
                                description: "PLAYER_ID"
                            },
                            {
                                propertyName: "${data.gameRound.playerCountryCode}",
                                description: "PLAYER_COUNTRY_CODE"
                            },
                            {
                                propertyName: "${data.gameRound.playerLanguageCode}",
                                description: "PLAYER_LANGUAGE"
                            },
                            {
                                propertyName: "${data.gameRound.playerCurrencyCode}",
                                description: "PLAYER_CURRENCY"
                            },
                            {
                                propertyName: "${data.gameRound.playerName}",
                                description: "PLAYER_NAME"
                            },
                            {
                                propertyName: "${data.gameRound.playerSex}",
                                description: "PLAYER_SEX"
                            },
                            {
                                propertyName: "${data.gameRound.playerDateOfBirth}",
                                description: "PLAYER_DATE_OF_BIRTH"
                            },
                            {
                                propertyName: "${data.gameRound.playerRegistrationDate}",
                                description: "PLAYER_REGISTRATION_DATE"
                            }
                        ]
                    },
                    {
                        subcategoryName: "GAME_DATA",
                        properties: [
                            {
                                propertyName: "${data.gameRound.externalGameId}",
                                description: "EXTERNAL_GAME_ID"
                            },
                            {
                                propertyName: "${data.gameRound.gameName}",
                                description: "GAME_NAME"
                            },
                            {
                                propertyName: "${data.gameRound.externalCgsGameId}",
                                description: "EXTERNAL_CGS_GAME_ID"
                            },
                            {
                                propertyName: "${data.gameRound.browserPlatform}",
                                description: "PLATFORM"
                            }
                        ]
                    },
                    {
                        subcategoryName: "GAME_SESSION_DATA",
                        properties: [
                            {
                                propertyName: "${data.gameRound.gameSessionId}",
                                description: "GAME_SESSION_ID"
                            },
                            {
                                propertyName: "${data.gameRound.sessionGameRoundId}",
                                description: "GAME_ROUND_ID"
                            }
                        ]
                    },
                    {
                        subcategoryName: "GAME_ROUND_DATES",
                        properties: [
                            {
                                propertyName: "${data.gameRound.dateStarted}",
                                description: "STAKE_MADE_DATE"
                            },
                            {
                                propertyName: "${data.gameRound.dateFinished}",
                                description: "GAME_ROUND_PROCESSED_DATE"
                            }
                        ]
                    },
                    {
                        subcategoryName: "MONEY",
                        properties: [
                            {
                                propertyName: "${data.gameRound.stake}",
                                description: "PLAYER_STAKE"
                            },
                            {
                                propertyName: "${data.gameRound.win}",
                                description: "PLAYER_WIN"
                            },
                            {
                                propertyName: "${data.gameRound.conversionRateToEUR}",
                                description: "CONVERSION_RATE"
                            },
                            {
                                propertyName: "${data.gameRound.moneyType}",
                                description: "MONEY_TYPE"
                            }
                        ]
                    }
                ]
            }
        ];
        var emaRtpData = {
            categoryName: "EMA_RTP_ALARM",
            subcategories: [
                {
                    subcategoryName: "",
                    properties: [
                        {
                            propertyName: "${data.expectedRtp}",
                            description: "GAME_RTP"
                        },
                        {
                            propertyName: "${data.warningThreshold}",
                            description: "WARNING_THRESHOLD_RTP"
                        },
                        {
                            propertyName: "${data.errorThreshold}",
                            description: "ERROR_THRESHOLD_RTP"
                        },
                        {
                            propertyName: "${data.actualRtp}",
                            description: "RTP_CALCULATED_ACCORDING_EMA_RTP"
                        }
                    ]
                }
            ]
        };
        var highWinData = {
            categoryName: "HW_PER_GAME",
            subcategories: [
                {
                    subcategoryName: "",
                    properties: [
                        {
                            propertyName: "${data.stakeSum}",
                            description: "TOTAL_SUM_STAKES"
                        },
                        {
                            propertyName: "${data.winSum}",
                            description: "TOTAL_SUM_WINS"
                        },
                        {
                            propertyName: "${data.stakeWindow}",
                            description: "STAKE_WINDOW_SIZE"
                        },
                        {
                            propertyName: "${data.warningThreshold}",
                            description: "SUM_STAKES_THRESHOLD_WARNING"
                        },
                        {
                            propertyName: "${data.errorThreshold}",
                            description: "SUM_STAKES_THRESHOLD_ERROR"
                        }
                    ]
                }
            ]
        };

        if (alarmType === 'EMA_RTP') {
            $scope.tableData.unshift(emaRtpData);
        }
        else if (alarmType === 'HIGH_WIN') {
            $scope.tableData.unshift(highWinData);
        }

        $scope.onCancelHelpModal = function() {
            $modalInstance.dismiss('cancel');
        }
    }
]);
