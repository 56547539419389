app.controller('FreeRoundsCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', '$translate',
    function($scope, $log, $modal, requestService, notificationService, $translate) {

        $scope.translations = initTranslations();
        init();
        getCampaignTableData();

        function init() {
            $scope.onNewCampaign = function() {
                openCampaignModal(null, false);
            };

            $scope.onViewCampaign = function(id) {
                openCampaignModal(id, true);
            };

            $scope.onEditCampaign = function(id) {
                openCampaignModal(id, false);
            };

            $scope.onDeleteCampaign = function(id) {
                deleteCampaign(id);
            };

            $scope.table = {
                campaigns: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 10,
                totalSize: 0,
                sortBy: [],
                sortColumn: null,
                sortAsc: null,
                offset: 0
            };

            $scope.onRefreshTableData = function() {
                getCampaignTableData();
            };
        }

        function getCampaignTableData() {
            requestService.get(
                '/api/campaigns/campaign-list',
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                    } else {
                        updateCampaignTableClientPaging(response);
                    }
                }
            );
        }

        function updateCampaignTable(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);

            $scope.table.campaigns = tableData;
            //$scope.table.offset = response.dataOffset;
            //$scope.table.totalSize = response.dataSize;
        }

        function updateCampaignTableClientPaging(response) {
            $scope.table.campaigns = response.list;
        }

        function openCampaignModal(campaignId, openInReadMode) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/campaign_manager/campaign.html',
                controller: 'CampaignModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    campaignId: function() {
                        return campaignId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    }
                }
            });

            modalInstance.result.then(function() {
                getCampaignTableData();
            }, function() {
                getCampaignTableData();
            });
        }

        function deleteCampaign(campaignId) {
            requestService.delete(
                '/api/campaigns/campaign/' + campaignId,
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);

                        notificationService.processApplicationError(response.status, $scope.translations.errorRemovingCampaign);
                    }

                    getCampaignTableData();
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.REMOVING_CAMPAIGN']).then(function(translation) {
                translations.errorRemovingCampaign = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.REMOVING_CAMPAIGN'];
            }, function(translationIds) {
                translations.errorRemovingCampaign = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.REMOVING_CAMPAIGN'];
            });
            return translations;
        }
    }
]);
