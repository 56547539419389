app.controller('CasinoGamesManagementByGameCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', 'GAME_PLATFORM', 'ERROR_CODES',
    function($scope, $log, $modal, requestService, notificationService, GAME_PLATFORM, ERROR_CODES) {

        init();
        initTable();
        getGamesWithCgs();
        getCurrenciesList();

        function initTable() {
            $scope.table = {
                games: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getGamesWithCgs();
            };
        }

        if ($scope.formData && $scope.formData.casinoId) {
            getGamesWithCgs();
        }

        function init() {
            $scope.tabsHandler.activeTab = "CASINOS_BY_GAME";
            $scope.searchFilterByGameName = "";
            $scope.searchFilterByCasinoName = "";

            $scope.toggleCasinoSettings = function(gameSettings) {
                gameSettings.isCollapsed = !gameSettings.isCollapsed;
            };

            $scope.onCreateOrUpdateCasinoSettings = function(casinoId, gameId, name, canInherit, parentId, cgsToUpdate) {
                openCasinoSettingsModal(casinoId, gameId, name, false, canInherit, parentId, cgsToUpdate);
            };
        }

        function getGamesWithCgs() {
            $scope.pageLoading = true;
            requestService.get(
                '/api/casino-management/all-casinos-by-games',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.gamesList = response.list;
                        updateAllCurrencyAndCasinoNames($scope.gamesList);
                        $scope.pageLoading = false;
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.errorGeneratingAllCgs);
                    }
                }
            )
        }

        function updateAllCurrencyAndCasinoNames(gamesList) {
            for (var i = 0; i < gamesList.length; i++) {
                updateCurrencyAndCasinoName(gamesList[i]);
            }
        }

        function updateCurrencyAndCasinoName(game) {
            for (var j = 0; j < game.casinosGameSettings.length; j++) {
                if (game.casinosGameSettings[j].casinoGameSettings !== null) {
                    for (var k = 0; k < $scope.currenciesList.length; k++) {
                        if (game.casinosGameSettings[j].casinoGameSettings.coinValuesCurrencyId === $scope.currenciesList[k].id) {
                            game.casinosGameSettings[j].casinoGameSettings.coinValuesCurrencyName = $scope.currenciesList[k].code;
                        }
                    }
                }

                for (var l = 0; l < $scope.casinoList.length; l++) {
                    if (game.casinosGameSettings[j].casinoId === $scope.casinoList[l].id) {
                        game.casinosGameSettings[j].casinoName = $scope.casinoList[l].name;
                    }
                }
            }
        }

        function getCurrenciesList() {
            requestService.get(
                '/api/enums/currency-list',
                function(data) {
                    $scope.currenciesList = data;
                }
            );
        }

        function updateCasinoGameSettings(gameId, cgsToUpdate) {
            cgsToUpdate.isLoading = true;
            requestService.get(
                '/api/casino-management/casinos-by-game?gameId=' + gameId,
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        cgsToUpdate.casinosGameSettings = response.list;
                        updateCurrencyAndCasinoName(cgsToUpdate);
                        cgsToUpdate.isLoading = false;
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.errorGeneratingCgs);
                    }
                }
            );
        }

        function openCasinoSettingsModal(casinoId, gameId, gameName, openInReadMode, canInherit, parentId, cgsToUpdate) {
            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/casino_game_management/casino_game_settings_modal.html',
                controller: 'CasinoSettingsModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    casinoId: function() {
                        return casinoId;
                    },
                    gameId: function() {
                        return gameId;
                    },
                    gameName: function() {
                        return gameName;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    },
                    canInherit: function() {
                        return canInherit;
                    },
                    parentId: function() {
                        return parentId;
                    },
                    canEdit: function() {
                        //Set to true because right now only the admin is able to do the action
                        return true;
                    }
                }
            });

            modalInstance.result.then(
                function() {
                    updateCasinoGameSettings(gameId, cgsToUpdate);
                }, function() {
                    //Dismiss / Cancel callback
                }
            );
        }

    }
]);

app.filter('filterCgs', ['GLOBAL_CONSTANTS', function(GLOBAL_CONSTANTS) {
    return function(input, filter) {
        var filteredResult = [];
        for (var i = 0; i < input.length; i++) {
            if (input[i].casinoId !== GLOBAL_CONSTANTS.ROOT_CASINO_ID && input[i].casinoName.toLowerCase().includes(filter.toLowerCase())) {
                filteredResult.push(input[i]);
            }
        }
        return filteredResult;
    }
}]);

app.filter('filterByGameName', function() {
    return function(input, filter) {
        if (input === undefined) {
            return input;
        }
        var filteredResult = [];
        for (var i = 0; i < input.length; i++) {
            if(input[i].game.name.toLowerCase().includes(filter.toLowerCase())) {
                filteredResult.push(input[i]);
            }
        }
        return filteredResult;
    }
});
