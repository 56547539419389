app.controller('GameModalInstanceCtrl', ['$scope', '$modalInstance', '$modal', '$log', '$filter', 'requestService', 'notificationService', 'gameId',
    'openInReadMode', 'ERROR_CODES', '$translate',
    function($scope, $modalInstance, $modal, $log, $filter, requestService, notificationService, gameId, openInReadMode, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();
        initCgsDropdown();

        if ($scope.gameId) {
            getGame($scope.gameId);
        }

        function init() {
            $scope.formData = {};
            $scope.formErrors = {};
            $scope.gameId = gameId;
            $scope.inReadMode = openInReadMode;
            $scope.simpleMode = true;
            $scope.warningMessage = false;
            $scope.generalJsUrl = "";
            $scope.checkboxes = {
                DESKTOP: false,
                MOBILE: false,
                MINIGAME: false
            };
            $scope.clientModes = [
                {platform: $scope.GAME_PLATFORM.DESKTOP},
                {platform: $scope.GAME_PLATFORM.MOBILE},
                {platform: $scope.GAME_PLATFORM.MINIGAME}
            ];
            $scope.displayModes = $scope.clientModes;

            $scope.onCreateGame = function() {
                createGame();
            };

            $scope.onSaveGame = function(gameId) {
                saveGame(gameId);
            };

            $scope.onEditGame = function() {
                $scope.inReadMode = false;
            };

            $scope.onNewCgsGame = function() {
                openCgsGameModal(null, false);
            };

            $scope.onCancelEditGame = function() {
                cancelModal();
            };

            $scope.onViewCgsGame = function(cgsGameId) {
                openCgsGameModal(cgsGameId, true);
            };

            $scope.onEditCgsGame = function(cgsGameId) {
                openCgsGameModal(cgsGameId, false);
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.onChangeMode = function () {
                if ($scope.simpleMode === true) {
                    var urls = getListOfUrls($scope.displayModes);
                    if (urls.length > 0 && !areUrlsSame(urls)) {
                        $scope.warningMessage = true;
                    } else {
                        $scope.warningMessage = false;
                    }
                }
            };
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getGame(id) {
            requestService.get('/api/games/game?id=' + id,
                function(data) {
                    if (data.status.code === 'OK') {
                        $scope.formData = data.gameValues;

                        var clientModes = $scope.formData.clientModes;
                        var displayModes = $scope.displayModes;

                        var urls = getListOfUrls(clientModes);
                        if (urls.length > 0 && areUrlsSame(urls)) {
                            $scope.generalJsUrl = urls[0];
                            setCheckboxes();
                        } else {
                            $scope.simpleMode = false;
                        }

                        if (clientModes && clientModes.length > 0) {
                            for (var i = 0; i < clientModes.length; i++) {
                                for (var j = 0; j < displayModes.length; j++) {
                                    if (clientModes[i].platform === displayModes[j].platform) {
                                        displayModes[j] = clientModes[i];
                                    }
                                }
                            }
                        }
                    } else {
                        notificationService.processApplicationError(
                            data.status,
                            'Error when loading game',
                            cancelModal()
                        );
                    }
                }
            );
        }

        function hasUrl(mode, property) {
            return mode.hasOwnProperty(property) &&
                typeof mode[property] === 'string' &&
                mode[property].length > 0;
        }

        function hasGameUrl(mode) {
            return hasUrl(mode, 'jsPublicUrl');
        }

        function hasReplayUrl(mode) {
            return hasUrl(mode, 'replayJsPublicUrl');
        }

        function setCheckboxes() {
            if ($scope.formData.clientModes && $scope.formData.clientModes.length > 0) {
                for (var i = 0; i < $scope.formData.clientModes.length; i++) {
                    var mode = $scope.formData.clientModes[i];
                    if (hasGameUrl(mode) && hasReplayUrl(mode)) {
                        $scope.checkboxes[mode.platform] = true;
                    } else {
                        $scope.checkboxes[mode.platform] = false;
                    }
                }
            }
        }

        function getListOfUrls(modes) {
            var result = [];

            for (var i = 0; i < modes.length; i++) {
                if (hasGameUrl(modes[i])) {
                    result.push(modes[i].jsPublicUrl);
                }
                if (hasReplayUrl(modes[i])) {
                    result.push(modes[i].replayJsPublicUrl);
                }
            }

            return result;
        }

        function areUrlsSame(list) {
            for (var i = 1; i < list.length; i++) {
                if(list[i] !== list[0]) {
                    return false;
                }
            }
            return true;
        }

        function refreshCgsGames(gameId) {
            requestService.get(
                '/api/games/cgs-game-list',
                function(data) {
                    $scope.availableCgsGames = data.list;
                });

            if (gameId) {
                requestService.get('/api/games/game?id=' + gameId,
                    function(data) {
                        if (data.status.code === 'OK') {
                            $scope.formData.cgsGamesIds = data.gameValues.cgsGamesIds;
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorLoadingGame);
                        }
                    }
                );
            }
        }

        function createGame() {
            setBeforeSaveUrls();
            requestService.postEncoded('/api/games/game',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        notificationService.showInfoToast($scope.translations.infoGameCreated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            $scope.validationError = data.status.message;
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorCreatingGame);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function saveGame(id) {
            setBeforeSaveUrls();
            requestService.putEncoded('/api/games/game/' + id,
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        notificationService.showInfoToast($scope.translations.infoGameUpdated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            $scope.validationError = data.status.message;
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorSavingGame);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function setBeforeSaveUrls() {
            $scope.formData.clientModes = angular.copy($scope.displayModes);
            for (var i = $scope.formData.clientModes.length - 1; i >= 0 ; i--) {
                var clientMode = $scope.formData.clientModes[i];
                if ($scope.simpleMode === true) {
                    if ($scope.checkboxes[clientMode.platform] === true) {
                        clientMode.jsPublicUrl = $scope.generalJsUrl;
                        clientMode.replayJsPublicUrl = $scope.generalJsUrl;
                    } else {
                        $scope.formData.clientModes.splice(i,1);
                    }
                } else {
                    if (!hasGameUrl(clientMode) && !hasReplayUrl(clientMode)) {
                        $scope.formData.clientModes.splice(i,1);
                    } else {
                        if (!hasGameUrl(clientMode)) {
                            clientMode.jsPublicUrl = undefined;
                        }
                        if (!hasReplayUrl(clientMode)) {
                            clientMode.replayJsPublicUrl = undefined;
                        }
                    }
                }
            }
        }

        function openCgsGameModal(cgsGameId, openInReadMode) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/admin/game_administration/modals/cgs_game_modal.html',
                controller: 'CgsGameModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    cgsGameId: function() {
                        return cgsGameId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    }
                }
            });

            modalInstance.result.then(
                function() {
                    $scope.onRefreshCgsGameList();
                }
            );

        }

        ////<editor-fold desc="CGS GAMES PICKER">
        function initCgsDropdown() {
            $scope.availableCgsGames = [];
            $scope.formData.cgsGamesIds = [];

            $scope.filterAlreadySelectedGames = function(item) {
                return ($scope.formData.cgsGamesIds.indexOf(item.id) === -1);
            };

            $scope.onAddCgsGame = function(item, model) {
                if (!$scope.formData.name) {
                    $scope.formData.name = item.description;
                }
                $scope.formData.cgsGamesIds.push(item.id);
            };

            $scope.onRemoveCgsGame = function(cgsGameId) {
                var index = $scope.formData.cgsGamesIds.indexOf(cgsGameId);
                if (index >= 0) {
                    $scope.formData.cgsGamesIds.splice(index, 1);
                }
            };

            $scope.onRefreshCgsGameList = function() {
                refreshCgsGames($scope.gameId);
            };
        }
        ////</editor-fold>

        function initTranslations() {
            var translations = {};
            $translate(['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME', 'GAME_ADMINISTRATION.INFO_MESSAGES.GAME_CREATED',
                'GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM', 'GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_GAME',
                'GAME_ADMINISTRATION.INFO_MESSAGES.GAME_UPDATED', 'GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_GAME'])
                .then(function(translation) {
                    translations.errorLoadingGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME'];
                    translations.errorInvalidForm = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_GAME'];
                    translations.errorSavingGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_GAME'];
                    translations.infoGameCreated = translation['GAME_ADMINISTRATION.INFO_MESSAGES.GAME_CREATED'];
                    translations.infoGameUpdated = translation['GAME_ADMINISTRATION.INFO_MESSAGES.GAME_UPDATED'];
                }, function(translationIds) {
                    translations.errorLoadingGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME'];
                    translations.errorInvalidForm = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_GAME'];
                    translations.errorSavingGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_GAME'];
                    translations.infoGameCreated = translationIds['GAME_ADMINISTRATION.INFO_MESSAGES.GAME_CREATED'];
                    translations.infoGameUpdated = translationIds['GAME_ADMINISTRATION.INFO_MESSAGES.GAME_UPDATED'];
                });
            return translations;
        }
    }
]);
