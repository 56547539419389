app.controller('CgsGameModalInstanceCtrl', ['$scope', '$log', '$modalInstance', '$modal', 'requestService', 'notificationService', 'cgsGameId',
    'openInReadMode', 'ERROR_CODES', '$translate',
    function($scope, $log, $modalInstance, $modal, requestService, notificationService, cgsGameId, openInReadMode, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();

        function getDefaultDescription(cgsGame) {
            return cgsGame["name"] + ' - ' + cgsGame["pop"] + '%';
        }

        function init() {
            $scope.formData = {
                configParamsDefault: [],
                configParamsRanges: []
            };
            $scope.formErrors = {};
            $scope.cgsGameId = cgsGameId;
            $scope.inReadMode = openInReadMode;

            $scope.configParamsDefaultControl = {};
            $scope.configParamsRangesControl = {};

            $scope.onSaveGame = function() {
                $scope.configParamsDefaultControl.onSaveForm(function() {
                    $scope.configParamsRangesControl.onSaveForm(function() {
                        saveCgsGame($scope.cgsGameId);
                    });
                });
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                $scope.configParamsDefaultControl.onCancelForm();
                $scope.configParamsRangesControl.onCancelForm();
                cancelModal();
            };

            $scope.getRawCgsGames = getRawCgsGames();

            $scope.fillDataOfSelectedGame = function(rawCgsGameId) {
                for (var i = 0; i < $scope.rawCgsGameList.length; i++) {
                    if ($scope.rawCgsGameList[i]["id"] === rawCgsGameId) {
                        var cgsGame = $scope.rawCgsGameList[i];
                        $scope.formData.externalCgsId = cgsGame["id"];
                        $scope.formData.description = getDefaultDescription(cgsGame);
                        $scope.formData.rtp = parseFloat((cgsGame["pop"] / 100).toFixed(4));
                        $scope.formData.configParamsDefault = [
                            {
                                key: "lidx",
                                type: "NUMBER",
                                value: "0"
                            },
                            {
                                key: "bidx",
                                type: "NUMBER",
                                value: "0"
                            },
                            {
                                key: "midx",
                                type: "NUMBER",
                                value: "0"
                            }
                        ];
                        $scope.formData.configParamsRanges = [
                            {
                                key: "betpl",
                                type: "LIST_OF_NUMBERS",
                                value: cgsGame["betpl"].toString()
                            },
                            {
                                key: "bmode",
                                type: "LIST_OF_NUMBERS",
                                value: cgsGame["bmode"].toString()
                            },
                            {
                                key: "lines",
                                type: "LIST_OF_NUMBERS",
                                value: cgsGame["lines"].toString()
                            },
                            {
                                key: "type",
                                type: "STRING",
                                value: cgsGame["type"]
                            }
                        ];
                    }
                }
            };

            if ($scope.cgsGameId) {
                getCgsGame($scope.cgsGameId);
            }
        }

        function getRawCgsGames() {
            $scope.rawCgsGameList = [];
            requestService.get('/api/games/raw-cgs-game-list',
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.rawCgsGameList = data.list;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingCgsGames);
                    }
                }
            );
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getCgsGame(id) {
            requestService.get('/api/games/cgs-game?id=' + id,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.cgsGameValues;
                        $scope.cgsGameId = data.gameId;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingGame, cancelModal());
                    }
                }
            );
        }

        function saveCgsGame(id) {
            if (id) {
                requestService.putEncoded('/api/games/cgs-game/' + id,
                    $scope.formData,
                    function(data) {
                        $scope.formErrors = data.status.formErrors || data.status.validationErrors || {};

                        if (data.status.code === 'OK') {
                            notificationService.showInfoToast($scope.translations.infoCgsGameUpdated);
                            closeModal();
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCgsGame);
                            }
                        }
                    },
                    function(error) {
                        $scope.formErrors = {};
                    }
                );

            } else {
                requestService.postEncoded('/api/games/cgs-game',
                    $scope.formData,
                    function(data) {

                        $scope.formErrors = data.status.formErrors || {};

                        if (data.status.code === 'OK') {
                            notificationService.showInfoToast($scope.translations.infoCgsGameCreated);
                            closeModal();
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorSavingCgsGame);
                            }
                        }
                    },
                    function(error) {
                        $scope.formErrors = {};
                    }
                );
            }
        }

        function initTranslations() {
            var translations = {};
            $translate(['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_CGS_GAMES', 'GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME',
                'GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_UPDATED', 'GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM',
                'GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_CREATED', 'GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_CGS_GAME',
                'GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_CGS_GAME'])
                .then(function(translation) {
                    translations.errorLoadingCgsGames = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_CGS_GAMES'];
                    translations.errorLoadingGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME'];
                    translations.errorInvalidForm = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCgsGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_CGS_GAME'];
                    translations.errorSavingCgsGame = translation['GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_CGS_GAME'];
                    translations.infoCgsGameUpdated = translation['GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_UPDATED'];
                    translations.infoCgsGameCreated = translation['GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_CREATED'];
                }, function(translationIds) {
                    translations.errorLoadingCgsGames = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_CGS_GAMES'];
                    translations.errorLoadingGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.LOADING_GAME'];
                    translations.errorInvalidForm = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCgsGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.CREATING_CGS_GAME'];
                    translations.errorSavingCgsGame = translationIds['GAME_ADMINISTRATION.ERROR_MESSAGES.SAVING_CGS_GAME'];
                    translations.infoCgsGameUpdated = translationIds['GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_UPDATED'];
                    translations.infoCgsGameCreated = translationIds['GAME_ADMINISTRATION.INFO_MESSAGES.CGS_GAME_CREATED'];
                });
            return translations;
        }

    }
]);

