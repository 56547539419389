app.factory('userAuthService', ['$http', '$state', 'store', '$rootScope', '$location', '$modal', '$log', 'requestService', 'notificationService', 'USER_ROLES',
    function($http, $state, store, $rootScope, $location, $modal, $log, requestService, notificationService, USER_ROLES) {

        var service = {};

        service.showLoginForm = showLoginForm;

        service.resetPassword = resetPassword;
        service.updatePassword = updatePassword;

        service.getAppInfo = getAppInfo;

        service.getUser = getUser;
        service.setUser = setUser;
        service.setUserData = setUserData;

        service.getUserOnClient = getUserOnClient;
        service.storeUserOnClient = storeUserOnClient;
        service.getUserByToken = getUserByToken;
        service.removeUser = removeUser;

        service.changePassword = changePassword;

        service.setUserRoles = setUserRoles;

        service.setHttpAuthData = setHttpAuthData;

        service.getAuthorizationToken = getAuthorizationToken;

        service.isAuthenticated = isAuthenticated;
        service.isAuthorized = isAuthorized;

        // Base64 encoding service used by AuthenticationService
        var Base64 = {

            keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

            encode: function(input) {
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;

                do {
                    chr1 = input.charCodeAt(i++);
                    chr2 = input.charCodeAt(i++);
                    chr3 = input.charCodeAt(i++);

                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;

                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }

                    output = output +
                        this.keyStr.charAt(enc1) +
                        this.keyStr.charAt(enc2) +
                        this.keyStr.charAt(enc3) +
                        this.keyStr.charAt(enc4);
                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";
                } while (i < input.length);

                return output;
            },

            decode: function(input) {
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;

                // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
                var base64test = /[^A-Za-z0-9\+\/\=]/g;
                if (base64test.exec(input)) {
                    window.alert("There were invalid base64 characters in the input text.\n" +
                        "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                        "Expect errors in decoding.");
                }
                input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

                do {
                    enc1 = this.keyStr.indexOf(input.charAt(i++));
                    enc2 = this.keyStr.indexOf(input.charAt(i++));
                    enc3 = this.keyStr.indexOf(input.charAt(i++));
                    enc4 = this.keyStr.indexOf(input.charAt(i++));

                    chr1 = (enc1 << 2) | (enc2 >> 4);
                    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                    chr3 = ((enc3 & 3) << 6) | enc4;

                    output = output + String.fromCharCode(chr1);

                    if (enc3 != 64) {
                        output = output + String.fromCharCode(chr2);
                    }
                    if (enc4 != 64) {
                        output = output + String.fromCharCode(chr3);
                    }

                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";

                } while (i < input.length);

                return output;
            }
        };

        function showLoginForm() {
            if ($rootScope.DEBUG) {
                $rootScope.globals.applicationName = 'BackOffice DEBUG Mode';
                setUser('admin', 'admin', [
                    //USER_ROLES.ALARMS_UPDATE,
                    //USER_ROLES.ALARMS_VIEW,
                    //USER_ROLES.MARKETING_MATERIALS_VIEW,
                    //USER_ROLES.MARKETING_MATERIALS_UPDATE,
                    //USER_ROLES.GAME_SESSIONS_VIEW,
                    //USER_ROLES.DATA_PACKAGES_DOWNLOAD,
                    //USER_ROLES.DATA_PACKAGES_REGENERATE,
                    //USER_ROLES.REPORTS,
                    //USER_ROLES.JACKPOTS_VIEW,
                    //USER_ROLES.PLAYERS_SEARCH,
                    //USER_ROLES.GAME_MANAGEMENT,
                    //USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW,
                    //USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE,
                    //USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                    //USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE,
                    USER_ROLES.ADMIN
                ]);
            } else {
                $location.path('/login');
            }
        }

        function resetPassword(login, onSuccess) {
            requestService.postEncoded('/api/user/request-reset-password',
                {
                    login: login
                },
                function(response) {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                }
            );
        }

        function updatePassword(formData, onSuccess, onError) {
            requestService.postEncoded('/api/user/update-user-password',
                formData,
                function(response) {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                },
                function(error) {
                    notificationService.processServerError(error);
                    if (onError) {
                        onError(error);
                    }
                }
            );
        }

        function getAppInfo(onSuccess, onError) {
            requestService.get('/app-info',
                function(response) {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                },
                function(error) {
                    notificationService.processServerError(error);
                    if (onError) {
                        onError(error);
                    }
                }
            );
        }

        function getUser(onSuccess, onError) {
            requestService.get('/api/user/user',
                function(response) {
                    if (response.status.code === 'OK') {
                        setUserData(response.user);
                    }
                    if (onSuccess) {
                        onSuccess(response);
                    }
                },
                function(error) {
                    notificationService.processServerError(error);
                    if (onError) {
                        onError(error);
                    }
                }
            );
        }

        function setUser(login, password, roles) {
            var authData = Base64.encode(login + ':' + password);

            $rootScope.globals.currentUser = {
                username: login, //TODO rename to login
                roles: roles,
                authData: authData
            };

            setHttpAuthData(authData);
            storeUserOnClient();
        }

        function setUserData(userDataObject) {
            angular.extend($rootScope.globals.currentUser, userDataObject);
            storeUserOnClient();
        }

        function removeUser() {
            $rootScope.globals.currentUser = null;
            clearHttpAuthData();
            removeUserOnClient();
        }

        function getUserOnClient() {
            return store.get('ngb.user');
        }

        function storeUserOnClient() {
            // Store into session storage
            store.set('ngb.user', $rootScope.globals.currentUser);
        }

        function removeUserOnClient() {
            store.remove('ngb.user');
        }

        function getUserByToken(resetToken, onSuccess, onError) {
            requestService.getWithParams('/api/user/user-by-reset-token',
                { "resetToken": resetToken },
                function(response) {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                },
                function(error) {
                    notificationService.processServerError(error);
                    if (onError) {
                        onError(error);
                    }
                }
            );
        }

        function changePassword(token, formData, onSuccess, onError) {
            requestService.postEncoded('/api/user/reset-password/' + token,
                formData,
                function(response) {
                    if (onSuccess) {
                        onSuccess(response);
                    }
                },
                function(error) {
                    notificationService.processServerError(error);
                    if (onError) {
                        onError(error);
                    }
                }
            );
        }

        function setUserRoles(roles) {
            if ($rootScope.globals.currentUser) {
                $rootScope.globals.currentUser.roles = roles;
            }
        }

        function setHttpAuthData(authData) {
            $http.defaults.headers.common['Authorization'] = 'MyBasic ' + authData;
        }

        function getAuthorizationToken() {
            var authData = $rootScope.globals.currentUser.authData || null;
            if (authData) {
                return 'MyBasic ' + $rootScope.globals.currentUser.authData || '';
            }
            return '';
        }

        function clearHttpAuthData() {
            $http.defaults.headers.common['Authorization'] = '';
        }

        function isAuthenticated() {
            return ($rootScope.globals && $rootScope.globals.currentUser);
        }

        function isAuthorized(authorizedRoles) {
            if (!isAuthenticated()) {
                return false;
            }

            var hasRole = false;
            if (!authorizedRoles || authorizedRoles.length == 0) {
                hasRole = true;
            } else {
                if (angular.isArray(authorizedRoles)) {
                    for (var i = 0, len = authorizedRoles.length; i < len; i++) {
                        if ($rootScope.globals.currentUser.roles &&
                            $rootScope.globals.currentUser.roles.indexOf(authorizedRoles[i]) !== -1) {
                            hasRole = true;
                        }
                    }
                } else {
                    if ($rootScope.globals.currentUser.roles &&
                        $rootScope.globals.currentUser.roles.indexOf(authorizedRoles) !== -1) {
                        hasRole = true;
                    }
                }
            }
            return hasRole;
        }

        return service;

    }
]);
