app.controller('SessionWalletModalCtrl', ['$scope', '$modalInstance', 'sessionWallet',
    function($scope, $modalInstance, sessionWallet) {

        $scope.sessionWallet = sessionWallet;
        $scope.orderColumn = '';

        $scope.onClose = function() {
            $modalInstance.dismiss('cancel');
        }
    }
]);
