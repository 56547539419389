app.controller('CampaignTargetingModalInstanceCtrl', ['$scope', '$log', '$modalInstance', '$modal', '$timeout', '$filter', 'requestService',
    'notificationService', 'campaignId', 'campaignName', 'campaignTargetingId', 'openInReadMode', 'editAllowed', 'ERROR_CODES', '$translate',
    function($scope, $log, $modalInstance, $modal, $timeout, $filter, requestService, notificationService, campaignId, campaignName,
        campaignTargetingId, openInReadMode, editAllowed, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {
                campaignId: campaignId
            };
            $scope.campaignName = campaignName;
            $scope.formErrors = {};
            $scope.inReadMode = openInReadMode;
            $scope.editAllowed = editAllowed;

            $scope.onSaveTargeting = function() {
                saveCampaignTargeting(campaignTargetingId);
            };

            $scope.onEditTargeting = function() {
                $scope.inReadMode = false;
            };

            $scope.onCancelEditTargeting = function() {
                closeModal();
            };

            $scope.onCheckAgeRange = function(inputFrom, inputTo) {
                if (inputFrom && inputTo) {

                    var from = Number(inputFrom);
                    var to = Number(inputTo);

                    if (isFinite(to) && isFinite(from) && from > to) {
                        return $scope.translations.errorInvalidAgeRange;
                    }
                }
                return '';
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            if (campaignTargetingId) {
                $timeout(function() {
                        getCampaignTargeting(campaignTargetingId);
                    },
                    $scope.DEFAULT_TIMEOUT
                );
            }
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getCampaignTargeting(id) {
            requestService.get('/api/campaigns/campaign-targeting?id=' + id,
                function(data) {
                    if (data.status.code === 'OK') {
                        $scope.formData = data.campaignTargetingValues;
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorLoadingCampaignTargeting, cancelModal());
                    }
                });
        }

        function saveCampaignTargeting(id) {

            if (id) {
                requestService.putEncoded('/api/campaigns/campaign-targeting/' + id,
                    $scope.formData,
                    function(data) {
                        $scope.formErrors = data.status.formErrors || {};

                        if (data.status.code === 'OK') {
                            notificationService.showInfoToast($scope.translations.popupCampaignTargetingUpdated);
                            closeModal();
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCampaignTargeting);
                            }
                        }
                    },
                    function(error) {
                        $scope.formErrors = {};
                    }
                );
            } else {
                requestService.postEncoded('/api/campaigns/campaign-targeting',
                    $scope.formData,
                    function(data) {

                        $scope.formErrors = data.status.formErrors || {};

                        if (data.status.code === 'OK') {
                            notificationService.showInfoToast($scope.translations.popupCampaignTargetingCreated);
                            closeModal();
                        } else {
                            if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast($scope.translations.errorInvalidForm);
                            } else {
                                notificationService.processApplicationError(data.status, $scope.translations.errorCreatingCampaignTargeting);
                            }
                        }
                    },
                    function(error) {
                        $scope.formErrors = {};
                    }
                );
            }
        }

        function initTranslations() {
            var translations = {};
            $translate(['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_AGE_RANGE', 'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN_TARGETING',
                'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM', 'FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN_TARGETING',
                'FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_CREATED',
                'FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_UPDATED'])
                .then(function(translation) {
                    translations.errorInvalidAgeRange = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_AGE_RANGE'];
                    translations.errorLoadingCampaignTargeting = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN_TARGETING'];
                    translations.errorInvalidForm = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCampaignTargeting = translation['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN_TARGETING'];

                    translations.popupCampaignTargetingCreated = translation['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_CREATED'];
                    translations.popupCampaignTargetingUpdated = translation['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_UPDATED'];
                }, function(translationIds) {
                    translations.errorInvalidAgeRange = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_AGE_RANGE'];
                    translations.errorLoadingCampaignTargeting = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.LOADING_CAMPAIGN_TARGETING'];
                    translations.errorInvalidForm = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorCreatingCampaignTargeting = translationIds['FR_CAMPAIGN_MANAGER.ERROR_MESSAGES.CREATING_CAMPAIGN_TARGETING'];

                    translations.popupCampaignTargetingCreated = translationIds['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_CREATED'];
                    translations.popupCampaignTargetingUpdated = translationIds['FR_CAMPAIGN_MANAGER.POPUP_CAMPAIGN_TARGETING_UPDATED'];
                });
            return translations;
        }
    }]);
