app.controller('GameSettingsCtrl', ['$scope', '$log', '$modal', 'requestService', 'GAME_PLATFORM',
    function($scope, $log, $modal, requestService, GAME_PLATFORM) {

        init();
        initTable();

        function init() {
            $scope.searchFilter = '';

            $scope.onNewGameSettings = function(gameId, name) {
                openGameSettingsModal(gameId, name, false);
            };

            $scope.onViewGameSettings = function(gameId, name) {
                openGameSettingsModal(gameId, name, true);
            };

            $scope.onEditGameSettings = function(gameId, name) {
                openGameSettingsModal(gameId, name, false);
            };
        }

        function initTable() {
            $scope.table = {
                games: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getTableData();
            };

            getTableData();
        }

        function getTableData() {
            requestService.get(
                '/api/games/game-settings-list',
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                    } else {
                        updateTable(response);
                    }
                }
            );
        }

        function updateTable(response) {
            $scope.table.games = response.list;
        }

        function openGameSettingsModal(gameId, gameName, openInReadMode) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/admin/game_management/game_settings_modal.html',
                controller: 'GameSettingsModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    gameName: function() {
                        return gameName;
                    },
                    gameId: function() {
                        return gameId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    }
                }
            });

            modalInstance.result.then(function() {
                getTableData();
            }, function() {
                getTableData();
            });
        }
    }
]);
