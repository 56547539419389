app.factory('cacheService', ['$cacheFactory',
    function($cacheFactory) {

        //TODO store service
        var cache = $cacheFactory('ngb.cache');

        cache.getWithDefault = getWithDefault;

        return cache;

        function getWithDefault(key, defaultValue) {
            if (angular.isUndefined(cache.get(key))) {
                cache.put(key, defaultValue);
            }
            return cache.get(key);
        }
    }
]);
