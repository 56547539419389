app.controller('LoginCtrl', ['$scope', '$location', 'userAuthService', 'EVENT_CODES',
    function($scope, $location, userAuthService, EVENT_CODES) {

        init();

        function init() {
            $scope.onLogin = function() {
                userAuthService.showLoginForm();
            };

            $scope.onLogout = function() {
                userAuthService.removeUser();
                $scope.$emit(EVENT_CODES.LOGOUT_SUCCESS);
            };
        }
    }
]);

app.controller('LoginPageCtrl', ['$rootScope', '$scope', '$log', 'userAuthService', 'notificationService', 'EVENT_CODES', 'ERROR_CODES', '$translate',
    'cookieService', 'requestService', function($rootScope, $scope, $log, userAuthService, notificationService, EVENT_CODES, ERROR_CODES, $translate, cookieService, requestService) {

        init();

        function init() {
            $translate(['LOGIN.REQUEST_TO_RESET_PASSWORD', 'LOGIN.EXPIRED_PASSWORD_HEADLINE', 'LOGIN.EXPIRED_PASSWORD_MESSAGE'])
                .then(function (translations) {
                $scope.requestToResetPassword = translations['LOGIN.REQUEST_TO_RESET_PASSWORD'];
                $scope.expiredPasswordHeadline = translations['LOGIN.EXPIRED_PASSWORD_HEADLINE'];
                $scope.expiredPasswordMessage = translations['LOGIN.EXPIRED_PASSWORD_MESSAGE'];
            }, function (translationIds) {
                $scope.requestToResetPassword = translationIds['LOGIN.REQUEST_TO_RESET_PASSWORD'];
                $scope.expiredPasswordHeadline = translationIds['LOGIN.EXPIRED_PASSWORD_HEADLINE'];
                $scope.expiredPasswordMessage = translationIds['LOGIN.EXPIRED_PASSWORD_MESSAGE'];
            });

            var STATES = {
                LOGIN: 'LOGIN',
                UPDATE_EXPIRED: 'UPDATE_EXPIRED',
                RESET: 'RESET'
            };

            $scope.formData = {};
            $scope.formErrors = {};
            $scope.STATES = STATES;
            $scope.state = STATES.LOGIN;

            $scope.onLogin = function(userName, password) {
                userAuthService.setUser(userName, password, null);
                userAuthService.getUser(function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        requestService.setAcceptLanguage(response.user.locale);

                        $translate.use(response.user.locale);
                        cookieService.setCookie('userLanguage', response.user.locale);
                        $rootScope.$broadcast(EVENT_CODES.LOGIN_SUCCESS);
                    } else if (response.status.code === ERROR_CODES.PASSWORD_EXPIRED) {
                        notificationService.showMessageDialog($scope.expiredPasswordHeadline, $scope.expiredPasswordMessage,
                            null)
                            .then(function() {
                                $scope.state = STATES.UPDATE_EXPIRED;
                                $scope.formData.password = null;
                                $scope.formErrors = {};
                            });
                    } else {
                        // In BasicAuth this shouldn't happen (caught in http interceptor and delegated through event back to login form)
                        $log.info('Error in login form', response);
                        $scope.formErrors.message = response.status.message || '';
                    }
                });
            };

            $scope.onResetPassword = function(userName) {
                userAuthService.resetPassword(userName, function(response) {
                    $scope.formErrors = response.status.formErrors || {};
                    if (response.status.code === 'OK') {
                        notificationService.showMessageDialog(null, $scope.requestToResetPassword, null)
                            .then(function() {
                                $scope.state = STATES.LOGIN;
                                $scope.formErrors = {};
                            });
                    } else {
                        if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            $scope.formErrors.message = response.status.message || '';
                            notificationService.showWarningToast('Invalid form');
                        } else {
                            notificationService.processApplicationError(response.status);
                        }
                    }
                });
            };

            $scope.onUpdateUserPassword = function() {
                userAuthService.updatePassword(
                    $scope.formData,
                    function(response) {
                        $scope.formErrors = response.status.formErrors || {};

                        if (response.status.code === ERROR_CODES.OK) {

                            notificationService.showInfoToast('User successfully updated');
                            notificationService.showMessageDialog('User successfully updated', null, null)
                                .then(function() {
                                    $scope.state = STATES.LOGIN;
                                    $scope.formErrors = {};
                                });
                        } else {
                            if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                                notificationService.showWarningToast('Invalid form');
                            } else {
                                notificationService.processApplicationError(
                                    response.status,
                                    'Error when updating user'
                                );
                            }
                        }
                    }
                );
            };

            $rootScope.$on(EVENT_CODES.NOT_AUTHENTICATED, function() {
                userAuthService.removeUser();
                $scope.formErrors.message = 'Incorrect credentials or user is not active.';
                $scope.state = STATES.LOGIN;
            });

            $scope.globals = $scope.globals || {};
            userAuthService.getAppInfo(
                function(response) {
                    if (response.status.code === 'OK') {
                        $scope.globals.applicationName = response.applicationName;
                        $scope.globals.brandUrl = response.brandUrl;
                    }
                }
            );

            $scope.changeLanguage = function(language) {
                if (cookieService.getCookie('userLanguage') !== language) {
                    requestService.setAcceptLanguage(language);

                    $translate.use(language);
                    cookieService.setCookie('userLanguage', language);
                }
            };
        }
    }
]);
