app.controller('AlarmSettingsEmailCtrl', ['$scope', 'requestService', 'ERROR_CODES',
    function($scope, requestService, ERROR_CODES) {
        init();

        function init() {
            $scope.emailTemplates = [];
            getEmailTemplates();
        }

        $scope.emailTabs = [
            {name: 'EMA_RTP', route: 'alarm-settings.email.ema'},
            {name: 'HIGH_WIN', route: 'alarm-settings.email.hw'}
        ];

        $scope.startEmailEditing = function(data) {
            data.original = angular.copy(data);
            data.editing = true;
        };

        $scope.cancelEmailEditing = function(data) {
            data.subject = data.original.subject;
            data.body = data.original.body;
            delete data.original;
            delete data.editing;
            delete data.formErrors;
        };

        $scope.updateEmailTempalte = function(alarmType) {
            requestService.putEncoded(
                '/api/alarm-settings/update-email-template/' + alarmType.id,
                alarmType,
                function(response) {
                    if (response.status.code === 'OK') {
                        delete alarmType.original;
                        delete alarmType.editing;
                        delete alarmType.formErrors;
                    } else if (response.status.code === "VALIDATION_FAILED") {
                        alarmType.formErrors = response.status.formErrors;
                    }
                }
            );
        };

        function getEmailTemplates() {
            requestService.get(
                '/api/alarm-settings/get-all-email-templates',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.emailTemplates = response.list;
                    } else {
                        $log.error(response.status);
                    }
                }
            );
        }
    }
]);
