var app = angular.module('app', [
    // 'ngRoute',
    'ui.router',
    'ngSanitize',
    'ngCookies',
    'angular-storage',
    'ui.bootstrap',
    'ui.bootstrap.contextMenu',
    'http-auth-interceptor',
    'ngToast',
    'ui.select',
    'siTable',
    'angular-loading-bar',
    'xeditable',
    'as.sortable',
    'ngIdle',
    'angular.filter',
    'ngFileUpload',
    'nvd3',
    'gridster',
    //'angularStats',
    'config',
    'ivh.treeview',
    'nzToggle',
    'pascalprecht.translate'
]);

app.config(['uiSelectConfig', 'ngToastProvider', '$compileProvider', '$stateProvider', '$urlRouterProvider',
    'buttonConfig', 'IdleProvider', 'storeProvider', 'USER_ROLES', 'PROPERTIES', 'ivhTreeviewOptionsProvider', '$provide', '$translateProvider',
    function (uiSelectConfig, ngToastProvider, $compileProvider, $stateProvider, $urlRouterProvider,
              buttonConfig, IdleProvider, storeProvider, USER_ROLES, PROPERTIES, ivhTreeviewOptionsProvider, $provide, $translateProvider) {

        initGeneralSettings();
        initRouting();

        $provide.decorator('ivhTreeviewCheckboxDirective', function($delegate) {
            $delegate.shift();
            return $delegate;
        });

        $translateProvider.useUrlLoader('/api/i18n/bundle');
        $translateProvider.fallbackLanguage('EN');
        $translateProvider.useSanitizeValueStrategy('escape');

        ivhTreeviewOptionsProvider.set({
            onCbChange: function(locals) {
                console.log('ivhNode:' +locals.ivhNode.label +
                    ', ivhIsSelected:' + locals.ivhIsSelected
                );
            },
            defaultSelectedState: false,
            validate: false,
            expandToDepth: -1,
            twistieExpandedTpl: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
            twistieCollapsedTpl: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
            twistieLeafTpl: ''
        });

        function initGeneralSettings() {
            if (PROPERTIES.PRODUCTION == false) {
                $compileProvider.debugInfoEnabled(true);
            }

            // UI Bootstrap settings
            uiSelectConfig.theme = 'bootstrap';
            uiSelectConfig.resetSearchInput = true;
            buttonConfig.activeClass = 'btn-success active';

            // Toast notifications
            ngToastProvider.configure({
                verticalPosition: 'top',
                horizontalPosition: 'right',
                maxNumber: 5
                //dismissButton: true
            });

            // Storage settings
            storeProvider.setStore('sessionStorage'); // better would be session cookies, expired after browser close (use cookies and set expiration on some reasonable date)

            // Idle settings
            IdleProvider.idle(PROPERTIES.IDLE_TIMEOUT || 840); // in seconds
            IdleProvider.timeout(PROPERTIES.WARNING_TIMEOUT || 60); // in seconds
        }

        function initRouting() {

            // Redirects
            $urlRouterProvider.otherwise("/");

            // States configuration
            var gameSessionRoles = [
                USER_ROLES.ADMIN,
                USER_ROLES.GAME_SESSIONS_VIEW
            ];
            var marketingRoles = [
                USER_ROLES.ADMIN,
                USER_ROLES.MARKETING_MATERIALS_VIEW,
                USER_ROLES.MARKETING_MATERIALS_UPDATE
            ];
            var reportsRoles = [
                USER_ROLES.ADMIN,
                USER_ROLES.INVOICING_REPORT,
                USER_ROLES.CASINO_GAME_PERFORMANCE_REPORT,
                USER_ROLES.FREE_ROUNDS_CAMPAIGN_COST_REPORT,
                USER_ROLES.PLAYER_PERFORMANCE_REPORT,
                USER_ROLES.CASINO_PERFORMANCE_PER_DAY_REPORT,
                USER_ROLES.PLAYER_DEMOGRAPHIC_DATA_REPORT,
                USER_ROLES.USERS_LIST_REPORT,
                USER_ROLES.GAME_ROUND_FEATURES_PER_PLAYER_REPORT
            ];

            $stateProvider
                .state("home", {
                    url: '/',
                    templateUrl: 'app/home.html',
                    data: {}
                })

                .state("login", {
                    url: '/login',
                    templateUrl: 'app/login/login.html',
                    data: {}
                })
                .state("reset", {
                    url: '/reset?token',
                    templateUrl: 'app/login/reset.html',
                    data: {}
                })

                .state('game-sessions', {
                    url: '/game-sessions?casinoId&playerId&sessionId&gameRoundId&gameId&dateFrom&dateTo',
                    templateUrl: 'app/game_session/game_session.html',
                    data: {roles: gameSessionRoles}
                })
                .state('game-sessions.session_by_player', {
                    url: '/sessions/player',
                    templateUrl: 'app/game_session/tabs/game_session_by_player.html',
                    data: {roles: gameSessionRoles}
                })
                .state('game-sessions.session_by_session_id', {
                    url: '/sessions/session',
                    templateUrl: 'app/game_session/tabs/game_session_by_session_id.html',
                    data: {roles: gameSessionRoles}
                })
                .state('game-sessions.round_by_round_id', {
                    url: '/rounds/round',
                    templateUrl: 'app/game_session/tabs/game_round_by_round_id.html',
                    data: {roles: gameSessionRoles}
                })
                .state('analytics', {
                    url: '/analytics',
                    templateUrl: 'app/analytics/analytics.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.REPORTING_VIEW
                        ]
                    }
                })
                .state("managed-freerounds", {
                    url: '/managed-freerounds',
                    templateUrl: 'app/managed_freerounds/managed_freerounds.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                            USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE,
                            USER_ROLES.GSMC_FREE_ROUND_CAMPAIGNS_UPDATE
                        ]
                    }
                })
                .state("session-wallet", {
                    url: '/session-wallet',
                    templateUrl: 'app/session_wallet/session_wallet.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.SESSION_WALLET_VIEW,
                            USER_ROLES.SESSION_WALLET_UPDATE
                        ]
                    }
                })
                .state("session-wallet.active-sessions", {
                    url: '/active-sessions',
                    templateUrl: 'app/session_wallet/tabs/active_sessions.html',
                    data: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.SESSION_WALLET_VIEW,
                        USER_ROLES.SESSION_WALLET_UPDATE
                    ]
                })
                .state("session-wallet.all-sessions", {
                    url: '/all-sessions',
                    templateUrl: 'app/session_wallet/tabs/all_sessions.html',
                    data: [
                        USER_ROLES.ADMIN,
                        USER_ROLES.SESSION_WALLET_VIEW,
                        USER_ROLES.SESSION_WALLET_UPDATE
                    ]
                })
                .state('reports', {
                    url: '/reports/:reportType',
                    templateProvider: ['$stateParams', '$templateRequest', '$location',
                        function($stateParams, templateRequest, $location) {
                            var tmplPath;
                            switch ($stateParams.reportType) {
                                case 'invoicing' :
                                    tmplPath = 'app/reports/invoicing.html';
                                    break;

                                case 'casino-game-performance' :
                                    tmplPath = 'app/reports/casino_game_performance.html';
                                    break;

                                case 'free-rounds-campaign-cost' :
                                    tmplPath = 'app/reports/free_rounds_campaign_cost.html';
                                    break;

                                case 'player-performance' :
                                    tmplPath = 'app/reports/player_performance.html';
                                    break;

                                case 'casino-performance-per-day-report' :
                                    tmplPath = 'app/reports/casino_performance_per_day.html';
                                    break;

                                case 'casino-performance-per-day-detailed' :
                                    tmplPath = 'app/reports/casino_performance_per_day_detailed.html';
                                    break;

                                case 'player-demographic-data' :
                                    tmplPath = 'app/reports/player_demographic_data.html';
                                    break;

                                case 'users-list' :
                                    tmplPath = 'app/reports/users_list.html';
                                    break;

                                case 'game-round-features-per-player' :
                                    tmplPath = 'app/reports/game_round_features_per_player_report.html';
                                    break;

                                default:
                                    $location.path('/');
                                    break;
                            }

                            if (tmplPath) {
                                return templateRequest(tmplPath);
                            } else {
                                return '';
                            }
                        }
                    ],
                    data: {roles: reportsRoles}
                })

                .state('casino-game-management', {
                    url: '/casino-game-management',
                    templateUrl: 'app/casino_game_management/casino_game_settings.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.GAME_MANAGEMENT,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE
                        ]
                    }
                })
                .state('casino-game-management.games-by-casino', {
                    url: '/games-by-casino',
                    templateUrl: 'app/casino_game_management/tabs/games_by_casino.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.GAME_MANAGEMENT,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE
                        ]
                    }
                })
                .state('casino-game-management.casinos-by-game', {
                    url: '/casinos-by-game',
                    templateUrl: 'app/casino_game_management/tabs/casinos_by_game.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.GAME_MANAGEMENT,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW,
                            USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE
                        ]
                    }
                })
                .state('alarms', {
                    url: '/alarms',
                    templateUrl: 'app/alarms/alarms.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.ALARMS_VIEW,
                            USER_ROLES.ALARMS_UPDATE
                        ]
                    }
                })
                .state('free-spins', {
                    url: '/free-spins',
                    templateUrl: 'app/campaign_manager/free_rounds.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                            USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE
                        ]
                    }
                })
                .state('materials-root', {
                    url: '/materials',
                    templateUrl: 'app/marketing/marketing_materials_root.html',
                    data: {roles: marketingRoles}
                })
                .state('materials-detail', {
                    url: '/materials-details/{path:.*}',
                    templateUrl: 'app/marketing/marketing_materials_sublevel.html',
                    data: {roles: marketingRoles}
                })
                .state('user', {
                    url: '/user',
                    templateUrl: 'app/user/user.html',
                    data: {}
                })
                .state('game-administration', {
                    url: '/admin-game-administration',
                    templateUrl: 'app/admin/game_administration/game_administration.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('game-administration.games', {
                    url: '/games',
                    templateUrl: 'app/admin/game_administration/tabs/game_list.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('game-administration.cgs-games', {
                    url: '/cgs-games',
                    templateUrl: 'app/admin/game_administration/tabs/cgs_game_list.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('game-management', {
                    url: '/admin-game-management',
                    templateUrl: 'app/admin/game_management/game_settings.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN,
                            USER_ROLES.GAME_MANAGEMENT
                        ]
                    }
                })
                .state('casino-management', {
                    url: '/admin-casino-management',
                    templateUrl: 'app/admin/casino_management/casino_management.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('demo-user-management', {
                    url: '/admin-demo-user-management',
                    templateUrl: 'app/admin/demo_user_management/demo_user_management.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('general-settings', {
                    url: '/admin-general-settings',
                    templateUrl: 'app/admin/general_settings/general_settings.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('alarm-settings', {
                    url: '/admin-alarm-settings',
                    templateUrl: 'app/admin/alarm_settings/alarm_settings.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.general', {
                    url: '/general',
                    templateUrl: 'app/admin/alarm_settings/tabs/general.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.recipients', {
                    url: '/recipients',
                    templateUrl: 'app/admin/alarm_settings/tabs/recipients.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.email', {
                    url: '/email',
                    templateUrl: 'app/admin/alarm_settings/tabs/email.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.email.ema', {
                    url: '/ema',
                    templateUrl: 'app/admin/alarm_settings/tabs/email_ema.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.email.hw', {
                    url: '/hw',
                    templateUrl: 'app/admin/alarm_settings/tabs/email_hw.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.sms', {
                    url: '/sms',
                    templateUrl: 'app/admin/alarm_settings/tabs/sms.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.sms.ema', {
                    url: '/ema',
                    templateUrl: 'app/admin/alarm_settings/tabs/sms_ema.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('alarm-settings.sms.hw', {
                    url: '/hw',
                    templateUrl: 'app/admin/alarm_settings/tabs/sms_hw.html',
                    data: {
                        roles: [
                            USER_ROLES.ADMIN
                        ]
                    }
                })
                .state('user-administration', {
                    url: '/admin-user-administration',
                    templateUrl: 'app/admin/user_administration/users.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
                .state('players', {
                    url: '/admin-players',
                    templateUrl: 'app/admin/players/players.html',
                    data: {
                        roles: [USER_ROLES.ADMIN, USER_ROLES.PLAYERS_SEARCH]
                    }
                })
                .state('audit', {
                    url: '/admin-audit',
                    templateUrl: 'app/admin/audit/audit.html',
                    data: {
                        roles: [USER_ROLES.ADMIN]
                    }
                })
            ;
        }




    }
]);

app.run(['$rootScope', '$modal', '$log', '$location', 'store', '$state', 'userAuthService', 'authService', 'notificationService', 'requestService',
    'Idle', 'EVENT_CODES', 'editableOptions', 'USER_ROLES', 'GAME_PLATFORM', 'PROPERTIES','$timeout', '$translate', 'cookieService',
    function($rootScope, $modal, $log, $location, store, $state, userAuthService, authService, notificationService, requestService, Idle, EVENT_CODES,
        editableOptions, USER_ROLES, GAME_PLATFORM, PROPERTIES, $timeout, $translate, cookieService) {

        initLanguage();
        initGeneralSettings();
        initEventHandlers();
        initRootScope();
        initIdleWatcher();
        initReportWatcher();

        function initGeneralSettings() {
            // DEBUG mode
            $rootScope.DEBUG = PROPERTIES.DEBUG || false;
            $rootScope.SHOW_VERSION = PROPERTIES.SHOW_VERSION || false;
            $rootScope.VERSION = PROPERTIES.VERSION;

            // xEditable module settings
            editableOptions.theme = 'bs3';
        }

        // Initialize event handlers
        function initEventHandlers() {
            $rootScope.$on('event:auth-loginRequired', function() {
                // $log.debug('event:auth-loginRequired');
                $rootScope.$emit(EVENT_CODES.NOT_AUTHENTICATED);
            });

            $rootScope.$on('event:auth-forbidden', function() {
                // $log.debug('event:auth-forbidden');
                $rootScope.$emit(EVENT_CODES.NOT_AUTHORIZED);
            });

            $rootScope.$on('event:page-notFound', function() {
                // $log.debug('event:auth-forbidden');

            });

            $rootScope.$on(EVENT_CODES.NOT_AUTHENTICATED, function() {
                // $log.debug('EVENT_CODES.NOT_AUTHENTICATED');
                notificationService.dismissAll();
                if($state.current.name !== 'login') {
                    notificationService.showErrorToast($translate.instant('GENERAL.APPLICATION.NOT_LOGGED_IN'));
                    userAuthService.showLoginForm();
                }
            });

            $rootScope.$on(EVENT_CODES.NOT_AUTHORIZED, function() {
                // $log.debug('EVENT_CODES.NOT_AUTHORIZED');
                notificationService.dismissAll();
                notificationService.showWarningDialog($translate.instant('GENERAL.APPLICATION.UNAUTHORIZED_TITLE'),
                    $translate.instant('GENERAL.APPLICATION.UNAUTHORIZED_MESSAGE'))
                    .then(function() {
                        userAuthService.getUser();
                        $location.back();
                    }
                );
            });

            $rootScope.$on(EVENT_CODES.LOGIN_SUCCESS, function() {
                // $log.debug('EVENT_CODES.LOGIN_SUCCESS');
                notificationService.dismissAll();
                var requestUpdater = function(config) {
                    config.headers['Authorization'] = userAuthService.getAuthorizationToken();
                    return config;
                };
                authService.loginConfirmed('success', requestUpdater);

                if ($rootScope.lastVisited && $rootScope.lastVisited.path !== "/login"
                    && !$rootScope.lastVisited.path.includes("/reset")
                    && $rootScope.isAuthorized($rootScope.lastVisited.roles)) {
                    $location.url($rootScope.lastVisited.path);
                    $rootScope.lastVisited = null;
                } else {
                    $location.path('/');
                }

                getFinishedReportsCount();
            });

            $rootScope.$on(EVENT_CODES.LOGIN_FAILED, function() {
                // $log.debug('EVENT_CODES.LOGIN_FAILED');
                notificationService.dismissAll();
                authService.loginCancelled(null, 'cancel');
                userAuthService.removeUser();

                userAuthService.showLoginForm();
            });

            $rootScope.$on(EVENT_CODES.LOGOUT_SUCCESS, function() {
                notificationService.dismissAll();
                userAuthService.showLoginForm();
                Idle.unwatch();
            });

            $rootScope.$on(EVENT_CODES.USER_DATA_CHANGED, function() {
                // $log.info(EVENT_CODES.USER_DATA_CHANGED);
                userAuthService.getUser();
            });

            $rootScope.$on(EVENT_CODES.PASSWORD_CHANGED, function() {
                notificationService.dismissAll();
                $location.url($location.path());
                userAuthService.showLoginForm();
            });

            $rootScope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams, options) {
                    if (!$location.path().contains('/login') && !$location.path().contains('/reset')) {
                        // Dismiss all shown notifications
                        notificationService.dismissAll();

                        var roles = toState.data && toState.data.roles;
                        $rootScope.lastVisited = {
                            path: $location.url(),
                            roles: roles
                        };
                        // $log.debug('lastVisited', $rootScope.lastVisited);

                        // For each routing change check if user logged in
                        if (!userAuthService.isAuthenticated()) {
                            $rootScope.$emit(EVENT_CODES.NOT_AUTHENTICATED);
                            event.preventDefault();
                        } else {
                            if (!userAuthService.isAuthorized(roles)) {
                                notificationService.showWarningDialog(
                                    $translate.instant('GENERAL.APPLICATION.NOT_AUTHORIZED_TITLE'),
                                    $translate.instant('GENERAL.APPLICATION.NOT_AUTHORIZED_MESSAGE') + ' \'' + $location.url() + '\'.'
                                ).then(function() {
                                        $location.path('/');
                                    }
                                );
                                event.preventDefault();
                            }

                            Idle.watch();
                        }
                    }
                }
            );
        }

        function initRootScope() {
            $rootScope.USER_ROLES = USER_ROLES;
            $rootScope.EVENT_CODES = EVENT_CODES;
            $rootScope.GAME_PLATFORM = GAME_PLATFORM;

            $rootScope.globals = {};
            $rootScope.popoverDelay = 0; // [ms]
            $rootScope.popoverIconPlacement = 'left';

            $rootScope.DEFAULT_TIMEOUT = 500;

            $rootScope.isAuthorized = userAuthService.isAuthorized;

            $rootScope.$state = $state;

            // Keep user logged in after page refresh
            $rootScope.globals.currentUser = userAuthService.getUserOnClient() || null;

            userAuthService.getAppInfo(
                function(response) {
                    if (response.status.code === 'OK') {
                        $rootScope.globals.applicationName = response.applicationName;
                        $rootScope.globals.brandUrl = response.brandUrl;
                    }
                }
            );

            // Define functions available in whole application
            $rootScope.downloadFile = downloadFile;
            $rootScope.clearDropdownSearch = clearDropdownSearch;

            $rootScope.lastVisited = {
                path: $location.url()
            };

            if ($rootScope.globals.currentUser) {
                userAuthService.setHttpAuthData($rootScope.globals.currentUser.authData);
                userAuthService.getUser(function(response) {
                    requestService.setAcceptLanguage(response.user.locale);

                    $translate.use(response.user.locale);
                    $log.info('User successfully logged');
                });
            } else {
                var token = $location.search().token;

                if (!token) {
                    userAuthService.showLoginForm();
                }
            }

        }

        function downloadFile(url) {
            $("#downloadFormPoster").remove();
            $("<div id='downloadFormPoster' style='display: none;'><iframe name='downloadFormPosterIframe'></iframe></div>").appendTo('body');
            $("<form action='" + url + "' target='downloadFormPosterIframe' method='post'></form>")
                .appendTo("#downloadFormPoster")
                .submit();
        }

        function clearDropdownSearch($select) {
            $select.search = '';
        }

        function initIdleWatcher() {

            $rootScope.$on('IdleStart', function() {
                // the user appears to have gone idle
                $log.info('User is idle');
                notificationService.showWarningDialog($translate.instant('GENERAL.APPLICATION.USER_IDLE_TITLE'),
                    $translate.instant('GENERAL.APPLICATION.USER_IDLE_MESSAGE'));
            });

            $rootScope.$on('IdleWarn', function(e, countdown) {
                // follows after the IdleStart event, but includes a countdown until the user is considered timed out
                // the countdown arg is the number of seconds remaining until then.
                // you can change the title or display a warning dialog from here.
                // you can let them resume their session by calling Idle.watch()
            });

            // When user has timed out (idleDuration + timeout > idle)
            $rootScope.$on('IdleTimeout', function() {
                $log.info('The user was log out after idle for too long.');
                notificationService.dismissAll();
                userAuthService.showLoginForm();
                notificationService.showWarningDialog($translate.instant('GENERAL.APPLICATION.USER_LOGGED_OUT_TITLE'),
                    $translate.instant('GENERAL.APPLICATION.USER_LOGGED_OUT_MESSAGE'));
                $timeout(userAuthService.removeUser, 0);
            });

            // When user comes back from AFK. Use this to hide the dialogs.
            $rootScope.$on('IdleEnd', function() {
                notificationService.dismissAll();
                $log.info('User is back');
            });
        }

        function initReportWatcher() {
            $rootScope.$on('ReportPrepared', function(event, args) {
                var reportId = args.reportId;
                var reportType = args.reportType;
                getReportStatus(reportId, reportType)
            });
        }

        function getReportStatus(reportId, reportType) {
            requestService.get(
                '/api/report/status?reportId=' + reportId,
                function(response) {
                    if (JSON.parse(response) !== 'FINISHED' && JSON.parse(response) !== 'FAILED') {
                        $timeout(function() {
                                getReportStatus(reportId, reportType);
                            },
                            3000
                        );
                    } else {
                        notificationService.showInfoToast($translate.instant('REPORTS.HEADINGS.' + reportType)
                            + ' '
                            + $translate.instant('GENERAL.APPLICATION.REPORT_COMPLETED'));
                    }
                },
                function(error) {
                    notificationService.processServerError(error, $translate.instant('GENERAL.APPLICATION.ERROR_GETTING_REPORT_STATUS'));
                }
            );
        }

        function getFinishedReportsCount() {
            requestService.get(
                '/api/report/finished-count',
                function(response) {
                    var count = response;

                    if (count > 0) {
                        notificationService.showInfoToast(count + $translate.instant('GENERAL.APPLICATION.REPORT_COMPLETED'));
                    }
                },
                function(error) {
                    notificationService.processServerError(error, $translate.instant('GENERAL.APPLICATION.ERROR_GETTING_REPORT_COUNT'));
                }
            );
        }

        function initLanguage() {
            var userLanguage = cookieService.getCookie('userLanguage');
            if (!userLanguage) {
                requestService.setAcceptLanguage('EN');

                $translate.use('EN');
                cookieService.setCookie('userLanguage', 'EN');
            } else {
                requestService.setAcceptLanguage(userLanguage);

                $translate.use(userLanguage);
            }
        }
    }
]);
