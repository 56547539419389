app.controller('ReportsCtrl', ['$scope', '$rootScope', '$stateParams', '$state', '$timeout', 'requestService', 'notificationService',
    'urlParamsService', 'ERROR_CODES', '$translate', 'REPORT_CONSTANTS',
    function ($scope, $rootScope, $stateParams, $state, $timeout, requestService, notificationService, urlParamsService, ERROR_CODES, $translate,
        REPORT_CONSTANTS) {

        init();
        initTable();

        function init() {
            $scope.translations = {};
            $scope.remainingItemsCount = 0;
            $translate(['REPORTS.ERROR_MESSAGES.MISSING_REPORT_TYPE', 'REPORTS.ERROR_MESSAGES.NOT_AUTHORIZED',
                'REPORTS.ERROR_MESSAGES.GENERATING_REPORT', 'REPORTS.ERROR_MESSAGES.GETTING_REPORT_DATA',
                'REPORTS.ERROR_MESSAGES.GETTING_REPORT_STATUS', 'REPORTS.ERROR_MESSAGES.NO_DATA_FOUND', 'REPORTS.ERROR_MESSAGES.DOWNLOADING_REPORT'])
                .then(function (translations) {
                    $scope.translations.missingReportType = translations['REPORTS.ERROR_MESSAGES.MISSING_REPORT_TYPE'];
                    $scope.translations.notAuthorized = translations['REPORTS.ERROR_MESSAGES.NOT_AUTHORIZED'];
                    $scope.translations.generatingReportError = translations['REPORTS.ERROR_MESSAGES.GENERATING_REPORT'];
                    $scope.translations.gettingReportDataError = translations['REPORTS.ERROR_MESSAGES.GETTING_REPORT_DATA'];
                    $scope.translations.gettingReportStatusErrorError = translations['REPORTS.ERROR_MESSAGES.GETTING_REPORT_STATUS'];
                    $scope.translations.noDataFound = translations['REPORTS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    $scope.translations.downloadingReportError = translations['REPORTS.ERROR_MESSAGES.DOWNLOADING_REPORT'];
                }, function (translationIds) {
                    $scope.translations.missingReportType = translationIds['REPORTS.ERROR_MESSAGES.MISSING_REPORT_TYPE'];
                    $scope.translations.notAuthorized = translationIds['REPORTS.ERROR_MESSAGES.NOT_AUTHORIZED'];
                    $scope.translations.generatingReportError = translationIds['REPORTS.ERROR_MESSAGES.GENERATING_REPORT'];
                    $scope.translations.gettingReportDataError = translationIds['REPORTS.ERROR_MESSAGES.GETTING_REPORT_DATA'];
                    $scope.translations.gettingReportStatusError = translationIds['REPORTS.ERROR_MESSAGES.GETTING_REPORT_STATUS'];
                    $scope.translations.noDataFound = translationIds['REPORTS.ERROR_MESSAGES.NO_DATA_FOUND'];
                    $scope.translations.downloadingReportError = translationIds['REPORTS.ERROR_MESSAGES.DOWNLOADING_REPORT'];
                });

            // TODO
            // $translate('REPORTS.REPORTS_FILTER', $scope.remainingItemsCount).then(function(translation) {
            //     $scope.translations.reportFilter = translation;
            // }, function(translationId) {
            //     $scope.translations.reportFilter = translationId;
            // });

            // $scope.formData = (urlParamsService.getParams() || {});
            $scope.formData = {};
            $scope.formErrors = [];

            $scope.reportTypes = {
                "invoicing": {name: 'INVOICING'},
                'casino-game-performance': {name: 'CASINO_GAME_PERFORMANCE'},
                'free-rounds-campaign-cost': {name: 'FR_CAMPAIGN_COST'},
                'player-performance': {name: 'PLAYER_PERFORMANCE'},
                'casino-performance-per-day-report': {name: 'CASINO_PERFORMANCE_PER_DAY'},
                'casino-performance-per-day-detailed': {name: 'CASINO_PERFORMANCE_PER_DAY_DETAILED'},
                'player-demographic-data': {name: 'PLAYER_DEMOGRAPHIC_DATA'},
                'game-round-features-per-player': {name: 'GAME_ROUND_FEATURES_PER_PLAYER'},
                'users-list': {name: 'USERS_LIST'}
            };

            $scope.maxRowsToDisplay = REPORT_CONSTANTS.MAX_ROWS_TO_DISPLAY;

            $scope.reportType = $stateParams.reportType;
            if (!$scope.reportType) {
                notificationService.showWarningToast($scope.translations.missingReportType);
                $state.go('home')
            }

            $scope.displayAll = false;

            $scope.onGetTableData = function(reportId) {
                getReportData(reportId, $scope.reportType);
            };

            $scope.onExport = function(reportId) {
                exportReportData(reportId, $scope.reportType);
            };

            $scope.onGenerateReport = function() {
                generateReport($scope.reportType, $scope.formData);
            };

            $scope.onSendMail = function() {
                exportReportMail($scope.reportType, $scope.formData);
            };

            $scope.toCasinoName = function(c) {
                return c.name;
            };

            $scope.onPreselectLast7Days = function() {
                var date = new Date();
                $scope.formData.dateTo = date.toISOString();
                $scope.formData.dateFrom = new Date(date.setDate(date.getDate() - 7));
            }

            $scope.onPreselectLast30Days = function() {
                var date = new Date();
                $scope.formData.dateTo = date.toISOString();
                $scope.formData.dateFrom = new Date(date.setDate(date.getDate() - 30));
            }

            $scope.onPreselectLast90Days = function() {
                var date = new Date();
                $scope.formData.dateTo = date.toISOString();
                $scope.formData.dateFrom = new Date(date.setDate(date.getDate() - 90));
            }

            if ($scope.reportType === 'casino-game-performance'
                || $scope.reportType === 'casino-performance-per-day-report'
                || $scope.reportType === 'casino-performance-per-day-detailed'
                || $scope.reportType === 'free-rounds-campaign-cost'
                || $scope.reportType === 'player-demographic-data') {
                requestService.get(
                    '/api/reports/' + $scope.reportType + '/casino-tree',
                    function (data) {
                        $scope.treeStructure = data;
                        // NBO-1 Prefill input fields for specific report
                        if ($scope.reportType === 'casino-performance-per-day-detailed') {
                            $scope.formData.casinos = [];
                            preselectAllCasinos($scope.treeStructure, $scope.formData.casinos)
                            updateTreeview();

                            var date = new Date();
                            $scope.formData.dateTo = date.toISOString();
                            $scope.formData.dateFrom = new Date(date.setDate(1));
                        }
                    },
                    function() {
                    }
                );
            }

            getReportStatus($scope.reportType);
        }

        function preselectAllCasinos(list, resultArray) {
            resultArray.push(list.id)
            for (var i = 0; i < list.children.length; i++) {
                var childrenArray = preselectAllCasinos(list.children[i], resultArray)
                resultArray.concat(childrenArray)
            }
        }

        function updateTreeview() {
            if ($scope.formData.casinos && $scope.formData.casinos.length > 0) {
                for (var i = 0; i < $scope.formData.casinos.length; i++) {
                    selectNode($scope.treeStructure, $scope.formData.casinos[i]);
                }
            }
            $scope.treeStructureFinal = $scope.treeStructure;
        }

        function selectNode(node, casinoId) {
            if (node.id === casinoId) {
                node.selected = true;
            } else {
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        selectNode(node.children[i], casinoId);
                    }
                }
            }
        }

        function initTable() {
            $scope.reports = {
                columnsInfo: {
                    'casinoName': {displayName: 'CASINO'},
                    'gameName': {displayName: 'GAME'},
                    'campaignName': {displayName: 'CAMPAIGNS'},
                    'countryName': {displayName: 'COUNTRY'},
                    'countryCode': {displayName: 'COUNTRY'},
                    'gameId': {displayName: 'GAME_ID'},
                    'count': {displayName: 'GAME_ROUNDS', filter: 'number', class: 'number'},
                    'gameRoundCount': {displayName: 'ROUNDS', filter: 'number', class: 'number'},
                    'roundsOnlyRM': {displayName: 'REAL_MONEY_ROUNDS', filter: 'number', class: 'number'},
                    'roundsBonuses': {displayName: 'BONUSES_AND_FR_ROUNDS', filter: 'number', class: 'number'},
                    'roundsCombined': {displayName: 'COMBINED_ROUNDS', filter: 'number', class: 'number'},
                    'stake': {displayName: 'STAKE', filter: 'number:2', class: 'number'},
                    'stakeInSystemBase': {displayName: 'STAKE_IN_SYSTEM_BASE', filter: 'number:2', class: 'number'},
                    'stakeInReportCurrency': {displayName: 'STAKE_IN_REPORT_CURRENCY', filter: 'number:2', class: 'number'},
                    'frStakeInSystemBase': {displayName: 'FR_STAKES_IN_SYSTEM_BASE', filter: 'number:2', class: 'number'},
                    'stakeOnlyRMInReportCurrency': {displayName: 'REAL_MONEY_STAKE', filter: 'number:2', class: 'number'},
                    'stakeBonusesInReportCurrency': {displayName: 'BONUSES_AND_FR_STAKE', filter: 'number:2', class: 'number'},
                    'stakeCombinedInReportCurrency': {displayName: 'COMBINED_STAKE', filter: 'number:2', class: 'number'},
                    'win': {displayName: 'WIN', filter: 'number:2', class: 'number'},
                    'winInSystemBase': {displayName: 'WIN_IN_SYSTEM_BASE', filter: 'number:2', class: 'number'},
                    'winInReportCurrency': {displayName: 'WIN_IN_REPORT_CURRENCY', filter: 'number:2', class: 'number'},
                    'winOnlyRMInReportCurrency': {displayName: 'REAL_MONEY_WIN', filter: 'number:2', class: 'number'},
                    'winBonusesInReportCurrency': {displayName: 'BONUSES_AND_FR_WIN', filter: 'number:2', class: 'number'},
                    'winCombinedInReportCurrency': {displayName: 'COMBINED_WIN', filter: 'number:2', class: 'number'},
                    'hold': {displayName: 'HOLD', filter: 'number:2', class: 'number'},
                    'holdInSystemBase': {displayName: 'HOLD_IN_SYSTEM_BASE', filter: 'number:2', class: 'number'},
                    'holdInReportCurrency': {displayName: 'HOLD_IN_REPORT_CURRENCY', filter: 'number:2', class: 'number'},
                    'holdOnlyRMInReportCurrency': {displayName: 'REAL_MONEY_HOLD', filter: 'number:2', class: 'number'},
                    'holdBonusesInReportCurrency': {displayName: 'BONUSES_AND_FR_HOLD', filter: 'number:2', class: 'number'},
                    'holdCombinedInReportCurrency': {displayName: 'COMBINED_HOLD', filter: 'number:2', class: 'number'},
                    'rtp': {displayName: 'RTP', filter: 'percentage:2', class: 'number'},
                    'avgStakeInSystemBase': {displayName: 'AVG_STAKE_IN_SYSTEM_BASE', filter: 'number:2', class: 'number'},
                    'avgStakeInReportCurrency': {displayName: 'AVG_STAKE_IN_REPORT_CURRENCY', filter: 'number:2', class: 'number'},
                    'playerCount': {displayName: 'PLAYER_COUNT', filter: 'number', class: 'number'},
                    'jackpotWinnings': {displayName: 'JACKPOT_WINNINGS', filter: 'number:4', class: 'number'},
                    'date': {displayName: 'DATE', filter: 'dateFilterShort'},
                    'moneyType': {displayName: 'MONEY_TYPE'},
                    'currencyCode': {displayName: 'CURRENCY'},
                    'conversionRateToSystemBase': {displayName: 'CONVERSION_RATE_TO_SYSTEM_BASE', filter: 'number', class: 'number'},
                    'conversionRateToReportCurrency': {displayName: 'CONVERSION_RATE_TO_REPORT_CURRENCY', filter: 'number', class: 'number'},
                    'claimedPlayerCount': {displayName: 'PLAYERS_NUM', filter: 'number', class: 'number'},
                    'freeRoundCount': {displayName: 'FR_PLAYED_NUM', filter: 'number', class: 'number'},
                    'frAmountConvertedToRealMoneyInSystemBase': {displayName: 'FR_WINS_TO_RM', filter: 'number:2', class: 'number'},
                    'frAmountConvertedToBonusInSystemBase': {displayName: 'FR_WINS_TO_BONUSES', filter: 'number:2', class: 'number'},
                    'frConsumed': {displayName: 'CAMPAIGNS_WO_WIN_NUM', filter: 'number', class: 'number'},
                    'frToBonus': {displayName: 'CAMPAIGNS_TO_BONUSES_NUM', filter: 'number', class: 'number'},
                    'bAmountConvertedToRealMoneyInSystemBase': {displayName: 'BONUSES_TO_RM', filter: 'number', class: 'number'},
                    'bConsumed': {displayName: 'BONUSES_WO_WIN_NUM', filter: 'number', class: 'number'},
                    'bConverted': {displayName: 'BONUSES_TO_RM_NUM', filter: 'number', class: 'number'},
                    'bActive': {displayName: 'BONUSES_NOT_FINISHED_NUM', filter: 'number', class: 'number'},
                    'gender': {displayName: 'GENDER'},
                    'genderPercentage': {displayName: 'GENDER_PERC', filter: 'percentage:2', class: 'number'},
                    'genderCount': {displayName: 'GENDER_NUM', filter: 'number', class: 'number'},
                    'year': {displayName: 'YEAR', filter: 'number', class: 'number'},
                    'playerId': {displayName: 'PLAYER_ID'},
                    'gameRoundId': {displayName: 'GAME_ROUND_ID', filter: 'number', class: 'number'},
                    'gameSessionId': {displayName: 'GAME_SESSION_ID', filter: 'number', class: 'number'},
                    'dateFinished': {displayName: 'DATE_FINISHED', filter: 'dateFilterLong'},
                    'winBeforeGambler': {displayName: 'WIN_BEFORE_GAMBLER', filter: 'number', class: 'number'},
                    'winBeforeGamblerInSystemBase': {displayName: 'WIN_BEFORE_GAMBLER_IN_SYSTEM_BASE', filter: 'number', class: 'number'},
                    'gamblerSteps': {displayName: 'GAMBLER_STEPS', filter: 'number', class: 'number'},
                    'coinValue': {displayName: 'COIN_VALUE', filter: 'number', class: 'number'},
                    'gamblerBtn': {displayName: 'GAMBLER_BTN'}
                },
                "invoicing": [
                    'date', 'moneyType', 'gameName', 'currencyCode',
                    'conversionRateToReportCurrency', 'count', 'stake',
                    'win', 'hold', 'stakeInReportCurrency', 'winInReportCurrency', 'holdInReportCurrency'
                ],
                'casino-game-performance': [
                    'casinoName', 'gameName', 'gameRoundCount',
                    'stakeInReportCurrency', 'winInReportCurrency', 'holdInReportCurrency',
                    'rtp', 'avgStakeInReportCurrency', 'playerCount'
                ],
                'free-rounds-campaign-cost': [
                    'casinoName', 'campaignName', 'countryName',
                    'claimedPlayerCount', 'freeRoundCount', 'frStakeInSystemBase',
                    'frAmountConvertedToRealMoneyInSystemBase',
                    'frAmountConvertedToBonusInSystemBase',
                    'frConsumed', 'frToBonus',
                    'bAmountConvertedToRealMoneyInSystemBase',
                    'bConsumed', 'bConverted', 'bActive'
                ],
                'casino-performance-per-day-report': [
                    'casinoName', 'date',
                    'roundsOnlyRM', 'stakeOnlyRMInReportCurrency', 'winOnlyRMInReportCurrency', 'holdOnlyRMInReportCurrency'
                ],
                'player-demographic-data': [
                    'casinoName', 'countryCode', 'gameName',
                    'year', 'gender', 'genderCount',
                    'genderPercentage'
                ],
                'casino-performance-per-day-detailed': [
                    'casinoName', 'date',
                    'roundsOnlyRM', 'stakeOnlyRMInReportCurrency', 'winOnlyRMInReportCurrency', 'holdOnlyRMInReportCurrency',
                    'roundsBonuses', 'stakeBonusesInReportCurrency', 'winBonusesInReportCurrency', 'holdBonusesInReportCurrency',
                    'roundsCombined', 'stakeCombinedInReportCurrency', 'winCombinedInReportCurrency', 'holdCombinedInReportCurrency'
                ],
                'game-round-features-per-player': [
                    'playerId', 'gameSessionId', 'gameRoundId', 'gameName', 'dateFinished',
                    'stake', 'win', 'winBeforeGambler', 'conversionRateToSystemBase', 'coinValue',
                    'stakeInSystemBase', 'winInSystemBase', 'winBeforeGamblerInSystemBase', 'holdInSystemBase', 'gamblerSteps', 'gamblerBtn'
                ]
            };

            $scope.report = {};
            $scope.previewTable = false;
            $scope.noDataFound = false;
        }

        function generateReport(reportType, formData) {
            $scope.previewTable = false;

            requestService.postEncoded(
                '/api/reports/' + reportType + '/generate',
                formData,
                function(response) {
                    $scope.formErrors = response.status.formErrors || {};

                    if (response.status.code === ERROR_CODES.OK) {
                        var data = {
                            reportId: response.generatedReportId,
                            reportType: $scope.reportTypes[reportType].name
                        };

                        $rootScope.$broadcast('ReportPrepared', data);

                        getReportStatus(reportType, formData);
                    } else {
                        if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($translate.instant('REPORTS.ERROR_MESSAGES.INVALID_DATA'));
                        } else if (response.status.code === ERROR_CODES.UNAUTHORIZED) {
                            notificationService.showWarningToast($scope.translations.notAuthorized);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.generatingReportError);
                        }
                    }
                },
                function(error) {
                    notificationService.processServerError(error.status, $scope.translations.generatingReportError);
                }
            );
        }

        function getReportStatus(reportType) {
            if (reportType !== 'users-list') {
                $scope.noDataFound = false;

                requestService.get(
                    '/api/reports/' + reportType + '/status',
                    function(response) {
                        if (response.status.code === ERROR_CODES.OK) {
                            $scope.reportsStatus = response.reportsStatus.reports;

                            var allFinished =  response.reportsStatus.allFinished;

                            if (!allFinished) {
                                $timeout(function() {
                                        getReportStatus(reportType);
                                    },
                                    3000
                                );
                            } else {
                                return;
                            }
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.gettingReportStatusError );
                        }
                    },
                    function(error) {
                        notificationService.processServerError( error.status, $scope.translations.gettingReportStatusError);
                    }
                );
            }
        }

        function getReportData(reportId, reportType) {
            requestService.get(
                '/api/reports/' + reportType + '/table-data?reportId=' + reportId,

                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.report = response.report;

                        if (response.report.rows === 0) {
                            notificationService.showInfoToast($scope.translations.noDataFound);
                        }

                        //FIXME remove
                        if (response.report.rows.length) {
                            $scope.noDataFound = false;
                            $scope.previewTable = true;
                        } else {
                            $scope.noDataFound = true;
                            $scope.previewTable = false;
                        }
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.gettingReportDataError);

                        $scope.previewTable = false;
                    }
                },
                function(error) {
                    notificationService.processServerError(error.status, $scope.translations.gettingReportDataError);
                }
            );
        }

        function exportReportData(reportId, reportType) {
            requestService.getWithResponseType(
                '/api/reports/' + reportType + '/download-report?reportId=' + reportId,
                'arraybuffer',
                function(data, status, headers) {
                    var filename = headers('content-filename');
                    if (window.navigator.msSaveOrOpenBlob) {
                        var fileData = [data];
                        var blobObject = new Blob(fileData);

                        window.navigator.msSaveOrOpenBlob(blobObject, filename);
                    } else {
                        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
                        saveAs(blob, filename);
                    }
                },
                function(error) {
                    notificationService.processApplicationError(error.status, $scope.translations.downloadingReportError);
                }
            );
        }

        function exportReportMail(reportType, formData) {
            requestService.postEncodedWithResponseType(
                '/api/reports/' + reportType + '/send-report',
                formData,
                function(response) {
                    $scope.formErrors = response.status.formErrors || {};

                    if (!response.status.code === ERROR_CODES.OK) {
                        if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.invalidData);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.generatingReportError);
                        }
                        // $scope.previewTable = false;
                    }
                }
            );
        }
    }
]);

app.controller('ReportsCasinoDropdownCtrl', ['$scope', '$stateParams', 'requestService',
    function($scope, $stateParams, requestService) {

        init();

        function init() {
            var reportType = $stateParams.reportType;
            if (reportType) {
                $scope.list = [];
                $scope.onRefreshList = function() {
                    requestService.get(
                        '/api/reports/' + reportType + '/casino-list',
                        function(data) {
                            $scope.list = data;
                        }
                    );
                };
            }
        }
    }
]);

app.controller('ReportsCurrencyDropdownCtrl', ['$scope', '$stateParams', 'requestService',
    function($scope, $stateParams, requestService) {

        init();

        function init() {
            var reportType = $stateParams.reportType;
            if (reportType) {
                $scope.list = [];
                $scope.onRefreshList = function() {
                    requestService.get(
                        '/api/reports/' + reportType + '/currency-list',
                        function(data) {
                            $scope.list = data;
                        }
                    );
                };
            }
        }
    }
]);

app.controller('ReportsGameDropdownCtrl', ['$scope', '$stateParams', 'requestService',
    function($scope, $stateParams, requestService) {

        init();

        function init() {
            var reportType = $stateParams.reportType;
            if (reportType) {
                $scope.list = [];
                $scope.onRefreshList = function() {
                    requestService.get(
                        '/api/reports/' + reportType + '/game-list',
                        function(data) {
                            data.sort(function(a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            });
                            $scope.list = data;
                        }
                    );
                };
            }
        }
        // casinos: $scope.formData.casinos //TODO fetch only available games (union)
    }
]);

app.controller('CampaignsDropdownCtrl', ['$scope', '$stateParams', 'requestService',
    function($scope, $stateParams, requestService) {
        var reportType = $stateParams.reportType;
        if (reportType) {
            $scope.list = [];
            $scope.onRefreshList = function() {
                requestService.get(
                    '/api/reports/' + reportType + '/campaign-list',
                    function(data) {
                        $scope.list = data;
                    }
                );
            };
        }
    }
]);

app.filter('reportFilter', function() {
    return function(data, displayAll) {

        if (displayAll || data.length <= 5) {
            return data.join(', ')
        } else {
            var totalCount = data.length;
            var firstFive = [];

            for (var i = 0; i < 5; i++) {
                firstFive.push(data[i])
            }

            var remainingItemsCount = totalCount - 5;

            return firstFive.join(', ') + ' and ' + remainingItemsCount + ' more';
        }
    }
});
