app.controller('CgsGameListCtrl', ['$scope', '$log', '$modal', 'requestService',
    function($scope, $log, $modal, requestService) {

        init();
        initTable();

        function init() {
            $scope.searchFilter = '';

            $scope.onNewCgsGame = function() {
                openCgsGameModal(null, false);
            };

            $scope.onViewCgsGame = function(id) {
                openCgsGameModal(id, true);
            };

            $scope.onEditCgsGame = function(id) {
                openCgsGameModal(id, false);
            };
        }

        function initTable() {
            $scope.table = {
                cgsGames: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25
            };

            $scope.onRefreshTableData = function() {
                getTableData();
            };

            getTableData();
        }

        function getTableData() {
            requestService.get(
                '/api/games/cgs-game-list',
                function(response) {
                    if (response.status.code !== 'OK') {
                        $log.error(response.status);
                    } else {
                        updateTable(response);
                    }
                }
            );
        }

        function updateTable(response) {
            $scope.table.cgsGames = response.list;
        }

        function openCgsGameModal(cgsGameId, openInReadMode) {

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/admin/game_administration/modals/cgs_game_modal.html',
                controller: 'CgsGameModalInstanceCtrl',
                backdrop: false,
                size: '-lg',
                resolve: {
                    cgsGameId: function() {
                        return cgsGameId;
                    },
                    openInReadMode: function() {
                        return openInReadMode;
                    }
                }
            });

            modalInstance.result.then(function() {
                getTableData();
            }, function() {
                getTableData();
            });
        }
    }
]);
