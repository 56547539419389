app.controller('UserModalInstanceCtrl', ['$scope', '$log', '$modalInstance', '$modal', '$filter', 'requestService', 'notificationService', 'login',
    'loginToDuplicate', 'ERROR_CODES', 'USER_ROLES', '$q', 'GLOBAL_CONSTANTS', '$translate',
    function($scope, $log, $modalInstance, $modal, $filter, requestService, notificationService, login, loginToDuplicate, ERROR_CODES, USER_ROLES, $q,
        GLOBAL_CONSTANTS, $translate) {

        $scope.translations = initTranslations();
        initForm();
        initRoles();

        if (login) {
            getUser(login);
        } else if (loginToDuplicate) {
            duplicateRoles(loginToDuplicate);
        }

        function initForm() {
            $scope.formData = {
                login: login,
                locale: 'EN',
                roles: [],
                casinoPermissions: [],
                company: ""
            };
            $scope.formErrors = {};
            $scope.login = login;

            $scope.isGenericCollapsed = true;
            $scope.isCasinoSpecificCollapsed = true;
            $scope.selectedCasinoPermission = [];
            $scope.selectedCasinoPermissionBackup = [];

            $scope.companies = [];

            $scope.onCreate = function() {
                createUser();
            };

            $scope.onSave = function(login) {
                saveUser(login);
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.onCancelPermissionModal = function() {
                $scope.userPermissionModal.dismiss('cancel');
            };

            $scope.onSaveUserPermission = function() {
                $scope.userPermissionModal.close();
            };

            getCompaniesList();
        }

        function initRoles() {
            $scope.availableRoles = [
                {group: 'SEARCH', perCasino: true, role: USER_ROLES.GAME_SESSIONS_VIEW},

                {group: 'REPORTS', perCasino: true, role: USER_ROLES.INVOICING_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.CASINO_GAME_PERFORMANCE_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.FREE_ROUNDS_CAMPAIGN_COST_REPORT, dependency: USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.PLAYER_PERFORMANCE_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.CASINO_PERFORMANCE_PER_DAY_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.CASINO_PERFORMANCE_PER_DAY_DETAILED_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.PLAYER_DEMOGRAPHIC_DATA_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.GAME_ROUND_FEATURES_PER_PLAYER_REPORT},
                {group: 'REPORTS', perCasino: true, role: USER_ROLES.USERS_LIST_REPORT},

                {group: 'CASINO', perCasino: true, role: USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW, dependants: [USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE]},
                {group: 'CASINO', perCasino: true, role: USER_ROLES.CASINO_GAME_MANAGEMENT_UPDATE, dependency: USER_ROLES.CASINO_GAME_MANAGEMENT_VIEW},

                {group: 'ALARMS', perCasino: true, role: USER_ROLES.ALARMS_VIEW, dependants: [USER_ROLES.ALARMS_UPDATE]},
                {group: 'ALARMS', perCasino: true, role: USER_ROLES.ALARMS_UPDATE, dependency: USER_ROLES.ALARMS_VIEW},

                {group: 'ANALYTICS', perCasino: true, role: USER_ROLES.REPORTING_VIEW},

                {
                    group: 'CAMPAIGNS',
                    perCasino: true,
                    role: USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW,
                    dependants: [
                        USER_ROLES.FREE_ROUNDS_CAMPAIGN_COST_REPORT,
                        USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE,
                        USER_ROLES.GSMC_FREE_ROUND_CAMPAIGNS_UPDATE
                    ]
                },
                {group: 'CAMPAIGNS', perCasino: true, role: USER_ROLES.FREE_ROUND_CAMPAIGNS_UPDATE, dependency: USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW},
                {group: 'CAMPAIGNS', perCasino: true, role: USER_ROLES.GSMC_FREE_ROUND_CAMPAIGNS_UPDATE, dependency: USER_ROLES.FREE_ROUND_CAMPAIGNS_VIEW},

                {group: 'MARKETING', perCasino: true, role: USER_ROLES.MARKETING_MATERIALS_VIEW, dependants: [USER_ROLES.MARKETING_MATERIALS_UPDATE]},
                {group: 'MARKETING', perCasino: false, role: USER_ROLES.MARKETING_MATERIALS_UPDATE, dependency: USER_ROLES.MARKETING_MATERIALS_VIEW},

                {group: 'ADMIN', perCasino: true, role: USER_ROLES.PLAYERS_SEARCH},

                {group: 'ADMIN', perCasino: false, role: USER_ROLES.GAME_MANAGEMENT}
            ];
            $scope.availableCasinoRoles = $filter('filter')($scope.availableRoles, {perCasino: true});

            $scope.casinos = [];
            $scope.casinosMap = [];
            $scope.allCasinosPromise = getCasinoList();

            $scope.areAllRolesSelected = function(roles) {
                return roles.length >= $scope.availableRoles.length;
            };

            $scope.areAllCasinoRolesSelected = function(roles) {
                return roles.length >= $scope.availableCasinoRoles.length;
            };

            $scope.isRoleSelected = function(roles, role) {
                return roles.indexOf(role) !== -1;
                //var filtered = $filter('filter')(roles, role, true);
                //return filtered.length != 0;
            };

            $scope.isNoRoleSelected = function(roles) {
                return roles.length === 0;
            };

            $scope.onSelectRole = function(roles, newRole, dependentRole) {
                if (!$scope.isRoleSelected(roles, newRole)) {
                    roles.push(newRole);

                    if (dependentRole && !$scope.isRoleSelected(roles, dependentRole)) {
                        roles.push(dependentRole);
                    }
                } else {
                    var index = roles.indexOf(newRole);
                    roles.splice(index, 1);
                    for (var i = 0; i < $scope.availableRoles.length; i++) {
                        if ($scope.availableRoles[i].role === newRole) {
                            if ($scope.availableRoles[i].dependants) {
                                var dependants = $scope.availableRoles[i].dependants;
                                var dependantsLength = dependants.length;
                                if (dependantsLength > 0) {
                                    for (var j = dependantsLength - 1; j >= 0 ; j--) {
                                        var roleIndex = roles.indexOf(dependants[j]);
                                        if (roleIndex !== -1) {
                                            roles.splice(roleIndex, 1);
                                        }
                                    }
                                }
                            }
                            break;
                        }
                    }
                }
            };

            $scope.onSelectAllRoles = function(roles) {
                for (var i = 0, len = $scope.availableRoles.length; i < len; i++) {
                    var role = $scope.availableRoles[i].role;
                    if (!$scope.isRoleSelected(roles, role)) {
                        roles.push(role);
                    }
                }
            };

            $scope.onSelectAllCasinoRoles = function(roles) {
                for (var i = 0, len = $scope.availableCasinoRoles.length; i < len; i++) {
                    var role = $scope.availableCasinoRoles[i].role;
                    if (!$scope.isRoleSelected(roles, role)) {
                        roles.push(role);
                    }
                }
            };

            $scope.onDeselectAllRoles = function(roles) {
                roles.length = 0;
            };

            $scope.onAddCasinoPermission = function() {
                openCasinoPermissionModal();
            };

            $scope.onRemoveCasinoPermission = function(object) {
                var index = $scope.formData.casinoPermissions.indexOf(object);
                $scope.formData.casinoPermissions.splice(index, 1);
            };

            $scope.setCasinoName = function(casinoPermission) {
                $q.all([$scope.allCasinosPromise]).then(function() {
                    for (var i = 0; i < $scope.casinos.length; i++) {
                        if ($scope.casinos[i].id === casinoPermission.casinoId) {
                            casinoPermission.name = $scope.casinos[i].name;
                            casinoPermission.path = $scope.casinos[i].path;
                        }
                    }
                });
            };

            $scope.onEditCasinoPermission = function(casinoPermission) {
                var index;
                for (var i = 0; i < $scope.formData.casinoPermissions.length; i++) {
                    if ($scope.formData.casinoPermissions[i].casinoId === casinoPermission.casinoId) {
                        index = i;
                        break;
                    }
                }
                openCasinoPermissionModal(index);
            };

            $scope.inheritsFromParent = function(item) {
                if ($scope.selectedCasinoPermission.casinoId) {
                    var parentId = $scope.casinosMap[$scope.selectedCasinoPermission.casinoId].parentId;
                    if (parentId && parentId !== GLOBAL_CONSTANTS.ROOT_CASINO_ID) {
                        return hasParentRole(parentId, item.role);
                    } else {
                        for (var i = 0; i < $scope.formData.roles.length; i++) {
                            if ($scope.formData.roles[i] === item.role) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            };

            $scope.pathAndName = function(item) {
                if (item !== null && item !== undefined) {
                    return item.path + item.name;
                }
            };
        }

        function getCompaniesList() {
            requestService.get(
                '/api/users/company-names-suggest-list',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.companies = response.list;
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.errorGettingCompaniesList);
                    }
                }
            );
        }

        function hasParentRole(parentId, role) {
            for (var i = 0; i < $scope.formData.casinoPermissions.length; i++) {
                if ($scope.formData.casinoPermissions[i].casinoId === parentId) {
                    if ($scope.formData.casinoPermissions[i].roles.indexOf(role) !== -1) {
                        return true;
                    } else {
                        if ($scope.casinosMap[parentId].parentId && $scope.casinosMap[parentId].parentId !== GLOBAL_CONSTANTS.ROOT_CASINO_ID) {
                            return hasParentRole($scope.casinosMap[parentId].parentId, role)
                        } else {
                            for (var j = 0; j < $scope.formData.roles.length; j++) {
                                if ($scope.formData.roles[j] === role) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                }
            }
            return false;
        }

        function getCasinoList() {
            var deferred = $q.defer();
            requestService.get(
                '/api/enums/casino-list',
                function(response) {
                    $scope.casinos = response;
                    for (var i = 0; i < $scope.casinos.length; i++) {
                        var index = $scope.casinos[i].id;
                        $scope.casinosMap[index] = $scope.casinos[i];
                    }
                    deferred.resolve()
                },
                function(response) {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function refreshRolesCollapseStatus() {
            if ($scope.formData.roles.length > 0) {
                $scope.isGenericCollapsed = false;
            }
            if ($scope.formData.casinoPermissions.length > 0) {
                $scope.isCasinoSpecificCollapsed = false;
            }
        }

        function duplicateRoles(loginToDuplicate) {
            requestService.get('/api/users/user/' + loginToDuplicate,
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        if (response.values) {
                            $scope.formErrors = response.message;
                            $scope.formData = {
                                name: response.values.login,
                                roles: response.values.roles,
                                casinoPermissions: response.values.casinoPermissions
                            };
                            refreshRolesCollapseStatus();
                        }
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.errorDuplicatingUser);
                    }
                }
            );
        }

        function getUser(login) {
            requestService.get('/api/users/user/' + login,
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        if (response.values) {
                            $scope.formData = response.values;
                            $scope.formErrors = response.message;
                            // Update collapsible panels
                            refreshRolesCollapseStatus();
                        }
                    } else {
                        notificationService.processServerError(response.status, $scope.translations.errorLoadingUser, cancelModal);
                    }
                }
            );
        }

        function createUser() {
            requestService.postEncoded('/api/users/user',
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;

                        notificationService.showInfoToast($scope.translations.infoUserCreated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast('Invalid form');
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorCreatingUser);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function saveUser(login) {
            requestService.putEncoded('/api/users/user/' + login,
                $scope.formData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData = data.values;

                        notificationService.showInfoToast($scope.translations.infoUserUpdated);
                        closeModal();
                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidForm);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorUpdatingUser);
                        }
                    }
                },
                function(error) {
                    $scope.formErrors = {};
                }
            );
        }

        function openCasinoPermissionModal(casinoPermissionIndex) {
            if (casinoPermissionIndex != null) {
                $scope.selectedCasinoPermission = $scope.formData.casinoPermissions[casinoPermissionIndex];
                $scope.selectedCasinoPermissionBackup = angular.copy($scope.selectedCasinoPermission);
            } else {
                $scope.casinoPermissionsBackup = angular.copy($scope.formData.casinoPermissions);
                $scope.formData.casinoPermissions.push({
                    casinoId: null,
                    roles: []
                });
                $scope.selectedCasinoPermission = $scope.formData.casinoPermissions[$scope.formData.casinoPermissions.length - 1];
            }
            $scope.userPermissionModal = $modal.open({
                animation: true,
                templateUrl: 'app/admin/user_administration/user_permission_modal.html',
                scope: $scope,
                backdrop: true,
                size: 'lg',
                resolve: {}
            });

            $scope.userPermissionModal.result.then(function() {
                //success function
                $scope.setCasinoName($scope.formData.casinoPermissions[$scope.formData.casinoPermissions.length - 1]);
            }, function() {
                if (casinoPermissionIndex != null) {
                    $scope.formData.casinoPermissions[casinoPermissionIndex] = angular.copy($scope.selectedCasinoPermissionBackup);
                } else {
                    $scope.formData.casinoPermissions = angular.copy($scope.casinoPermissionsBackup);
                }
            });
        }

        function initTranslations() {
            var translations = {};
            $translate(['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER', 'USER_ADMINISTRATION.ERROR_MESSAGES.GETTING_COMPANIES_LIST',
                'USER_ADMINISTRATION.ERROR_MESSAGES.DUPLICATING_USER', 'USER_ADMINISTRATION.ERROR_MESSAGES.LOADING_USER',
                'USER_ADMINISTRATION.INFO_MESSAGES.USER_CREATED', 'USER_ADMINISTRATION.ERROR_MESSAGES.CREATING_USER',
                'USER_ADMINISTRATION.INFO_MESSAGES.USER_UPDATED', 'USER_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM',
                'USER_ADMINISTRATION.ERROR_MESSAGES.UPDATING_USER'])
                .then(function(translation) {
                    translations.errorRemovingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER'];
                    translations.errorGettingCompaniesList = translation['USER_ADMINISTRATION.ERROR_MESSAGES.GETTING_COMPANIES_LIST'];
                    translations.errorDuplicatingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.DUPLICATING_USER'];
                    translations.errorLoadingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.LOADING_USER'];
                    translations.errorCreatingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.CREATING_USER'];
                    translations.errorInvalidForm = translation['USER_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorUpdatingUser = translation['USER_ADMINISTRATION.ERROR_MESSAGES.UPDATING_USER'];
                    translations.infoUserCreated = translation['USER_ADMINISTRATION.INFO_MESSAGES.USER_CREATED'];
                    translations.infoUserUpdated = translation['USER_ADMINISTRATION.INFO_MESSAGES.USER_UPDATED'];
                }, function(translationIds) {
                    translations.errorRemovingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.REMOVING_USER'];
                    translations.errorGettingCompaniesList = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.GETTING_COMPANIES_LIST'];
                    translations.errorDuplicatingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.DUPLICATING_USER'];
                    translations.errorLoadingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.LOADING_USER'];
                    translations.errorCreatingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.CREATING_USER'];
                    translations.errorInvalidForm = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.INVALID_FORM'];
                    translations.errorUpdatingUser = translationIds['USER_ADMINISTRATION.ERROR_MESSAGES.UPDATING_USER'];
                    translations.infoUserCreated = translationIds['USER_ADMINISTRATION.INFO_MESSAGES.USER_CREATED'];
                    translations.infoUserUpdated = translationIds['USER_ADMINISTRATION.INFO_MESSAGES.USER_UPDATED'];
                });
            return translations;
        }
    }
]);

app.filter('casinoFilter', function() {
    return function(casinos, casinoPermissions, selectedCasinoId) {
        var result = [];

        for (var i = 0; i < casinos.length; i++) {
            var alreadySelected = false;
            for (var j = 0; j < casinoPermissions.length; j++) {
                if (casinoPermissions[j].casinoId === casinos[i].id && casinoPermissions[j].casinoId !== selectedCasinoId) {
                    alreadySelected = true;
                    break;
                }
            }
            if (!alreadySelected) {
                result.push(casinos[i]);
            }
        }
        return result;
    }
});
