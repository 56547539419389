app.controller('AlarmSettingsRecipientsCtrl', ['$scope', 'requestService', 'ERROR_CODES', '$filter', '$q',
    function($scope, requestService, ERROR_CODES, $filter, $q) {
        init();

        function init() {
            $scope.recipients = [];
            $scope.recipientsBeforeSave = [];
            $scope.allUsers = [];
            $scope.availableRecipients = [];
            $scope.recipientSelection = {
                selectedAvailableRecipient: ""
            };

            if ($scope.DEBUG) {
                initDebugData();
            }

            var promise1 = getAllUsers();
            var promise2 = getAllRecipients();
            $q.all([promise1, promise2]).then(setAvailableRecipients);
        }

        function initDebugData() {
            $scope.recipients = [
                {id: 1, receiveEmail: false, receiveSms: true, userId: 2},
                {id: 2, receiveEmail: true, receiveSms: true, userId: 3},
            ];

            $scope.allUsers = [
                {id: 2, active: true, email: 'mail@email.com', lastLogin: 1476286103000, login: "John", name: "John Doe", phone: 123456789},
                {id: 3, active: true, email: 'em@asd.com', lastLogin: 1476286103000, login: "Isaac", name: "Isaac", phone: 987654},
                {id: 4, active: true, email: 'sumi@bubelah.com', lastLogin: 1476286103000, login: "Hello", name: "Hello World", phone: 000000},
            ];
        }

        $scope.showRecipientName = function(recipient) {
            var selected = $filter('filter')($scope.allUsers, {id: recipient.userId}, true);
            return selected.length ? selected[0].name : 'Not set';
        };

        $scope.editRecipients = false;

        $scope.enableEditRecipients = function() {
            $scope.editRecipients = true;
            $scope.recipientsBeforeSave = angular.copy($scope.recipients);
        };

        $scope.cancelEditRecipients = function() {
            $scope.recipients = angular.copy($scope.recipientsBeforeSave);
            setAvailableRecipients();
            closeEditing();
        };

        $scope.removeRecipient = function(recipient) {
            for (var i = 0; i < $scope.recipients.length; i++) {
                if ($scope.recipients[i].id === recipient.id) {
                    $scope.recipients.splice(i, 1);
                }
            }
            setAvailableRecipients();
        };

        $scope.updateRecipients = function() {
            requestService.postEncoded(
                '/api/alarm-settings/update-recipients',
                {recipients: $scope.recipients},
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        closeEditing();
                    } else {
                        $log.error(response.status);
                    }
                }
            );
        };

        $scope.addRecipient = function() {
            if ($scope.recipientSelection.selectedAvailableRecipient != null && $scope.recipientSelection.selectedAvailableRecipient.length != 0) {
                $scope.recipients.push(
                    {id: null, receiveEmail: false, receiveSms: false, userId: $scope.recipientSelection.selectedAvailableRecipient.id});
                $scope.recipientSelection.selectedAvailableRecipient = "";
                setAvailableRecipients();
            }
        };

        function getAllUsers() {
            var deferred = $q.defer();
            requestService.get(
                '/api/users/users-list',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.allUsers = response.list;
                        deferred.resolve(response.list);
                    } else {
                        $log.error(response.status);
                        deferred.reject(response.status);
                    }
                }
            );
            return deferred.promise;
        }

        function getAllRecipients() {
            var deferred = $q.defer();
            requestService.get(
                '/api/alarm-settings/get-all-alarm-recipients',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.recipients = response.list;
                        deferred.resolve(response.list);
                    } else {
                        $log.error(response.status);
                        deferred.reject(response.status);
                    }
                }
            );
            return deferred.promise;
        }

        function closeEditing() {
            $scope.recipientsBeforeSave = [];
            $scope.editRecipients = false;
        }

        function setAvailableRecipients() {
            $scope.availableRecipients = angular.copy($scope.allUsers);
            for (var i = 0; i < $scope.recipients.length; i++) {
                for (var j = 0; j < $scope.availableRecipients.length; j++) {
                    if ($scope.recipients[i].userId === $scope.availableRecipients[j].id) {
                        $scope.availableRecipients.splice([j], 1);
                        break;
                    }
                }
            }
            $scope.availableRecipients.sort(function(a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
    }
]);
