app.controller('ImportWhitelistIpsModalCtrl', ['$scope', '$modalInstance', '$modal', 'requestService', 'notificationService', 'ERROR_CODES',
    'uploadCsvUrl', 'IP_TYPES', '$q', 'Upload', 'casinoId', '$translate',
    function ($scope, $modalInstance, $modal, requestService, notificationService, ERROR_CODES, uploadCsvUrl, IP_TYPES, $q, Upload, casinoId,
        $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.uploadCsvUrl = uploadCsvUrl;
            $scope.casinoId = casinoId;

            $scope.uploaded = false;
            $scope.table = {};

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.onUploadWhitelistIps = function(file) {
                if (file && !file.$error) {
                    Upload.upload({
                        url: $scope.uploadCsvUrl,
                        data: {
                            casinoId: $scope.casinoId,
                            file: file
                        }
                    }).progress(function(evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.progress = progressPercentage + '% ' + evt.config.data.file.name + '\n' + $scope.progress;
                    }).success(function(data, status, headers, config) {
                        onSuccess(data);
                    }).error(function(error) {
                        onError(error);
                    });
                }
            };
        }

        function onSuccess(data) {
            $scope.progress = null;

            if (data.status.code === ERROR_CODES.OK) {
                $scope.uploaded = true;

                notificationService.showInfoToast($scope.translations.infoPlayerIpWhitelistUpdated);

                $scope.table.totalImported = data.saved;
                $scope.table.totalDisabled = data.disabledDuplicates.length;
                $scope.table.totalLocked = data.lockedDuplicates.length;
                $scope.table.disabledList = data.disabledDuplicates;
                $scope.table.lockedList = data.lockedDuplicates;
                $scope.table.totalDuplicates = data.duplicates;
            } else {
                $scope.uploaded = false;

                if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                    notificationService.showWarningToast($scope.translations.errorInvalidFile);
                } else {
                    notificationService.showWarningDialog($scope.translations.errorInvalidIpAddress, data.status.message);
                }
            }
        }

        function onError(error) {
            notificationService.processServerError(error);
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function initTranslations() {
            var translations = {};
            $translate(['GENERAL.WHITELIST_IPS.INFO_MESSAGES.PLAYER_IP_WHITELIST_UPDATED', 'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_FILE',
                'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_IP_ADDRESS'])
                .then(function(translation) {
                    translations.errorInvalidFile = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_FILE'];
                    translations.errorInvalidIpAddress = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_IP_ADDRESS'];
                    translations.infoPlayerIpWhitelistUpdated = translation['GENERAL.WHITELIST_IPS.INFO_MESSAGES.PLAYER_IP_WHITELIST_UPDATED'];
                }, function(translationIds) {
                    translations.errorInvalidFile = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_FILE'];
                    translations.errorInvalidIpAddress = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.INVALID_IP_ADDRESS'];
                    translations.infoPlayerIpWhitelistUpdated = translationIds['GENERAL.WHITELIST_IPS.INFO_MESSAGES.PLAYER_IP_WHITELIST_UPDATED'];
                });
            return translations;
        }
    }
]);
