app.controller('AlarmsSettingsGeneral', ['$scope', 'requestService', '$filter', 'ERROR_CODES', 'GLOBAL_CONSTANTS', 'notificationService', '$q',
    '$log', '$translate',
    function($scope, requestService, $filter, ERROR_CODES, GLOBAL_CONSTANTS, notificationService, $q, $log, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.allCasinos = [];
            $scope.emaRtps = [];
            $scope.availableCasinosForHw = [];
            $scope.hws = [];
            $scope.formErrors = {
                emaRtpForm: {}
            };
            $scope.tempData = {
                selectedCasino: null
            };

            $scope.ROOT_CASINO_ID = GLOBAL_CONSTANTS.ROOT_CASINO_ID;

            getEmaSettings();
            $scope.promise1 = getAllCasinos();
            $scope.promise2 = getHwSettings();
            $q.all([$scope.promise1, $scope.promise2]).then(setAvailableCasinos);
        }

        $scope.addSpecificCasino = function() {
            if ($scope.tempData.selectedCasino !== undefined && $scope.tempData.selectedCasino !== null) {
                // in a case when in the casino search filter was entered text, remove search term to be able to add new row in edit mode
                delete $scope.searchFilter;
                $scope.insertedCasino = {
                    casinoId: $scope.tempData.selectedCasino.id,
                    stakeWindowSize: $scope.hws[0].stakeWindowSize,
                    warningThreshold: $scope.hws[0].warningThreshold,
                    errorThreshold: $scope.hws[0].errorThreshold
                };
                $scope.hws.push($scope.insertedCasino);
                setAvailableCasinos();
            }
        };

        $scope.cancelHwSpecificCasino = function(data) {
            if (data.id === undefined) {
                for (var i = 0; i < $scope.hws.length; i++) {
                    if ($scope.hws[i].casinoId === data.casinoId) {
                        $scope.hws.splice(i, 1);
                        break;
                    }
                }
                setAvailableCasinos();
            }
        };

        $scope.saveEmaRtp = function(id, data) {
            data.id = id;
            var d = $q.defer();
            requestService.putEncoded(
                '/api/alarm-settings/update-emartp',
                data,
                function(response) {
                    if (response.status.code === 'OK') {
                        notificationService.showInfoToast($scope.translations.infoEmaRtpUpdated);
                        $scope.formErrors.emaRtpForm = {};
                        d.resolve();
                    } else if (response.status.code === 'VALIDATION_FAILED') {
                        $scope.formErrors.emaRtpForm = response.status.formErrors || {};
                        d.resolve($scope.translations.errorValidationFailed);
                    } else {
                        notificationService.processApplicationError(response.status, $scope.translations.errorSavingEmaRtpSettings);
                        d.resolve($scope.translations.errorSavingEmaRtpSettings);
                    }
                },
                function(response) {
                    d.reject($scope.translations.errorServerError);
                }
            );

            return d.promise;
        };

        $scope.createOrUpdateHighWin = function(highWinId, casinoId, highWinData) {
            highWinData.id = highWinId;
            highWinData.casinoId = casinoId;
            var d = $q.defer();
            if (highWinId === undefined) {
                requestService.postEncoded(
                    '/api/alarm-settings/create-or-update-hw',
                    highWinData,
                    function(response) {
                        if (response.status.code === 'OK') {
                            for (var i = 0; i < $scope.hws.length; i++) {
                                if ($scope.hws[i].casinoId === casinoId) {
                                    $scope.hws[i].id = response.highWinId;
                                    $scope.hws[i].formErrors = {};
                                }
                            }
                            notificationService.showInfoToast($scope.translations.infoHwAlarmSaved);
                            d.resolve();
                        } else if (response.status.code === 'VALIDATION_FAILED') {
                            for (var i = 0; i < $scope.hws.length; i++) {
                                if ($scope.hws[i].casinoId === casinoId) {
                                    $scope.hws[i].formErrors = response.status.formErrors || {};
                                }
                            }
                            d.resolve($scope.translations.errorValidationFailed);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.errorSavingHwSettings);
                            d.resolve($scope.translations.errorSavingHwSettings);
                        }
                    },
                    function(response) {
                        d.reject($scope.translations.errorServerError);
                    }
                );

                return d.promise;
            } else {
                requestService.putEncoded(
                    '/api/alarm-settings/create-or-update-hw',
                    highWinData,
                    function(response) {
                        if (response.status.code === 'OK') {
                            for (var i = 0; i < $scope.hws.length; i++) {
                                if ($scope.hws[i].casinoId === casinoId) {
                                    $scope.hws[i].formErrors = {};
                                }
                            }
                            notificationService.showInfoToast($scope.translations.infoHwAlarmUpdated);
                            d.resolve();
                        } else if (response.status.code === 'VALIDATION_FAILED') {
                            for (var i = 0; i < $scope.hws.length; i++) {
                                if ($scope.hws[i].casinoId === casinoId) {
                                    $scope.hws[i].formErrors = response.status.formErrors || {};
                                }
                            }
                            d.resolve($scope.translations.errorValidationFailed);
                        } else {
                            notificationService.processApplicationError(response.status, $scope.translations.errorSavingHwSettings);
                            d.resolve($scope.translations.errorSavingHwSettings);
                        }
                    },
                    function(response) {
                        d.reject($scope.translations.errorServerError);
                    }
                )
            }

            return d.promise;
        };

        $scope.setCasinoPathAndName = function(highWin) {
            $q.all([$scope.promise1, $scope.promise2]).then(function() {
                var selected = $filter('filter')($scope.allCasinos, {id: highWin.casinoId}, true);
                highWin.path = selected[0].path;
                highWin.name = selected[0].name;
            });
        };

        $scope.removeHwSpecificSetting = function(form, highWinId) {
            requestService.delete(
                '/api/alarm-settings/remove-hw/' + highWinId,
                function(response) {
                    if (response.status.code === 'OK') {
                        //form.$hide();
                        for (var i = 0; i < $scope.hws.length; i++) {
                            if ($scope.hws[i].id === highWinId) {
                                $scope.hws.splice(i, 1);
                                break;
                            }
                        }
                        setAvailableCasinos();
                    } else {
                        notificationService.processApplicationError(data.status, $scope.translations.errorRemovingHwSettings);
                    }
                }
            );
        };

        function getAllCasinos() {
            var deferred = $q.defer();
            requestService.get(
                '/api/enums/casino-list',
                function(response) {
                    $scope.allCasinos = response;
                    deferred.resolve(response);
                }
            );
            return deferred.promise;
        }

        function getEmaSettings() {
            requestService.get(
                '/api/alarm-settings/get-ema-settings',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.emaRtps = response.list;
                    } else {
                        $log.error(response.status);
                    }
                }
            );
        }

        function getHwSettings() {
            var deferred = $q.defer();
            requestService.get(
                '/api/alarm-settings/get-hw-settings',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.hws = response.list;
                        deferred.resolve(response.list);
                    } else {
                        $log.error(response.status);
                        deferred.reject(response.status);
                    }
                }
            );
            return deferred.promise;
        }

        function setAvailableCasinos() {
            $scope.availableCasinosForHw = angular.copy($scope.allCasinos);
            for (var i = 1; i < $scope.hws.length; i++) {
                for (var j = 0; j < $scope.availableCasinosForHw.length; j++) {
                    if ($scope.hws[i].casinoId === $scope.availableCasinosForHw[j].id) {
                        $scope.availableCasinosForHw.splice([j], 1);
                        break;
                    }
                }
            }
            $scope.tempData.selectedCasino = null;
        }

        function initTranslations() {
            var translations = {};
            $translate(['ALARM_SETTINGS.INFO_MESSAGES.EMA_RTP_UPDATED', 'ALARM_SETTINGS.ERROR_MESSAGES.VALIDATION_FAILED',
                'ALARM_SETTINGS.ERROR_MESSAGES.SAVING_EMA_RTP_SETTINGS', 'ALARM_SETTINGS.ERROR_MESSAGES.SERVER_ERROR',
                'ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_SAVED', 'ALARM_SETTINGS.ERROR_MESSAGES.SAVING_HIGH_WIN_SETTINGS',
                'ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_UPDATED', 'ALARM_SETTINGS.ERROR_MESSAGES.REMOVING_HIGH_WIN_SETTINGS'])
                .then(function(translation) {
                    translations.errorValidationFailed = translation['ALARM_SETTINGS.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorSavingEmaRtpSettings = translation['ALARM_SETTINGS.ERROR_MESSAGES.SAVING_EMA_RTP_SETTINGS'];
                    translations.errorServerError = translation['ALARM_SETTINGS.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorSavingHwSettings = translation['ALARM_SETTINGS.ERROR_MESSAGES.SAVING_HIGH_WIN_SETTINGS'];
                    translations.errorRemovingHwSettings = translation['ALARM_SETTINGS.ERROR_MESSAGES.REMOVING_HIGH_WIN_SETTINGS'];
                    translations.infoEmaRtpUpdated = translation['ALARM_SETTINGS.INFO_MESSAGES.EMA_RTP_UPDATED'];
                    translations.infoHwAlarmSaved = translation['ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_SAVED'];
                    translations.infoHwAlarmUpdated = translation['ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_UPDATED'];
                }, function(translationIds) {
                    translations.errorValidationFailed = translationIds['ALARM_SETTINGS.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorSavingEmaRtpSettings = translationIds['ALARM_SETTINGS.ERROR_MESSAGES.SAVING_EMA_RTP_SETTINGS'];
                    translations.errorServerError = translationIds['ALARM_SETTINGS.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorSavingHwSettings = translationIds['ALARM_SETTINGS.ERROR_MESSAGES.SAVING_HIGH_WIN_SETTINGS'];
                    translations.errorRemovingHwSettings = translationIds['ALARM_SETTINGS.ERROR_MESSAGES.REMOVING_HIGH_WIN_SETTINGS'];
                    translations.infoEmaRtpUpdated = translationIds['ALARM_SETTINGS.INFO_MESSAGES.EMA_RTP_UPDATED'];
                    translations.infoHwAlarmSaved = translationIds['ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_SAVED'];
                    translations.infoHwAlarmUpdated = translationIds['ALARM_SETTINGS.INFO_MESSAGES.HIGH_WIN_ALARM_UPDATED'];
                });
            return translations;
        }
    }
]);
