app.controller('AuditlogsCtrl', ['$scope', '$log', 'requestService', 'notificationService', '$translate',
    function($scope, $log, requestService, notificationService, $translate) {

        $scope.translations = initTranslations();
        init();
        getAuditLogs();

        function init() {
            $scope.formData = {};
            $scope.formErrors = {};

            $scope.onRefresh = function() {
                getAuditLogs();
            };

            $scope.table = {
                auditLogs: [],
                sortColumns: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25,
                totalSize: 0,
                sortBy: [],
                sortColumn: null,
                sortAsc: null,
                offset: 0
            };

            $scope.isSortable = function(columnName) {
                return $scope.table.sortColumns.indexOf(columnName) != -1;
            };

            // Watch for changes, then load data
            $scope.$watch('table.offset', onPageOffsetChange, true);
            $scope.$watch('table.sortBy', onSortByChange, true);
            $scope.$watch('table.pageSize', onPaginationChange, true);
        }

        function onPaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getAuditLogs();
            }
        }

        function onPageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getAuditLogs();
            }
        }

        function onSortByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.table.sortBy.length > 0) {
                    if ($scope.table.sortBy[0].charAt(0) !== '-') {
                        $scope.table.sortColumn = $scope.table.sortBy[0];
                        $scope.table.sortAsc = true;
                    } else {
                        $scope.table.sortColumn = $scope.table.sortBy[0].slice(1);
                        $scope.table.sortAsc = false;
                    }
                } else {
                    $scope.table.sortAsc = null;
                    $scope.table.sortColumn = null;
                }
                getAuditLogs();
            }
        }

        function getAuditLogs() {
            var sortColumn = "";
            if ($scope.table.sortColumn) {
                sortColumn = '&orderColumn=' + $scope.table.sortColumn;
            }
            requestService.get(
                '/api/audit/table-data' +
                '?dataOffset=' + $scope.table.offset +
                '&dataSegmentSize=' + $scope.table.pageSize +
                '&dataSize=' + $scope.table.totalSize +
                sortColumn +
                '&orderAsc=' + $scope.table.sortAsc,
                function(response) {
                    updateData(response);
                }
            );
        }

        function updateData(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);
            requestService.parseJsonInResponse(tableData, 'data');

            $scope.table.totalSize = response.dataSize;
            $scope.table.auditlogs = tableData;
            //$scope.table.sortColumns = response.sortColumns; //TODO send from server

            if (response.dataSize == 0) {
                notificationService.showInfoToast($scope.translations.infoNoDataFound);
            }
        }

        function initTranslations() {
            var translations = {};
            $translate(['AUDIT_LOGS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND'])
                .then(function(translation) {
                    translations.infoNoDataFound = translation['AUDIT_LOGS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND'];
                }, function(translationIds) {
                    translations.infoNoDataFound = translationIds['AUDIT_LOGS_OVERVIEW.INFO_MESSAGES.NO_DATA_FOUND'];
                });
            return translations;
        }
    }
]);
