'use strict';

/**
 * @ngdoc Transform routines for ElasticSearch search results to data structure expected by the drawing routine.
 * @name ngs.graph.sevices:Nvd3TransformService
 * @description
 * # Transform routines for ElasticSearch search results to data structure expected by the drawing routine.
 * # This means remove all unnecessary fields and flatten the structure as needed.
 */
app.factory('Nvd3TransformService', [
    function() {

        function orDefault(value, defaultValue) {
            if (defaultValue === null) {
                return value;
            } else {
                return value || defaultValue || 0;
            }
        }

        function getAggregatedValue(bucket, metric) {
            switch (metric) {
                case "rtp":
                    if (!bucket.aggregatedValueWin || !bucket.aggregatedValueStake) {
                        return 0;
                    } else if (!bucket.aggregatedValueWin.value || !bucket.aggregatedValueStake.value) {
                        return 0;
                    } else {
                        return bucket.aggregatedValueWin.value / bucket.aggregatedValueStake.value;
                    }
                case "count":
                    return bucket.doc_count;
                default:
                    return bucket.aggregatedValue && bucket.aggregatedValue.value;
            }
        }

        function extractSeries(buckets, metric) {

            var terms = buckets.map(function(v) {
                return v.key;
            });

            var values = {};
            buckets.forEach(function(termBucket) {
                termBucket.series.buckets.forEach(function(seriesBucket) {
                    if (!values[seriesBucket.key]) {
                        values[seriesBucket.key] = {};
                    }

                    values[seriesBucket.key][termBucket.key] = getAggregatedValue(seriesBucket, metric);
                });
            });

            return {
                series: Object.keys(values),
                terms: terms,
                values: values
            };
        }

        function extractWithoutSeries(buckets, metric, seriesName) {

            var transformMetricBuckets = function(termBucket) {
                return {
                    x: termBucket.key,
                    y: getAggregatedValue(termBucket, metric)
                };
            };

            return [{
                key: seriesName,
                values: buckets.map(transformMetricBuckets)
            }];
        }

        function transformTermsMultiMetricSearchData(data, metrics, defaultValue) {
            var struct = extractMultiMetric(data.aggregations.data.by_term.buckets, metrics);

            return mapToNvd3Format(struct, defaultValue);
        }

        function extractMultiMetric(buckets, metrics) {

            var terms = buckets.map(function(v) {
                return v.key;
            });

            var values = {};
            buckets.forEach(function(termBucket) {
                metrics.forEach(function(metric) {
                    if (!values[metric]) {
                        values[metric] = {};
                    }

                    values[metric][termBucket.key] = extractMultiMetricValue(termBucket, metric);
                });
            });

            return {
                series: Object.keys(values),
                terms: terms,
                values: values
            };
        }

        function extractMultiMetricValue(bucket, metric) {
            switch (metric) {
                case "rtp":
                    if (!bucket.aggregatedValue_rtpWin || !bucket.aggregatedValue_rtpStake) {
                        return 0;
                    } else if (!bucket.aggregatedValue_rtpWin.value || !bucket.aggregatedValue_rtpStake.value) {
                        return 0;
                    } else {
                        return bucket.aggregatedValue_rtpWin.value / bucket.aggregatedValue_rtpStake.value;
                    }
                case "count":
                    return bucket.doc_count;
                default:
                    return bucket["aggregatedValue_" + metric] && bucket["aggregatedValue_" + metric].value;
            }
        }

        function mapToNvd3Format(struct, defaultValue) {
            return struct.series.map(function(seriesKey) {
                var values = struct.terms.map(function(termsKey) {
                    return {
                        x: termsKey,
                        y: orDefault(struct.values[seriesKey][termsKey], defaultValue)
                    };
                });

                return {
                    key: seriesKey,
                    values: values
                };
            });
        }

        function transformTimeBasedSeriesData(data, metric, defaultValue) {
            var struct = extractSeries(data.aggregations.data.by_date.buckets, metric);

            return mapToNvd3Format(struct, defaultValue);
        }

        function transformTimeBasedData(data, metric, seriesName) {
            return extractWithoutSeries(data.aggregations.data.by_date.buckets, metric, seriesName);
        }

        function transformTermBasedSeriesData(data, metric, defaultValue) {
            var struct = extractSeries(data.aggregations.data.by_term.buckets, metric);

            return mapToNvd3Format(struct, defaultValue);
        }

        function transformTermBasedData(data, metric, seriesName) {
            return extractWithoutSeries(data.aggregations.data.by_term.buckets, metric, seriesName);
        }

        function onlyValues(fn) {
            return function() {
                var result = fn.apply(this, arguments);

                if (result && result.length) {
                    return result[0].values || [];
                } else {
                    return [];
                }
            };
        }

        function transformTermsSearchData(data) {
            return data.aggregations.data.terms.buckets;
        }

        var resultFunctions = {
            /**
             * Expects ES result and optionally default value and returns NVD3 formatted data
             * If defaultValue if null no default is used otherwise value || defaultValue || 0.
             * params - data, metric, defaultValue
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   defaultValue - value for missing series value
             */
            transformTimeBasedSeriesData: transformTimeBasedSeriesData,
            /**
             * Expects ES result and returns NVD3 formatted data
             * params - data, metric, seriesName
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   seriesName - name for the series that will be created from values for NVD3
             */
            transformTimeBasedData: transformTimeBasedData,

            /**
             * Expects ES result and optionally default value and return NVD3 formatted data
             * If defaultValue if null no default is used otherwise value || defaultValue || 0.
             * params - data, metric, defaultValue
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   defaultValue - value for missing series value
             */
            transformTermBasedSeriesData: transformTermBasedSeriesData,
            /**
             * Expects ES result and returns NVD3 formatted data
             * params - data, metric, seriesName
             *   data - search result from ES (raw json)
             *   metric - metric name e.g. rtp, count, winInEur
             *   seriesName - name for the series that will be created from values for NVD3
             */
            transformTermBasedData: transformTermBasedData,

            /**
             * Expects ES result and returns NVD3 formatted data
             * params - data, metrics, defaultValue
             *   data - search result from ES (raw json)
             *   metrics - array of metric names e.g. [rtp, count, winInEur]
             *   defaultValue - value for missing series value
             */
            transformTermsMultiMetricSearchData: transformTermsMultiMetricSearchData
        };

        var normal = resultFunctions;
        var flat = {
            transformTimeBasedData: onlyValues(transformTimeBasedData),
            transformTermBasedData: onlyValues(transformTermBasedData)
        };

        return {
            discreteBarChart: normal,
            historicalBarChart: normal,
            multiBarChart: normal,
            lineChart: normal,
            stackedAreaChart: normal,
            pieChart: flat,
            donutChart: flat,
            multiBarHorizontalChart: normal,
            lineWithFocusChart: normal,

            terms: {
                /**
                 * Expects ES result and returns NVD3 formatted data
                 * params - data
                 *   data - search result from ES (raw json)
                 */
                transformTermsSearchData: transformTermsSearchData
            }
        };
    }
]);
