app.controller('SessionWalletCtrl', ['$scope', '$log', '$modal', 'requestService', 'notificationService', 'GAME_PLATFORM', 'ERROR_CODES',
    'GLOBAL_CONSTANTS', '$location', '$translate',
    function($scope, $log, $modal, requestService, notificationService, GAME_PLATFORM, ERROR_CODES, GLOBAL_CONSTANTS, $location, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.tabs = [
                {name: 'ACTIVE_SESSIONS', route: 'session-wallet.active-sessions'},
                {name: 'ALL_SESSIONS', route: 'session-wallet.all-sessions'}
            ];
            $scope.searchDataActive = {
                casinoId: null,
                playerId: null,
                sessionId: null,
                onlyActive: true
            };
            $scope.searchDataAll = {
                casinoId: null,
                playerId: null,
                sessionId: null,
                onlyActive: false
            };
            $scope.searchDataActiveApplied = {};
            $scope.searchDataAllApplied = {};
            $scope.tableActive = {
                pageSizes: [1, 10, 25, 50, 100],
                totalSize: 0,
                dataOffset: 0,
                dataSegmentSize: 25,
                orderColumn: [],
                orderAsc: true
            };
            $scope.tableAll = {
                pageSizes: [1, 10, 25, 50, 100],
                totalSize: 0,
                dataOffset: 0,
                dataSegmentSize: 25,
                orderColumn: [],
                orderAsc: true
            };
            //TODO error handling
            $scope.formErrors = {};
            $scope.sessionWalletsActive = [];
            $scope.sessionWalletsAll = [];

            $scope.search = function() {
                var onlyActive = isOnlyActive();
                applyFilter(onlyActive);
                getSessionWallets(onlyActive);
            };

            $scope.onRefresh = function() {
                getSessionWallets(isOnlyActive());
            };

            $scope.disableRefreshButton = function(onlyActive) {
                if (onlyActive) {
                    return angular.equals($scope.searchDataActiveApplied, {});
                } else {
                    return angular.equals($scope.searchDataAllApplied, {});
                }
            };

            $scope.bonusAmount = function(bonuses) {
                var totalAmount = 0;
                for (var i = 0; i < bonuses.length; i++) {
                    totalAmount += bonuses[i].amount;
                }
                return totalAmount;
            };

            $scope.freeRoundsAvailable = function(freeRounds) {
                var totalAmount = 0;
                for (var i = 0; i < freeRounds.length; i++) {
                    totalAmount += freeRounds[i].availableRounds;
                }
                return totalAmount;
            };

            $scope.showDetails = function(sessionWallet) {
                $modal.open({
                    animate: true,
                    templateUrl: 'app/session_wallet/session_wallet_modal.html',
                    controller: 'SessionWalletModalCtrl',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        sessionWallet: function() {
                            return sessionWallet;
                        }
                    }
                });
            };

            // Watch for changes, then load data
            $scope.$watch('tableActive.dataOffset', onPageOffsetChange);
            $scope.$watch('tableActive.orderColumn', onOrderColumnChange, true);
            $scope.$watch('tableActive.dataSegmentSize', onPaginationChange, true);
            $scope.$watch('tableAll.dataOffset', onPageOffsetChange);
            $scope.$watch('tableAll.orderColumn', onOrderColumnChange, true);
            $scope.$watch('tableAll.dataSegmentSize', onPaginationChange, true);
        }

        function applyFilter(onlyActive) {
            if (onlyActive) {
                $scope.searchDataActiveApplied =                    angular.copy($scope.searchDataActive);
                $scope.searchDataActiveApplied.orderAsc =           angular.copy($scope.tableActive.orderAsc);
                $scope.searchDataActiveApplied.dataOffset =         angular.copy($scope.tableActive.dataOffset);
                $scope.searchDataActiveApplied.dataSegmentSize =    angular.copy($scope.tableActive.dataSegmentSize);
                $scope.searchDataActiveApplied.orderColumn =        angular.copy($scope.tableActive.orderColumn);
            } else {
                $scope.searchDataAllApplied =                       angular.copy($scope.searchDataAll);
                $scope.searchDataAllApplied.orderAsc =              angular.copy($scope.tableAll.orderAsc);
                $scope.searchDataAllApplied.dataOffset =            angular.copy($scope.tableAll.dataOffset);
                $scope.searchDataAllApplied.dataSegmentSize =       angular.copy($scope.tableAll.dataSegmentSize);
                $scope.searchDataAllApplied.orderColumn =           angular.copy($scope.tableAll.orderColumn);
            }
        }

        function isOnlyActive() {
            return $location.path().includes("active-sessions");
        }

        function onPageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (isOnlyActive()) {
                    $scope.searchDataActiveApplied.dataOffset = $scope.tableActive.dataOffset;
                    getSessionWallets(true);
                } else {
                    $scope.searchDataAllApplied.dataOffset = $scope.tableAll.dataOffset;
                    getSessionWallets(false)
                }
            }
        }

        function onPaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (isOnlyActive()) {
                    if (!angular.equals($scope.searchDataActiveApplied, {})) {
                        $scope.searchDataActiveApplied.dataSegmentSize = $scope.tableActive.dataSegmentSize;
                        $scope.searchDataActiveApplied.dataOffset = 0;
                        getSessionWallets(true);
                    }
                } else {
                    if (!angular.equals($scope.searchDataAllApplied, {})) {
                        $scope.searchDataAllApplied.dataSegmentSize = $scope.tableAll.dataSegmentSize;
                        $scope.searchDataAllApplied.dataOffset = 0;
                        getSessionWallets(false);
                    }
                }
            }
        }

        function onOrderColumnChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (isOnlyActive()) {
                    if (!angular.equals($scope.searchDataActiveApplied, {})) {
                        if ($scope.tableActive.orderColumn.length === 0) {
                            $scope.searchDataActiveApplied.orderColumn = $scope.tableActive.orderColumn;
                        } else if ($scope.tableActive.orderColumn[0].charAt(0) !== '-') {
                            $scope.searchDataActiveApplied.orderColumn = $scope.tableActive.orderColumn[0];
                            $scope.tableActive.orderAsc = true;
                        } else {
                            $scope.searchDataActiveApplied.orderColumn = $scope.tableActive.orderColumn[0].slice(1);
                            $scope.tableActive.orderAsc = false;
                        }
                        $scope.searchDataActiveApplied.orderAsc = $scope.tableActive.orderAsc;
                        getSessionWallets(true);
                    }
                } else {
                    if (!angular.equals($scope.searchDataAllApplied, {})) {
                        if ($scope.tableAll.orderColumn.length === 0) {
                            $scope.searchDataAllApplied.orderColumn = $scope.tableAll.orderColumn;
                        } else if ($scope.tableAll.orderColumn[0].charAt(0) !== '-') {
                            $scope.searchDataAllApplied.orderColumn = $scope.tableAll.orderColumn[0];
                            $scope.tableAll.orderAsc = true;
                        } else {
                            $scope.searchDataAllApplied.orderColumn = $scope.tableAll.orderColumn[0].slice(1);
                            $scope.tableAll.orderAsc = false;
                        }
                        $scope.searchDataAllApplied.orderAsc = $scope.tableAll.orderAsc;
                        getSessionWallets(false);
                    }
                }
            }
        }

        function getSessionWallets(onlyActive) {
            var requestData = {};
            if (onlyActive) {
                requestData = $scope.searchDataActiveApplied;
            } else {
                requestData = $scope.searchDataAllApplied;
            }
            requestService.postEncoded(
                '/api/session-wallet/get-session-wallets',
                requestData,
                function(data) {
                    if (data.sessionWallets.length > 0) {
                        if (onlyActive) {
                            $scope.sessionWalletsActive = data.sessionWallets;
                            $scope.tableActive.dataOffset = data.paging.dataOffset;
                            $scope.tableActive.totalSize = data.paging.dataSize;
                        } else {
                            $scope.sessionWalletsAll = data.sessionWallets;
                            $scope.tableAll.dataOffset = data.paging.dataOffset;
                            $scope.tableAll.totalSize = data.paging.dataSize;
                        }
                    } else {
                        notificationService.showInfoToast($scope.translations.noDataFound);
                    }
                }
            );
        }

        function initTranslations() {
            var translations = {};
            $translate(['GENERAL.INFO_TOASTS.NO_DATA_FOUND']).then(function(translation) {
                translations.noDataFound = translation['GENERAL.INFO_TOASTS.NO_DATA_FOUND'];
            }, function(translationIds) {
                translations.noDataFound = translationIds['GENERAL.INFO_TOASTS.NO_DATA_FOUND'];
            });
            return translations;
        }
    }
]);
