app.controller('CasinoManagementCtrl', ['$scope', '$modal', 'requestService', '$log', 'ERROR_CODES', 'GLOBAL_CONSTANTS',
    function($scope, $modal, requestService, $log, ERROR_CODES, GLOBAL_CONSTANTS) {
        $scope.formData = [];
        $scope.formErrors = [];
        $scope.table = {
            casinos: []
        };
        $scope.casinosAndGroups = [];

        init();

        function init() {
            getTableData();
            getCasinosAndGroups();
        }

        $scope.onNewCasino = function() {
            openCasinoModal(null);
        };

        $scope.onEditCasino = function(casinoId) {
            openCasinoModal(casinoId);
        };

        $scope.getParentName = function(parentId) {
            for (var i = 0; i < $scope.casinosAndGroups.length; i++) {
                if ($scope.casinosAndGroups[i].id === parentId) {
                    return $scope.casinosAndGroups[i].name;
                }
            }
        };

        function getTableData() {
            requestService.get(
                '/api/casino-management/get-casinos',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.table.casinos = response.list;
                        removeRootCasinoFromList($scope.table.casinos);
                    } else {
                        $log.error(response.status);
                    }
                }
            );
        }

        function removeRootCasinoFromList(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i]['casinoId'] === GLOBAL_CONSTANTS.ROOT_CASINO_ID) {
                    list.splice(i, 1);
                    break;
                }
            }
        }

        function getCasinosAndGroups() {
            requestService.get(
                '/api/enums/casino-list',
                function(data) {
                    $scope.casinosAndGroups = data;
                }
            );
        }

        function openCasinoModal(casinoId) {
            var modalInstance = $modal.open({
                animate: true,
                templateUrl: 'app/admin/casino_management/casino_management_modal.html',
                controller: 'CasinoManagementModalCtrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    casinoId: function() {
                        return casinoId;
                    },
                    casinosAndGroups: function() {
                        return $scope.table.casinos;
                    }
                }
            });

            modalInstance.result.then(
                // $modal.close() will get into here
                function() {
                    getTableData();
                },
                // dismissed here.
                // e.g. backdrop click, escape key press
                function() {
                    getTableData();
                });
        }
    }
]);
