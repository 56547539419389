app.controller('MarketingMaterialsSublevelFolderModalCtrl', ['$scope', '$log', '$modalInstance', '$modal', 'requestService', 'notificationService',
    'path', 'name', 'ERROR_CODES', '$q', '$translate',
    function ($scope, $log, $modalInstance, $modal, requestService, notificationService, path, name, ERROR_CODES, $q, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.formData = {
                oldName: name,
                name: name,
                parentPath: path,
                oldPath: path + "/" + name
            };
            $scope.formErrors = {};
            $scope.treeStructure = {};

            $scope.restrictedCasinosPromise = {};
            $scope.treeStructurePromise = getTreeStructure();

            $scope.onCreate = function() {
                createItem();
            };

            $scope.onSave = function() {
                saveItem();
            };

            $scope.onClose = function() {
                closeModal();
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            getAllCasinos();
            getTreeStructure();

            if (name) {
                $scope.restrictedCasinosPromise = getRestrictedCasinoIds();
            }

            $q.all([$scope.treeStructurePromise, $scope.restrictedCasinosPromise]).then(updateTreeview);
        }

        function getAllCasinos() {
            $scope.casinosList = [];
            requestService.get(
                '/api/enums/casino-list',
                function(data) {
                    $scope.casinosList = data;
                }
            );
        };

        function getTreeStructure() {
            var deferred = $q.defer();
            requestService.get(
                '/api/casino-tree',
                function(data) {
                    $scope.treeStructure = data;
                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getRestrictedCasinoIds() {
            var deferred = $q.defer();
            requestService.get('/api/files/folder?path=' + $scope.formData.parentPath + '/' + name,
                function(data) {
                    if (data.status.code === ERROR_CODES.OK) {
                        $scope.formData.restrictedCasinoIds = data.values.restrictedCasinoIds;
                        deferred.resolve();
                    } else {
                        notificationService.processApplicationError(
                            data.status,
                            $scope.translations.errorGetRestrictedCasinos,
                            cancelModal()
                        );
                        deferred.reject();
                    }
                },
                function() {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function updateTreeview() {
            if ($scope.formData.restrictedCasinoIds && $scope.formData.restrictedCasinoIds.length > 0) {
                for (var i = 0; i < $scope.formData.restrictedCasinoIds.length; i++) {
                    selectNode($scope.treeStructure, $scope.formData.restrictedCasinoIds[i]);
                }
            }
            $scope.treeStructureFinal = $scope.treeStructure;
        }

        function selectNode(node, casinoId) {
            if (node.id === casinoId) {
                node.selected = true;
            } else {
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        selectNode(node.children[i], casinoId);
                    }
                }
            }
        }

        function getSelectedRestrictedCasinos() {
            var selectedCasinos = [];
            getChildCasinos($scope.treeStructure, selectedCasinos);
            return selectedCasinos;
        }

        function getChildCasinos(casino, array) {
            if (casino.selected === true) {
                array.push(casino.id);
            }
            if (casino.children && casino.children.length > 0) {
                for (var i = 0; i < casino.children.length; i++) {
                    if (casino.children[i].children && casino.children[i].children.length > 0) {
                        getChildCasinos(casino.children[i], array);
                    } else {
                        if (casino.children[i].selected === true) {
                            array.push(casino.children[i].id);
                        }
                    }
                }
            }
        }

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function createItem() {
            $scope.formData.path = $scope.formData.parentPath + "/" + $scope.formData.name;
            requestService.postEncoded('/api/files/folder-create',
                $scope.formData,
                function(data) {
                    onSuccess(data);
                },
                function(error) {
                    onError(error);
                }
            );
        }

        function saveItem() {
            $scope.formData.path = $scope.formData.parentPath + "/" + $scope.formData.name;
            requestService.putEncoded('/api/files/folder-update',
                $scope.formData,
                function(data) {
                    onSuccess(data);
                },
                function(error) {
                    onError(error);
                }
            );
        }

        function onError(error) {
            $scope.formErrors = {};
            notificationService.processServerError(error);
        }

        function onSuccess(data) {
            $scope.progress = null;
            $scope.formErrors = data.status.formErrors || {};

            if (data.status.code === ERROR_CODES.OK) {
                notificationService.showInfoToast($scope.translations.errorInfoFolderStored);
                closeModal();
            } else {
                if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                    notificationService.showWarningToast($scope.translations.infoInvalidForm);
                } else {
                    notificationService.processApplicationError(data.status, $scope.translations.errorStoringFolder);
                }
            }
        }

        function initTranslations() {
            var translations = {};
            $translate(['MARKETING_MATERIALS.ERROR_MESSAGES.GET_RESTRICTED_CASINOS', 'MARKETING_MATERIALS.INFO_FOLDER_STORED',
                'GENERAL.INFO_TOASTS.INVALID_FORM', 'MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'])
                .then(function(translate) {
                    translations.errorGetRestrictedCasinos = translate['MARKETING_MATERIALS.ERROR_MESSAGES.GET_RESTRICTED_CASINOS'];
                    translations.errorStoringFolder = translate['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'];
                    translations.errorInfoFolderStored = translate['MARKETING_MATERIALS.INFO_FOLDER_STORED'];
                    translations.infoInvalidForm = translate['GENERAL.INFO_TOASTS.INVALID_FORM'];
                }, function(translationIds) {
                    translations.errorGetRestrictedCasinos = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.GET_RESTRICTED_CASINOS'];
                    translations.errorStoringFolder = translationIds['MARKETING_MATERIALS.ERROR_MESSAGES.STORING_FOLDER'];
                    translations.errorInfoFolderStored = translationIds['MARKETING_MATERIALS.INFO_FOLDER_STORED'];
                    translations.infoInvalidForm = translationIds['GENERAL.INFO_TOASTS.INVALID_FORM'];
                });
            return translations;
        }
    }
]);
