app.controller('AnalyticsCtrl', ['$scope', '$q', '$log', '$filter', '$timeout', '$interval', '$modal', 'requestService', 'notificationService',
    'analyticsService', 'cacheService', 'store', 'userSettingsService', 'Nvd3TransformService', 'TabularTransformService', 'QUERY_TYPE',
    'ERROR_CODES', '$translate',
    function($scope, $q, $log, $filter, $timeout, $interval, $modal, requestService, notificationService, analyticsService, cacheService, store,
        userSettingsService, Nvd3TransformService, TabularTransformService, QUERY_TYPE, ERROR_CODES, $translate) {

        $scope.translations = initTranslations();

        // Mount scope
        var DASHBOARD_LIMIT = 24;
        $scope.QUERY_TYPE = QUERY_TYPE;

        // Init
        initDashboard();
        initWidgetFunctions();
        registerEvents();

        // Restore data
        getStoredDashboard();

        function registerEvents() {
            // Register resize on window resize event
            angular.element(window).on('resize', function(e) {
                $scope.$broadcast('resize');
            });

            $scope.$on('$destroy', function() {
                // Make sure that the interval is destroyed too
                stopRefreshTimer();
            });
        }

        function openWidgetModal(searchQuery, onSuccess, onCancel) {
            var modalTexts = {
                okText: $scope.translations.modalButtonOk,
                okClass: 'btn-success'
            };
            _openWidgetModal(searchQuery, onSuccess, onCancel, modalTexts);
        }

        function openExportWidgetModal(searchQuery, onSuccess, onCancel) {
            var modalTexts = {
                okText: $scope.translations.modalButtonExport,
                okClass: 'btn-info'
            };
            _openWidgetModal(searchQuery, onSuccess, onCancel, modalTexts);
        }

        function _openWidgetModal(searchQuery, onSuccess, onCancel, modalTexts) {
            $scope.modalOnSuccess = onSuccess;
            $scope.modalOnCancel = onCancel;
            $scope.modalTexts = angular.copy(modalTexts);
            $scope.modalSearchQuery = searchQuery ? angular.copy(searchQuery) : {};
            $scope.formErrors = {};

            $scope.analyticsModal = $modal.open({
                animation: true,
                templateUrl: 'app/analytics/analytics_modal.html',
                scope: $scope,
                controller: 'AnalyticsModalInstanceCtrl',
                backdrop: true,
                size: 'lg',
                resolve: {}
            });

            $scope.analyticsModal.result.then(
                function() {
                    $scope.addingWidget = false;
                    $scope.modalOpened = false;
                },
                function() {
                    $scope.addingWidget = false;
                    $scope.modalOpened = false;
                }
            );
        }

        function initDashboard() {
            /*
             Client data structure
             -- dashboards
             ---- dashboard
             ---- refreshInterval
             ------ graphs
             -------- type => structure
             -------- searchQuery
             -------- graph
             ------------ title
             ------------ options
             ------------ data
             ------------ api
             */
            $scope.refreshIntervals = analyticsService.getRefreshIntervals();
            $scope.limit = DASHBOARD_LIMIT;

            $scope.dashboard = {
                refreshInterval: $scope.refreshIntervals[0].value,
                graphs: []
            };

            // Dashboard grid config
            $scope.gridsterOptions = {
                margins: [15, 15],
                columns: 4,
                defaultSizeX: 2,
                defaultSizeY: 1,
                mobileBreakPoint: 992,
                mobileModeEnabled: true,
                swapping: true,
                pushing: true,
                draggable: {
                    handle: '.box-header',
                    stop: function(event, $element, widget) {
                        $timeout(function() {
                            if (widget.api && widget.api.update) {
                                widget.api.update();
                            }
                            storeUserDashboard();
                        }, 500)
                    }
                },
                resizable: {
                    enabled: true,
                    handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],
                    // Resize
                    resize: function(event, $element, widget) {
                        if (widget.api && widget.api.update) {
                            widget.api.update();
                        }
                    },
                    // Optional callback fired when item is finished resizing
                    stop: function(event, $element, widget) {
                        $timeout(function() {
                            if (widget.api && widget.api.update) {
                                widget.api.update();
                            }
                            storeUserDashboard();
                        }, 500)
                    }
                }
            };

            $scope.addingWidget = false;

            $scope.onClearDashboard = function() {
                $scope.dashboard.graphs = [];
                storeUserDashboard();
            };

            $scope.onRefreshDashboard = function() {
                refreshDashboardData($scope.dashboard);
            };

            $scope.graphConfig = analyticsService.getGraphDefaultConfig();

            $scope.graphEvents = {
                resize: function(e, scope) {
                    $timeout(function() {
                        if (scope.api && scope.api.update) {
                            scope.api.update();
                        }
                    }, 500)
                }
            };

            $scope.onAddWidget = function(referenceSearchQuery) {
                if ($scope.dashboard.graphs.length < DASHBOARD_LIMIT) {
                    openWidgetModal(referenceSearchQuery, function(searchQuery) {
                        var isTimeSelected = angular.isDefined(searchQuery.aggregationInterval);
                        var isTermSelected = angular.isDefined(searchQuery.term);
                        var isMetricSelected = angular.isDefined(searchQuery.metric);
                        var graphDefinition = searchQuery.graphDefinition;

                        if (isTermSelected) {
                            if (isMetricSelected) {
                                if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_HISTOGRAM) !== -1) {
                                    addTermHistogram(searchQuery);
                                }
                            } else {
                                if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_LIST) !== -1) {
                                    addTermList(searchQuery);
                                }
                            }
                        } else if (isTimeSelected) {
                            if (graphDefinition.types.indexOf(QUERY_TYPE.TIME_HISTOGRAM) !== -1) {
                                addTimeHistogram(searchQuery);
                            }
                        }
                    });
                    $scope.addingWidget = true;
                } else {
                    notificationService.showWarningToast($scope.translations.errorTooManyCharts);
                }
            };

            $scope.onExportGraphDirectly = function() {
                openExportWidgetModal(null, function(searchQuery) {
                    var queryType = getQueryType(searchQuery);
                    generateExport(queryType, searchQuery);
                })
            };

            $scope.onRefreshIntervalChange = function() {
                //$log.info($scope.dashboard.refreshInterval);
                if ($scope.dashboard.refreshInterval > 0) {
                    stopRefreshTimer();
                    startRefreshTimer();
                } else {
                    stopRefreshTimer();
                }
                storeUserDashboard();
            };
        }

        function getQueryType(searchQuery) {
            var isTimeSelected = angular.isDefined(searchQuery.aggregationInterval);
            var isTermSelected = angular.isDefined(searchQuery.term);
            var isMetricSelected = angular.isDefined(searchQuery.metric);
            var graphDefinition = searchQuery.graphDefinition;
            var queryType = null;

            if (isTermSelected) {
                if (isMetricSelected) {
                    if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_HISTOGRAM) !== -1) {
                        queryType = QUERY_TYPE.TERM_HISTOGRAM;
                    }
                } else {
                    if (graphDefinition.types.indexOf(QUERY_TYPE.TERM_LIST) !== -1) {
                        queryType = QUERY_TYPE.TERM_LIST;
                    }
                }
            } else if (isTimeSelected) {
                if (graphDefinition.types.indexOf(QUERY_TYPE.TIME_HISTOGRAM) !== -1) {
                    queryType = QUERY_TYPE.TIME_HISTOGRAM;
                }
            }
            return queryType;
        }

        function addTermList(searchQuery) {
            var queryType = QUERY_TYPE.TERM_LIST;
            var title = buildTitle(searchQuery);
            var listOptions = {
                queryType: queryType,
                searchQuery: searchQuery,
                title: title,
                options: {
                    chart: {
                        type: "table"
                    }
                }
            };

            var newWidget = angular.copy(listOptions);
            $scope.dashboard.graphs.push(newWidget);

            refreshGraphData(newWidget).then(function() {
                storeUserDashboard();
            });
        }

        function addTermHistogram(searchQuery) {
            var queryType = QUERY_TYPE.TERM_HISTOGRAM;
            var newWidget = angular.copy(createWidgetDefinition(searchQuery.graphDefinition, searchQuery, queryType));
            $scope.dashboard.graphs.push(newWidget);
            refreshGraphData(newWidget).then(function() {
                storeUserDashboard();
            });
        }

        function addTimeHistogram(searchQuery) {
            var queryType = QUERY_TYPE.TIME_HISTOGRAM;
            var newWidget = angular.copy(createWidgetDefinition(searchQuery.graphDefinition, searchQuery, queryType));
            $scope.dashboard.graphs.push(newWidget);
            refreshGraphData(newWidget).then(function() {
                storeUserDashboard();
            });
        }

        var refreshTimer;

        function startRefreshTimer() {
            refreshTimer = $interval(function() {
                //$log.debug('Start data refresh');
                refreshDashboardData($scope.dashboard);

                if ($scope.dashboard.refreshInterval <= 0) {
                    stopRefreshTimer();
                }
            }, $scope.dashboard.refreshInterval);
        }

        function stopRefreshTimer() {
            //$log.debug('Stop data refresh');
            if (angular.isDefined(refreshTimer)) {
                $interval.cancel(refreshTimer);
                refreshTimer = undefined;
            }
        }

        function initWidgetFunctions() {
            $scope.onRefreshGraph = function(graph) {
                refreshGraphData(graph);
            };

            $scope.onEditGraph = function(widget) {
                openWidgetModal(widget.searchQuery, function(searchQuery) {
                    var queryType = getQueryType(searchQuery);
                    var newWidget = createWidgetDefinition(searchQuery.graphDefinition, searchQuery, queryType);

                    // Extend/Copy doesn't work correctly, in case there was a switch to time based histograms, date starts incorrectly at 1970 ignores (values)
                    // angular.extend(widget, newWidget);

                    widget.data = null;
                    widget.queryType = newWidget.queryType;
                    widget.searchQuery = newWidget.searchQuery;
                    widget.options = newWidget.options;

                    refreshGraphData(widget);
                    storeUserDashboard();
                })
            };

            $scope.onCloneGraph = function(widget) {
                var searchQuery = angular.copy(widget.searchQuery);
                $scope.onAddWidget(searchQuery);
            };

            $scope.onRemoveGraph = function(widget) {
                $scope.dashboard.graphs.splice($scope.dashboard.graphs.indexOf(widget), 1);
                storeUserDashboard();
            };

            $scope.onExportGraph = function(widget) {
                generateExport(widget.queryType, widget.searchQuery);
            };
        }

        function getStoredDashboard() {
            userSettingsService.get('ngb.dashboard', function(storedDashboard) {
                if (storedDashboard) {
                    // Fill missing graph options removed during storage to DB (for optimization)
                    reloadDashboardGraphOptions(storedDashboard);
                    $scope.dashboard = storedDashboard;
                    for (var i = 0; i < $scope.dashboard.graphs.length; i++) {
                        $scope.dashboard.graphs[i].initialized = false;
                    }
                    graphInitializationIterator($scope.dashboard, 0);

                    if ($scope.dashboard.refreshInterval > 0) {
                        stopRefreshTimer();
                        startRefreshTimer();
                    }
                }
            });
        }

        function storeUserDashboard() {
            //$log.debug("STORE dashboard");
            var minifiedGraphs = [];
            angular.forEach($scope.dashboard.graphs, function(item) {
                var minified = angular.copy(item);
                delete minified.data;
                delete minified.options;
                delete minified.title;
                // Remains searchQuery and gridster options
                minifiedGraphs.push(minified);
            });
            var dashboardToStore = {
                refreshInterval: $scope.dashboard.refreshInterval,
                graphs: minifiedGraphs
            };
            userSettingsService.put('ngb.dashboard', dashboardToStore);
        }

        function reloadDashboardGraphOptions(dashboard) {
            angular.forEach(dashboard.graphs, function(widget) {
                var queryType = widget.queryType;
                if (angular.isDefined(queryType)) {
                    var searchQuery = widget.searchQuery;
                    var title = buildTitle(searchQuery);
                    var xAxisLabel = $filter('camelCaseToHuman')(searchQuery.aggregationInterval || searchQuery.term);
                    var yAxisLabel = $scope.translations[searchQuery.metric];

                    // Recalculate title and options
                    angular.extend(widget, {
                        title: title,
                        headline: widget.searchQuery.graphDefinition.title,
                        options: analyticsService.getGraphDefaultOptions(
                            searchQuery.graphDefinition,
                            queryType,
                            title,
                            xAxisLabel,
                            yAxisLabel,
                            searchQuery.aggregationInterval
                        )
                    });
                }
            });
        }

        function refreshDashboardData(dashboard) {
            angular.forEach(dashboard.graphs, function(graph) {
                refreshGraphData(graph);
            })

        }

        function refreshGraphData(widget) {
            var deferred =  $q.defer();
            var queryType = widget.queryType;
            var searchQuery = widget.searchQuery;
            widget['initialized'] = false;
            widget['failed'] = false;

            if (queryType.indexOf(QUERY_TYPE.TERM_HISTOGRAM) !== -1) {
                getTermHistogramData(searchQuery).then(function(transformedData) {
                    processTransformedData(transformedData, widget, false);
                    deferred.resolve();
                });
            } else if (queryType.indexOf(QUERY_TYPE.TIME_HISTOGRAM) !== -1) {
                getTimeHistogramData(searchQuery).then(function(transformedData) {
                    processTransformedData(transformedData, widget, false);
                    deferred.resolve();
                });
            } else if (queryType.indexOf(QUERY_TYPE.TERM_LIST) !== -1) {
                getTermListData(searchQuery).then(function(transformedData) {
                    processTransformedData(transformedData, widget, true);
                    deferred.resolve();
                });
            }
            return deferred.promise;
        }

        function processTransformedData(transformedData, widget, isTermList) {
            if (transformedData.timed_out === true || transformedData.failed > 0) {
                widget['data'] = null;
                widget['failed'] = true;
                widget['initialized'] = false;
            } else {
                widget['data'] = transformedData;
                if (!isTermList && widget.api && widget.api.updateWithData) {
                    widget.api.updateWithData(transformedData);
                }
                widget['failed'] = false;
                widget['initialized'] = true;
            }
        }

        function graphInitializationIterator(dashboard, index) {
            if (index < dashboard.graphs.length) {
                refreshGraphData(dashboard.graphs[index]).then(function() {
                    graphInitializationIterator(dashboard, index + 1);
                });
            }
        }

        function getTimeHistogramData(searchQuery) {
            var deferred = $q.defer();
            var requestData = {
                title: buildTitle(searchQuery),
                type: QUERY_TYPE.TIME_HISTOGRAM,
                aggregationInterval: searchQuery.aggregationInterval,
                series: searchQuery.series,
                metric: searchQuery.metric
            };
            addTimeIntervalToRequest(requestData, searchQuery);

            requestService.postEncoded(
                '/api/analytics/time-histogram',
                requestData,
                function(response) {

                    if (response.status) {
                        $scope.formErrors = response.status.formErrors;
                        resolveErrorResponse(deferred, response);
                    } else {
                        logDataIncomplete(requestData.title, response);

                        $scope.formErrors = {};

                        var typeOfGraph = searchQuery.graphDefinition.type;
                        var isSeriesBased = angular.isDefined(requestData.series) && angular.isString(requestData.series);

                        var transformedData = transformData(typeOfGraph, response, requestData.metric, isSeriesBased, true);
                        transformedData["timed_out"] = response.timed_out;
                        transformedData["failed"] = response["_shards"].failed;
                        deferred.resolve(transformedData);
                    }
                },
                function(error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise
        }

        function getTermHistogramData(searchQuery) {
            var deferred = $q.defer();
            var requestData = {
                title: buildTitle(searchQuery),
                type: QUERY_TYPE.TERM_HISTOGRAM,
                term: searchQuery.term,
                series: searchQuery.series,
                metric: searchQuery.metric
            };
            addTimeIntervalToRequest(requestData, searchQuery);

            requestService.postEncoded(
                '/api/analytics/term-histogram',
                requestData,
                function(response) {
                    if (response.status) {
                        $scope.formErrors = response.status.formErrors;
                        resolveErrorResponse(deferred, response);
                    } else {
                        logDataIncomplete(requestData.title, response);
                        $scope.formErrors = {};

                        var isSeriesBased = angular.isDefined(requestData.series) && angular.isString(requestData.series);
                        var typeOfGraph = searchQuery.graphDefinition.type;

                        var transformedData = transformData(typeOfGraph, response, requestData.metric, isSeriesBased, false);
                        transformedData["timed_out"] = response.timed_out;
                        transformedData["failed"] = response["_shards"].failed;
                        deferred.resolve(transformedData);
                    }
                },
                function(error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise
        }

        function getTermListData(searchQuery) {
            var deferred = $q.defer();
            var requestData = {
                title: buildTitle(searchQuery),
                type: QUERY_TYPE.TERM_LIST,
                term: searchQuery.term
            };
            addTimeIntervalToRequest(requestData, searchQuery);

            requestService.postEncoded(
                '/api/analytics/term-search',
                requestData,
                function(response) {
                    if (response.status) {
                        $scope.formErrors = response.status.formErrors;
                        resolveErrorResponse(deferred, response);
                    } else {
                        logDataIncomplete(requestData.title, response);
                        $scope.formErrors = {};

                        var transformedData = Nvd3TransformService.terms.transformTermsSearchData(response);
                        transformedData["timed_out"] = response.timed_out;
                        transformedData["failed"] = response["_shards"].failed;
                        deferred.resolve(transformedData);
                    }
                },
                function(error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise
        }

        function logDataIncomplete(title, response) {
            if (response.timed_out === true || response._shards.failed > 0) {
                console.warn("Graph initialized: "
                    + title
                    + "\nSHARDS "
                    + "\nTotal: " + response._shards.total
                    + "\nSuccessful: " + response._shards.successful
                    + "\nFailed: " + response._shards.failed);
            }
        }

        function addTimeIntervalToRequest(requestData, searchQuery) {
            if (searchQuery.relativeTimeInterval) {
                requestData['relativeTimeFrom'] = searchQuery.relativeTimeInterval.from;
                requestData['relativeTimeTo'] = searchQuery.relativeTimeInterval.to;
            }

            if (searchQuery.absoluteTimeInterval) {
                requestData['absoluteTimeFrom'] = searchQuery.absoluteTimeInterval.from;
                requestData['absoluteTimeTo'] = searchQuery.absoluteTimeInterval.to;
            }
        }

        function resolveErrorResponse(deferred, response) {
            if (response.status.code === ERROR_CODES.VALIDATION_FAILED) {
                notificationService.showWarningToast($scope.translations.errorInvalidSearchQuery);
                deferred.reject($scope.translations.errorValidation);
            } else {
                notificationService.processApplicationError(response.status, $scope.translations.errorRetrievingData
                );
            }
            deferred.reject($scope.translations.errorNoDataRetrieved);
        }

        function createWidgetDefinition(graphDefinition, searchQuery, queryType) {
            var title = buildTitle(searchQuery);
            var xAxisLabel = $filter('camelCaseToHuman')(searchQuery.aggregationInterval || searchQuery.term);
            var yAxisLabel = $filter('camelCaseToHuman')(searchQuery.metric);
            return {
                queryType: queryType,
                searchQuery: searchQuery,
                title: title,
                options: analyticsService.getGraphDefaultOptions(
                    graphDefinition,
                    queryType,
                    title,
                    xAxisLabel,
                    yAxisLabel,
                    searchQuery.aggregationInterval
                )
            };
        }

        function buildTitle(searchQuery) {
            var sq = searchQuery;

            var showPart = ((sq.metric) ? 'Show ' + $filter('camelCaseToHuman')(sq.metric) + ' per ' : '');
            var whatPart = $filter('camelCaseToHuman')((sq.aggregationInterval || sq.term));
            var groupByPart = ((sq.series) ? 'group by ' + $filter('camelCaseToHuman')(sq.series) : '');

            var forPart = 'for ';
            if (sq.relativeTimeInterval) {
                forPart += sq.relativeTimeInterval.title;
            }
            if (sq.absoluteTimeInterval && sq.absoluteTimeInterval.from != null && sq.absoluteTimeInterval.to != null) {
                forPart += $filter('dateFilterShortFull')(sq.absoluteTimeInterval.from) + ' - ' + $filter('dateFilterShortFull')(
                        sq.absoluteTimeInterval.to);
            }

            return [showPart, whatPart, groupByPart, forPart].join(' ');
        }

        function buildTitleForExport(searchQuery) {
            var dateFormatted = $filter('dateFilterShort')(new Date());
            return [buildTitle(searchQuery), dateFormatted].join('_');
        }

        function transformData(typeOfGraph, rawData, metric, isSeriesBased, isTimeBased) {
            var isTable = typeOfGraph === 'table';
            var transformationObject;

            if (isTable) {
                // Use table transformation service
                transformationObject = TabularTransformService;
            } else {
                // Use histogram transformation service
                transformationObject = Nvd3TransformService[typeOfGraph];
            }

            if (isSeriesBased) {
                if (isTimeBased) {
                    return transformationObject.transformTimeBasedSeriesData(rawData, metric);
                } else {
                    return transformationObject.transformTermBasedSeriesData(rawData, metric);
                }
            } else {
                if (isTimeBased) {
                    return transformationObject.transformTimeBasedData(rawData, metric, $scope.translations.values);
                } else {
                    return transformationObject.transformTermBasedData(rawData, metric, $scope.translations.values);
                }
            }
        }

        function generateExport(queryType, searchQuery) {
            var title = buildTitleForExport(searchQuery);

            var requestData = {
                title: title,
                type: queryType,
                aggregationInterval: searchQuery.aggregationInterval,
                term: searchQuery.term,
                series: searchQuery.series,
                metric: searchQuery.metric
            };
            addTimeIntervalToRequest(requestData, searchQuery);

            requestService.postEncoded(
                '/api/analytics/export-link',
                requestData,
                function(data) {
                    $scope.formErrors = data.status.formErrors || {};

                    if (data.status.code === ERROR_CODES.OK) {
                        var url = '/api/analytics/export-generate?link=' +
                            data.link +
                            '&' + requestService.getUrlEncodedRequest(requestData);
                        download(url);

                    } else {
                        if (data.status.code === ERROR_CODES.VALIDATION_FAILED) {
                            notificationService.showWarningToast($scope.translations.errorInvalidData);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorGeneratingExportLink);
                        }
                    }
                }
            );
        }

        function download(url) {
            $("#downloadFormPoster").remove();
            $("<div id='downloadFormPoster' style='display: none;'><iframe name='downloadFormPosterIframe'></iframe></div>").appendTo('body');
            $("<form action='" + url + "' target='downloadFormPosterIframe' method='post'></form>")
                .appendTo("#downloadFormPoster")
                .submit();
        }

        function initTranslations() {
            var translations = {};
            $translate(['ANALYTICS.WIDGET.METRICS.WIN_IN_EUR', 'ANALYTICS.WIDGET.METRICS.HOLD_IN_EUR', 'ANALYTICS.WIDGET.METRICS.STAKE_IN_EUR',
                'ANALYTICS.WIDGET.VALUES', 'ANALYTICS.MODAL.BUTTON_OK', 'ANALYTICS.MODAL.BUTTON_EXPORT', 'ANALYTICS.ERROR_MESSAGES.TOO_MANY_CHARTS',
                'ANALYTICS.ERROR_MESSAGES.INVALID_SEARCH_QUERY', 'ANALYTICS.ERROR_MESSAGES.VALIDATION_ERROR',
                'ANALYTICS.ERROR_MESSAGES.RETRIEVING_DATA', 'ANALYTICS.ERROR_MESSAGES.NO_DATA_RETRIEVED', 'ANALYTICS.ERROR_MESSAGES.INVALID_DATA',
                'ANALYTICS.ERROR_MESSAGES.GENERATING_EXPORT_LINK'
            ])
                .then(function (translation) {
                    translations.winInEur = translation['ANALYTICS.WIDGET.METRICS.WIN_IN_EUR'];
                    translations.holdInEur = translation['ANALYTICS.WIDGET.METRICS.HOLD_IN_EUR'];
                    translations.stakeInEur = translation['ANALYTICS.WIDGET.METRICS.STAKE_IN_EUR'];
                    translations.values = translation['ANALYTICS.WIDGET.VALUES'];
                    translations.modalButtonOk = translation['ANALYTICS.MODAL.BUTTON_OK'];
                    translations.modalButtonExport = translation['ANALYTICS.MODAL.BUTTON_EXPORT'];
                    translations.errorTooManyCharts = translation['ANALYTICS.ERROR_MESSAGES.TOO_MANY_CHARTS'];
                    translations.errorInvalidSearchQuery = translation['ANALYTICS.ERROR_MESSAGES.INVALID_SEARCH_QUERY'];
                    translations.errorValidation = translation['ANALYTICS.ERROR_MESSAGES.VALIDATION_ERROR'];
                    translations.errorRetrievingData = translation['ANALYTICS.ERROR_MESSAGES.RETRIEVING_DATA'];
                    translations.errorNoDataRetrieved = translation['ANALYTICS.ERROR_MESSAGES.NO_DATA_RETRIEVED'];
                    translations.errorInvalidData = translation['ANALYTICS.ERROR_MESSAGES.INVALID_DATA'];
                    translations.errorGeneratingExportLink = translation['ANALYTICS.ERROR_MESSAGES.GENERATING_EXPORT_LINK'];
                }, function (translationIds) {
                    translations.winInEur = translationIds['ANALYTICS.WIDGET.METRICS.WIN_IN_EUR'];
                    translations.holdInEur = translationIds['ANALYTICS.WIDGET.METRICS.HOLD_IN_EUR'];
                    translations.stakeInEur = translationIds['ANALYTICS.WIDGET.METRICS.STAKE_IN_EUR'];
                    translations.values = translationIds['ANALYTICS.WIDGET.VALUES'];
                    translations.modalButtonOk = translationIds['ANALYTICS.MODAL.BUTTON_OK'];
                    translations.modalButtonExport = translationIds['ANALYTICS.MODAL.BUTTON_EXPORT'];
                    translations.errorTooManyCharts = translationIds['ANALYTICS.ERROR_MESSAGES.TOO_MANY_CHARTS'];
                    translations.errorInvalidSearchQuery = translationIds['ANALYTICS.ERROR_MESSAGES.INVALID_SEARCH_QUERY'];
                    translations.errorValidation = translationIds['ANALYTICS.ERROR_MESSAGES.VALIDATION_ERROR'];
                    translations.errorRetrievingData = translationIds['ANALYTICS.ERROR_MESSAGES.RETRIEVING_DATA'];
                    translations.errorNoDataRetrieved = translationIds['ANALYTICS.ERROR_MESSAGES.NO_DATA_RETRIEVED'];
                    translations.errorInvalidData = translationIds['ANALYTICS.ERROR_MESSAGES.INVALID_DATA'];
                    translations.errorGeneratingExportLink = translationIds['ANALYTICS.ERROR_MESSAGES.GENERATING_EXPORT_LINK'];
                });

            // translations.winInEur = $translate.instant('ANALYTICS.WIDGET.METRICS.WIN_IN_EUR');
            return translations;
        }
    }
]);
