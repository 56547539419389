app.controller('AlarmSettingsCtrl', ['$scope', '$modal',
    function($scope, $modal) {
        $scope.tabs = [
            {name: 'GENERAL', route: 'alarm-settings.general'},
            {name: 'RECIPIENTS', route: 'alarm-settings.recipients'},
            {name: 'EMAIL_TEMPLATES', route: 'alarm-settings.email.ema'},
            {name: 'SMS_TEMPLATES', route: 'alarm-settings.sms.ema'}
        ];

        $scope.openHelpModal = function(alarmType) {
            $modal.open({
                animate: true,
                templateUrl: 'app/admin/alarm_settings/tabs/help_modal.html',
                controller: 'AlarmSettingsHelpModalCtrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    alarmType: function() {
                        return alarmType;
                    }
                }
            })
        };

        $scope.activeTab = "GENERAL";

        $scope.tabClicked = function(tab) {
            $scope.activeTab = tab;
        };
    }
]);
