app.controller('WhitelistModalCtrl', ['$scope', '$modalInstance', '$modal', 'requestService', 'notificationService', 'ERROR_CODES', 'getUrl',
    'postUrl', 'deleteUrl', 'IP_TYPES', '$q', 'casinoId', 'uploadCsvUrl', 'downloadCsvUrl', 'downloadXlsUrl', 'deleteAllUrls', 'deleteUnlockedUrls', 'inReadMode', '$timeout',
    '$translate',
    function ($scope, $modalInstance, $modal, requestService, notificationService, ERROR_CODES, getUrl, postUrl, deleteUrl, IP_TYPES, $q,
        casinoId, uploadCsvUrl, downloadCsvUrl, downloadXlsUrl, deleteAllUrls, deleteUnlockedUrls, inReadMode, $timeout, $translate) {

        $scope.translations = initTranslations();
        init();

        function init() {
            $scope.table = {
                whitelistIps: [],
                sortColumns: [],
                pageSizes: [1, 10, 25, 50, 100],
                pageSize: 25,
                totalSize: 0,
                sortBy: [],
                sortColumn: null,
                sortAsc: null,
                offset: 0
            };

            $scope.inReadMode = inReadMode;

            $scope.getUrl = getUrl;
            $scope.postUrl = postUrl;
            $scope.deleteUrl = deleteUrl;

            if (casinoId !== null) {
                $scope.deleteAllUrls = deleteAllUrls + '/' + casinoId;
                $scope.deleteUnlockedUrls = deleteUnlockedUrls +  '/' + casinoId;
            } else {
                $scope.deleteAllUrls = deleteAllUrls;
                $scope.deleteUnlockedUrls = deleteUnlockedUrls;
            }
            $scope.uploadCsvUrl = uploadCsvUrl;
            $scope.downloadCsvUrl = downloadCsvUrl;
            $scope.downloadXlsUrl = downloadXlsUrl;
            $scope.casinoId = casinoId;

            $scope.filters = {};
            $scope.filterOptions = {};
            $scope.selectedFilters = {
                ipAddress: "",
                countryId: null,
                enabled: null,
                locked: null,
                note: "",
                ipType: []
            };
            $scope.filterExpanded = false;

            $scope.tempData = {
                selectedWhitelistIp: null
            };

            $scope.applyFilters = function() {
                $scope.filters = {
                    ipAddress: $scope.selectedFilters.ipAddress,
                    countryId: $scope.selectedFilters.countryId,
                    enabled: $scope.selectedFilters.enabled,
                    locked: $scope.selectedFilters.locked,
                    note: $scope.selectedFilters.note,
                    ipType: $scope.selectedFilters.ipType
                };
                getWhitelistIps();
            };

            $scope.addWhitelistIp = function() {
                $scope.insertedWhitelistIp = {
                    id: null,
                    ipAddress: "",
                    note: "",
                    enabled: true,
                    locked: true,
                    ipType: "",
                    editing: true
                };
                $scope.table.whitelistIps.push($scope.insertedWhitelistIp );
            };

            $scope.onEditingWhitelist = function(data) {
                var store = angular.copy(data);
                data.store = store;
                data.editing = true;
            };

            $scope.onCancelWhitelistEditing = function(whitelistIp) {
                if (whitelistIp.id) {
                    whitelistIp.enabled = whitelistIp.store.enabled;
                    whitelistIp.locked = whitelistIp.store.locked;
                    delete whitelistIp.store;
                } else {
                    for (var i = 0; i < $scope.table.whitelistIps.length; i++) {
                        if ($scope.table.whitelistIps[i].$$hashKey === whitelistIp.$$hashKey) {
                            $scope.table.whitelistIps.splice(i, 1);
                            break;
                        }
                    }

                }
                whitelistIp.editing = false;
                //cancelWhitelistEditing(whitelistIp);
            };

            $scope.onCancel = function() {
                cancelModal();
            };

            $scope.filterUpdate = function(option, array, checked){
                if (checked) {
                    array.push(option);
                } else {
                    for (var i = 0; i < array.length; i++) {
                        if (array[i] === option) {
                            array.splice(i, 1);
                            return;
                        }
                    }
                }
            };

            $scope.onRefresh = function() {
                getWhitelistIps();
            };

            $scope.createOrUpdateWhitelist = function(whitelist, $data) {
                var data = angular.copy(whitelist);
                data.ipAddress = $data.ipAddress;
                data.note = $data.note;
                if (casinoId !== null) {
                    data.casinoId = casinoId;
                }
                var d = $q.defer();
                if (!whitelist.id) {
                    requestService.postEncoded(
                        $scope.postUrl,
                        data,
                        function(response) {
                            if (response.status.code === 'OK') {
                                whitelist.id = response.whitelist.id;
                                whitelist.countryName = response.whitelist.countryName;
                                whitelist.countryCode = response.whitelist.countryCode;
                                whitelist.ipType = response.whitelist.ipType;
                                whitelist.editing = false;
                                whitelist.formErrors = {};
                                notificationService.showInfoToast($scope.translations.infoWhitelistIpSaved);
                                d.resolve();
                            } else if (response.status.code === 'VALIDATION_FAILED') {
                                whitelist.formErrors = response.status.formErrors || {};
                                d.resolve($scope.translations.errorValidationFailed);
                            } else {
                                notificationService.processApplicationError(response.status, $scope.translations.errorSavingWhitelistIp);
                                d.resolve($scope.translations.errorSavingWhitelistIp);
                            }
                        },
                        function(response) {
                            d.reject($scope.translations.errorServer);
                        }
                    );
                    return d.promise;
                } else {
                    requestService.putEncoded(
                        $scope.postUrl,
                        data,
                        function(response) {
                            if (response.status.code === 'OK') {
                                whitelist.countryName = response.whitelist.countryName;
                                whitelist.countryCode = response.whitelist.countryCode;
                                whitelist.ipType = response.whitelist.ipType;
                                whitelist.editing = false;
                                whitelist.formErrors = {};
                                notificationService.showInfoToast($scope.translations.infoWhitelistIpUpdated);
                                d.resolve();
                            } else if (response.status.code === 'VALIDATION_FAILED') {
                                whitelist.formErrors = response.status.formErrors || {};
                                d.resolve($scope.translations.errorValidationFailed);
                            } else {
                                notificationService.processApplicationError(response.status, $scope.translations.errorSavingWhitelistIp);
                                d.resolve($scope.translations.errorSavingWhitelistIp);
                            }
                        },
                        function(response) {
                            d.reject($scope.translations.errorServer);
                        }
                    )
                }

                return d.promise;
            };

            $scope.onRemoveWhitelist = function(whitelistId) {
                requestService.delete(
                    $scope.deleteUrl + whitelistId,
                    function(response) {
                        if (response.status.code === 'OK') {
                            //form.$hide();
                            for (var i = 0; i < $scope.table.whitelistIps.length; i++) {
                                if ($scope.table.whitelistIps[i].id === whitelistId) {
                                    $scope.table.whitelistIps.splice(i, 1);
                                    break;
                                }
                            }
                            notificationService.showInfoToast($scope.translations.infoWhitelistIpRemoved);
                        } else {
                            notificationService.processApplicationError(data.status, $scope.translations.errorRemovingWhitelistIp);
                        }
                    }
                );
            };

            $scope.openImportCsvModal = function() {
                var modalInstance = $modal.open({
                    animation: true,
                    templateUrl: 'app/templates/whitelist/import_whitelist_ips_modal.html',
                    controller: 'ImportWhitelistIpsModalCtrl',
                    backdrop: false,
                    size: '-md',
                    resolve: {
                        uploadCsvUrl: function() {
                            return $scope.uploadCsvUrl;
                        },
                        casinoId: function() {
                            return $scope.casinoId;
                        }
                    }
                });

                modalInstance.result.then(function() {
                    getWhitelistIps();
                }, function() {
                    getWhitelistIps();
                });
            };

            $scope.exportCsvFile = function() {
                function getFileNameFromHeader(header) {
                    if (!header) {
                        return null;
                    }

                    var result = header.split(";")[1].trim().split("=")[1];

                    return result.replace(/"/g, '');
                }

                requestService.postEncodedWithResponseType(
                    $scope.downloadCsvUrl,
                    null,
                    'arraybuffer',
                    function(data, status, headers) {
                        var fileName = getFileNameFromHeader(headers('Content-Disposition')) || "whitelist-ips.csv";

                        if (window.navigator.msSaveOrOpenBlob) {
                            var fileData = [data];
                            var blobObject = new Blob(fileData);

                            window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                        } else {
                            var blob = new Blob([data], {type: "text/csv"});

                            saveAs(blob, fileName);
                        }
                    },
                    function(error) {
                        notificationService.processApplicationError(error.status, $scope.translations.errorDownloadReportFailed + error.status.message);
                    }
                );
            };

            $scope.exportXlsFile = function() {
                function getFileNameFromHeader(header) {
                    if (!header) {
                        return null;
                    }

                    var result = header.split(";")[1].trim().split("=")[1];

                    return result.replace(/"/g, '');
                }

                requestService.postEncodedWithResponseType(
                    $scope.downloadXlsUrl,
                    null,
                    'arraybuffer',
                    function(data, status, headers) {
                        var fileName = getFileNameFromHeader(headers('Content-Disposition')) || "whitelist-ips.xlsx";

                        if (window.navigator.msSaveOrOpenBlob) {
                            var fileData = [data];
                            var blobObject = new Blob(fileData);

                            window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                        } else {
                            var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

                            saveAs(blob, fileName);
                        }
                    },
                    function(error) {
                        notificationService.processApplicationError(error.status, $scope.translations.errorDownloadReportFailed + error.status.message);
                    }
                );
            };

            $scope.deleteBunchIps = function(deleteAll) {
                if (deleteAll) {
                    var modalInstance = notificationService.showConfirmDangerDialog($scope.translations.modalAllTitle,
                        $scope.translations.modalAllMessage);
                    modalInstance.then(
                        function() {
                            //CLOSE - OK
                            requestService.delete(
                                $scope.deleteAllUrls,
                                function(response) {
                                    if (response.status.code !== 'OK') {
                                        $log.error(response.status);
                                        notificationService.processApplicationError(response.status, $scope.translations.errorRemovingWhitelistIp);
                                    }
                                    getWhitelistIps();
                                }
                            );
                        },
                        function() {
                        // CANCEL
                        }
                    );
                } else {
                    var modalInstance = notificationService.showConfirmWarningDialog($scope.translations.modalNonLockedTitle,
                        $scope.translations.modalNonLockedMessage);
                    modalInstance.then(
                        function() {
                            //CLOSE - OK
                            requestService.delete(
                                $scope.deleteUnlockedUrls,
                                function(response) {
                                    if (response.status.code !== 'OK') {
                                        $log.error(response.status);
                                        notificationService.processApplicationError(response.status, $scope.translations.errorRemovingWhitelistIp);
                                    }
                                    getWhitelistIps();
                                }
                            );
                        },
                        function() {
                            // CANCEL
                        }
                    );
                }
            };

            $scope.toggleOverflow = function(event) {
                setTimeout(function(){
                    event.currentTarget.parentElement.parentElement.children[1].children[0].classList.toggle('overflow-inherit');
                }, 450);
            };

            // Watch for changes, then load data
            $scope.$watch('table.offset', onPageOffsetChange);
            $scope.$watch('table.sortBy', onSortByChange, true);
            $scope.$watch('table.pageSize', onPaginationChange, true);

            getFiltersOptions();
            getWhitelistIps();
        }

        function cancelWhitelistEditing(whitelistIp) {
            whitelistIp.editing = false;
        }

        function getWhitelistIps() {
            var sortColumn = null;
            var orderAsc = null;

            if ($scope.table.sortColumn) {
                sortColumn = '&orderColumn=' + $scope.table.sortColumn;
                orderAsc = '&orderAsc=' + $scope.table.sortAsc;
            } else {
                sortColumn = '&orderColumn=IP_ADDRESS';
                orderAsc = '&orderAsc=true';
            }

            requestService.postEncoded(
                $scope.getUrl +
                                // TODO: ipeev '&casinoId' for player_ip_whitelist (same for create or update)
                                '?dataOffset=' + $scope.table.offset +
                                '&dataSegmentSize=' + $scope.table.pageSize +
                                '&dataSize=' + $scope.table.totalSize +
                                sortColumn +
                                orderAsc,
                {
                    casinoId: $scope.casinoId,
                    ipAddress: $scope.filters.ipAddress,
                    countryId: $scope.filters.countryId,
                    enabled: $scope.filters.enabled,
                    locked: $scope.filters.locked,
                    note: $scope.filters.note,
                    ipType: $scope.filters.ipType
                },
                function(response) {
                    updateData(response);
                }
            );
        }

        function updateData(response) {
            var tableData = requestService.transformResponseIntoTableData(response.data, response.dataColumns);
            requestService.parseJsonInResponse(tableData, 'additionalData');

            $scope.table.totalSize = response.dataSize;
            $scope.table.whitelistIps = tableData;
            $scope.table.offset = response.dataOffset;

            if (response.dataSize == 0) {
                notificationService.showInfoToast($scope.translations.infoNoDataFound);
            }
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

        function getFiltersOptions() {
            requestService.get(
                '/api/enums/player-whitelist-filter-options',
                function(data) {
                    $scope.filterOptions.ipType = data.ipType;
                }
            );
        }

        function onPageOffsetChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                $timeout(function() {
                        getWhitelistIps();
                    },
                    $scope.DEFAULT_TIMEOUT
                );
            }
        }

        function onSortByChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.table.sortBy.length > 0) {
                    if ($scope.table.sortBy[0].charAt(0) !== '-') {
                        $scope.table.sortColumn = $scope.table.sortBy[0];
                        $scope.table.sortAsc = true;
                    } else {
                        $scope.table.sortColumn = $scope.table.sortBy[0].slice(1);
                        $scope.table.sortAsc = false;
                    }
                } else {
                    $scope.table.sortAsc = null;
                    $scope.table.sortColumn = null;
                }
                getWhitelistIps();
            }
        }

        function onPaginationChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                getWhitelistIps();
            }
        }

        function initTranslations() {
            var translations = {};
            translations.filterLocked = $translate.instant('GENERAL.WHITELIST_IPS.FILTER.LOCKED');
            translations.filterUnlocked = $translate.instant('GENERAL.WHITELIST_IPS.FILTER.UNLOCKED');
            translations.filterBoth = $translate.instant('GENERAL.WHITELIST_IPS.FILTER.BOTH');
            $translate(['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_SAVED', 'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.VALIDATION_FAILED',
                'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SAVING_WHITELIST_IP', 'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SERVER_ERROR',
                'GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_UPDATED', 'GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_REMOVED',
                'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.REMOVING_WHITELIST_IP', 'GENERAL.WHITELIST_IPS.ERROR_MESSAGES.DOWNLOAD_REPORT_FAILED',
                'GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.TITLE', 'GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.MESSAGE',
                'GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.TITLE', 'GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.MESSAGE',
                'GENERAL.WHITELIST_IPS.INFO_MESSAGES.NO_DATA_FOUND'])
                .then(function(translation) {
                    translations.errorValidationFailed = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorSavingWhitelistIp = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SAVING_WHITELIST_IP'];
                    translations.errorRemovingWhitelistIp = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.REMOVING_WHITELIST_IP'];
                    translations.errorServer = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorDownloadReportFailed = translation['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.DOWNLOAD_REPORT_FAILED'];

                    translations.infoWhitelistIpSaved = translation['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_SAVED'];
                    translations.infoWhitelistIpUpdated = translation['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_UPDATED'];
                    translations.infoWhitelistIpRemoved = translation['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_REMOVED'];
                    translations.infoNoDataFound = translation['GENERAL.WHITELIST_IPS.INFO_MESSAGES.NO_DATA_FOUND'];

                    translations.modalAllTitle = translation['GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.TITLE'];
                    translations.modalAllMessage = translation['GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.MESSAGE'];
                    translations.modalNonLockedTitle = translation['GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.TITLE'];
                    translations.modalNonLockedMessage = translation['GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.MESSAGE'];
                }, function(translationIds) {
                    translations.errorValidationFailed = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.VALIDATION_FAILED'];
                    translations.errorSavingWhitelistIp = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SAVING_WHITELIST_IP'];
                    translations.errorRemovingWhitelistIp = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.REMOVING_WHITELIST_IP'];
                    translations.errorServer = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.SERVER_ERROR'];
                    translations.errorDownloadReportFailed = translationIds['GENERAL.WHITELIST_IPS.ERROR_MESSAGES.DOWNLOAD_REPORT_FAILED'];

                    translations.infoWhitelistIpSaved = translationIds['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_SAVED'];
                    translations.infoWhitelistIpUpdated = translationIds['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_UPDATED'];
                    translations.infoWhitelistIpRemoved = translationIds['GENERAL.WHITELIST_IPS.INFO_MESSAGES.WHITELIST_IP_REMOVED'];
                    translations.infoNoDataFound = translationIds['GENERAL.WHITELIST_IPS.INFO_MESSAGES.NO_DATA_FOUND'];

                    translations.modalAllTitle = translationIds['GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.TITLE'];
                    translations.modalAllMessage = translationIds['GENERAL.WHITELIST_IPS.MODAL_DELETE_ALL_IPS.MESSAGE'];
                    translations.modalNonLockedTitle = translationIds['GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.TITLE'];
                    translations.modalNonLockedMessage = translationIds['GENERAL.WHITELIST_IPS.MODAL_DELETE_NON_LOCKED_IPS.MESSAGE'];
                });
            return translations;
        }
    }
]);
