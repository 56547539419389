app.directive('stRatio', function() {
    return {
        link: function(scope, element, attr) {
            var ratio = +(attr.stRatio);
            element.css('width', ratio + '%');
        }
    };
});

app.directive('multipleButtons', function() {
    return {
        restrict: 'AE',
        scope: false,
        templateUrl: 'app/templates/multiple_buttons.html',

        link: function(scope, element, attrs) {

            scope.all = false;

            scope.$on('uis:allSelected', function(event, areAllSelected) {
                scope.all = areAllSelected;
            });

            scope.onSelectAll = function() {
                //scope.all = true;
                if (!scope.inReadMode) {
                    scope.$broadcast('uis:selectAll');
                }
            };

            scope.onSelectNone = function() {
                //scope.all = false;
                if (!scope.inReadMode) {
                    scope.$broadcast('uis:selectNone');
                }
            };
        }
    }
});

app.directive('roundFeatures', function() {
    return {
        restrict: 'E',
        scope: {
            features: '=features',
            compactMode: '=compactMode'
        },
        templateUrl: 'app/templates/round_features.html'
    }
});

app.directive('columnsPicker', function($filter) {
    return {
        restrict: 'E',
        scope: {
            columnsInfo: '=columnsInfo',
            columns: '=columns',
            direction: '@direction',
            onChange: '&',
            translationNamespace: '=translationNamespace'
        },
        templateUrl: 'app/templates/columns_picker.html',
        link: function(scope, element, attrs) {

            checkAndUpdateAllSelected();

            function checkAndUpdateAllSelected() {
                var hidden = $filter('filter')(scope.columns, {visible: false});
                scope.allSelected = hidden.length == 0;
            }

            scope.onToggle = function(open) {
                if (!open && scope.onChange) {
                    scope.onChange();
                }
            };

            scope.onSelectAll = function() {
                var i = 0, len = scope.columns.length;
                if (scope.allSelected) {
                    for (; i < len; i++) {
                        scope.columns[i].visible = false;
                    }
                } else {
                    for (; i < len; i++) {
                        scope.columns[i].visible = true;
                    }
                }
                checkAndUpdateAllSelected();
            };

            scope.onSelectColumn = function(column) {
                column.visible = !column.visible;
                if (!column.visible) {
                    scope.allSelected = false;
                } else {
                    checkAndUpdateAllSelected();
                }
            };

        }
    }
});

app.directive('columnsToggler', function($filter) {
    return {
        restrict: 'E',
        scope: {
            columnsInfo: '=columnsInfo',
            direction: '@direction',
            onChange: '&',
            elementToChange: '=elementToChange',
            translationNamespace: '=translationNamespace'
        },
        templateUrl: 'app/templates/columns_toggler.html',
        link: function(scope, element, attrs) {
            checkAndUpdateAllSelected();

            function checkAndUpdateAllSelected() {
                var allSelected = true;
                for (var property in scope.columnsInfo) {
                    if (scope.columnsInfo[property].visible === false) {
                        allSelected = false;
                        break;
                    }
                }

                scope.allSelected = allSelected;
            }

            scope.onToggle = function(open) {
                if (!open && scope.onChange) {
                    scope.onChange();
                }
            };

            scope.onSelectAll = function() {
                scope.allSelected = !scope.allSelected;
                for (var property in scope.columnsInfo) {
                    if (scope.allSelected === true) {
                        scope.columnsInfo[property].visible = true;
                    } else {
                        scope.columnsInfo[property].visible = false;
                    }
                }
            };

            scope.onSelectColumn = function(column) {
                column.visible = !column.visible;
                checkAndUpdateAllSelected();
            };

        }
    }
});

app.directive('settingsMap', function($filter, $log, $q, $translate) {
    return {
        restrict: 'AE',
        scope: {
            params: '=',
            control: '=',
            editable: '='
        },
        templateUrl: 'app/templates/settings_map.html',
        link: function(scope, element, attrs) {
            scope.translations = initTranslations();
            scope.internalControl = scope.control || {};
            scope.internalControl.editingForm = false;

            // FILTER
            scope.filterNotDeletedParams = function(param) {
                return param.isDeleted !== true;
            };

            // FORM VALIDATIONS
            scope.checkKey = function(newKey) {
                if (!newKey) {
                    return scope.translations.missingKey;
                }
                if (hasKeyDuplicates(newKey)) {
                    return scope.translations.duplicateKey;
                }
            };

            function hasKeyDuplicates(newKey) {
                var filtered = $filter('filter')(scope.params, {key: newKey}, true);
                return filtered.length > 1;

            }

            scope.checkValue = function(value) {
                if (!value) {
                    return scope.translations.missingValue;
                }
            };

            // ACTIONS
            var copyBeforeEdit = [];
            scope.editParams = function() {
                backupChanges();
                scope.settingsform.$show();
                scope.internalControl.editingForm = true;
            };

            // Mark param as deleted
            scope.deleteParam = function(index) {
                var filtered = $filter('filter')(scope.params, scope.filterNotDeletedParams);
                if (filtered.length > 0) {
                    filtered[index].isDeleted = true;
                }
            };

            scope.addParam = function() {
                scope.params.push({
                    value: '',
                    isNew: true
                });
            };

            scope.cancelChanges = function() {
                restoreChanges();
            };

            scope.disableEditing = function() {
                scope.internalControl.editingForm = false;
            };

            scope.saveChanges = function() {
                var results = [];
                for (var i = 0, l = scope.params.length; i < l; i++) {
                    //for (var i = scope.params.length; i--;) {
                    var param = scope.params[i];

                    //Cross params validation
                    //if (!hasKeyDuplicates(param.key)) {
                    // Actually delete user
                    if (param.isDeleted) {
                        scope.params.splice(i, 1);
                    }
                    // Mark as not new
                    if (param.isNew) {
                        delete param.isNew;
                    }
                    //}
                }

                return $q.all(results);
            };

            function backupChanges() {
                angular.copy(scope.params, copyBeforeEdit);
            }

            function restoreChanges() {
                angular.copy(copyBeforeEdit, scope.params);
            }

            // EXPOSED METHODS
            scope.internalControl.onSaveForm = function(onSuccess) {
                scope.settingsform.$onaftersave = function() {
                    scope.settingsform.$onaftersave = angular.noop;
                    scope.saveChanges();
                    onSuccess();
                };
                scope.settingsform.$submit();
            };

            scope.internalControl.onCancelForm = function() {
                scope.settingsform.$cancel();
            };

            scope.internalControl.onSwitchToEditMode = function() {
                scope.editParams();
            };

            function initTranslations() {
                var translations = {};
                $translate(['GENERAL.SETTINGS_MAP.MISSING_KEY', 'GENERAL.SETTINGS_MAP.DUPLICATE_KEY', 'GENERAL.SETTINGS_MAP.MISSING_VALUE'])
                    .then(function(translation) {
                        translations.missingKey = translation['GENERAL.SETTINGS_MAP.MISSING_KEY'];
                        translations.duplicateKey = translation['GENERAL.SETTINGS_MAP.DUPLICATE_KEY'];
                        translations.missingValue = translation['GENERAL.SETTINGS_MAP.MISSING_VALUE'];
                    }, function(translationIds) {
                        translations.missingKey = translationIds['GENERAL.SETTINGS_MAP.MISSING_KEY'];
                        translations.duplicateKey = translationIds['GENERAL.SETTINGS_MAP.DUPLICATE_KEY'];
                        translations.missingValue = translationIds['GENERAL.SETTINGS_MAP.MISSING_VALUE'];
                    });
                return translations;
            }
        }
    };
});

app.directive('paramsMap', function($filter, $log, $q, $translate) {
    return {
        restrict: 'AE',
        scope: {
            params: '=',
            control: '=',
            editable: '='
        },
        templateUrl: 'app/templates/params_map.html',
        link: function(scope, element, attrs) {
            scope.translations = initTranslations();
            scope.internalControl = scope.control || {};
            scope.internalControl.editingForm = false;
            scope.types = ['', 'STRING', 'NUMBER', 'BOOLEAN', 'LIST_OF_NUMBERS'];
            var numberPattern = /^\d+$/;
            var numberListPattern = /^\s*\d+(\s*,\s*\d+)*\s*$/;
            var numberRegExp = new RegExp(numberPattern);
            var listOfNumbersRegExp = new RegExp(numberListPattern);

            // FILTER
            scope.filterNotDeletedParams = function(param) {
                return param.isDeleted !== true;
            };

            // FORM VALIDATIONS
            scope.checkKey = function(newKey) {
                if (!newKey) {
                    return scope.translations.missingKey;
                }
                if (hasKeyDuplicates(newKey)) {
                    return scope.translations.duplicateKey;
                }
            };

            function hasKeyDuplicates(newKey) {
                var filtered = $filter('filter')(scope.params, {key: newKey}, true);
                return filtered.length > 1;

            }

            scope.checkType = function(newType, value) {
                if (!newType) {
                    return scope.translations.missingType;
                }
            };

            scope.checkValue = function(value, type) {
                switch (type) {
                    case 'STRING':
                        if (!angular.isString(value) || value.trim().length === 0) {
                            return scope.translations.stringExpected;
                        }
                        break;
                    case 'NUMBER':
                        if (!numberRegExp.test(value)) {
                            return scope.translations.numberExpected;
                        }
                        break;
                    case 'BOOLEAN':
                        if (value !== 'true' && value !== 'false') {
                            return scope.translations.booleanExpected;
                        }
                        break;
                    case 'LIST_OF_NUMBERS':
                        if (!listOfNumbersRegExp.test(value)) {
                            return scope.translations.listOfNumbersExpected;
                        }
                        break;
                    case '':
                        return scope.translations.valueRequired;
                        break;
                }
            };

            // ACTIONS
            var copyBeforeEdit = [];
            scope.editParams = function() {
                backupChanges();
                scope.paramsform.$show();
                scope.internalControl.editingForm = true;
            };

            // Mark param as deleted
            scope.deleteParam = function(param) {
                param.isDeleted = true;
            };

            scope.addParam = function() {
                scope.params.push({
                    value: '',
                    type: '',
                    isNew: true
                });
            };

            scope.cancelChanges = function() {
                restoreChanges();
            };

            scope.disableEditing = function() {
                scope.internalControl.editingForm = false;
            };

            scope.saveChanges = function() {
                var results = [];
                for (var i = 0, l = scope.params.length; i < l; i++) {
                    //for (var i = scope.params.length; i--;) {
                    var param = scope.params[i];

                    //Cross params validation
                    //if (!hasKeyDuplicates(param.key)) {
                    // Actually delete user
                    if (param.isDeleted) {
                        scope.params.splice(i, 1);
                    }
                    // Mark as not new
                    if (param.isNew) {
                        delete param.isNew;
                    }
                    //}
                }

                return $q.all(results);
            };

            function backupChanges() {
                angular.copy(scope.params, copyBeforeEdit);
            }

            function restoreChanges() {
                angular.copy(copyBeforeEdit, scope.params);
            }

            // EXPOSED METHODS
            scope.internalControl.onSaveForm = function(onSuccess) {
                scope.paramsform.$onaftersave = function() {
                    scope.paramsform.$onaftersave = angular.noop;
                    scope.saveChanges();
                    onSuccess();
                };
                scope.paramsform.$submit();
            };

            scope.internalControl.onCancelForm = function() {
                scope.paramsform.$cancel();
            };

            scope.internalControl.onSwitchToEditMode = function() {
                scope.editParams();
            };

            function initTranslations() {
                var translations = {};
                $translate(['GENERAL.PARAMS_MAP.MISSING_KEY', 'GENERAL.PARAMS_MAP.DUPLICATE_KEY',
                    'GENERAL.PARAMS_MAP.MISSING_TYPE', 'GENERAL.PARAMS_MAP.STRING_EXPECTED', 'GENERAL.PARAMS_MAP.NUMBER_EXPECTED',
                    'GENERAL.PARAMS_MAP.BOOLEAN_EXPECTED', 'GENERAL.PARAMS_MAP.LIST_OF_NUMBERS_EXPECTED', 'GENERAL.PARAMS_MAP.VALUE_REQUIRED'
                ])
                    .then(function(translation) {
                        translations.missingKey = translation['GENERAL.PARAMS_MAP.MISSING_KEY'];
                        translations.duplicateKey = translation['GENERAL.PARAMS_MAP.DUPLICATE_KEY'];
                        translations.missingType = translation['GENERAL.PARAMS_MAP.MISSING_TYPE'];
                        translations.stringExpected = translation['GENERAL.PARAMS_MAP.STRING_EXPECTED'];
                        translations.numberExpected = translation['GENERAL.PARAMS_MAP.NUMBER_EXPECTED'];
                        translations.booleanExpected = translation['GENERAL.PARAMS_MAP.BOOLEAN_EXPECTED'];
                        translations.listOfNumbersExpected = translation['GENERAL.PARAMS_MAP.LIST_OF_NUMBERS_EXPECTED'];
                        translations.valueRequired = translation['GENERAL.PARAMS_MAP.VALUE_REQUIRED'];
                    }, function(translationIds) {
                        translations.missingKey = translationIds['GENERAL.PARAMS_MAP.MISSING_KEY'];
                        translations.duplicateKey = translationIds['GENERAL.PARAMS_MAP.DUPLICATE_KEY'];
                        translations.missingType = translationIds['GENERAL.PARAMS_MAP.MISSING_TYPE'];
                        translations.stringExpected = translationIds['GENERAL.PARAMS_MAP.STRING_EXPECTED'];
                        translations.numberExpected = translationIds['GENERAL.PARAMS_MAP.NUMBER_EXPECTED'];
                        translations.booleanExpected = translationIds['GENERAL.PARAMS_MAP.BOOLEAN_EXPECTED'];
                        translations.listOfNumbersExpected = translationIds['GENERAL.PARAMS_MAP.LIST_OF_NUMBERS_EXPECTED'];
                        translations.valueRequired = translationIds['GENERAL.PARAMS_MAP.VALUE_REQUIRED'];
                    });
                return translations;
            }
        }
    };
});

app.directive('inputNumberConverter', function() {
    return {
        priority: 1,
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {

            function toView(value) {
                if (value) {
                    return Number(value);
                }
                return value;
            }

            ngModel.$formatters.push(toView);
            //ngModel.$parsers.push(toModel);
        }
    };
});

app.directive('dropdownIdConverter', function() {
    return {
        priority: 1,
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {

            function toView(value) {
                if (value) {
                    for (var i = 0, l = value.length; i < l; i++) {
                        value[i] = parseInt(value[i]);
                    }
                }
                return value;
            }

            ngModel.$formatters.push(toView);
            //ngModel.$parsers.push(toModel);
        }
    };
});

app.directive('multiselectDirective', function() {
    return {
        restrict: 'EA',
        templateUrl: 'app/templates/multiselect.html',
        scope: {
            availableGamesWagering: '=',
            gamesWagering: '='
        },
        link: function(scope, el, attrs) {
            //console.log(available + selected);

            scope.moveItem = function(items, from, to) {
                if (items !== undefined) {
                    for (var i = 0; i < items.length; i++) {
                        var index = from.indexOf(items[i]);
                        if (index > -1) {
                            from.splice(index, 1);
                            to.push(items[i]);
                        }
                    }
                }
            };
        }
    }
});

app.directive('tooltip', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            $(element).hover(function() {
                // on mouseenter
                $(element).tooltip('show');
            }, function() {
                // on mouseleave
                $(element).tooltip('hide');
            });
        }
    };
});

app.directive('ivhDropdown', ['$document', function ($document) {
    return {
        restrict: 'E',
        scope: {
            ivhModel: '=',
            selectedArray: '=',
            isDisabled: '=',
            placeholder: '@'
        },
        templateUrl: 'app/templates/ivh-dropDown.html',
        replace: true,
        link: function (scope, element, attr) {
            scope.treeviewInitialized = false;

            scope.onClickShowHideIVHDropdown = function (e) {
                if (e.target.classList[0] === "results-holder"
                    && e.target.parentElement.parentElement.children.length === 3
                    && e.target.parentElement.parentElement.children[2].classList[0] === "dropDownContent") {
                    e.target.parentElement.parentElement.children[2].classList.toggle("show");
                    e.target.parentElement.parentElement.children[1].classList.toggle("show");
                }
                else if (e.target.classList[0] === "dropDownButton"
                    && e.target.parentElement.children.length === 3
                    && e.target.parentElement.children[2].classList[0] === "dropDownContent") {
                    e.target.parentElement.children[2].classList.toggle("show");
                    e.target.classList.toggle("show");
                }
                else if (e.target.classList[0] === "dropDownButtonIcon"
                    && e.target.parentElement.parentElement.children.length === 3
                    && e.target.parentElement.parentElement.children[2].classList[0] === "dropDownContent") {
                    e.target.parentElement.parentElement.children[2].classList.toggle("show");
                    e.target.parentElement.classList.toggle("show");
                }
                e.stopPropagation();
            };

            function initTreeCheckboxes(node) {
                if (node.children && node.children.length > 0) {
                    if (node.selected) {
                        selectOrDeselectChildElements(node, node.selected)
                    } else {
                        angular.forEach(node.children, function(value){
                            initTreeCheckboxes(value)
                        });
                    }
                    for(var i = 0; i < node.children.length; i++) {
                        node.children[i].parent = node;
                    }
                }
            }

            function selectOrDeselectChildElements(node, select) {
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        if (node.children[i].children && node.children[i].children.length > 0) {
                            selectOrDeselectChildElements(node.children[i], select);
                        }
                        if (select) {
                            node.children[i].selected = true;
                        } else {
                            node.children[i].selected = false;
                        }
                    }
                }
            }

            function selectOrDeselectParentElements(parentNode, select) {
                if(select) {
                    if (areAllChildElementsSelected(parentNode) === true) {
                        parentNode.selected = true;
                    }
                } else {
                    parentNode.selected = false;
                }
                if(parentNode.parent) {
                    selectOrDeselectParentElements(parentNode.parent, select);
                }
            }

            function areAllChildElementsSelected(node) {
                for(var i = 0; i < node.children.length; i++) {
                    if (node.children[i].children && node.children[i].children.length > 0) {
                        if (areAllChildElementsSelected(node.children[i]) === false) {
                            return false;
                        }
                    } else {
                        if(node.children[i].selected === false || node.children[i].selected === undefined) {
                            return false;
                        }
                    }
                }
                return true;
            }

            scope.$watchCollection('ivhModel', function () {
                if (scope.treeviewInitialized === false) {
                    if (scope.ivhModel && scope.ivhModel.id !== undefined) {
                        initTreeCheckboxes(scope.ivhModel);
                        generateSelectedText();
                        if (scope.isDisabled === true) {
                            disableAllCheckboxes(scope.ivhModel);
                        }
                        scope.treeviewInitialized = true;
                    }
                }
            });

            scope.$watch('isDisabled', function(newVal, oldVal) {
                if (newVal === false && oldVal === true) {
                    enableAllCheckboxes(scope.ivhModel);
                }
            },true);

            function disableAllCheckboxes(model) {
                model.disabled = true;
                if (model.children && model.children.length > 0) {
                    for (var i = 0; i < model.children.length; i++) {
                        disableAllCheckboxes(model.children[i]);
                    }
                }
            }

            function enableAllCheckboxes(model) {
                model.disabled = false;
                if (model.children && model.children.length > 0) {
                    for (var i = 0; i < model.children.length; i++) {
                        enableAllCheckboxes(model.children[i]);
                    }
                }
            }

            scope.changeCallback = function (node) {
                selectOrDeselectChildElements(node, node.selected);
                if (node.parent) {
                    selectOrDeselectParentElements(node.parent, node.selected);
                }
                generateSelectedText();
                updateSelectedItemsArray();
            };

            function updateSelectedItemsArray() {
                var array = [];
                fillArray(scope.ivhModel, array);
                scope.selectedArray = array;
            }

            function fillArray(node, array) {
                if (node.selected && node.selected === true) {
                    array.push(node.id);
                }
                if (node.children && node.children.length > 0) {
                    for (var i = 0; i < node.children.length; i++) {
                        fillArray(node.children[i], array);
                    }
                }
            }

            function generateSelectedText() {
                var selectedItm = getSelectedFormIVHTreeModel(scope.ivhModel);
                if (selectedItm.length > 0) {
                    if (selectedItm.length === 1) {
                        scope.selectedText = selectedItm[0];
                    } else {
                        if (selectedItm[0] == 'All') {
                            scope.selectedText = 'All';
                        } else {
                            scope.selectedText = selectedItm[0] + ' and '
                                + (selectedItm.length - 1) + ' other(s)';
                        }
                    }
                } else {
                    scope.selectedText = scope.placeholder || "";
                }
                //console.log(selectedItm);
            }

            $document.bind('click', function (event) {
                var isClickedElementChildOfPopup = element.find(
                        event.target).length > 0;
                if (!isClickedElementChildOfPopup
                    && element[0].children[2].classList.contains("show")) {
                    element[0].children[2].classList.toggle("show");
                }
                scope.$apply();
            });

            var setZindex = function () {
                var zIndexDropDownButton = parseInt(
                    element[0].children[0].style.zIndex === '' ? 0
                        : element[0].children[0].style.zIndex);
                element[0].children[0].style.zIndex = zIndexDropDownButton;
                element[0].children[0].children[0].style.zIndex = zIndexDropDownButton
                    + 1;
                element[0].children[1].style.zIndex = zIndexDropDownButton + 2;
            };

            setZindex();

            var getSelectedFormIVHTreeModel = function (ivhModel) {
                var selectedItems = [];
                subSelection(ivhModel, selectedItems);

                return selectedItems;
            };

            var subSelection = function(ivhModel, array) {
                if (ivhModel.selected === true) {
                    array.push(ivhModel.label);
                }
                if (ivhModel.children && ivhModel.children.length > 0) {
                    for (var i = 0; i < ivhModel.children.length; i++) {
                        if (ivhModel.children[i].selected) {
                            array.push(ivhModel.children[i].label);
                        }
                        if (ivhModel.children[i].children && ivhModel.children[i].children.length > 0) {
                            for (var j = 0; j < ivhModel.children[i].children.length; j++) {
                                subSelection(ivhModel.children[i].children[j], array);
                            }

                        }
                    }
                }
            };
        }
    };
}]);

app.directive('ivhTreeviewCheckbox', function() {
    return {
        scope: false,
        template: '<input type="checkbox" ng-model="node.selected" ng-disabled="node.disabled"/>',
        link: function (scope, element, attrs) {
            element.on('click', function(event) {
                scope.trvw.onCbChange(scope.node, scope.node.selected);
            });
        }
    };
});

app.directive('typeaheadFocus', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {

            // Array of keyCode values for arrow keys
            var ARROW_KEYS = [37, 38, 39, 40];

            function manipulateViewValue(e) {
                /* we have to check to see if the arrow keys were in the input because if they were trying to select
                 * a menu option in the typeahead, this may cause unexpected behavior if we were to execute the rest
                 * of this function
                 */
                if (ARROW_KEYS.indexOf(e.keyCode) >= 0) {
                    return;
                }

                var viewValue = ngModel.$viewValue;

                //restore to null value so that the typeahead can detect a change
                if (ngModel.$viewValue == ' ') {
                    ngModel.$setViewValue(null);
                }

                //force trigger the popup
                ngModel.$setViewValue(' ');

                //set the actual value in case there was already a value in the input
                ngModel.$setViewValue(viewValue || ' ');
            }

            /* trigger the popup on 'click' because 'focus'
             * is also triggered after the item selection.
             * also trigger when input is deleted via keyboard
             */
            element.bind('click keyup', manipulateViewValue);

            //compare function that treats the empty space as a match
            scope.$emptyOrMatch = function(actual, expected) {
                if (expected == ' ') {
                    return true;
                }
                return actual ? actual.toString().toLowerCase().indexOf(
                        expected.toLowerCase()) > -1 : false;
            };
        }
    };
});

app.directive('toggleBtn', function () {
    return {
        restrict: 'EA',
        replace: true,
        require: ['name', '^ngModel', 'onType', 'offType'],
        scope: {
            isDisabled: '=',
            onType: '@',
            offType: '@',
            name: '@',
            ngModel: '=',
            ngChange: '&',
            onLabel: '@',
            offLabel: '@',
            required: '@',
            ngTrueValue:'@',
            ngFalseValue:'@',
            size: '@'
        },
        template:
        ' <div class="toggle-switch" ng-class="\'on\'+onType+ \' \' + \'off\'+offType + \' \' + size"> ' +
        '	<span ng-if="offLabel" class="off-label" ng-bind="offLabel"></span> ' +
        ' 	<input ng-model="ngModel" id="{{name}}" name="{{name}}" type="checkbox" selected="ngModel" ng-disabled="isDisabled" ng-change="ngChange()" ' +
        '			hidden="" ng-true-value="{{ngTrueValue? ngTrueValue:true}}" ng-false-value="{{ngFalseValue? ngFalseValue:false}}" ng-required="required"><label for="{{name}}" ' +
        '			class="toggle-knob"></label> ' +
        '	<span ng-if="onLabel" class="on-label" ng-bind="onLabel"></span> ' +
        '</div> '
    };
});
