app.controller('GameSessionCtrl', ['$scope', 'cacheService', '$stateParams', '$location', '$state', 'urlParamsService',
    function($scope, cacheService, $stateParams, $location, $state, urlParamsService) {

        initFilter();
        if ($scope.DEBUG) {
            initData();
        }

        function initData() {
            $scope.page.selectedCasinoId = 1;
        }

        function initFilter() {
            var casinoId;
            if ($stateParams.casinoId) {
                casinoId = $stateParams.casinoId;
            } else {
                casinoId = cacheService.get('game_session.casinoId');
                urlParamsService.setParam('casinoId', casinoId);
            }

            $scope.page = {
                casinoError: '',
                selectedCasinoId: casinoId
            };

            $scope.onCasinoSelect = function() {
                var casinoId = $scope.page.selectedCasinoId;
                urlParamsService.setParam('casinoId', casinoId);
                cacheService.put('game_session.casinoId', casinoId);
            }
        }

    }]);

app.controller('GameSessionTabsCtrl', ['$scope', 'userSettingsService',
    function($scope, userSettingsService) {
        $scope.page.tabs = [
            {name: 'GAME_SESSION_BY_PLAYER', route: 'game-sessions.session_by_player'},
            {name: 'GAME_SESSION_BY_ID', route: 'game-sessions.session_by_session_id'},
            {name: 'GAME_ROUND_BY_ID', route: 'game-sessions.round_by_round_id'}
        ];

        $scope.page.tables = {
            columnsInfo: {
                'sessionId': {displayName: 'SESSION_ID'},
                'gameRoundId': {displayName: 'GAME_ROUND_ID'},
                'playerId': {displayName: 'PLAYED_ID'},
                'gameId': {displayName: 'GAME_ID'},
                'gameName': {displayName: 'GAME_NAME'},
                'platform': {displayName: 'PLATFORM'},
                'state': {displayName: 'GAME_ROUND_STATE'},
                'dateStarted': {displayName: 'STARTED', filter: 'dateFilterLong'},
                'dateFundsAllocated': {displayName: 'MONEY_ALLOCATED', filter: 'dateFilterLong'},
                'dateAccounted': {displayName: 'ACCOUNTED', filter: 'dateFilterLong'},
                'dateFinished': {displayName: 'FINISHED', filter: 'dateFilterLong'},
                'moneyType': {displayName: 'MONEY_TYPE'},
                'stake': {displayName: 'STAKE', filter: 'number:2', class: 'number'},
                'win': {displayName: 'WIN', filter: 'number:2', class: 'number'},
                'features': {displayName: 'FEATURES'},
                'currency': {displayName: 'CURRENCY'},
                'conversionRateToSystemBase': {displayName: 'CONVERSION_RATE_TO_SYSTEM_BASE', filter: 'number:4', class: 'number'},
                'moneyBreakdown': {displayName: 'MONEY_BREAKDOWN'},
                'gsBonusesConvertedToRealMoney': {displayName: 'GS_BONUSES_CONVERTED_TO_RM', filter: 'number:2', class: 'number'},
                'freeRoundsConvertedToRealMoney': {displayName: 'FREE_ROUNDS_CONVERTED_TO_RM', filter: 'number:2', class: 'number'},
                'freeRoundsConvertedToBonus': {displayName: 'FREE_ROUNDS_CONVERTED_TO_BM', filter: 'number:2', class: 'number'},
                'walletType': {displayName: 'WALLET_TYPE'},
                'currentState': {displayName: 'CURRENT_STATE'},
                'totalStake': {displayName: 'TOTAL_STAKE', filter: 'number:2', class: 'number'},
                'totalWin': {displayName: 'TOTAL_WIN', filter: 'number:2', class: 'number'},
                'totalHold': {displayName: 'TOTAL_HOLD', filter: 'number:2', class: 'number'},
                'startBalance': {displayName: 'START_BALANCE', filter: 'number:2', class: 'number'},
                'endBalance': {displayName: 'END_BALANCE', filter: 'number:2', class: 'number'},
                'rmStake': {displayName: 'RM_STAKE', filter: 'number:2', class: 'number'},
                'rmWin': {displayName: 'RM_WIN', filter: 'number:2', class: 'number'},
                'rmHold': {displayName: 'RM_HOLD', filter: 'number:2', class: 'number'},
                'bmStake': {displayName: 'BM_STAKE', filter: 'number:2', class: 'number'},
                'bmWin': {displayName: 'BM_WIN', filter: 'number:2', class: 'number'},
                'bmHold': {displayName: 'BM_HOLD', filter: 'number:2', class: 'number'},
                'fmStake': {displayName: 'FM_STAKE', filter: 'number:2', class: 'number'},
                'fmWin': {displayName: 'FM_WIN', filter: 'number:2', class: 'number'},
                'fmHold': {displayName: 'FM_HOLD', filter: 'number:2', class: 'number'},
                'casinoFreeRoundsReason': {displayName: 'CASINO_FREE_ROUNDS_REASON'}, // TODO get state of applied campaign here
                'casinoFreeRoundsStake': {displayName: 'CASINO_FREE_ROUNDS_STAKE', filter: 'number:2', class: 'number'},
                'casinoFreeRoundsWin': {displayName: 'CASINO_FREE_ROUNDS_WIN', filter: 'number:2', class: 'number'},
                'casinoFreeRoundsHold': {displayName: 'CASINO_FREE_ROUNDS_HOLD', filter: 'number:2', class: 'number'},
                'casinoFreeRoundsDone': {displayName: 'CASINO_FREE_ROUNDS_DONE'},
                'gsFreeRoundsStake': {displayName: 'GS_FREE_ROUNDS_STAKE', filter: 'number:2', class: 'number'},
                'gsFreeRoundsWin': {displayName: 'GS_FREE_ROUNDS_WIN', filter: 'number:2', class: 'number'},
                'gsFreeRoundsHold': {displayName: 'GS_FREE_ROUNDS_HOLD', filter: 'number:2', class: 'number'},
                'gsFreeRoundsDone': {displayName: 'GS_FREE_ROUNDS_DONE'}
            },
            gameSessionConfig: {
                columns: [
                    {name: 'gameName', visible: true},
                    {name: 'walletType', visible: true},
                    {name: 'currentState', visible: true},
                    {name: 'dateStarted', visible: true},
                    {name: 'dateFinished', visible: true},
                    {name: 'currency', visible: true},
                    {name: 'totalStake', visible: true},
                    {name: 'totalWin', visible: true},
                    {name: 'totalHold', visible: true},
                    {name: 'startBalance', visible: true},
                    {name: 'endBalance', visible: true},
                    {name: 'rmStake', visible: true},
                    {name: 'rmWin', visible: true},
                    {name: 'rmHold', visible: true},
                    {name: 'bmStake', visible: true},
                    {name: 'bmWin', visible: true},
                    {name: 'bmHold', visible: true},
                    {name: 'fmStake', visible: true},
                    {name: 'fmWin', visible: true},
                    {name: 'fmHold', visible: true},
                    {name: 'casinoFreeRoundsReason', visible: true},
                    {name: 'casinoFreeRoundsStake', visible: true},
                    {name: 'casinoFreeRoundsWin', visible: true},
                    {name: 'casinoFreeRoundsHold', visible: true},
                    {name: 'casinoFreeRoundsDone', visible: true},
                    {name: 'gsFreeRoundsStake', visible: true},
                    {name: 'gsFreeRoundsWin', visible: true},
                    {name: 'gsFreeRoundsHold', visible: true},
                    {name: 'gsFreeRoundsDone', visible: true},
                    {name: 'gsBonusesConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToBonus', visible: true}
                ]
            },
            gameRoundBreakdownConfig: {
                columns: [
                    {name: 'playerId', visible: true},
                    {name: 'gameId', visible: true},
                    {name: 'gameName', visible: true},
                    {name: 'platform', visible: true},
                    {name: 'state', visible: true},
                    {name: 'dateStarted', visible: true},
                    {name: 'dateFundsAllocated', visible: true},
                    {name: 'dateAccounted', visible: true},
                    {name: 'dateFinished', visible: true},
                    {name: 'moneyType', visible: true},
                    {name: 'stake', visible: true},
                    {name: 'win', visible: true},
                    {name: 'features', visible: true},
                    {name: 'currency', visible: true},
                    {name: 'conversionRateToSystemBase', visible: true},
                    {name: 'moneyBreakdown', visible: true},
                    {name: 'gsBonusesConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToBonus', visible: true}
                ]
            },
            gameRoundConfig: {
                columns: [
                    {name: 'playerId', visible: true},
                    {name: 'gameId', visible: true},
                    {name: 'gameName', visible: true},
                    {name: 'platform', visible: true},
                    {name: 'state', visible: true},
                    {name: 'dateStarted', visible: true},
                    {name: 'dateFundsAllocated', visible: true},
                    {name: 'dateAccounted', visible: true},
                    {name: 'dateFinished', visible: true},
                    {name: 'moneyType', visible: true},
                    {name: 'stake', visible: true},
                    {name: 'win', visible: true},
                    {name: 'currency', visible: true},
                    {name: 'features', visible: true},
                    {name: 'conversionRateToSystemBase', visible: true},
                    {name: 'moneyBreakdown', visible: true},
                    {name: 'gsBonusesConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToRealMoney', visible: true},
                    {name: 'freeRoundsConvertedToBonus', visible: true}
                ]
            }
        };

        var GAME_ROUND_COLUMNS = 'GAME_ROUND_COLUMNS';
        var GAME_SESSION_COLUMNS = 'GAME_SESSION_COLUMNS';
        var GAME_ROUND_BREAKDOWN_COLUMNS = 'GAME_ROUND_BREAKDOWN_COLUMNS';

        if (!$scope.DEBUG) {
            userSettingsService.get(GAME_ROUND_COLUMNS, function(columns) {
                if (columns) {
                    $scope.page.tables.gameRoundConfig.columns = columns;
                } else {
                    userSettingsService.put(
                        GAME_ROUND_COLUMNS,
                        $scope.page.tables.gameRoundConfig.columns
                    );
                }
            });

            userSettingsService.get(GAME_SESSION_COLUMNS, function(columns) {
                if (columns) {
                    $scope.page.tables.gameSessionConfig.columns = columns;
                } else {
                    userSettingsService.put(
                        GAME_SESSION_COLUMNS,
                        $scope.page.tables.gameSessionConfig.columns
                    );
                }
            });

            userSettingsService.get(GAME_ROUND_BREAKDOWN_COLUMNS, function(columns) {
                if (columns) {
                    $scope.page.tables.gameRoundBreakdownConfig.columns = columns;
                } else {
                    userSettingsService.put(
                        GAME_ROUND_BREAKDOWN_COLUMNS,
                        $scope.page.tables.gameRoundBreakdownConfig.columns
                    );
                }
            });
        }

        $scope.onRoundColumnsChange = function() {
            userSettingsService.put(
                GAME_ROUND_COLUMNS,
                $scope.page.tables.gameRoundConfig.columns
            );
        };

        $scope.onSessionColumnsChange = function() {
            userSettingsService.put(
                GAME_SESSION_COLUMNS,
                $scope.page.tables.gameSessionConfig.columns
            );
        };

        $scope.onRoundBreakdownColumnsChange = function() {
            userSettingsService.put(
                GAME_ROUND_BREAKDOWN_COLUMNS,
                $scope.page.tables.gameRoundBreakdownConfig.columns
            );
        };

    }
]);

app.controller('GameSessionCasinoDropdownCtrl', ['$scope', 'requestService',
    function($scope, requestService) {

        init();

        function init() {

            $scope.status = {
                isopen: false
            };

            $scope.toggled = function(open) {
                $scope.onCasinoList();
            };

            $scope.toggleDropdown = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.status.isopen = !$scope.status.isopen;
            };

            $scope.onCasinoList = function() {
                requestCasinoList();
            };
        }

        function requestCasinoList() {
            requestService.get(
                '/api/game-session/casino-list',
                function(data) {
                    $scope.casinoList = data;
                }
            );
        }

    }]);

app.controller('CasinoGameDropdownCtrl', ['$scope', 'requestService', '$filter',
    function($scope, requestService, $filter) {

        init();

        function init() {
            $scope.list = [];
            $scope.$watch('page.selectedCasinoId', onCasinoChange);
        }

        function onCasinoChange() {
            if ($scope.page.selectedCasinoId) {
                requestService.get(
                    '/api/game-session/casino-game-list?casinoId=' + $scope.page.selectedCasinoId,
                    function(data) {
                        data.sort(function(a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        });
                        $scope.list = data;

                        // if gameId no longer available => clear
                        var filtered = $filter('filter')(data, {id: $scope.tab.formData.gameId}, true);
                        if (filtered.length === 0) {
                            $scope.tab.formData.gameId = null;
                        }
                    }
                )
            }
        }

    }
]);

app.factory('urlParamsService', ['$state', '$timeout', '$location',
    function($state, $timeout, $location) {

        var service = {};

        service.getParam = getParam;
        service.getParams = getParams;

        service.setParam = setParam;
        service.setParams = setParams;

        service.hasAnyParams = hasAnyParams;
        service.hasParams = hasParams;
        service.hasParam = hasParam;

        return service;

        function getParam(key) {
            // Get
            var search = $location.search();
            return (search && search[key]) || undefined;
        }

        function getParams() {
            return angular.copy($location.search());
        }

        /**
         * @param params [{key: value}, ...]
         */
        function setParams(params) {
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    // Put
                    setParam(key, params[key]);
                }
            }
        }

        function setParam(key, value) {
            if (!value ||
                (angular.isString(value) && value.indexOf('/') !== -1)
            ) {
                console.log('ignored', value);
                // Ignore
                // Angular has problems with slashes in url, can not encode/decode it properly
            } else {
                $state.current.reloadOnSearch = false;

                // Put
                $location.search(key, value);

                $timeout(function() {
                    $state.current.reloadOnSearch = undefined;
                });
            }
        }

        function hasAnyParams() {
            return Object.keys(getParams()).length > 0;
        }

        /**
         *
         * @param params - array of param keys
         * @returns {boolean}
         */
        function hasParams(params) {
            if (hasAnyParams()) {
                var hasAllParams = true;
                angular.forEach(params, function(param) {
                    if (!hasParam(param)) {
                        hasAllParams = false;
                    }
                });
                return hasAllParams;
            } else {
                return false;
            }
        }

        function hasParam(param) {
            return angular.isDefined(getParam(param));
        }
    }
]);
