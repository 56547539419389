app.filter('toArrayFilter', function() {

    return function(listOfObjects, prop) {
        listOfObjects = listOfObjects || [];
        var result = [];

        for (var i = 0, len = listOfObjects.length; i < len; i++) {
            if (listOfObjects[i].hasOwnProperty(prop)) {
                result.push(listOfObjects[i][prop]);
            }
        }
        return result;
    };

});

app.filter('toListOfObjectsFilter', function() {

    return function(listOfPrimitives, prop) {
        listOfPrimitives = listOfPrimitives || [];
        var result = [];

        for (var i = 0, len = listOfPrimitives.length; i < len; i++) {
            var item = {};
            item[prop] = listOfPrimitives[i];
            result.push(item);
        }
        return result;
    };

});

app.filter('startsWith', function() {

    return function(object, prop) {
        object = object || {};
        var result = [];

        for (var attr in object) {
            var lowerStr = (attr + '').toLowerCase();
            if (lowerStr.indexOf(prop.toLowerCase()) === 0) {
                result.push({name: attr, value: object[attr]});
            }
        }
        return result;
    };

});

app.filter('applyFilter', ['$filter', '$log', function($filter, $log) {
    return function(value, filterSpec) {
        if (filterSpec) {
            var args = filterSpec.split(':');
            var filter = $filter(args.shift());
            args.unshift(value);
            return filter.apply(null, args);
        } else {
            return value;
        }
    };
}]);

app.filter('dateFilterShort', ['$filter', function($filter) {
    return function(value) {
        return $filter('date')(value, 'shortDate');
    };
}]);

app.filter('dateFilterShortFull', ['$filter', function($filter) {
    return function(value) {
        return $filter('date')(value, 'MM/dd/yyyy');
    };
}]);

app.filter('dateLongTimeShortFilter', ['$filter', function($filter) {
    return function(value) {
        return $filter('date')(value, 'MM/dd/yyyy HH:mm');
    };
}]);

app.filter('dateFilterLong', ['$filter', function($filter) {
    return function(value) {
        return $filter('date')(value, 'MM/dd/yyyy HH:mm:ss');
    };
}]);

app.filter('timeFilterShort', ['$filter', function($filter) {
    return function(value) {
        return $filter('date')(value, 'HH:mm');
    };
}]);

app.filter('booleanToHuman', ['$translate', function($translate) {
    return function(value) {
        if (value) {
            return $translate.instant('GENERAL.FILTERS.BOOLEAN_TO_HUMAN.YES');
        } else {
            return $translate.instant('GENERAL.FILTERS.BOOLEAN_TO_HUMAN.NO');
        }
    };
}]);

app.filter('filterSelected', function() {
    return function(availableList, selectedArray, prop) {
        if (!availableList.length > 0 || !selectedArray || !selectedArray.length > 0) {
            return availableList;
        }
        var result = [];
        for (var i = 0, len = selectedArray.length; i < len; i++) {
            for (var j = 0, len2 = availableList.length; j < len2; j++) {
                if (availableList[j][prop] == selectedArray[i]) {
                    result.push(availableList[j]);
                }
            }
        }
        return result;
    }
});

app.filter('camelCaseToHuman', function() {
    return function(input) {
        if (typeof input !== "string") {
            return input;
        }

        return input.match(/^[a-z]+|[A-Z][a-z]*/g).map(function(x) {
            return x[0].toUpperCase() + x.substr(1).toLowerCase();
        }).join(' ');
    };
});

app.filter('genderFilter', ['$translate', function($translate) {
    return function(input) {
        if (input === true) {
            return $translate.instant('GENERAL.FILTERS.GENDER.MEN');
        }
        if (input === false) {
            return $translate.instant('GENERAL.FILTERS.GENDER.WOMEN');
        }
        return '';
    }
}]);

app.filter('genderAbbreviation', ['$translate', function($translate) {
    return function(input) {
        if (input === 'M') {
            return $translate.instant('GENERAL.FILTERS.GENDER_ABBREVIATION.MALE');
        }
        if (input === 'W') {
            return $translate.instant('GENERAL.FILTERS.GENDER_ABBREVIATION.FEMALE');
        }
        return input;
    }
}]);

app.filter('percentage', ['$filter', function($filter) {
    return function(input, decimals) {
        return $filter('number')(input * 100, decimals) + '%';
    };
}]);

app.filter('showValuesInTextarea', function() {
    return function(input, key) {
        var result = "";
        for (var i = 0; i < input.length; i++) {
            result += input[i][key];
            if (i != input.length - 1) {
                result += ", ";
            }
        }
        return result;
    }
});

app.filter('alarmSource', ['ALARM_CONSTANTS', function(ALARM_CONSTANTS) {
    return function(input) {
        return ALARM_CONSTANTS[input];
    }
}]);

app.filter('rowsLimit', function(){
    return function(input, maxRows, property) {
        if (input.length > maxRows) {
            return input.length;
        }
        var result = "";
        for (var i = 0; i < input.length; i++) {
            if (property) {
                result += input[i][property];
            } else {
                result += input[i];
            }
            if (i != input.length - 1) {
                result += '<br />';
            }
        }
        return result;
    }
});

app.filter('ellipsis', ['GLOBAL_CONSTANTS', function(GLOBAL_CONSTANTS) {
    return function(input) {
        var ellipsisLength = GLOBAL_CONSTANTS.ELLIPSIS_LENGTH;
        if (input.length > ellipsisLength) {
            return input.substring(0, ellipsisLength) + "...";
        }
        return input;
    }
}]);

app.filter('versionsFilter', function() {
    return function(input) {
        var versions = [];
        for (var i = 0; i < input.length; i++) {
            if (versions.indexOf(input[i]) === -1) {
                versions.push(input[i]);
            }
        }
        if (versions.length > 1) {
            return input.join(', ');
        }
        return versions.toString();
    }
});
