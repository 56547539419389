app.controller('AlarmSettingsSmsCtrl', ['$scope', 'requestService', 'ERROR_CODES',
    function($scope, requestService, ERROR_CODES) {
        init();

        function init() {
            $scope.smsTemplates = [];
            getSmsTemplates();
        }

        $scope.smsTabs = [
            {name: 'EMA_RTP', route: 'alarm-settings.sms.ema'},
            {name: 'HIGH_WIN', route: 'alarm-settings.sms.hw'}
        ];

        $scope.getSmsTemplates = getSmsTemplates();

        $scope.startSmsEditing = function(data) {
            data.original = angular.copy(data);
            data.editing = true;
        };

        $scope.cancelSmsEditing = function(data) {
            data.message = data.original.message;
            delete data.original;
            delete data.editing;
            delete data.formErrors;
        };

        $scope.updateSmsTempalte = function(alarmType) {
            requestService.putEncoded(
                '/api/alarm-settings/update-sms-template/' + alarmType.id,
                alarmType,
                function(response) {
                    if (response.status.code === 'OK') {
                        delete alarmType.original;
                        delete alarmType.editing;
                        delete alarmType.formErrors;
                    } else if (response.status.code === 'VALIDATION_FAILED') {
                        alarmType.formErrors = response.status.formErrors;
                    }
                }
            );
        };

        //$scope.textareaKeypress = smsCalculation;
        $scope.smsCalculation = smsCalculation;

        function smsCalculation(index, data) {
            var element = $('#textarea-' + index);
            data.textLength = element.val().length;
            data.amountOfSms = Math.ceil(data.textLength / 160);
        }

        function getSmsTemplates() {
            requestService.get(
                '/api/alarm-settings/get-all-sms-templates',
                function(response) {
                    if (response.status.code === ERROR_CODES.OK) {
                        $scope.smsTemplates = response.list;
                    } else {
                        $log.error(response.status);
                    }
                }
            );
        }
    }
]);
